<!-- ********** TEMPLATES ********** -->
<!-- Brand, Product Description, Product Number, Sales Pack Size -->
<ng-template #info>
  <div data-cy="quick-view-product-info-section" class="quick-view-main-info">
    <p data-cy="quick-view-product-brand-text" class="quick-view-reg-txt">
      {{ (product?.summary?.brand | acronymTitleCase) || '' }}
    </p>
    <p
      data-cy="quick-view-product-description-text"
      class="quick-view-bold-txt"
    >
      {{ (product?.summary?.productDescTxtl | acronymTitleCase) || ' ' }}
    </p>
    <div
      data-cy="quick-view-product-other-info-section"
      class="quick-view-other-info"
    >
      <p data-cy="quick-view-product-number-text">
        #{{ product?.summary?.productNumber || ' ' }}
      </p>
      <span data-cy="quick-view-product-info-separator-text">|</span>
      <p data-cy="quick-view-product-sales-pack-size-text">
        {{ product?.summary?.salesPackSize }}
      </p>
      <span
        *ngIf="product?.item?.customerProductNumber"
        data-cy="quick-view-product-info-separator-text"
      >
        |
      </span>
      <p
        *ngIf="product?.item?.customerProductNumber"
        data-cy="quick-view-product-customer-number-text"
      >
        {{ 'i18n.lists.custProductNo' | translate }}
        {{ product?.item?.customerProductNumber }}
      </p>
    </div>
  </div>
</ng-template>

<!-- Product Tags -->
<ng-template #tags>
  <div class="quick-view-product-tags">
    <app-usf-product-tag-list [tagList]="productTags">
    </app-usf-product-tag-list>
  </div>
</ng-template>

<!-- Cases -->
<ng-template #pricingCases>
  <div data-cy="quick-view-product-cases-section" class="quick-view-cases">
    <app-product-inventory-info
      [productInventory]="product?.inventory"
      [productProperties]="product?.summary?.properties"
      [productSummary]="product?.summary"
      [hideOutOfStock]="product?.hideOutOfStock"
    ></app-product-inventory-info>
    <app-quantity-input
      [productNumber]="product?.productNumber"
      [quantity]="casesOrdered"
      [isDisabled]="!product?.orderable"
      [isOffline]="false === (panAppState.online$ | async)"
      [isEaches]="false"
      [platform]="platformService?.platformType"
      (quantityUpdateEmitter)="changeQuantityHandler($event)"
    >
    </app-quantity-input>
    <span
      *ngIf="!pricingDisabled && product?.pricing?.unitPrice * 1 > 0"
      data-cy="quick-view-product-unit-price-text"
    >
      {{ (product?.pricing?.unitPrice | currency) || 'loading..' }}
      {{ product?.summary?.priceUom || 'CS' }}
    </span>
    <span
      *ngIf="pricingDisabled || product?.pricing?.unitPrice * 1 === 0"
      data-cy="quick-view-product-unit-price-text"
    >
      {{ 'i18n.lists.noPrice' | translate }}
    </span>
  </div>
</ng-template>

<!-- Eaches -->
<ng-template #pricingEaches>
  <div data-cy="quick-view-product-cases-section" class="quick-view-eaches">
    <app-product-inventory-info
      [productInventory]="product?.inventory"
      [productProperties]="product?.summary?.properties"
      [productSummary]="product?.summary"
      [hideOutOfStock]="product?.hideOutOfStock"
      eaches
    ></app-product-inventory-info>
    <app-quantity-input
      [productNumber]="product?.productNumber"
      [quantity]="eachesOrdered"
      [isDisabled]="!product?.orderable"
      [isOffline]="false === (panAppState.online$ | async)"
      [isEaches]="true"
      [platform]="platformService?.platformType"
      (quantityUpdateEmitter)="changeQuantityHandler($event)"
    >
    </app-quantity-input>
    <span
      *ngIf="!pricingDisabled && product?.pricing?.eachPrice * 1 > 0"
      data-cy="quick-view-product-eaches-price-text"
    >
      {{ (product?.pricing?.eachPrice | currency) || 'loading..' }}
      {{
        (product?.summary?.catchWeightFlag
          ? '/' + product?.summary?.priceUom
          : product?.summary?.eachUom) || 'EA'
      }}
    </span>
    <span
      *ngIf="pricingDisabled || product?.pricing?.eachPrice * 1 === 0"
      data-cy="quick-view-product-eaches-price-text"
    >
      {{ 'i18n.lists.noPrice' | translate }}
    </span>
  </div>
</ng-template>

<!-- Add to List -->
<ng-template #addToList>
  <ng-container>
    <ion-button
      data-cy="quick-view-add-to-list-button"
      class="usf-fill-green-button"
      (click)="openAddToListModal()"
    >
      {{ 'i18n.lists.addToList' | translate }}
    </ion-button>
  </ng-container>
</ng-template>

<!-- Additional Description -->
<ng-template #description>
  <div>
    <span data-cy="quick-view-description-label" class="quick-view-bold-txt">
      {{ 'i18n.productDetail.description' | translate }}
    </span>
    <p
      data-cy="quick-view-description-text"
      class="quick-view-paragraph-txt"
      [class.extended-description]="disableNotes"
    >
      {{ productDetail?.detail?.additionalDescription }}
    </p>
  </div>
</ng-template>

<!-- Product Note -->
<ng-template #note>
  <div>
    <span data-cy="quick-view-product-notes-label" class="quick-view-bold-txt">
      {{ 'i18n.lists.productNotes' | translate }}
    </span>
    <p data-cy="quick-view-product-notes-text" class="quick-view-paragraph-txt">
      {{ product?.item?.note }}
    </p>
  </div>
</ng-template>

<!-- Ingredients -->
<ng-template #ingredients>
  <div>
    <p data-cy="quick-view-ingredients-label" class="quick-view-bold-txt">
      {{ 'i18n.productDetail.ingredients' | translate }}
    </p>
    <p data-cy="quick-view-ingredients-text" class="quick-view-paragraph-txt">
      {{ productDetail?.detail?.ingredients }}
    </p>
  </div>
</ng-template>

<!-- Claims Tags -->
<ng-template #claimsTags>
  <div class="quick-view-claims-tags">
    <app-usf-product-tag-list [tagList]="claimTags"></app-usf-product-tag-list>
  </div>
</ng-template>

<div
  id="quick-view-info"
  [class.split-card]="!isOriginalProduct"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'tablet'
        : 'mobile'
      : 'desktop'
  "
>
  <ion-grid>
    <ion-row
      *ngIf="
        platformService?.platformType !== platformService?.platformEnum?.mobile;
        else mobileProductInfo
      "
    >
      <!-- ********** LEFT SIDE ********** -->
      <ion-col size="5.875">
        <!-- Product Images -->
        <ion-row>
          <div class="quick-view-product-image-viewer">
            <app-product-detail-viewer
              [product]="product?.summary"
              [isQuickView]="true"
            ></app-product-detail-viewer>
          </div>
        </ion-row>
        <!-- Ingredients -->
        <ion-row
          *ngIf="productDetail?.detail?.ingredients"
          class="quick-view-content-row"
        >
          <ng-container [ngTemplateOutlet]="ingredients"></ng-container>
        </ion-row>
        <!-- Claims Tags -->
        <ion-row *ngIf="claimTags?.length" class="quick-view-content-row">
          <ng-container [ngTemplateOutlet]="claimsTags"></ng-container>
        </ion-row>
        <!-- More Details -->
        <ion-row class="quick-view-content-row">
          <div
            data-cy="quick-view-more-details-button"
            class="quick-view-more-details"
            (click)="goToProductDetail(product?.productNumber)"
          >
            <span>
              {{ 'i18n.productDetail.productMoreDetails' | translate }}
            </span>
          </div>
        </ion-row>
      </ion-col>

      <!-- ********** RIGHT SIDE ********** -->
      <ion-col offset="0.25" size="5.875">
        <!-- Brand, Product Description, Product Number, Sales Pack Size -->
        <ion-row>
          <ng-container [ngTemplateOutlet]="info"></ng-container>
        </ion-row>
        <!-- Tags -->
        <ion-row *ngIf="productTags?.length" class="quick-view-content-row">
          <ng-container [ngTemplateOutlet]="tags"></ng-container>
        </ion-row>
        <!-- Quick View Banner -->
        <ion-row *ngIf="product?.quickViewWarning?.message">
          <app-usf-product-quick-view-warning
            *ngIf="isOriginalProduct"
            [quickViewWarning]="product?.quickViewWarning"
          ></app-usf-product-quick-view-warning>
        </ion-row>
        <!-- Promotions -->
        <ion-row
          *ngIf="product?.promotionDetails"
          class="quick-view-content-row"
        >
          <div
            data-cy="quick-view-promotions-section"
            class="usf-product-card-promotions"
          >
            <span data-cy="quick-view-promotions-offer-description-text">
              {{ product?.promotionDetails?.offerBenefitDesc }}
            </span>
          </div>
        </ion-row>
        <!-- Pricing -->
        <ion-row class="quick-view-content-row">
          <div class="quick-view-pricing">
            <!-- Cases -->
            <ng-container [ngTemplateOutlet]="pricingCases"></ng-container>
            <!-- Eaches -->
            <ng-container
              *ngIf="product?.summary?.breakable"
              [ngTemplateOutlet]="pricingEaches"
            ></ng-container>
          </div>
        </ion-row>
        <ion-row class="quick-view-content-row">
          <ion-col size="6">
            <ng-container *ngIf="showSearchForSubs">
              <ion-button
                class="usf-fill-green-button"
                data-cy="quick-view-search-for-replacement-or-subs-button"
                (click)="searchReplacement()"
              >
                {{
                  (product?.summary?.properties?.has(
                    propertiesEnum.discontinued
                  )
                    ? 'i18n.productDetail.searchForReplacement'
                    : 'i18n.productDetail.searchForSubstitute'
                  ) | translate
                }}
              </ion-button>
            </ng-container>
          </ion-col>
        </ion-row>
        <!-- Add to List -->
        <ion-row
          *ngIf="
            product?.inventory?.isInStock &&
            !product?.summary?.properties?.has(propertiesEnum.discontinued)
          "
          class="quick-view-content-row"
        >
          <ng-container [ngTemplateOutlet]="addToList"></ng-container>
        </ion-row>
        <!-- Description -->
        <ion-row
          *ngIf="productDetail?.detail?.additionalDescription"
          class="quick-view-content-row"
        >
          <ng-container [ngTemplateOutlet]="description"></ng-container>
        </ion-row>
        <!-- Product Note -->
        <ion-row *ngIf="product?.item?.note" class="quick-view-content-row">
          <ng-container [ngTemplateOutlet]="note"></ng-container>
        </ion-row>
      </ion-col>
    </ion-row>

    <ng-template #mobileProductInfo>
      <!-- Product Images -->
      <ion-row>
        <div class="quick-view-product-image-viewer">
          <app-product-detail-viewer
            [product]="product?.summary"
            [isQuickView]="true"
          ></app-product-detail-viewer>
        </div>
      </ion-row>
      <!-- Brand, Product Description, Product Number, Sales Pack Size -->
      <ion-row class="quick-view-content-row">
        <ng-container [ngTemplateOutlet]="info"></ng-container>
      </ion-row>
      <!-- Tags -->
      <ion-row *ngIf="productTags?.length" class="quick-view-content-row">
        <ng-container [ngTemplateOutlet]="tags"></ng-container>
      </ion-row>
      <!-- Quick View Banner -->
      <ion-row *ngIf="product?.quickViewWarning?.message">
        <app-usf-product-quick-view-warning
          *ngIf="isOriginalProduct"
          [quickViewWarning]="product?.quickViewWarning"
        ></app-usf-product-quick-view-warning>
      </ion-row>
      <!-- Promotions -->
      <ion-row *ngIf="product?.promotionDetails" class="quick-view-content-row">
        <div
          data-cy="quick-view-promotions-section"
          class="usf-product-card-promotions"
        >
          <span data-cy="quick-view-promotions-offer-description-text">
            {{ product?.promotionDetails?.offerBenefitDesc }}
          </span>
        </div>
      </ion-row>
      <!-- Pricing -->
      <ion-row class="quick-view-content-row">
        <div class="quick-view-pricing">
          <!-- Cases -->
          <ng-container [ngTemplateOutlet]="pricingCases"></ng-container>
          <!-- Eaches -->
          <ng-container
            *ngIf="product?.summary?.breakable"
            [ngTemplateOutlet]="pricingEaches"
          ></ng-container>
        </div>
      </ion-row>
      <!-- Search sub/replacement-->
      <ion-row class="quick-view-content-row">
        <ion-col>
          <ng-container *ngIf="showSearchForSubs">
            <ion-button
              class="usf-fill-green-button"
              data-cy="quick-view-search-for-replacement-or-subs-button"
              (click)="searchReplacement()"
            >
              {{
                (product?.summary?.properties?.has(propertiesEnum.discontinued)
                  ? 'i18n.productDetail.searchForReplacement'
                  : 'i18n.productDetail.searchForSubstitute'
                ) | translate
              }}
            </ion-button>
          </ng-container>
        </ion-col>
      </ion-row>
      <!-- Add to List -->
      <ion-row
        *ngIf="
          product?.inventory?.isInStock &&
          !product?.summary?.properties?.has(propertiesEnum.discontinued)
        "
        class="quick-view-content-row"
      >
        <ng-container [ngTemplateOutlet]="addToList"></ng-container>
      </ion-row>
      <!-- Claims Tags -->
      <ion-row *ngIf="claimTags?.length" class="quick-view-content-row">
        <ng-container [ngTemplateOutlet]="claimsTags"></ng-container>
      </ion-row>
      <!-- Ingredients -->
      <ion-row
        *ngIf="productDetail?.detail?.ingredients"
        class="quick-view-content-row"
      >
        <ng-container [ngTemplateOutlet]="ingredients"></ng-container>
      </ion-row>
      <!--  Description -->
      <ion-row
        *ngIf="productDetail?.detail?.additionalDescription"
        class="quick-view-content-row"
      >
        <ng-container [ngTemplateOutlet]="description"></ng-container>
      </ion-row>
      <!-- Product Note -->
      <ion-row *ngIf="product?.item?.note" class="quick-view-content-row">
        <ng-container [ngTemplateOutlet]="note"></ng-container>
      </ion-row>
      <!-- More Details -->
      <ion-row class="quick-view-content-row">
        <div
          data-cy="quick-view-more-details-button"
          class="quick-view-more-details"
          (click)="goToProductDetail(product?.productNumber)"
        >
          <span>
            {{ 'i18n.productDetail.productMoreDetails' | translate }}
          </span>
        </div>
      </ion-row>
    </ng-template>
  </ion-grid>
</div>
