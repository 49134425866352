import { Injectable } from '@angular/core';
import { InventoryHotkeyService } from './inventory-hotkey.service';
import { ListHotkeyService } from './list-hotkey.service';
import { HotKeys } from '../../constants/hot-key.enum';
import { MerchHotkeyService } from './merch-hotkey.service';
import { SELLER_QUICKVIEW_URLS } from '@shared/constants/hot-key.constants';

@Injectable({
  providedIn: 'root',
})
export class HotKeyService {
  constructor(
    private listHotkeyService: ListHotkeyService,
    private inventoryHotkeyService: InventoryHotkeyService,
    private merchHotkeyService: MerchHotkeyService,
  ) {}

  onKeyEvent(event: KeyboardEvent, url: string) {
    const ionicElementToFocus = this.getIonicElementToFocusInto(event);
    if (
      event.code === HotKeys.sellerQuickViewKey &&
      this.isMerchandisingHotkey(url)
    ) {
      this.merchHotkeyService.onKeyEvent(event, ionicElementToFocus);
    } else if (this.isListHotkey(url)) {
      this.listHotkeyService.onKeyEvent(event, ionicElementToFocus);
    } else if (this.isInventoryHotkey(url)) {
      this.inventoryHotkeyService.onKeyEvent(event, ionicElementToFocus);
    }
  }

  isListHotkey(url: string) {
    return (
      (url.includes('lists') && url.includes('detail')) ||
      url.includes('recentlyPurchased') ||
      url.includes('masterList')
    );
  }

  isInventoryHotkey(url: string) {
    return url.includes('inventory-worksheet');
  }
  isMerchandisingHotkey(url: string) {
    return SELLER_QUICKVIEW_URLS.some(sellerUrl => url.includes(sellerUrl));
  }

  private getIonicElementToFocusInto(event: KeyboardEvent): HTMLElement {
    let element: HTMLElement;
    const target = event?.target as HTMLElement;
    let parent = target?.offsetParent as HTMLElement;
    for (let i = 0; i < 3; i++) {
      if (parent?.hasAttribute(HotKeys.globalKeyHandler)) {
        element = parent;
        break;
      } else {
        parent = parent?.offsetParent as HTMLElement;
      }
    }
    return element;
  }
}
