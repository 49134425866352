import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { ShippingChargeEnum } from '@product-detail/models/shipping-charge.enum';
import { ProductPriceUtil } from '@product-detail/utils/product-price.util';
import { KeyValue } from '@shared/models/key-value.model';
import { FormatCurrencyAndDecimalPipe } from '@shared/pipes/format-currency-and-decimal.pipe';

import { SellerPriceDetails } from '@usf/price-types';

@Component({
  selector: 'app-seller-detail-description-pricing',
  templateUrl: './seller-detail-description-pricing.component.html',
  styleUrls: ['./seller-detail-description-pricing.component.scss'],
})
export class SellerDetailDescriptionPricingComponent implements OnChanges {
  @Input() sellerPriceDetail: SellerPriceDetails;
  @Input() product: ProductDetailViewModel;

  minimum: number;
  target: number;
  maximum: number;

  pricingDetailsTwo: KeyValue[] = [];
  shippingEnum = ShippingChargeEnum;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sellerPriceDetail?.currentValue) {
      this.updateFirstPricingDetails();
      this.updateSecondPricingDetails();
    }
  }

  updateFirstPricingDetails() {
    const { maximum, minimum, target } = ProductPriceUtil.getMaxMinTargetPrices(
      this.sellerPriceDetail,
    );
    this.maximum = maximum;
    this.minimum = minimum;
    this.target = target;
  }

  updateSecondPricingDetails(): void {
    const {
      currentPrice: {
        autoShippingCharges,
        grossProfitPercent,
        grossProfitDollars,
      },
      customerAscInd,
    } = this.sellerPriceDetail;

    const applyFlag = autoShippingCharges?.ascApplyFlag;
    const customerNumberAscInd =
      customerAscInd === this.shippingEnum.included ||
      customerAscInd === this.shippingEnum.notIncluded;
    const ascCharge =
      applyFlag === this.shippingEnum.included ||
      applyFlag === this.shippingEnum.notIncluded;

    const decimalPipe = new FormatCurrencyAndDecimalPipe();

    this.pricingDetailsTwo = [
      {
        keyLabel: 'GPP',
        value: `${grossProfitPercent}%`,
      },
      {
        keyLabel: 'GPD',
        value: `${decimalPipe.transform(grossProfitDollars)}`,
      },
      {
        keyLabel: 'Asc Ind',
        value: customerNumberAscInd ? applyFlag : this.shippingEnum.noCharge,
      },
      {
        keyLabel: 'Asc Charge',
        value:
          ascCharge && customerNumberAscInd
            ? `${decimalPipe.transform(autoShippingCharges?.unitAscRate)}`
            : '',
      },
    ].filter(Boolean);
    this.sellerPriceDetail?.districtNgpCprInd === 'Y' &&
      this.pricingDetailsTwo.splice(2, 1);
  }
}
