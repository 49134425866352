import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '@panamax/app-state';
import { LastBuy } from '@product-detail/models/last-buy.model';

@Component({
  selector: 'app-seller-detail-more-information-last-four-buys',
  templateUrl: './seller-detail-more-information-last-four-buys.component.html',
  styleUrls: ['./seller-detail-more-information-last-four-buys.component.scss'],
})
export class SellerDetailMoreInformationLastFourBuysComponent
  implements OnInit, OnChanges
{
  @Input() last4ProductsDataSource: LastBuy[];

  last4ProductsColumns: string[];
  last4ProductsHeaderConfig: { title: string; cellReference: string }[];

  constructor(
    readonly platformService: PlatformService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.loadLastFourBuysTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadLastFourBuysTable();
  }

  loadLastFourBuysTable() {
    if (
      this.platformService?.platformType !==
        this.platformService?.platformEnum?.mobile &&
      this.last4ProductsDataSource?.length > 0
    ) {
      this.last4ProductsColumns = Object.keys(this.last4ProductsDataSource[0]);
      this.last4ProductsHeaderConfig = this.last4ProductsColumns?.map(
        header => ({
          title: header === 'wwp' ? 'WWP' : this.getTranslatedHeader(header),
          cellReference: header,
        }),
      );
    }
  }

  private getTranslatedHeader(header: string) {
    return this.translateService.instant(
      `i18n.productDetail.sellerDetailMoreInformation.${header}`,
    );
  }
}
