/* eslint-disable no-shadow */
export enum SEARCH_PAGES_ENUM {
  search = 'search',
  catalog = 'catalog',
  typeAhead = 'typeAhead',
  productTypeAhead = 'productTypeAhead',
  list = 'list',
  listDetail = 'listDetail',
  deals = 'deals',
  reviewOrder = 'reviewOrder',
  pdp = 'pdp',
  topSellers = 'topSellers',
  trendingNow = 'trendingNow',
  didYouForget = 'didYouForget',
  inventoryWorksheet = 'inventoryWorksheet',
}

export enum SEARCH_PAGES_SEARCH_KEYS {
  newScoopProducts = 'newscoopproducts',
  whileSuppliesLast = 'whilesupplieslast',
}
