import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { CustomerSwitchStatus } from '@app/ngrx-customer/constants/customer-switch-status';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ORDER_ACTIONS } from '@order/actions/order-actions';
import { AddToOrderViewModel } from '@order/models/add-to-order-view-model';
import { DownloadOrdersEvent } from '@order/models/download-orders-event';
import { DyfProductInfo } from '@order/models/dyf-view-model';
import { InspiredPicksViewModel } from '@order/models/inspired-picks-view-model';
import { MerchZoneViewModel } from '@order/models/merch-zone-view-model';
import { OrderMinimumViewModel } from '@order/models/order-minimum-view-model';
import { RecentOrderTileViewModel } from '@order/models/recent-order-tile-view-model';
import { selectAddToOrderModel } from '@order/selectors/add-to-order/add-to-order.selectors';
import { selectFrequentlyBoughtTogetherProductNumbers } from '@order/selectors/frequently-bought-together/frequently-bought-together.selectors';
import { selectMerchZoneProductNumbers } from '@order/selectors/merch-zone/merch-zone.selectors';
import { selectOrderViewModel } from '@order/selectors/order-view-model.selector';
import { selectRecentOrderViewModel } from '@order/selectors/recent-order-tile-view-model-selector';
import { confirmQuantiyExceptionAnalytics } from '@order/tracking/analytics/factories/confirm-quantity-exception-analytics.factory';
import { ORDER_ANALYTICS_ACTIONS } from '@order/tracking/analytics/order-analytics.actions';
import { OrderAnalyticsMessages } from '@order/tracking/analytics/order-analytics.constants';
import { RemoveFromOrderService } from '@order/tracking/shared/services/remove-from-order.service';
import { CartTracingService } from '@order/tracking/tracing/cart/cart-tracing.service';
import {
  PanAppState,
  PlatformService,
  SelectedCustomerState,
  TokenAuthContext,
  Tracking,
  UsfTokenService,
} from '@panamax/app-state';
import { extractPoDates } from '@product-detail/utils/po-dates-util';
import { OrderStatusDetails } from '@shared/constants/order-status-title.enum';
import { PageTitle } from '@shared/constants/page-titles.enum';
import { PATHS } from '@shared/constants/paths';
import { FEATURES } from '@shared/constants/splitio-features';
import { NavigationHelperService } from '@shared/helpers/navigation.helpers.service';
import { AppStateForProduct } from '@shared/models/app-state-for-product';
import { isHideOutOfStock } from '@shared/selectors/helpers/product-info.selectors.helper';
import { SharedTrackingService } from '@shared/services/analytics/shared-tracking.service';
import { genericTracingTransformer } from '@shared/tracking/tracing/generic-tracing-transformer';
import { Customer } from '@usf/customer-types/customer';
import {
  CART_SELECTORS,
  CartState,
  DeliveryStoreService,
  DirectShippingStoreService,
  MY_ORDERS_ACTIONS,
  MyOrdersStoreService,
  NEXT_DELIVERY_ACTIONS,
  NextDeliveryDate,
  Order,
  ORDER_EXCEPTION_ACTIONS,
  ORDER_ITEM_FILTER_ACTIONS,
  OrderHeader,
  orderHeaderSelectors,
  OrderItem,
  orderItemSelectors,
  OrderItemState,
  OrderItemStoreService,
  OrderStatus,
  OrderStoreService,
  partnerSelectors,
  UqeStoreService,
} from '@usf/ngrx-order';
import { UqeProductNumber } from '@usf/ngrx-order/lib/models/client/uqe-product';
import { PartnerState } from '@usf/ngrx-order/lib/models/state/partner-state';
import {
  getLoadingStateOfProducts,
  LoadingState,
  PRODUCT_ATTRIBUTE_TRACK,
} from '@usf/ngrx-product';
import { PoDate, ProductPropertiesEnum } from '@usf/product-types';
import { combineLatest, Observable, of, Subscription, timer } from 'rxjs';
import {
  debounceTime,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { LoginService } from 'src/app/login/services/login.service';
import { CustomerStoreService } from 'src/app/ngrx-customer/services/customer/customer-store.service';
import { MessageTypeEnum } from 'src/app/ngrx-message/constants/messageTypeEnum';
import { Message } from 'src/app/ngrx-message/models/client/message';
import { MessageStoreService } from 'src/app/ngrx-message/services/message/message-store.service';
import { ProductAlternative } from 'src/app/product-detail/models/product-alternative';
import { ProductAlternativeSearchService } from 'src/app/product-detail/services/product-alternative-search.service';
import { ProductDetailTagsService } from 'src/app/product-detail/services/product-detail-tags.service';
import { productIsInStatus } from 'src/app/product-detail/utils/product-summary.utils';
import { TagProduct } from 'src/app/shared/components/usf-product-tag-list/models/tag-product.model';
import { UsfProductCardModeEnum } from 'src/app/shared/constants/usf-product-card-mode.enum';
import {
  CustomerOrdersViewModel,
  ImportedOrdersViewModel,
  MyOrdersViewModel,
} from 'src/app/shared/models/my-orders-view.model';
import { NetworkStatus } from 'src/app/shared/models/network-status.model';
import { Product } from 'src/app/shared/models/product.model';
import {
  productSelector,
  selectProductAttributesByIds,
} from 'src/app/shared/selectors/product.selectors';
import { v4 as uuidv4 } from 'uuid';
import { ProductService } from '../../shared/services/product/product.service';
import { OrderConfirmationVM } from '../models/order-confirmation-view-model';
import { OrderViewModel, Shipment } from '../models/order-view-model';
import {
  OrderTrackingGroup,
  SubmittedOrderTallies,
  SubmittedOrderViewModel,
} from '../models/submitted-order-view-model';
import { ConvertToWillCallTracingMessages } from '../pages/order-submitted/will-call/tracing/convert-to-will-call-tracing.constants';
import { selectDYFProductNumbers } from '../selectors/dyf/dyf.selectors';
import { selectInspiredPickProductNumbers } from '../selectors/inspired-picks/inspired-picks.selectors';
import {
  selectCustomerOrdersViewModel,
  selectImportedOrdersViewModel,
  selectMyOrdersViewModel,
  selectOrderStatusOrders,
  selectOrderWithTandemNumber,
  selectRequestCreditOrders,
} from '../selectors/my-orders-view-model.selectors';
import { selectOrderConfirmationViewModel } from '../selectors/order-confirmation-view-model.selectors';
import { selectOrderItemWithProductNumber } from '../selectors/order-item-helper.selector';
import { selectOrderMinimumViewModel } from '../selectors/order-minimum/order-minimum.selectors';
import { selectOrderTotalPrice } from '../selectors/order-total-price.selectors';
import {
  getOrderTrackingGroups,
  selectSubmittedOrderViewModel,
} from '../selectors/submitted-order-view-model.selectors';
import { OrderAnalyticsService } from '../tracking/analytics/order-analytics.service';
import { ORDER_TRACING_ACTIONS } from '../tracking/tracing/order-tracing.actions';
import {
  OrderTracingMessages,
  OrderTracingOpenMethod,
} from '../tracking/tracing/order-tracing.constants';
import { OrderTracingService } from '../tracking/tracing/order-tracing.service';
import { createNewOrderTrace } from '../tracking/tracing/trace-models/create-new-order.trace';
import { OrderConversionService } from './order-conversion.service';
import { UserService } from '@app/user/services';
import { IssueOptions } from '@usf/list-types';
import { ServiceRequestFacadeService } from '@usf/ngrx-list';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private willCallErrorTimerSub: Subscription | undefined;
  networkStatus: NetworkStatus['isOnline'];
  networkStatusSub: Subscription;

  private _restrictNavigationForCurrentOrder$: Observable<boolean> =
    combineLatest([
      this.panAppState.currentUrl$,
      this.store.select(orderHeaderSelectors.selectOrderHeader),
      this.store.select(partnerSelectors.selectPartner),
    ]).pipe(
      map(([currentUrl, orderHeader, partnerState]) => {
        return (
          (orderHeader?.orderType === 'VS' &&
            !!orderHeader.tandemOrderNumber &&
            orderHeader.orderModifiable &&
            currentUrl?.endsWith('/order')) ||
          (currentUrl?.includes('/order-confirmation/') &&
            (partnerState?.siteRoleProfile?.siteRestricted ||
              partnerState?.siteRoleProfile?.exceptionRestricted)) ||
          currentUrl?.includes('/order-exceptions/') ||
          currentUrl?.includes('exceptionSubCompare') ||
          currentUrl?.includes('exceptionSearch')
        );
      }),
    );

  constructor(
    private loginService: LoginService,
    private orderStoreService: OrderStoreService,
    private customerStoreService: CustomerStoreService,
    private orderItemStoreStore: OrderItemStoreService,
    private productService: ProductService,
    private productDetailTagsService: ProductDetailTagsService,
    private router: Router,
    private messageStoreService: MessageStoreService,
    private panAppState: PanAppState,
    readonly platformService: PlatformService,
    private store: Store,
    private navigationHelperService: NavigationHelperService,
    private orderAnalyticsService: OrderAnalyticsService,
    private productAlternativeSearchService: ProductAlternativeSearchService,
    private deliveryStoreService: DeliveryStoreService,
    private orderTracing: OrderTracingService,
    private directShippingStoreService: DirectShippingStoreService,
    private uqeStoreService: UqeStoreService,
    private tokenService: UsfTokenService,
    private cartTracingService: CartTracingService,
    public modalController: ModalController,
    private myOrdersStoreService: MyOrdersStoreService,
    private translateService: TranslateService,
    private orderConversionService: OrderConversionService,
    private userService: UserService,
    public serviceRequestService: ServiceRequestFacadeService,
  ) {
    this.networkStatusSub = this.panAppState.online$.subscribe(
      status => (this.networkStatus = status),
    );
  }

  public get restrictNavigationForCurrentOrder$(): Observable<boolean> {
    return this._restrictNavigationForCurrentOrder$;
  }

  static calculateExpectedShipmentCount = (
    masterOrder: Order,
    productsMap: Map<number, Product>,
  ): number => {
    if (!masterOrder?.orderItems) return 0;
    const USF_SHIPPED = -1;
    const vendorNumbers = new Set<number>();

    for (const orderItem of masterOrder?.orderItems) {
      const product = productsMap?.get(orderItem?.productNumber);
      const isSpecialVendor = !!product?.summary?.properties?.has(
        ProductPropertiesEnum.specialVendor,
      );

      if (
        ['0', '2', '3', '4', '8', '9'].includes(
          product?.inventory?.productStatus,
        ) ||
        (product?.inventory?.productStatus === '1' && !isSpecialVendor)
      ) {
        vendorNumbers.add(USF_SHIPPED);
      } else {
        vendorNumbers.add(product?.inventory?.purchaseFromVendor);
      }
    }

    return vendorNumbers.size;
  };

  static displaySearchForSubs(
    product: Product,
    alternative?: ProductAlternative,
  ): boolean {
    return !!(
      product?.summary?.properties?.has(ProductPropertiesEnum.discontinued) ||
      product?.summary?.properties?.has(ProductPropertiesEnum.dwo) ||
      (productIsInStatus(['3', '4'], product) &&
        !product?.inventory?.isInStock) ||
      (productIsInStatus(['0', '2'], product) &&
        !product?.inventory?.isInStock) ||
      (productIsInStatus(['2'], product) &&
        alternative?.available &&
        alternative?.product?.inventory?.productStatus === '3' &&
        alternative?.product?.inventory?.isInStock) ||
      (product?.summary?.properties?.has(
        ProductPropertiesEnum.supplierOutOfStock,
      ) &&
        !alternative?.prioritizeBaseProduct)
    );
  }

  removeEmptyOrderItems() {
    this.orderItemStoreStore.removeEmptyOrderItems();
  }

  loadRequestCreditOrders$(
    keyword: string,
  ): Observable<{ myOrders: Order[]; ordersLoaded: boolean }> {
    return this.store.select(selectRequestCreditOrders(keyword));
  }

  loadMyOrdersViewModel(): Observable<MyOrdersViewModel> {
    return this.store.select(
      selectMyOrdersViewModel(this.platformService.platformType),
    );
  }

  loadOrderStatusOrders$(): Observable<Order[]> {
    return this.store.select(selectOrderStatusOrders());
  }

  loadCustomerOrdersViewModel(): Observable<CustomerOrdersViewModel> {
    return this.store.select(
      selectCustomerOrdersViewModel(this.platformService.platformType),
    );
  }

  loadImportedOrdersViewModel(): Observable<ImportedOrdersViewModel> {
    return this.store.select(
      selectImportedOrdersViewModel(this.platformService.platformType),
    );
  }

  loadRecentOrderVM(): Observable<RecentOrderTileViewModel> {
    return this.store.select(selectRecentOrderViewModel('keyword'));
  }

  loadCustomersByDivisionNumber$(
    divisionNumber: number,
  ): Observable<Customer[]> {
    return this.customerStoreService.loadCustomersByDivisionNumber$(
      divisionNumber,
    );
  }

  selectOrderWithTandemNumber(tandemNumber: number): Observable<Order> {
    return this.store.select(selectOrderWithTandemNumber(tandemNumber));
  }

  checkProductsLoaded(productNumbers: number[]): Observable<boolean> {
    return this.productService
      .selectLoadingStateOfProducts(productNumbers)
      .pipe(
        filter(loadingState => loadingState === LoadingState.loaded),
        take(1),
        map(loaded => !!loaded),
      );
  }

  switchToCustomer(customer: Customer, departmentNumber = 0) {
    this.loginService.switchToCustomer(
      customer.customerNumber,
      departmentNumber,
      customer.divisionNumber,
    );
  }

  createNewOrderAfterCustomerRelatedDataLoaded(
    customer: Customer,
    departmentNumber = '0',
  ) {
    this.store.dispatch(
      ORDER_ACTIONS.createNewOrderAfterCustomerRelatedDataLoaded({
        customer,
        departmentNumber,
      }),
    );
  }

  switchToCustomerIfNeeded(customer: Customer, departmentNumber = 0) {
    let currentCustomerNumber: number;
    let currentDepartmentNumber: number;

    this.panAppState.customer$.pipe(take(1)).subscribe(custState => {
      currentCustomerNumber = custState?.customerNumber;
      currentDepartmentNumber = custState?.departmentNumber;
    });

    if (
      customer?.customerNumber * 1 !== currentCustomerNumber * 1 ||
      (currentDepartmentNumber * 1 !== departmentNumber * 1 &&
        this.customerHasDepartment(customer, departmentNumber))
    ) {
      this.customerStoreService.setCustomerSwitchStatus(
        CustomerSwitchStatus.pending,
      );
      this.loginService.switchToCustomer(
        customer.customerNumber,
        departmentNumber,
        customer.divisionNumber,
      );
    }
  }

  isSuperUser$(): Observable<boolean> {
    return this.userService.isSuperUser$();
  }

  customerHasDepartment(customer: Customer, departmentNumber: number): boolean {
    if (departmentNumber === 0) {
      return false;
    }
    const exists = customer.departments.some(
      dept => Number(dept.departmentNumber) === departmentNumber,
    );
    return exists;
  }

  updateOrderHeader(
    orderHeader: OrderHeader,
    orderItems: OrderItem[],
    dateChange?: boolean,
  ) {
    // This client should always set decomposeFlag to true;
    orderHeader.decomposeFlag = true;

    orderHeader.requestedDeliveryDate =
      orderHeader.requestedDeliveryDate === null ||
      orderHeader.requestedDeliveryDate === undefined
        ? null
        : new Date(orderHeader.requestedDeliveryDate).toISOString();
    if (dateChange)
      orderHeader.confirmedDeliveryDate = new Date(
        orderHeader.requestedDeliveryDate,
      ).toISOString();
    // Add tracing logic for PO Number and Next Delivery Date updates
    if (dateChange) {
      this.store.dispatch(
        ORDER_ACTIONS.ChangeDeliveryDate({
          deliveryDate: orderHeader?.requestedDeliveryDate,
        }),
      );
      this.cartTracingService.traceChangeDeliveryDate(orderHeader);
    } else {
      this.cartTracingService.traceChangePO(orderHeader);
    }

    this.orderStoreService.updateOrderHeader(orderHeader, orderItems);
  }

  updateOrderHeaderWithNullDates(
    orderHeader: OrderHeader,
    orderItems: OrderItem[],
  ) {
    const updatedHeader = {
      ...orderHeader,
      decomposeFlag: true,
      requestedDeliveryDate: null,
      confirmedDeliveryDate: null,
    };
    // This client should always set decomposeFlag to true;

    this.orderStoreService.updateOrderHeader(updatedHeader, orderItems);
  }

  getOrderItemWithProductNumber$(productNumber: number): Observable<OrderItem> {
    return this.store.select(selectOrderItemWithProductNumber(productNumber));
  }

  getProductMapWithOrderItems$(
    orderItems: OrderItem[],
  ): Observable<Map<number, Product>> {
    const productNumbers = orderItems
      ?.filter(item => !!item)
      .map(item => item.productNumber);
    this.productService.loadProducts(productNumbers);
    return !!productNumbers
      ? this.productService.getProducts(productNumbers)
      : of(new Map());
  }

  getProductsForTracing$(
    orderItems: OrderItem[],
  ): Observable<Map<number, Product>> {
    const productNumbers = orderItems
      ?.filter(item => !!item)
      .map(item => item.productNumber);
    return !!productNumbers
      ? this.store.select(selectProductAttributesByIds(productNumbers))
      : of(new Map());
  }

  changeQuantity(
    orderHeader: OrderHeader,
    orderItems: OrderItem[],
    updateOrderItem: OrderItem,
    noOrderAndQty: boolean,
    trackingData?: Tracking,
    disableAddToOrderModal?: boolean,
  ) {
    let total = 0;
    let updateOrderHeader: OrderHeader = { ...orderHeader };
    let nextDelivery: NextDeliveryDate;
    this.deliveryStoreService
      .getNextDeliveryInformation()
      .pipe(take(1))
      .subscribe(nextDeliveryInfo => {
        nextDelivery = nextDeliveryInfo;
      });

    // R3 Entered orders do not have requestedDeliveryDates
    // If it's a previously submitted there will be a tandem number
    // If no requestedDeliveryDate then copy confirmedDeliveryDate
    if (!!updateOrderHeader?.tandemOrderNumber) {
      if (!updateOrderHeader.requestedDeliveryDate) {
        updateOrderHeader.requestedDeliveryDate =
          updateOrderHeader.confirmedDeliveryDate;
      }
    } else {
      // In_Progress
      if (
        !updateOrderHeader.hasOwnProperty('orderId') &&
        !updateOrderHeader.requestedDeliveryDate
      ) {
        updateOrderHeader = {
          dirtyFlag: true,
          requestedDeliveryDate: nextDelivery.deliveryDate,
          confirmedDeliveryDate: nextDelivery.deliveryDate,
        } as OrderHeader;
      }
    }
    if (typeof updateOrderHeader.requestedDeliveryDate === 'number') {
      updateOrderHeader.requestedDeliveryDate = new Date(
        updateOrderHeader.requestedDeliveryDate,
      ).toISOString();
    } else if (
      typeof updateOrderHeader.requestedDeliveryDate !== 'string' &&
      !!nextDelivery.deliveryDate
    ) {
      updateOrderHeader.requestedDeliveryDate =
        updateOrderHeader.requestedDeliveryDate?.toISOString();
    } else if (nextDelivery.deliveryDate === null) {
      updateOrderHeader.requestedDeliveryDate = null;
      updateOrderHeader.confirmedDeliveryDate = null;
    }

    // Some older in progress orders have requested but not confirmed delivery dates
    // In this case copy the requested to confirmed delivery dates
    if (
      (!updateOrderHeader.hasOwnProperty('confirmedDeliveryDate') ||
        !updateOrderHeader.confirmedDeliveryDate) &&
      updateOrderHeader.hasOwnProperty('requestedDeliveryDate') &&
      !!updateOrderHeader.requestedDeliveryDate
    ) {
      updateOrderHeader.confirmedDeliveryDate =
        updateOrderHeader.requestedDeliveryDate;
    }

    this.store
      .select(selectOrderTotalPrice(updateOrderItem))
      .pipe(take(1))
      .subscribe(orderTotal => (total = orderTotal));

    if (!updateOrderHeader.orderId) {
      this.panAppState.currentUrl$
        .pipe(
          take(1),
          map(url => this.mapURLToOpenMethod(url)),
          tap(openMethod => {
            const tracking = JSON.parse(JSON.stringify(createNewOrderTrace));
            tracking.tracing.data.isStartOfTrace = true;
            tracking.tracing.data.isEndOfTrace = false;
            tracking.tracing.transformFunc = genericTracingTransformer;
            tracking.tracing.data.attributes.order.openMethod = openMethod;
            tracking.tracing.data.attributes.order.nextDeliveryDate =
              nextDelivery.deliveryDate;
            // Dispatch the start of the create new order trace
            this.store.dispatch(
              ORDER_TRACING_ACTIONS.traceCreateNewOrderFromClient({ tracking }),
            );
          }),
        )
        .subscribe();
    }
    // Re-sequencing orderItems
    let updateOrderItemSequence;
    let origOrderItemsClone: OrderItem[] = JSON.parse(
      JSON.stringify(orderItems),
    );
    // if cart is empty then new sequence starts from 1 for new item being added
    if (!orderItems.length) {
      updateOrderItemSequence = {
        ...updateOrderItem,
        sequence: 1,
      };
    } else {
      // If old IP order items are in the cart, we check sequence for:
      // 1) If all the items have default sequence number which is 1, then we assign sequence by orderItem array's index order
      // 2) If IP order cart is mix of past items and items after sequencing, then we assign sequence to items having default sequence number by orderItem array's index order
      if (orderItems.every(item => item.sequence === 1)) {
        orderItems.forEach((item, i) => {
          origOrderItemsClone[i].sequence = i + 1;
        });
      } else if (orderItems.some(item => item.sequence === 1)) {
        orderItems.forEach((item, i) => {
          if (item.sequence === 1) {
            origOrderItemsClone[i].sequence = i + 1;
          }
        });
      }
      // Now, check it is a new item OR change quantity (except removing item) by comparing orderItem Vs updatedOrderItem
      // In case of change Qty, we find original item's sequence number and assign it to updatedOrderItem
      const itemIndex = origOrderItemsClone.findIndex(
        item => item.productNumber === updateOrderItem.productNumber,
      );
      if (itemIndex === -1) {
        // new item added to list
        updateOrderItemSequence = {
          ...updateOrderItem,
          sequence: Math.max(...orderItems.map(item => item.sequence)) + 1, //finding max to check last available sequence to avoid sequence mismatch if item deleted in between
        };
      } else {
        // matching item present(i.e change qty)
        updateOrderItemSequence = {
          ...updateOrderItem,
          sequence: origOrderItemsClone[itemIndex].sequence,
        };
      }

      //sorting by sequence number
      origOrderItemsClone.sort((a, b) => a.sequence - b.sequence);
    }

    if (noOrderAndQty && !disableAddToOrderModal) {
      if (!updateOrderHeader.customerNumber) {
        this.panAppState.customer$.pipe(take(1)).subscribe(custState => {
          updateOrderHeader.customerNumber = custState?.customerNumber;
        });
      }
      const modalData = {
        trackingData,
        updatedOrderHeader: {
          ...updateOrderHeader,
          decomposeFlag: true,
          totalDollars: total,
        },
        origOrderItemsClone,
        nextDeliveryDate: updateOrderHeader?.requestedDeliveryDate,
        updateOrderItemWithSequence: updateOrderItemSequence,
      };
      this.store.dispatch(ORDER_ACTIONS.openAddToOrderModal({ modalData }));
    } else {
      this.store
        .select(productSelector)
        .pipe(take(1))
        .subscribe(productMap => {
          this.cartTracingService.traceCartProductChangeQuantity(
            updateOrderHeader,
            origOrderItemsClone,
            updateOrderItemSequence,
            productMap,
          );

          if (RemoveFromOrderService.isItemRemoved(updateOrderItemSequence)) {
            this.orderAnalyticsService.trackRemoveFromOrder(
              trackingData,
              updateOrderHeader,
              total,
              origOrderItemsClone,
              updateOrderItemSequence,
            );
          } else if (trackingData) {
            trackingData.analytics?.data?.products[0]?.exceptionCode ===
            OrderAnalyticsMessages.uqe
              ? this.orderAnalyticsService.trackUpdateUqeQuantity(
                  trackingData,
                  updateOrderHeader,
                  total,
                  origOrderItemsClone,
                  updateOrderItemSequence,
                )
              : this.orderAnalyticsService.trackAddToOrder(
                  trackingData,
                  updateOrderHeader,
                  total,
                  origOrderItemsClone,
                  updateOrderItemSequence,
                );
          }
        });
      this.orderStoreService.updateOrder(
        { ...updateOrderHeader, decomposeFlag: true, totalDollars: total },
        origOrderItemsClone,
        updateOrderItemSequence,
      );
    }
  }

  // This is called by order-item service to update seller pricing
  // Low priority, but this should be named updateOrderItemSellerPricing
  updateOrder(
    orderHeader: OrderHeader,
    orderItems: OrderItem[],
    updateOrderItem: OrderItem,
  ) {
    let total = 0;
    this.store
      .select(selectOrderTotalPrice(updateOrderItem))
      .pipe(take(1))
      .subscribe(orderTotal => (total = orderTotal));
    this.orderStoreService.updateOrder(
      { ...orderHeader, decomposeFlag: true, totalDollars: total },
      orderItems,
      updateOrderItem,
    );
  }

  submitOrder(
    orderHeader: OrderHeader,
    orderItems: OrderItem[],
    trackingData?: Tracking,
  ) {
    // This client should always set decomposeFlag to true;
    orderHeader.decomposeFlag = true;
    this.orderStoreService.submitOrder(orderHeader, orderItems, trackingData);
  }

  copyOrder(order: Order, productMap: Map<number, Product>) {
    const updatedOrderItems: OrderItem[] = [];
    const updateOrderHeader: OrderHeader = {} as OrderHeader;
    for (let orderItem of order.orderItems) {
      let copiedItem: OrderItem = {
        eachesOrdered: orderItem.eachesOrdered,
        unitsOrdered: orderItem.unitsOrdered,
        productNumber: orderItem.productNumber,
        purchasedFromVendor: orderItem.purchasedFromVendor,
        vendorLeadTime: orderItem.vendorLeadTime,
        specialVendor: orderItem.specialVendor,
        eachPrice: productMap.get(orderItem.productNumber)?.pricing?.eachPrice,
        unitPrice: productMap.get(orderItem.productNumber)?.pricing?.unitPrice,
      } as OrderItem;
      updatedOrderItems.push(copiedItem);
    }
    const newOrder = {
      orderHeader: {
        ...updateOrderHeader,
        copiedFromTandemOrder: order?.orderHeader?.tandemOrderNumber,
      },
      orderItems: updatedOrderItems,
    };
    this.orderStoreService.createCopiedOrder(newOrder, order);
  }

  selectedCustomerDeliveryDays(): Observable<number[]> {
    return this.customerStoreService.selectedCustomerDeliveryDays();
  }

  selectedCustomerProntoDeliveryDays(): Observable<number[]> {
    return this.customerStoreService.selectedCustomerProntoDeliveryDays$().pipe(
      mergeMap(prontoDays =>
        combineLatest([
          of(prontoDays),
          this.panAppState.feature$([
            FEATURES.split_division_order_pronto.name,
          ]),
        ]),
      ),
      switchMap(([prontoDays, flag]) =>
        of(flag && !!prontoDays ? prontoDays : undefined),
      ),
    );
  }

  customerDeliveryDaysWithNumber(customerNumber: number): Observable<number[]> {
    return this.customerStoreService.customerDeliveryDaysWithNumber(
      customerNumber,
    );
  }

  resetOrder(): void {
    this.orderStoreService.resetOrder();
  }

  createNewOrder(message?: Message, pageTitle?: PageTitle): void {
    this.deliveryStoreService
      .getNextDeliveryInformation()
      .pipe(
        filter(nextDelivery => !!nextDelivery && !nextDelivery.loading),
        take(1),
        tap(nextDelivery => {
          const tracking = JSON.parse(JSON.stringify(createNewOrderTrace));
          tracking.tracing.data.isStartOfTrace = true;
          tracking.tracing.data.isEndOfTrace = true;
          tracking.tracing.transformFunc = genericTracingTransformer;
          tracking.tracing.data.attributes.order.openMethod =
            OrderTracingOpenMethod.createOrderButton;
          tracking.tracing.data.attributes.order.nextDeliveryDate =
            nextDelivery.deliveryDate;
          tracking.tracing.data.attributes.event =
            pageTitle === PageTitle.home
              ? OrderTracingMessages.createNewOrderFromHomePageTileEvent
              : OrderTracingMessages.createNewOrderEvent;
          // Dispatch the start of the create new order trace
          this.store.dispatch(
            ORDER_TRACING_ACTIONS.traceCreateNewOrderFromClient({ tracking }),
          );
          this.orderStoreService.createNewOrder(message);
        }),
      )
      .subscribe();
  }

  orderItemSearch(searchKey: string) {
    this.store.dispatch(
      ORDER_ITEM_FILTER_ACTIONS.setOrderItemFilter({ searchKey }),
    );
  }

  clearReviewOrderCartSearch() {
    this.store.dispatch(
      ORDER_ITEM_FILTER_ACTIONS.setOrderItemFilter({ searchKey: '' }),
    );
  }

  createNewOrderAndNav(message?: Message): void {
    this.createNewOrder(message);
    const path = [PATHS.LISTS];
    this.navigateTo(path);
  }

  makeOrderCurrentOrder(order: Order, customer: Customer = null) {
    this.panAppState.customer$.pipe(take(1)).subscribe(custState => {
      const currentCustomerNumber = custState?.customerNumber;
      const currentDepartmentNumber = custState?.departmentNumber * 1;

      const orderItemsCopy = order.orderItems.map(orderItem => ({
        ...orderItem,
        dirtyFlag: false,
      }));

      const orderCopy = { ...order, orderItems: orderItemsCopy };

      if (
        customer &&
        (customer.customerNumber !== currentCustomerNumber ||
          (this.customerHasDepartment(
            customer,
            orderCopy.orderHeader?.departmentNumber,
          ) &&
            orderCopy.orderHeader?.departmentNumber !==
              currentDepartmentNumber))
      ) {
        this.switchToCustomerWithOrder(orderCopy, customer);
      } else {
        this.orderStoreService.loadOrderIntoContext(orderCopy);
      }
    });
  }

  async switchToCustomerWithOrder(order: Order, customer: Customer) {
    const context: TokenAuthContext = {
      customerNumber: customer?.customerNumber,
      departmentNumber: order?.orderHeader?.departmentNumber,
      divisionNumber: customer?.divisionNumber,
    };
    // Set the customer switch status to pending for order switch trapping

    // TODO: uncomment when resolver is fixed
    // this.customerStoreService.setCustomerSwitchStatus(
    //   CustomerSwitchStatus.pending,
    // );
    await this.tokenService.refresh(context);

    this.panAppState.customer$
      .pipe(
        filter(
          customer =>
            !!customer?.hasOwnProperty('customerName') &&
            customer?.customerNumber === order.orderHeader.customerNumber,
        ),
        take(1),
      )
      .subscribe(() => this.orderStoreService.loadOrderIntoContext(order));
  }

  //This checks state for nextDeliveryInfo.deliveryDate and updates confirmed and requested delivery dates accordingly.
  //It should be called when navigating to cart page on IP orders that have a requestedDeliveryDate in the past.
  validateNextDeliveryDate(
    orderHeader: OrderHeader,
    orderItems: OrderItem[],
  ): void {
    let today = new Date();
    this.deliveryStoreService
      .getNextDeliveryInformation()
      .pipe(
        filter(nextDelivery => !!nextDelivery && !nextDelivery.loading),
        take(1),
      )
      .subscribe(nextDeliveryInfo => {
        let delDate = new Date(nextDeliveryInfo.deliveryDate);
        delDate.setHours(delDate.getHours() + delDate.getTimezoneOffset() / 60);
        let nextDeliveryDateWithOffset = new Date(delDate);
        let deliveryDate =
          nextDeliveryInfo.deliveryDate === null
            ? null
            : nextDeliveryDateWithOffset;
        if (deliveryDate === null) {
          this.updateOrderHeaderWithNullDates(orderHeader, orderItems);
        } else if (deliveryDate <= today && !!deliveryDate) {
          this.store.dispatch(NEXT_DELIVERY_ACTIONS.getDeliveryInfo());
        } else if (deliveryDate > today) {
          const updatedOrderHeader = {
            ...orderHeader,
            requestedDeliveryDate: new Date(nextDeliveryInfo.deliveryDate),
            confirmedDeliveryDate: new Date(nextDeliveryInfo.deliveryDate),
          };
          this.updateOrderHeader(updatedOrderHeader, orderItems, true);
        }
      });
  }

  substitutesLoaded(view: OrderViewModel): boolean {
    if (!!view) {
      return (view.orderItemState?.ids as number[]).every(id =>
        view.productsMap?.get(id)?.hasOwnProperty('alternative'),
      );
    } else {
      return false;
    }
  }

  // DSS call should only be made from the cart page (please don't call this function from other pages)
  getDirectShippingSavings(
    order: Order,
    updateOrderItem: OrderItem,
    checkTimestamp: boolean,
  ) {
    if (updateOrderItem && !updateOrderItem?.specialVendor) return;
    const orderItems = [...(order?.orderItems || []), updateOrderItem].map(
      item => {
        if (item?.productNumber == updateOrderItem?.productNumber) {
          return updateOrderItem;
        } else {
          return item;
        }
      },
    );
    if (orderItems?.length > 0) {
      this.directShippingStoreService.getDirectShippingSavings(
        { orderHeader: order?.orderHeader, orderItems: orderItems },
        checkTimestamp,
      );
    }
  }

  loadProductsForOrder(order: Order) {
    const productNumbers = order?.orderItems?.map(orderItem => {
      return orderItem?.productNumber;
    });

    this.productService.loadProducts(productNumbers);
  }

  loadUnusualQuantities(uqeProductNumbers: UqeProductNumber[]) {
    this.panAppState
      .feature$([FEATURES.split_global_order_unusual_quantities.name])
      .pipe(first())
      .subscribe(flag => {
        if (flag) {
          this.uqeStoreService.getUnusualQuantities(uqeProductNumbers);
        }
      });
  }

  loadSubmittedOrderViewModelById(
    orderId: string,
  ): Observable<SubmittedOrderViewModel> {
    const vm$ = this.store.select(selectSubmittedOrderViewModel(orderId)).pipe(
      filter(vm => vm.customerReady),
      filter(view =>
        view?.submittedOrderById?.orderItems?.every(
          item => !!view?.products?.get(item?.productNumber),
        ),
      ),
      switchMap(vm => this.getSubmittedOrderViewModelDisplayTags(vm as any)),
    );

    return combineLatest([vm$, this.panAppState.customer$]).pipe(
      switchMap(([vm, selectedCustomer]) => of({ ...vm, selectedCustomer })),
    );
  }

  loadOrderConfirmationViewModel$(
    masterOrderId: string,
    autoSubFeatureFlag: boolean,
  ): Observable<OrderConfirmationVM> {
    return this.store.select(
      selectOrderConfirmationViewModel(masterOrderId, autoSubFeatureFlag),
    );
  }

  cancelSubmittedOrder(order: Order) {
    if (!this.networkStatus) {
      return this.sendOfflineWarning(
        'You must be online to cancel this order.',
      );
    }

    sessionStorage.setItem('cancelSubmittedOrder', 'true');
    this.orderStoreService.cancelSubmittedOrder(order);
  }

  deleteOrder(orderHeader: OrderHeader, orderItems: OrderItem[]) {
    if (!orderHeader?.tandemOrderNumber) {
      // TODO: Remove this tracing code when we have removed orderHeader and orderItem slices
      this.loadOrderViewModel$()
        .pipe(take(1))
        .subscribe(vm => {
          const totalCartPrice = vm?.shipments[0]?.shipmentTallies?.totalPrice;
          this.orderTracing.traceIPOrderCancel(
            vm?.orderHeader?.orderId,
            Math.round(totalCartPrice * 100) / 100,
            this.getOrderItems(vm?.orderItemState?.entities),
            vm?.orderHeader?.tandemOrderNumber,
          );
        });
      this.orderStoreService.deleteOrder(orderHeader, orderItems);
    } else {
      this.cancelSubmittedOrder({ orderHeader, orderItems });
    }
  }

  getOrderItems = entities => Object.keys(entities)?.map(key => entities[key]);

  loadOrderViewModel$(): Observable<OrderViewModel> {
    const orderVM$ = combineLatest([
      this.getMerchZoneFlag$(),
      this.getFrequentlyBoughtTogetherFlag$(),
    ]).pipe(
      switchMap(([isMerchZoneEnabled, isFrequentlyBoughtTogetherEnabled]) => {
        return this.store.select(
          selectOrderViewModel(
            isMerchZoneEnabled,
            isFrequentlyBoughtTogetherEnabled,
          ),
        );
      }),
    );
    const deliveryDate$ =
      this.deliveryStoreService.getNextDeliveryInformation();
    const eligibleDays$ =
      this.customerStoreService.selectedCustomerDeliveryDays();

    return combineLatest([orderVM$, deliveryDate$, eligibleDays$]).pipe(
      map(([orderVM, deliveryDate, eligibleDays]) => {
        orderVM;
        if (!!orderVM) {
          (orderVM as OrderViewModel).hasOrder =
            !!orderVM.orderHeader &&
            !!orderVM.orderHeader.orderId &&
            orderVM.orderHeader.requestedDeliveryDate?.toString() !==
              'Invalid Date' &&
            !!deliveryDate?.deliveryDate &&
            !deliveryDate?.loading &&
            !!eligibleDays?.length;
        }
        return orderVM;
      }),
    );
  }

  loadOrderMinimumViewModel$(
    orderHeader?: OrderHeader,
  ): Observable<OrderMinimumViewModel> {
    return this.panAppState
      .feature$([FEATURES.split_division_order_pronto.name])
      .pipe(
        take(1),
        mergeMap(flag => {
          return this.store
            .select(selectOrderMinimumViewModel(orderHeader, flag))
            .pipe(
              debounceTime(10),
              map(orderMinVM => {
                return orderMinVM;
              }),
            );
        }),
      );
  }

  loadAddToOrderViewModel$(): Observable<AddToOrderViewModel[]> {
    return this.store.select(selectAddToOrderModel);
  }

  orderTotalPrice(): Observable<number> {
    return this.store.select(selectOrderTotalPrice(null));
  }

  getSubmittedOrderViewModelDisplayTags(
    vm: SubmittedOrderViewModel,
  ): Observable<SubmittedOrderViewModel> {
    if (!vm || !vm.submittedOrderById?.orderItems) {
      return of(vm);
    }
    const productNumbers = vm.submittedOrderById?.orderItems?.map(orderItem => {
      return orderItem.productNumber;
    });

    return this.productService.getProducts(productNumbers).pipe(
      map(productsMap => {
        const result: Map<number, TagProduct[]> = new Map();
        productsMap.forEach(product => {
          if (!!product) {
            result.set(
              product?.productNumber,
              this.productDetailTagsService.getDisplayTags(
                product?.summary,
                product?.inventory,
                product?.contract,
                product?.customerPill,
              ),
            );
          }
        });
        return { ...vm, products: productsMap, displayTags: result };
      }),
    );
  }

  private sendOfflineWarning(displayMessage: string) {
    this.messageStoreService.upsertMessage({
      stack: null,
      watermark: new Date().toUTCString(),
      read: false,
      type: MessageTypeEnum.warning,
      display: displayMessage,
    } as Message);
  }

  navigateTo(path) {
    if (this.platformService.isTouch.value) {
      path.unshift('touch');
    } else {
      path.unshift('desktop');
    }
    this.router.navigate(path);
    return path;
  }

  trackAddAdditionalDeliveryInstructions(productData: any[], orderId: string) {
    this.orderAnalyticsService.trackAdditionalDeliveryInstructions(
      productData,
      orderId,
    );
  }

  trackOrderPageLoad(
    orderHeader: OrderHeader,
    orderItemState: OrderItemState,
    dyfProducts: DyfProductInfo[],
    shipments: Shipment[],
    inspiredPicksVM: InspiredPicksViewModel,
    merchZoneVM: MerchZoneViewModel,
  ) {
    this.orderAnalyticsService.trackOrderPageLoad(
      orderHeader,
      orderItemState,
      dyfProducts,
      shipments,
      inspiredPicksVM,
      merchZoneVM,
    );
  }

  trackDidYouForgetClick(resultRank: number, product: Product) {
    this.orderAnalyticsService.trackDidYouForgetClick(resultRank, product);
  }

  trackInspiredPicksClick(resultRank: number, product: Product) {
    this.orderAnalyticsService.trackInspiredPicksClick(resultRank, product);
  }

  getOrderTrackingGroups(orderId: string): Observable<OrderTrackingGroup> {
    return this.store.select(getOrderTrackingGroups(orderId));
  }

  getDyfProductNumbers$(): Observable<number[]> {
    return this.store.select(selectDYFProductNumbers);
  }

  getInspiredPickProductNumbers$(): Observable<number[]> {
    return this.store.select(selectInspiredPickProductNumbers);
  }

  getMerchZoneProductNumbers$(): Observable<number[]> {
    return this.store.select(selectMerchZoneProductNumbers);
  }

  getFrequentlyBoughtTogetherProductNumbers$(): Observable<number[]> {
    return this.store.select(selectFrequentlyBoughtTogetherProductNumbers);
  }

  getOrderHeader$() {
    return this.store.select(orderHeaderSelectors.selectOrderHeader);
  }

  getOrderItemState$(): Observable<OrderItemState> {
    return this.store.select(orderItemSelectors.selectOrderItemState);
  }

  getSelectedCustomerState$(): Observable<SelectedCustomerState> {
    return this.panAppState.customer$;
  }

  getMerchZoneFlag$(): Observable<boolean> {
    return this.panAppState.feature$([
      FEATURES.split_global_review_order_merch_zone.name,
    ]);
  }

  getFrequentlyBoughtTogetherFlag$(): Observable<boolean> {
    return this.panAppState.feature$([
      FEATURES.split_global_review_order_frequently_bought_together.name,
    ]);
  }

  /**
   * Provide analytics on opening the hand pricing modal for a given product
   * @param orderItem Client Domain Order Item
   */
  trackHandPricingModalOpen(orderItem: OrderItem): void {
    let divisionNumber: number;
    let productMap: Map<number, Product>;
    // Get the current users division
    this.panAppState.customer$
      .pipe(take(1))
      .subscribe(cust => (divisionNumber = cust.divisionNumber));
    // Get a map of all products
    this.store
      .select(productSelector)
      .pipe(take(1))
      .subscribe(prodMap => (productMap = prodMap));

    // Request the order analytics service to track the hand pricing modal open event
    this.orderAnalyticsService.trackHandPricingModalOpen(
      orderItem,
      productMap,
      divisionNumber,
    );
  }

  /**
   * Provide analytics on changing the price via the hand pricing modal
   * @param orderItem Client Domain Order Item
   */
  trackHandPricingChange(orderItem: OrderItem): void {
    let divisionNumber: number;
    let productMap: Map<number, Product>;
    // Get the current users division
    this.panAppState.customer$
      .pipe(take(1))
      .subscribe(cust => (divisionNumber = cust.divisionNumber));
    // Get a map of all products
    this.store
      .select(productSelector)
      .pipe(take(1))
      .subscribe(prodMap => (productMap = prodMap));

    // Request the order analytics service to track the change in price
    this.orderAnalyticsService.trackHandPricingChange(
      orderItem,
      productMap,
      divisionNumber,
    );
  }

  navigateToSearchForSubs(product: Product, originSearchPage?: string) {
    this.orderAnalyticsService.trackSearchForSubs(product);
    this.productAlternativeSearchService.searchReplacement(
      product.summary,
      UsfProductCardModeEnum.cartReplacementSearch,
      false,
      product.alternative,
      originSearchPage,
    );
  }

  convertToWillCall(
    orderHeader: OrderHeader,
    orderItems: OrderItem[],
    prodMap: Map<number, Product>,
    isEditWillCallFlow: boolean,
  ) {
    // This client should always set decomposeFlag to true;
    const products = SharedTrackingService.analyticsProductData(
      orderItems,
      prodMap,
      orderHeader.divisionNumber,
    );

    let tracking: Tracking = {
      tracing: {
        data: {
          isEndOfTrace: false,
          isStartOfTrace: true,
          traceContext: ConvertToWillCallTracingMessages.traceContext,
          attributes: {
            event: isEditWillCallFlow
              ? ConvertToWillCallTracingMessages.editWillCallApplyEvent
              : ConvertToWillCallTracingMessages.submitWillCallOrderEvent,
            order: {
              orderId: orderHeader?.orderId,
              tandemOrderNumber: orderHeader?.tandemOrderNumber,
              origOrderId: !!orderHeader?.origOrderId
                ? orderHeader.origOrderId
                : '',
              origSplitOrderId: !!orderHeader?.origSplitOrderId
                ? orderHeader.origSplitOrderId
                : '',
              requestedDeliveryDate: orderHeader?.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader?.confirmedDeliveryDate,
              totalAmount: orderHeader?.totalDollars,
              pickUpDateTime: orderHeader?.willCallDateTime,
              pickUpPersonName: orderHeader?.willCallPersonName,
            },
            products: products,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    orderHeader.decomposeFlag = true;
    this.orderConversionService.setWillCallInFlight(true);
    this.orderStoreService.convertToWillCall(
      orderHeader,
      orderItems,
      isEditWillCallFlow,
      tracking,
    );
  }

  orderSubmitted(orderHeader: OrderHeader): boolean {
    return !!orderHeader?.tandemOrderNumber;
  }

  currentOrderIsSubmitted$(): Observable<boolean> {
    const orderHeaderSelector = orderHeaderSelectors.selectOrderHeader;
    const orderHeader$ = this.store.select(orderHeaderSelector);
    return orderHeader$.pipe(map(header => this.orderSubmitted(header)));
  }

  cartSyncing(cartState: CartState): boolean {
    return !!cartState?.dirty || !!cartState?.saving || !!cartState?.loading;
  }

  isCartSyncingIpOrder$(): Observable<boolean> {
    const cartSelector = CART_SELECTORS.selectCart;
    return this.store.select(cartSelector).pipe(
      map(state => {
        if (state?.orderHeader?.tandemOrderNumber > 0) {
          return false;
        } else {
          return this.cartSyncing(state);
        }
      }),
    );
  }

  shouldShowCartSyncingPopover$(): Observable<boolean> {
    const cartSelector = CART_SELECTORS.selectCart;
    return this.store.select(cartSelector).pipe(
      map(state => {
        return this.cartSyncing(state);
      }),
    );
  }

  mapURLToOpenMethod(url: string): OrderTracingOpenMethod {
    if (url?.includes('/search')) {
      return OrderTracingOpenMethod.searchPage;
    } else if (url?.includes('/lists')) {
      return OrderTracingOpenMethod.listPage;
    } else if (url?.includes('/products')) {
      return OrderTracingOpenMethod.PDP;
    } else {
      return undefined;
    }
  }

  setWillCallInFlight(willCallInFlight: boolean) {
    this.store.dispatch(
      MY_ORDERS_ACTIONS.setMyOrdersWillCallInFlight({ willCallInFlight }),
    );
  }

  submitAddToOrderModal(submittedModalData: any) {
    this.store.dispatch(
      ORDER_ACTIONS.dismissAddToOrderModal({ modalData: submittedModalData }),
    );
  }

  cancelAddToOrderModal(submittedModalData: any) {
    this.store.dispatch(
      ORDER_ACTIONS.cancelAddToOrderModal({ modalData: submittedModalData }),
    );
  }

  checkOrderStatus(orderId: string) {
    this.orderStoreService.checkOrderStatus(orderId);
  }

  printSubmittedOrder(
    orders: Order[],
    orderConfirmation: boolean = false,
    orderTallies: SubmittedOrderTallies,
    orderMinimum?: OrderMinimumViewModel,
    shipToCustomer?: Customer,
    shipToDepartmentName?: string,
    orderStatusDetails?: OrderStatusDetails,
  ) {
    this.store.dispatch(
      ORDER_ACTIONS.printSubmittedOrder({
        orders,
        orderConfirmation,
        orderTallies,
        orderMinimum,
        shipToCustomer,
        shipToDepartmentName,
        orderStatusDetails,
      }),
    );
  }

  printCart() {
    this.store.dispatch(ORDER_ACTIONS.printCart());
  }

  shareCart() {
    this.store.dispatch(ORDER_ACTIONS.shareCart());
  }

  shareSubmittedOrder(orders: Order[]) {
    this.store.dispatch(ORDER_ACTIONS.shareSubmittedOrder({ orders }));
  }

  shareOrderConfirmation(orders: Order[]) {
    this.store.dispatch(ORDER_ACTIONS.shareOrderConfirmation({ orders }));
  }

  downloadCart(fileInfo: DownloadOrdersEvent) {
    this.store.dispatch(ORDER_ACTIONS.downloadCart(fileInfo));
  }

  downloadFromOrderStatus(fileInfo: DownloadOrdersEvent) {
    this.store.dispatch(ORDER_ACTIONS.downloadOrderStatus(fileInfo));
  }

  startWillCallWebSocketTimer(orderHeader?: OrderHeader) {
    this.stopWillCallWebSocketTimer();

    const orderId =
      `${orderHeader?.customerNumber}:` +
      `${orderHeader?.departmentNumber}:` +
      `${orderHeader?.orderId}`;

    //This RxJS timer is an observable and it will emit single value (one time only after 10 seconds for this case) and observable will be complete after emitting single value.
    this.willCallErrorTimerSub = timer(10000).subscribe(() => {
      this.myOrdersStoreService.retrieveCustomerOrders();

      const order = this.myOrdersStoreService
        .getOrderWithId(orderId)
        .pipe(debounceTime(5000), take(1))
        .subscribe(order => {
          if (order?.orderHeader?.errorDetails) {
            const message = {
              id: this.uuid(),
              stack: null,
              watermark: new Date().toUTCString(),
              read: false,
              type: MessageTypeEnum.error,
              display:
                order?.orderHeader?.errorDetails?.errMessage ||
                "We're sorry but your order could not be converted to will call, please try again.",
              toast: true,
              sticky: true,
            } as Message;
            this.messageStoreService.upsertMessage(message);
          }
          this.setWillCallInFlight(false);
        });
    });
  }

  stopWillCallWebSocketTimer() {
    // Unsubscribe and clear the timer subscription if it exists
    if (this.willCallErrorTimerSub) {
      this.willCallErrorTimerSub.unsubscribe();
      this.willCallErrorTimerSub = undefined;
    }
  }

  uuid() {
    return uuidv4();
  }

  routeResolveExceptionsHomePage(orderId: string) {
    this.store.dispatch(
      ORDER_EXCEPTION_ACTIONS.homePageRouteResolveExceptions({
        orderId,
      }),
    );
  }

  isProductStateReady$(): Observable<boolean> {
    return this.orderProductNumbers$().pipe(
      switchMap(productNumbers => {
        if (!productNumbers?.length) {
          return of(LoadingState.loaded);
        }
        return this.store.select(getLoadingStateOfProducts(productNumbers));
      }),
      tap(loadingState => {
        if (loadingState === LoadingState.error) {
          this.messageStoreService.upsertMessage({
            id: this.uuid(),
            stack: null,
            watermark: new Date().toUTCString(),
            read: false,
            type: MessageTypeEnum.error,
            display: this.translateService.instant(
              'i18n.common.productsUnavailable',
            ),
            toast: true,
            sticky: false,
          } as Message);
        }
      }),
      map((loadingState: LoadingState) => loadingState === LoadingState.loaded),
    );
  }

  isCartLoading$(): Observable<boolean> {
    return this.store
      .select(CART_SELECTORS.selectCart)
      .pipe(map(cart => cart.loading));
  }

  orderProductNumbers$(): Observable<number[]> {
    return this.loadOrderViewModel$().pipe(
      map(vm => {
        return vm?.orderItemState?.ids as number[];
      }),
    );
  }

  isPricingForProductsReady$(): Observable<boolean> {
    return this.loadOrderViewModel$().pipe(
      switchMap(vm => {
        let allPricingLoaded = true;
        vm.productsMap.forEach(product => {
          if (product.pricing.loading) {
            allPricingLoaded = false;
          }
        });
        return of(allPricingLoaded);
      }),
      map((pricingLoaded: boolean) => pricingLoaded),
    );
  }

  confirmUnusualQuantityAnalitics(orderItem: OrderItem) {
    combineLatest([
      this.store.select(orderHeaderSelectors.selectOrderHeader),
      this.panAppState.customer$,
      this.store.select(productSelector),
    ])
      .pipe(take(1))
      .subscribe(([orderHeader, cust, prodMap]) => {
        const tracking = confirmQuantiyExceptionAnalytics(
          orderHeader?.orderId,
          orderHeader?.origOrderId,
          SharedTrackingService.analyticsProductData(
            [orderItem],
            prodMap,
            cust.divisionNumber,
            false,
            'UQE',
          ),
        );
        this.store.dispatch(
          ORDER_ANALYTICS_ACTIONS.trackConfirmQuantityException({ tracking }),
        );
      });
  }

  setLoading(loading: boolean) {
    this.orderStoreService.setLoading(loading);
  }

  checkCustomerIsAvailable(customerNumber: number) {
    this.customerStoreService
      .loadCustomers$()
      .pipe(
        filter(customers => !!customers.length),
        take(1),
      )
      .subscribe(customers => {
        const customer = customers.find(
          customer => customer.customerNumber === customerNumber,
        );
        if (!customer) {
          this.handleOrderDetailsPageError();
        }
      });
  }

  handleOrderDetailsPageError() {
    this.messageStoreService.upsertMessage({
      id: uuidv4(),
      stack: null,
      watermark: new Date().toUTCString(),
      read: false,
      type: MessageTypeEnum.error,
      display: this.translateService.instant(
        'i18n.orderSubmittedPage.orderDetailsError',
      ),
      toast: false,
      sticky: false,
      dismissRoute: ['my-orders-view'],
    } as Message);
  }

  orderStatusOnWhitelist(order: Order) {
    const whitelist = [
      OrderStatus.SUBMITTED,
      OrderStatus.SUBMITTED_WITH_EXCEPTIONS,
      OrderStatus.SUBMITTED_CREDIT_HOLD,
      OrderStatus.PICKING,
      OrderStatus.SHIPPED,
      OrderStatus.SHIPPED_WITH_EXCEPTIONS,
      OrderStatus.DELIVERED,
      OrderStatus.INVOICE,
      OrderStatus.ORDER_CANCELLED,
    ];
    return whitelist.includes(order.orderHeader.orderStatus);
  }

  isOrderItemAvailableToPromise = (
    product: Product,
    appStateForProduct: AppStateForProduct,
    selectedCustomer: Customer,
    partnerState: PartnerState,
    deliveryDate: string,
    orderHeader: OrderHeader,
    poDates: PoDate[],
    prioritizeAlternative = false,
  ) => {
    return isHideOutOfStock(
      product,
      appStateForProduct,
      selectedCustomer,
      partnerState,
      deliveryDate,
      orderHeader,
      extractPoDates(product?.summary?.poDates),
      product.alternative?.prioritizeAlternative,
    );
  };

  updateAvailableToPromiseOnOrderItems(
    productsMap: Map<number, Product>,
    appStateForProduct: AppStateForProduct,
    selectedCustomer: Customer,
    partnerState: PartnerState,
    orderHeader: OrderHeader,
    orderItems: OrderItem[],
  ) {
    orderItems.forEach(orderItem => {
      let product = productsMap.get(orderItem.productNumber);
      if (product) {
        const deliveryDate =
          orderHeader.confirmedDeliveryDate instanceof Date
            ? orderHeader.confirmedDeliveryDate.toISOString()
            : orderHeader.confirmedDeliveryDate;

        const hideOOS = this.isOrderItemAvailableToPromise(
          product,
          appStateForProduct,
          selectedCustomer,
          partnerState,
          deliveryDate,
          orderHeader,
          extractPoDates(product?.summary?.poDates),
          product.alternative?.prioritizeAlternative,
        );

        if (hideOOS) {
          const modifiedProduct = {
            ...product,
            summary: {
              ...product.summary,
              properties: product.summary?.properties
                ? new Set([
                    ...product.summary.properties,
                    ProductPropertiesEnum.availableToPromise,
                  ])
                : undefined,
            },
            trackingAttributes: product.trackingAttributes?.includes(
              PRODUCT_ATTRIBUTE_TRACK.availableToPromise,
            )
              ? product.trackingAttributes
              : product.trackingAttributes?.length
                ? `${product.trackingAttributes},${PRODUCT_ATTRIBUTE_TRACK.availableToPromise}`
                : PRODUCT_ATTRIBUTE_TRACK.availableToPromise,
          };

          productsMap.set(product.productNumber, modifiedProduct);
        }
      }
    });
    return productsMap;
  }

  getRecoveryOrderRequestReasonIssueDetails(): Observable<IssueOptions[]> {
    return this.serviceRequestService.recoveryRequestIssueOptions$();
  }

  trackEditOrderButtonClick(orderHeader: OrderHeader) {
    this.store.dispatch(
      ORDER_ACTIONS.modifyOrderButtonClick({
        orderId: `${orderHeader?.customerNumber}:${orderHeader?.departmentNumber}:${orderHeader?.orderId}`,
        orderStatus: orderHeader?.orderStatus,
      }),
    );
  }
}
