import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/store';
import {
  allowReporting,
  selectUserKind,
  selectUserPreferences,
  selectUserProfiles,
  selectUserEmailAndPhone,
  showMLM,
  showBusinessAnalytics,
  showGL,
  showInvoiceAndPayments,
  showInvoiceAndPaymentsMenu,
  showMPP,
  showRecipesOnDemand,
  showValueAddedServices,
  selectInteractBanners,
  selectCustomMessage,
  selectCustomMessages,
  selectUserCustomizations,
  selectUserStatus,
  showTv360,
  selectUserPreferencesState,
  isSuperUser,
  selectUserProfilesState,
  selectFoodCostRangePreferences,
  hideInvoiceAndPayments,
  selectUserPreferencesV2State,
  selectDefaultPoNumber,
} from '../store/selectors';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
  PanAppState,
  jwtDecode,
  selectCustomer,
  selectShowInventory,
} from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import { UserKinds } from '@usf/user-types/user';
import { UserPreferencesV2, PoNumber } from '@usf/user-types/user-preferencev2';
import { UserActions } from '@app/user/store/actions/action-types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private store: Store<State>,
    private panAppState: PanAppState,
  ) {}

  public get showMPPMoxe() {
    return combineLatest([
      this.store.select(showMPP),
      this.panAppState.feature$([FEATURES.split_dynamic_mpp.name], true),
      this.store.select(selectUserProfilesState),
      this.store.select(selectCustomer()),
    ]).pipe(
      filter(([ignore, ignore2, userprofiles, customer]) => {
        return !!userprofiles && userprofiles.loaded && !!customer;
      }),
      map(([showMPP, globalSplitFlag, ignore, customer]) => {
        return showMPP && globalSplitFlag && customer?.ogPrintPriceInd !== 'N';
      }),
    );
  }

  showMPPMoxe$ = () => this.showMPPMoxe;

  userKind$ = () => this.store.select(selectUserKind);

  userStatus$ = () => this.store.select(selectUserStatus);

  userProfiles$ = () => this.store.select(selectUserProfiles);

  userProfilesState$ = () => this.store.select(selectUserProfilesState);

  userCustomizations$ = () => this.store.select(selectUserCustomizations);

  userPreferences$ = () => this.store.select(selectUserPreferences);

  userPreferencesState$ = () => this.store.select(selectUserPreferencesState);

  userPreferencesFoodCostRange$ = () =>
    this.store.select(selectFoodCostRangePreferences);

  userEmailAndPhone$ = () => this.store.select(selectUserEmailAndPhone);

  showInventory$ = () => this.store.select(selectShowInventory);

  showMoxeInventory$ = () => {
    return combineLatest([
      this.showInventory$(),
      this.panAppState.feature$([FEATURES.split_global_inventory.name]),
      this.panAppState.accessToken$,
    ]).pipe(
      filter(([f, f1, accessToken]) => {
        if (accessToken) {
          return true;
        } else {
          return false;
        }
      }),
      map(
        ([showInventoryCCA, globalSplitFlag]) =>
          showInventoryCCA && globalSplitFlag,
      ),
    );
  };

  showMLM$ = () => this.store.select(showMLM);

  showMPP$ = () => this.store.select(showMPP);

  showGL$ = () => this.store.select(showGL);

  allowReporting$ = () => this.store.select(allowReporting);

  showBusinessAnalytics$ = () => this.store.select(showBusinessAnalytics);

  showInvoiceAndPaymentsMenu$ = () =>
    this.store.select(showInvoiceAndPaymentsMenu);

  showInvoiceAndPayments$ = () => this.store.select(showInvoiceAndPayments);

  showRecipesOnDemand$ = () => this.store.select(showRecipesOnDemand);

  showValueAddedServices$ = () => this.store.select(showValueAddedServices);

  interactBanners$ = () => this.store.select(selectInteractBanners);

  customMessage$ = () => this.store.select(selectCustomMessage);

  customMessages$ = () => this.store.select(selectCustomMessages);

  showTv360$ = () => this.store.select(showTv360);

  isSuperUser$ = (): Observable<boolean> =>
    combineLatest([
      this.store.select(isSuperUser),
      this.userProfilesState$(),
    ]).pipe(
      filter(([_superUser, userProfilesState]) => !!userProfilesState?.loaded),
      take(1),
      map(([superUser, _isUserProfilesLoaded]) => superUser),
    );

  showMassDelete$ = (): Observable<boolean> => {
    return combineLatest([
      this.store.select(isSuperUser),
      this.store.select(selectUserKind),
      this.store.select(selectUserProfiles),
    ]).pipe(
      map(([isSuperUser, userKind, userProfiles]) => {
        const massDeleteProfile = userProfiles?.find(
          userProf => userProf.key === 'MASS_SL_DELETE',
        );
        if (isSuperUser) {
          return false;
        } else {
          if (userKind === UserKinds.Internal) return true;
          else if (massDeleteProfile && massDeleteProfile.value === 'Y')
            return true;
        }
        return false;
      }),
    );
  };

  isGuestUser$ = (): Observable<boolean> =>
    this.panAppState.session$.pipe(
      map(currentSession => currentSession.isGuest),
    );

  hideInvoiceAndPayments$ = () => this.store.select(hideInvoiceAndPayments);

  defaultPoNumber$ = (customerNumber: number) =>
    this.store.select(selectDefaultPoNumber).pipe(
      map(poNumbers => {
        const poNumbersArray = poNumbers as PoNumber[];
        if (!customerNumber || !poNumbersArray?.length) {
          return '';
        }
        const matchingPoNumber = poNumbersArray.find(
          po => po.customerNumber === customerNumber,
        );
        return matchingPoNumber?.defaultPo || '';
      }),
    );

  getUserPreferencesV2(): Observable<UserPreferencesV2> {
    return this.store.pipe(
      select(selectUserPreferencesV2State),
      map(
        state =>
          state?.preferences ?? {
            userId: 0,
            favoriteCustomers: [],
            poNumber: [],
          },
      ), // Default preferences if undefined
    );
  }

  getUserPreferencesV2IsLoading(): Observable<boolean> {
    return this.store.pipe(
      select(selectUserPreferencesV2State),
      map(state => state?.loading),
    );
  }

  updateUserPreferencesV2(
    preferences: UserPreferencesV2,
  ): Observable<UserPreferencesV2> {
    this.store.dispatch(UserActions.updateUserPreferencesV2({ preferences }));
    return of(preferences);
  }

  isInternalUser(accessToken: string): boolean {
    return jwtDecode(accessToken)?.['usf-claims']?.userType === 'corp-ad';
  }

  isCustomerAdmin(accessToken: string): boolean {
    return jwtDecode(accessToken)?.['usf-claims']?.customerAdmin === true;
  }
}
