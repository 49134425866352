import { createSelector } from '@ngrx/store';
import { selectAllAiLists, selectSelectedAiList } from '@usf/ngrx-list-ai';
import { Crumb } from '@shared/components/breadcrumbs/breadcrumbs.component';
import { selectOnline } from '@panamax/app-state';
import { AIList, AIListItem, JobStatus } from '@usf/list-ai-types';
import {
  AIListBuilderViewModel,
  FileParse,
  ProductMatch,
} from '../model/ai-list-builder-view.model';
import { AIListProductCardViewModel } from '../model/ai-list-product-card.model';
import { ListsViewModel } from '../model/lists-view.model';
import { ListTypes } from '@shared/constants/lists-constants';
import { ListKey } from '@usf/list-types';

export const selectAIListBuilderViewModel = createSelector(
  selectSelectedAiList,
  selectOnline(),
  (aiList: AIList, isOnline: boolean): AIListBuilderViewModel => {
    const items = getAIListItemsFromList(aiList);
    // Item heights for all platforms and best selling products flow is 221.2, this will soon change to a couple different heights
    // that will have more logic involved once we start showing product data and what file the product was found in.
    const itemHeights: number[] = items.map(item => 221.2);
    return {
      aiListId: aiList?.aiListId,
      aiListName: aiList?.aiListName,
      breadcrumbs: getAiListBuilderBreadCrumbs(),
      ingredientPreference: aiList?.menuProductType,
      numberOfFilesUploaded: aiList?.sourceFiles?.length ?? 0,
      menuType: aiList?.menuType,
      numberOfProducts: items?.length,
      items,
      isOnline,
      itemHeights,
      fileParse: {
        // use selectFileParseForSelectedAiList, for now this is just mock data
        isParsingFiles: false,
        fileBeingParsedDisplayText: 'first',
      },
      productMatch: {
        // use selectProductMatchForSelectedAiList, for now this is just mock data
        isMatchingProducts: false,
        numberOfProductsMatched: 10,
      },
      updatedByUser: aiList?.updatedByUser,
    } as AIListBuilderViewModel;
  },
);

export const selectAiListsAsListViewModels = createSelector(
  selectAllAiLists,
  selectOnline(),
  (aiLists: AIList[]) => {
    const aiListViewModels: ListsViewModel[] = [];
    aiLists.forEach(aiList => {
      const items = getAIListItemsFromList(aiList);
      aiListViewModels.push({
        listKey: {
          listId: undefined,
          listTypeId: 'AI',
        } as ListKey,
        r4TempListId: undefined,
        listName: aiList.aiListName,
        divisionNumber: aiList.divisionNumber,
        customerNumber: aiList.customerNumber,
        departmentNumber: 0,
        listState: aiList.listState,
        effDate: '',
        endDate: '',
        listItemCount: items.length,
        discontinuedCount: 0,
        listGroupKeys: undefined,
        deleteDtm: '',
        updatedByUser: aiList.updatedByUser,
        isEnrichment: false,
        addUserIdSourceSystem: aiList.createdByUser?.userName,
        addDtmSourceSystem: aiList.addDtm,
        addSourceSystem: aiList.sourceSystem,
        updateUserIdSourceSystem: aiList.updatedByUser?.userName,
        updateDtmSourceSystem: aiList.updateDtm,
        updateSourceSystem: aiList.sourceSystem,
        version: '',
        aiListId: aiList.aiListId,
        showEllipsis: false,
      } as ListsViewModel);
    });
    return aiListViewModels;
  },
);

// Logic for this may change after meeting on 3/13/25
export const selectFileParseForSelectedAiList = createSelector(
  selectSelectedAiList,
  (aiList: AIList): FileParse =>
    ({
      isParsingFiles:
        aiList?.parsed === false &&
        aiList?.parseJobStates?.length > 0 &&
        aiList?.sourceFiles?.length > 0,
      fileBeingParsedDisplayText: getFileBeingParsedDisplayText(aiList),
    }) as FileParse,
);

// Logic for this may change after meeting on 3/13/25
export const selectProductMatchForSelectedAiList = createSelector(
  selectSelectedAiList,
  (aiList: AIList): ProductMatch =>
    ({
      isMatchingProducts:
        aiList?.matched === false &&
        aiList?.matchJobState.jobStatus === JobStatus.PROCESSING,
      numberOfProductsMatched: 0, // loop through all ai list items and see which ones have matches? or count from websocket? if we do count from websocket, what happens if websockets arent working?
    }) as ProductMatch,
);

const getFileBeingParsedDisplayText = (aiList: AIList): string => {
  let stringToReturn = '';
  const numberOfFilesParsed =
    aiList?.sourceFiles?.length - aiList?.parseJobStates?.length;

  // This logic will probably change, this is assuming if there there is a job id, its currently processing a file,
  // each job id represents one file being processed. Thinking instead of job id as a string array, it should also store the current status
  // of that job id (started, completed, fail ). We could then loop through this jobId array to accurately contruct our view text
  if (numberOfFilesParsed !== aiList?.sourceFiles?.length) {
    return numberToDisplayTextMap.get(numberOfFilesParsed + 1);
  }
  return stringToReturn;
};

const numberToDisplayTextMap = new Map([
  [1, 'first'],
  [2, 'second'],
  [3, 'third'],
  [4, 'fourth'],
  [5, 'fifth'],
  [6, 'sixth'],
  [7, 'seventh'],
  [8, 'eighth'],
  [9, 'ninth'],
]);

const getAiListBuilderBreadCrumbs = (): Crumb[] => {
  return [
    {
      label: 'i18n.breadcrumbs.home',
      url: '/desktop/home',
      active: false,
    },
    {
      label: 'i18n.breadcrumbs.myLists',
      url: '/desktop/lists',
      active: false,
    },
    {
      label: 'AI List Builder',
      url: '',
      active: true,
    },
  ];
};

const getAIListItemsFromList = (
  aiList: AIList,
): AIListProductCardViewModel[] => {
  let items: AIListProductCardViewModel[] = [];
  let lineNumber = 1;
  if (aiList?.groups?.length > 0) {
    for (const group of aiList.groups) {
      if (group?.groupItems?.length > 0) {
        for (const item of group.groupItems) {
          const itemProductCardViewModel = createAIListProductCardViewModel(
            item,
            lineNumber,
          );
          items.push(itemProductCardViewModel);
          lineNumber++;
        }
      }
    }
  }
  return items;
};

const createAIListProductCardViewModel = (
  item: AIListItem,
  index: number,
): AIListProductCardViewModel => {
  return {
    product: undefined, // undefined for now, in future, use product selector and look it up from map by using product number from item
    aiListItem: item,
    sourceFileDisplayText:
      item?.sourceFileName === 'Best Selling PIM Groups'
        ? 'i18n.lists.bestSellingProducts'
        : item?.sourceFileName,
    loading: true, // Will set this depending if the product is loaded in or not
    lineNumber: index,
  } as AIListProductCardViewModel;
};
