<section class="inventory-section">
  <h4 data-cy="seller-detail-inventory-text">
    {{ 'i18n.productDetail.sellerDetail.inventory' | translate }}
  </h4>
  <dl>
    <dt data-cy="seller-detail-inventory-label-text">
      {{ inventoryLabel | translate }}
    </dt>
    <dd data-cy="seller-detail-inventory-value-text">
      {{ inventoryValue }}
    </dd>
    <ng-container *ngIf="!!product?.currentWeeklyMovement">
      <dt data-cy="seller-detail-avg-weekly-movement-text">
        {{ 'i18n.productDetail.sellerDetail.avgWeeklyMovement' | translate }}:
      </dt>
      <dd data-cy="seller-detail-current-weekly-movement-text">
        {{ product.currentWeeklyMovement.projectedSales }} CS
      </dd>
      <dt data-cy="seller-detail-rate-of-movement-text">
        {{ 'i18n.productDetail.sellerDetail.rateOfMovement' | translate }}:
      </dt>
      <dd
        [title]="
          'i18n.productDetail.sellerDetail.rateOfMovementHoverReadout'
            | translate
        "
        class="rate-of-movement-value"
        data-cy="rate-of-movement-value-text"
        [class.rate-of-movement-trend-up]="
          product?.currentWeeklyMovement?.isTrendingUp === true
        "
        [class.rate-of-movement-trend-down]="
          product?.currentWeeklyMovement?.isTrendingUp === false
        "
      >
        {{ product?.currentWeeklyMovement?.rateLabel || '0%' }}
        <ion-icon
          *ngIf="product?.currentWeeklyMovement?.isTrendingUp !== undefined"
          [name]="
            product?.currentWeeklyMovement?.isTrendingUp
              ? 'arrow-up-circle'
              : 'arrow-down-circle'
          "
          class="rate-of-movement-trend-icon"
          data-cy="rate-of-movement-icon"
        ></ion-icon>
      </dd>
    </ng-container>
  </dl>
</section>
