<div class="modal-content" id="product-carousel-images">
  <div class="header">
    <ion-buttons slot="end">
      <ion-button
        *ngIf="(platformService?.isTouchExperience$ | async) === false"
        id="close-modal-btn"
        data-cy="close-carousel-images-modal-button"
        (click)="closeModal()"
      >
        <ion-icon
          slot="icon-only"
          name="close-outline"
          data-cy="carousel-images-close-icon"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>
  <div class="modal-body">
    <div class="carousel-component">
      <app-product-detail-viewer
        appTestId="pdp-summary-image-viewer"
        [product]="product?.summary"
        [showNoImageDescription]="true"
        [isModalView]="true"
        [activeSlide]="activeSlide"
        [sequenceVideoId]="'2'"
      ></app-product-detail-viewer>
    </div>
  </div>
  <ion-footer
    class="ion-footer-modal"
    *ngIf="(platformService?.isTouchExperience$ | async) === true"
  >
    <ion-toolbar>
      <ion-buttons>
        <ion-button
          id="close-modal-btn2"
          data-cy="close-modal-btn2"
          class="usf-fill-green-button"
          (click)="closeModal()"
        >
          {{ 'i18n.common.close' | translate }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</div>
