import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { ProductInventoryService } from '@product-detail/services/product-inventory.service';

@Component({
  selector: 'app-seller-detail-description-inventory',
  templateUrl: './seller-detail-description-inventory.component.html',
  styleUrls: ['./seller-detail-description-inventory.component.scss'],
})
export class SellerDetailDescriptionInventoryComponent
  implements OnInit, OnChanges
{
  @Input() product: ProductDetailViewModel;

  inventoryLabel = '';
  inventoryValue: string;
  displayEaches = false;

  constructor(private productInventoryService: ProductInventoryService) {}

  @Input()
  set eaches(value) {
    this.displayEaches = typeof value !== 'undefined' && value !== false;
  }

  ngOnInit() {
    this.defineValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.product?.currentValue) {
      this.defineValues();
    }
  }

  defineValues(): void {
    this.defineInventoryLabel();
    this.getInventoryValue();
    this.inventoryValue = this.inventoryQuantityMessage();
  }

  defineInventoryLabel(): void {
    this.inventoryLabel = this.productInventoryService.defineInventoryLabel(
      this.displayEaches,
      this.product?.summary?.properties,
      this.product?.summary,
      this.product?.inventory,
    );
  }

  getInventoryValue(): void {
    this.inventoryValue = this.productInventoryService.getInventoryValueFormat(
      this.product?.inventory,
      this.displayEaches,
    );
  }

  inventoryQuantityMessage(): string {
    return this.productInventoryService.inventoryQuantityMessage(
      this.inventoryValue,
      this.displayEaches,
      this.product?.summary?.properties,
      this.product?.summary,
      this.product?.inventory,
      this.product?.hideOutOfStock,
    );
  }
}
