import { Injectable } from '@angular/core';
import { PanAppState } from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import { Product } from '@shared/models/product.model';
import { Customer } from '@usf/customer-types';
import { ProductPropertiesEnum } from '@usf/product-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductShippingPriceService {
  constructor(private panAppState: PanAppState) {}

  static doesProductHaveShippingPrice = (
    shippingPriceFlag: boolean,
    product: Product,
    selectedCustomer: Customer,
  ) =>
    shippingPriceFlag &&
    !product?.notFound &&
    product?.summary?.properties?.has(ProductPropertiesEnum.direct) &&
    !product?.summary?.properties?.has(ProductPropertiesEnum.specialOrder) &&
    selectedCustomer?.ogPrintPriceInd !== 'N' &&
    product?.pricing?.unitPrice * 1 > 0 &&
    !!product?.pricing?.unitPriceBeforeShipping &&
    !!product?.pricing?.unitShippingCharge;

  isProductShippingPriceEnabled$(): Observable<boolean> {
    return this.panAppState.feature$([
      FEATURES.split_customization_direct_separate_shipping.name,
    ]);
  }
}
