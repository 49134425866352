<div *ngIf="!isDiscontinued" class="product-inventory-container">
  <label class="inventory-availability">
    <div
      [class.wrap-text]="inventoryValue === 'Not Available'"
      data-cy="inventory-label-text"
    >
      {{ inventoryLabel | translate }}
      <span data-cy="inventory-value-text">
        {{ inventoryValue }}
      </span>
    </div>
  </label>
</div>
