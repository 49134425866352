import { Component, Input } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';

@Component({
  selector: 'app-title-dropdown',
  templateUrl: './title-dropdown.component.html',
  styleUrls: ['./title-dropdown.component.scss'],
})
export class TitleDropdownComponent {
  selectedTitle: string;
  @Input() titles: string[];

  constructor(public nav: IonNav, readonly platformService: PlatformService) {}

  onSelectState(mrChange: MatRadioChange) {
    this.selectedTitle = mrChange.value;
  }

  returnToCreateNonUsfVendorForm() {
    this.nav.getPrevious().then(data => {
      data.params.selectedTitleInput = this.selectedTitle;
      this.nav.pop();
    });
  }
}
