<div
  class="seller-create-opportunity-container"
  appTestId="create-opportunity-modal"
>
  <div class="header">
    <h2
      class="title"
      data-cy="create-opportunity-header-title-text"
      appTestId="create-opportunity-header-title"
    >
      {{
        (formStep === OpportunityFormSteps.step1
          ? 'i18n.productDetail.createAnOpportunityModal.title'
          : 'i18n.productDetail.createAnOpportunityModal.titleCustomerNote'
        ) | translate
      }}
    </h2>
  </div>

  <ion-content
    [ngClass]="
      (platformService?.isTouchExperience$ | async)
        ? platformService?.platformType ===
          platformService?.platformEnum?.tablet
          ? 'ion-content-modal-tablet'
          : 'ion-content-modal-mobile'
        : 'ion-content-modal-desktop'
    "
  >
    <div class="content">
      <div class="product-summary">
        <div class="left-product-summary-section">
          <div class="product-card">
            <div
              class="product-image"
              appTestId="create-opportunity-summary-image"
            >
              <img
                src="{{ thumbnailImageURL }}"
                data-cy="create-opportunity-product-image"
                (error)="
                  this.thumbnailImageURL =
                    getAlternativeThumbnail(thumbnailImageURL)
                "
                *ngIf="
                  (panAppState.online$ | async) === true && !!thumbnailImageURL
                "
              />
              <ion-icon
                name="restaurant"
                *ngIf="
                  (panAppState.online$ | async) === false || !thumbnailImageURL
                "
                class="no-image"
                data-cy="create-opportunity-no-image-icon"
              ></ion-icon>
            </div>
            <div class="product-info">
              <span
                class="brand"
                [attr.data-cy]="
                  'create-opportunity-product-summary-brand-' +
                  product?.productNumber +
                  '-text'
                "
                appTestId="create-opportunity-summary-brand"
                >{{ product?.summary?.brand | acronymTitleCase }}</span
              >
              <span
                class="product-title"
                [attr.data-cy]="
                  'create-opportunity-product-description-' +
                  product?.productNumber +
                  '-text'
                "
                appTestId="create-opportunity-summary-product-desc"
                >{{
                  product?.summary?.productDescTxtl ||
                    'i18n.productDetail.productDescriptionNotAvailableMessage'
                    | translate
                }}</span
              >
              <div class="additional-info">
                <span
                  class="product-number"
                  [attr.data-cy]="
                    'create-opportunity-product-number-' +
                    product?.productNumber +
                    '-text'
                  "
                  appTestId="create-opportunity-summary-product-number"
                  >#{{ product?.summary?.productNumber }}</span
                >
                <span class="separator" data-cy="seperator-line"></span>
                <span
                  class="pack-size"
                  data-cy="product-pack-size-text"
                  appTestId="create-opportunity-summary-pack-size"
                  >{{ product?.summary?.salesPackSize | lowercase }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="current-price"
            *ngIf="!pricingDisabled"
            appTestId="create-opportunity-summary-price-container"
          >
            <span data-cy="create-opportunity-current-price-text">
              {{
                'i18n.productDetail.createAnOpportunityModal.currentPrice'
                  | translate
              }}:&nbsp;</span
            >
            <span
              data-cy="create-opportunity-price-details-text"
              *ngIf="product?.pricing?.unitPrice * 1 > 0"
              appTestId="create-opportunity-summary-unit-price"
              >{{ product?.pricing?.unitPrice | currency }}&nbsp;{{
                product?.summary?.catchWeightFlag
                  ? '/' + product?.summary?.priceUom
                  : product?.summary?.priceUom
              }}</span
            >
            <ng-container *ngIf="product?.summary?.breakable">
              <span
                data-cy="create-opportunity-price-details-text"
                *ngIf="
                  product?.pricing?.unitPrice * 1 > 0 &&
                  product?.pricing?.eachPrice * 1 > 0
                "
                >,&nbsp;</span
              >
              <span
                data-cy="create-opportunity-price-details-text"
                *ngIf="product?.pricing?.eachPrice * 1 > 0"
                appTestId="create-opportunity-summary-each-price"
                >{{ product?.pricing?.eachPrice | currency }}&nbsp;{{
                  product?.summary?.eachUom
                }}</span
              >
            </ng-container>
          </div>
          <div class="current-price" *ngIf="pricingDisabled">
            <span data-cy="no-price-text">
              {{ 'i18n.lists.noPrice' | translate }}
            </span>
          </div>
          <div
            *ngIf="formStep === OpportunityFormSteps.step2"
            class="additional-information"
          >
            <div
              class="additional-information-title"
              data-cy="create-opportunity-additional-info-text"
            >
              Additional Opportunity Information
              <span
                (click)="toggleInformation()"
                class="toggle-icon"
                data-cy="toggle-icon"
              >
                <img
                  [ngClass]="informationToggle ? 'arrow-top' : 'arrow-down'"
                  src="../../../../assets/images/arrow-top.png"
                  data-cy="toggle-image"
                />
              </span>
            </div>
            <div *ngIf="additionalInformation && informationToggle">
              <div
                class="additional-information-text"
                data-cy="create-opportunity-additional-information-text"
              >
                <b data-cy="customer-details-additional-information-text">
                  {{ 'i18n.additionalInformation.customer' | translate }}:</b
                >
                {{ product.selectedCustomer.customerName }} ({{
                  product.selectedCustomer.customerNumber
                }})
              </div>
              <div
                class="additional-information-text"
                data-cy="create-opportunity-additional-information-text"
              >
                <b data-cy="publish-opportunity-to-ecom-text">
                  {{
                    'i18n.additionalInformation.publishToEcom' | translate
                  }}:</b
                >
                {{ additionalInformation.publishOpportunity }}
              </div>
              <div
                class="additional-information-text"
                data-cy="create-opportunity-additional-information-text"
              >
                <b data-cy="opportunity-name-text">
                  {{
                    'i18n.additionalInformation.opportunityName' | translate
                  }}:</b
                >
                {{ additionalInformation.opportunityName }}
              </div>
              <div
                class="additional-information-text"
                data-cy="create-opportunity-additional-information-text"
              >
                <b data-cy="weekly-cases-text">
                  {{ 'i18n.additionalInformation.weeklyCases' | translate }}:</b
                >
                {{ additionalInformation.weeklyCases }}
              </div>
              <div
                *ngIf="product?.summary?.breakable"
                class="additional-information-text"
                data-cy="create-opportunity-additional-information-text"
              >
                <b data-cy="weekly-eaches-text">
                  {{
                    'i18n.additionalInformation.weeklyEaches' | translate
                  }}:</b
                >
                {{ additionalInformation.weeklyEaches }}
              </div>
              <div
                class="additional-information-text"
                data-cy="create-opportunity-additional-information-text"
              >
                <b data-cy="weekly-potential0-text"
                  >{{
                    'i18n.additionalInformation.weeklyPotential' | translate
                  }}:</b
                >
                $ {{ additionalInformation.weeklyPotential }}
              </div>
              <div
                class="additional-information-text"
                data-cy="create-opportunity-additional-information-text"
              >
                <b data-cy="estimated-ship-date-text"
                  >{{
                    'i18n.additionalInformation.estimatedShipDate' | translate
                  }}:</b
                >
                {{ additionalInformation.estimatedShipDate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="form-container"
        *ngIf="formStep === OpportunityFormSteps.step1"
      >
        <app-seller-create-opportunity-form
          [disabledPrices]="pricingDisabled"
          [showEaches]="product?.summary?.breakable"
          [productInformation]="product"
          (updateFormValidity)="formValidity($event)"
        >
        </app-seller-create-opportunity-form>
      </div>
      <div
        class="form-container"
        *ngIf="formStep === OpportunityFormSteps.step2"
      >
        <app-seller-create-opportunity-form2
          (updateFormValidity)="formValidity($event)"
          [selectedTitle]="selectedTitleInput"
        >
        </app-seller-create-opportunity-form2>
      </div>
    </div>
  </ion-content>

  <ion-footer class="ion-footer-modal">
    <ion-toolbar>
      <ion-buttons class="opportunity-footer-buttons">
        <ion-button
          id="cancel-modal-btn"
          data-cy="cancel-modal-btn"
          [ngClass]="
            platformService?.platformType !==
            platformService?.platformEnum?.mobile
              ? 'usf-outline-green-dsktp-tblt-modal-button'
              : 'usf-outline-green-button'
          "
          (click)="closeModal()"
        >
          {{ 'i18n.common.cancel' | translate }}
        </ion-button>

        <ion-button
          *ngIf="
            publishOpportunity === OpportunityPublishOpportunity.NO ||
            formStep === OpportunityFormSteps.step2
          "
          id="submit-modal-btn"
          data-cy="submit-modal-btn"
          [ngClass]="
            platformService?.platformType !==
            platformService?.platformEnum?.mobile
              ? validSubmission
                ? 'usf-fill-green-dsktp-tblt-modal-button'
                : 'usf-fill-disabled-dsktp-tblt-modal-button'
              : validSubmission
                ? 'usf-fill-green-button'
                : 'usf-fill-disabled-button'
          "
          (click)="createOpportunity()"
        >
          {{ 'i18n.common.submit' | translate }}
        </ion-button>
        <ion-button
          *ngIf="
            publishOpportunity === OpportunityPublishOpportunity.YES &&
            formStep !== OpportunityFormSteps.step2
          "
          id="submit-modal-btn2"
          data-cy="submit-modal-btn2"
          [ngClass]="
            platformService?.platformType !==
            platformService?.platformEnum?.mobile
              ? validSubmission
                ? 'usf-fill-green-dsktp-tblt-modal-button'
                : 'usf-fill-disabled-dsktp-tblt-modal-button'
              : validSubmission
                ? 'usf-fill-green-button'
                : 'usf-fill-disabled-button'
          "
          (click)="continue()"
        >
          {{ 'i18n.common.continue' | translate }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</div>
