<ion-header
  class="ion-no-border"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="(platformService?.isTouchExperience$ | async) === false"
      slot="end"
    >
      <ion-button
        id="close-modal-btn"
        data-cy="close-cpr-manual-modal-button"
        (click)="dismissModal(false)"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  data-cy="cpr-manual-modal-prompt-area-text"
  class="simple-action-modal_content"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <div class="content">
    <img
      class="alert-icon-border"
      src="../../../../../assets/images/alert-yellow.svg"
    />

    <div>
      <h3>
        {{
          'i18n.productDetail.customPricingRationaleModal.manualModalTitle'
            | translate
        }}
      </h3>

      <p>
        {{
          'i18n.productDetail.customPricingRationaleModal.manualModalQuestion1'
            | translate
        }}
        {{ priceToUpdate | formatCurrencyAndDecimalPipe }}
        {{
          'i18n.productDetail.customPricingRationaleModal.manualModalQuestion2'
            | translate
        }}
      </p>
      <div>
        <p>
          {{
            'i18n.productDetail.customPricingRationaleModal.manualReason'
              | translate
          }}
        </p>
        <ion-item data-cy="download-order-modal-order-section">
          <ion-select
            aria-labelledby="download-format"
            [interface]="'popover'"
            [interfaceOptions]="selectPopoverOptions"
            [(ngModel)]="selectedReason"
            toggleIcon="chevron-down-outline"
            expandedIcon="chevron-up-outline"
            placeholder="{{
              'i18n.productDetail.customPricingRationaleModal.optional'
                | translate
            }}"
          >
            <ion-select-option
              *ngFor="let reason of reasonOptions"
              value="{{ reason | translate }}"
              >{{ reason | translate }}</ion-select-option
            >
          </ion-select>
        </ion-item>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$ | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="no-modal-btn"
        data-cy="no-cpr-manual-modal-button"
        [ngClass]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismissModal(false)"
      >
        {{ 'i18n.common.no' | translate }}
      </ion-button>
      <ion-button
        id="yes-modal-btn"
        data-cy="yes-cpr-manual-modal-button"
        [ngClass]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="dismissModal(true)"
      >
        {{ 'i18n.common.yes' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
