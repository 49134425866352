import { Injectable } from '@angular/core';
import { ModalController, ModalOptions } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { CprManualModalComponent } from '@shared/components/cpr-modal/cpr-manual-modal/cpr-manual-modal.component';
import { ModalWithNavComponent } from '@shared/components/modal-with-nav/modal-with-nav.component';
import { ModalService } from '@shared/services/modal/modal.service';

@Injectable({
  providedIn: 'root',
})
export class CustomPricingRationaleManualModalService {
  isManual: string;
  reason: string;
  constructor(
    private modalController: ModalController,
    private modalService: ModalService,
    private platformService: PlatformService,
  ) {}

  //Making this a drawer component for the order page.
  //If PDP team needs it to be a drawer too, you can just remove the 'isDrawerComponent' check in the if condition
  async openModal(priceToUpdate: number, isDrawerComponent?: boolean) {
    if (isDrawerComponent && this.platformService.isTouch.value) {
      this.modalService.setModalOptions(
        true,
        ModalWithNavComponent,
        {
          rootPage: CprManualModalComponent,
          rootPageParams: {
            priceToUpdate: priceToUpdate,
          },
        },
        'modal-desktop-size-md',
      );
      const modal = await this.modalController.create(
        this.modalService.modalOptions,
      );
      await modal.present();
      await modal.onDidDismiss().then((resp: any) => {
        this.isManual = resp?.data.isManual;
        this.reason = resp?.data.reason;
      });
    } else {
      const modalOptions: ModalOptions = {
        component: CprManualModalComponent,
        componentProps: {
          priceToUpdate,
        },
        cssClass: 'modal-desktop-size-md',
      };
      const modal = await this.modalController.create(modalOptions);
      await modal.present();
      await modal.onDidDismiss().then((resp: any) => {
        this.isManual = resp?.data.isManual;
        this.reason = resp?.data.reason;
      });
    }
  }
}
