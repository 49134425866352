<ion-header
  class="ion-no-border"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-mobile'
  "
>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        data-cy="back-modal-btn"
        text=""
        (click)="returnToCreateNonUsfVendorForm()"
      ></ion-back-button>
    </ion-buttons>
    <ion-label>
      {{
        'i18n.productDetail.createAnOpportunityModal.form2.noteTitle'
          | translate
      }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="create-edit-non-usf-vendor-input_content"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-mobile'
  "
>
  <mat-radio-group
    class="mat-radio-options"
    [(ngModel)]="selectedTitle"
    (change)="onSelectState($event)"
  >
    <ion-item-divider></ion-item-divider>
    <ion-item
      [attr.data-cy]="'title-down-' + title + '-text'"
      *ngFor="let title of titles"
    >
      <ion-label data-cy="title-label-text">
        {{ title }}
      </ion-label>
      <mat-radio-button
        [value]="title"
        data-cy="title-button"
      ></mat-radio-button>
    </ion-item>
  </mat-radio-group>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="save-modal-btn"
        data-cy="save-modal-btn"
        [ngClass]="
          platformService.platformType === platformService.platformEnum.tablet
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        type="submit"
        (click)="returnToCreateNonUsfVendorForm()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
