<!-- ********** TEMPLATES ********** -->
<!-- Product Card Warning Message -->
<ng-template #ogWarningMessage>
  <p
    *ngIf="
      ellipsisClickData?.product?.productCardWarningMsg?.primaryText &&
      !ellipsisClickData?.product?.quickViewWarning?.message
    "
    data-cy="quick-view-warning-message-text"
    class="quick-view-warning-msg"
  >
    <ion-icon
      name="close-circle-outline"
      data-cy="quick-view-warning-message-text-icon"
    ></ion-icon>
    <strong>
      {{
        ellipsisClickData?.product?.productCardWarningMsg?.primaryText
          | translate
      }}
    </strong>
    <span data-cy="quick-view-warning-message-secondary-text">
      {{
        ellipsisClickData?.product?.productCardWarningMsg?.secondaryText
          | translate
      }}
    </span>
    <span data-cy="quick-view-warning-message-secondary-text-date">
      {{
        ellipsisClickData?.product?.productCardWarningMsg?.secondaryTextDate
          | translate
      }}
    </span>
    <span data-cy="quick-view-warning-message-bold-text">
      <b>{{
        ellipsisClickData?.product?.productCardWarningMsg?.boldedText
          | translate
      }}</b>
    </span>
  </p>
  <div
    *ngIf="
      ellipsisClickData?.product?.hasAlternative &&
      ellipsisClickData?.product?.alternative &&
      ellipsisClickData?.product?.quickViewWarning?.message
    "
    class="quick-view-warning"
  >
    <app-usf-product-quick-view-warning
      [quickViewWarning]="ellipsisClickData?.product?.quickViewWarning"
      [hasAlternative]="true"
    ></app-usf-product-quick-view-warning>
  </div>
</ng-template>

<!-- Brand, Product Description, Product Number, Sales Pack Price -->
<ng-template #ogProductInfo>
  <div
    data-cy="quick-view-og-product-info-section"
    class="quick-view-og-main-info"
  >
    <p data-cy="quick-view-og-product-brand-text" class="quick-view-reg-txt">
      {{
        (ellipsisClickData?.product?.summary?.brand | acronymTitleCase) || ''
      }}
    </p>
    <p
      data-cy="quick-view-og-product-description-text"
      class="quick-view-bold-txt"
    >
      {{
        (ellipsisClickData?.product?.summary?.productDescTxtl
          | acronymTitleCase) || ' '
      }}
    </p>
    <div
      data-cy="quick-view-og-product-sub-info-section"
      class="quick-view-og-other-info"
    >
      <p data-cy="quick-view-og-product-number-text">
        #{{ ellipsisClickData?.product?.summary?.productNumber || ' ' }}
      </p>
      <span data-cy="quick-view-og-product-separator-text">|</span>
      <p data-cy="quick-view-og-product-sales-pack-size-text">
        {{ ellipsisClickData?.product?.summary?.salesPackSize }}
      </p>
      <span
        *ngIf="ellipsisClickData?.product?.item?.customerProductNumber"
        data-cy="quick-view-og-product-separator-text"
      >
        |
      </span>
      <p
        *ngIf="ellipsisClickData?.product?.item?.customerProductNumber"
        data-cy="quick-view-og-product-customer-number-text"
      >
        {{ 'i18n.lists.custProductNo' | translate }}
        {{ ellipsisClickData?.product?.item?.customerProductNumber }}
      </p>
      <span
        *ngIf="ellipsisClickData?.product?.item?.note"
        data-cy="quick-view-og-product-separator-text"
      >
        |
      </span>
      <p
        *ngIf="ellipsisClickData?.product?.item?.note"
        data-cy="quick-view-og-product-note-text"
      >
        <ion-icon
          data-cy="quick-view-og-product-note-text-icon"
          name="document-text-outline"
        ></ion-icon>
        {{ ellipsisClickData?.product?.item?.note }}
      </p>
    </div>
  </div>
</ng-template>

<!-- Product Tags -->
<ng-template #ogProductTags>
  <div
    *ngIf="ogProductTagsList?.length"
    data-cy="quick-view-og-product-tags-section"
    class="quick-view-og-tags"
  >
    <app-usf-product-tag-list [tagList]="ogProductTagsList?.slice(0, 2)">
    </app-usf-product-tag-list>
    <span
      *ngIf="ogProductTagsList?.length > 2"
      data-cy="quick-view-og-product-extra-tags-text"
    >
      {{ '+' + (ogProductTagsList?.length - 2) }}
    </span>
  </div>
</ng-template>

<!-- Pricing (Cases) -->
<ng-template #ogProductPricingCases>
  <div
    class="quick-view-og-cases"
    data-cy="quick-view-og-product-cases-section"
  >
    <app-product-inventory-info
      [productInventory]="ellipsisClickData?.product?.inventory"
      [productProperties]="ellipsisClickData?.product?.summary?.properties"
      [productSummary]="ellipsisClickData?.product?.summary"
      [hideOutOfStock]="ellipsisClickData?.product?.hideOutOfStock"
    ></app-product-inventory-info>
    <app-quantity-input
      [productNumber]="ellipsisClickData?.product?.productNumber"
      [quantity]="(orderItem$ | async)?.unitsOrdered"
      [isDisabled]="!ellipsisClickData?.product?.orderable ? true : false"
      [isOffline]="false === (panAppState.online$ | async)"
      [isEaches]="false"
      [platform]="platformService?.platformType"
      (quantityUpdateEmitter)="onQuantityUpdate($event)"
    >
    </app-quantity-input>
    <span
      *ngIf="
        !ellipsisClickData?.pricingDisabled &&
        ellipsisClickData?.product?.pricing?.unitPrice * 1 > 0
      "
      data-cy="quick-view-og-product-unit-price-text"
    >
      {{
        (ellipsisClickData?.product?.pricing?.unitPrice | currency) ||
          'loading..'
      }}
      {{ ellipsisClickData?.product?.summary?.priceUom || 'CS' }}
    </span>
    <span
      *ngIf="
        ellipsisClickData?.pricingDisabled ||
        ellipsisClickData?.product?.pricing?.unitPrice * 1 === 0
      "
      data-cy="quick-view-og-product-unit-price-text"
    >
      {{ 'i18n.lists.noPrice' | translate }}
    </span>
  </div>
</ng-template>

<!-- Pricing (Eaches) -->
<ng-template #ogProductPricingEaches>
  <div
    class="quick-view-og-eaches"
    data-cy="quick-view-og-product-eaches-section"
  >
    <app-product-inventory-info
      [productInventory]="ellipsisClickData?.product?.inventory"
      [productProperties]="ellipsisClickData?.product?.summary?.properties"
      [productSummary]="ellipsisClickData?.product?.summary"
      [hideOutOfStock]="ellipsisClickData?.product?.hideOutOfStock"
      eaches
    ></app-product-inventory-info>
    <app-quantity-input
      [productNumber]="ellipsisClickData?.product?.productNumber"
      [quantity]="(orderItem$ | async)?.eachesOrdered"
      [isDisabled]="!ellipsisClickData?.product?.orderable ? true : false"
      [isOffline]="false === (panAppState.online$ | async)"
      [isEaches]="true"
      [platform]="platformService?.platformType"
      (quantityUpdateEmitter)="onQuantityUpdate($event)"
    >
    </app-quantity-input>
    <span
      *ngIf="
        !ellipsisClickData?.pricingDisabled &&
        ellipsisClickData?.product?.pricing?.eachPrice * 1 > 0
      "
      data-cy="quick-view-og-product-eaches-price-text"
    >
      {{
        (ellipsisClickData?.product?.pricing?.eachPrice | currency) ||
          'loading..'
      }}
      {{
        (ellipsisClickData?.product?.summary?.catchWeightFlag
          ? ellipsisClickData?.product?.summary?.priceUom
          : ellipsisClickData?.product?.summary?.eachUom) || 'EA'
      }}
    </span>
    <span
      *ngIf="
        ellipsisClickData?.pricingDisabled ||
        ellipsisClickData?.product?.pricing?.eachPrice * 1 === 0
      "
      data-cy="quick-view-og-product-eaches-price-text"
    >
      {{ 'i18n.lists.noPrice' | translate }}
    </span>
  </div>
</ng-template>

<ion-header
  class="ion-no-border quick-view_header"
  [class.split-card]="
    ellipsisClickData?.product?.hasAlternative &&
    ellipsisClickData?.product?.alternative &&
    selectedSegment !== 'sellersQuickView'
  "
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="(platformService?.isTouchExperience$ | async) === false"
      slot="end"
    >
      <ion-button
        data-cy="quick-view-close-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon
          data-cy="quick-view-close-modal-button-icon"
          slot="icon-only"
          name="close-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons>

    <!-- ********** SPLIT CARD - ORIGINAL PRODUCT CARD ********** -->
    <ng-container
      *ngIf="
        ellipsisClickData?.product?.hasAlternative &&
        ellipsisClickData?.product?.alternative
      "
    >
      <ion-grid>
        <ng-container
          *ngIf="selectedSegment === 'quickView'; else splitOgProductStatus"
        >
          <!-- DESKTOP/TABLET -->
          <ion-row
            *ngIf="
              platformService?.platformType !==
                platformService?.platformEnum?.mobile;
              else mobileProductCardWithAlt
            "
          >
            <ion-col size="5.875">
              <ng-container
                [ngTemplateOutlet]="ogWarningMessage"
              ></ng-container>

              <ng-container [ngTemplateOutlet]="ogProductInfo"></ng-container>
              <ng-container [ngTemplateOutlet]="ogProductTags"></ng-container>
            </ion-col>
            <ion-col offset="0.25" size="5.875" class="adjust-top">
              <ion-row>
                <div class="quick-view-og-pricing">
                  <ng-container
                    [ngTemplateOutlet]="ogProductPricingCases"
                  ></ng-container>
                  <ng-container
                    *ngIf="ellipsisClickData?.product?.summary?.breakable"
                    [ngTemplateOutlet]="ogProductPricingEaches"
                  ></ng-container>
                </div>
              </ion-row>
              <ion-row>
                <div class="quick-view-og-btns">
                  <ng-container *ngIf="selectedGroup$ | async as selectedGroup">
                    <ng-container
                      *ngIf="
                        selectedGroupListItems$
                          | async as selectedGroupListItems
                      "
                    >
                      <ion-button
                        class="usf-outline-orange-button"
                        data-cy="quick-view-delete-product-from-list-button"
                        (click)="
                          deleteProductFromList(
                            selectedGroup,
                            selectedGroupListItems
                          )
                        "
                      >
                        {{ 'i18n.productDetail.remove' | translate }}
                      </ion-button>
                    </ng-container>
                  </ng-container>
                  <ion-button
                    *ngIf="isCustomerUnrestricted"
                    class="usf-fill-green-button"
                    data-cy="quick-view-search-for-replacement-or-subs-button"
                    (click)="
                      searchReplacement(
                        ellipsisClickData?.product?.summary,
                        ellipsisClickData?.product?.trackingAttributes
                      )
                    "
                  >
                    {{
                      (ellipsisClickData?.product?.alternative?.type ===
                      productAlternativeTypes.replacement
                        ? 'i18n.productDetail.searchForReplacement'
                        : 'i18n.productDetail.searchForSubstitute'
                      ) | translate
                    }}
                  </ion-button>
                </div>
              </ion-row>
            </ion-col>
          </ion-row>

          <!-- MOBILE -->
          <ng-template #mobileProductCardWithAlt>
            <ion-row>
              <ng-container
                [ngTemplateOutlet]="ogWarningMessage"
              ></ng-container>
            </ion-row>
            <ion-row>
              <ng-container [ngTemplateOutlet]="ogProductInfo"></ng-container>
            </ion-row>
            <ion-row>
              <ng-container [ngTemplateOutlet]="ogProductTags"></ng-container>
            </ion-row>
            <ion-row>
              <div class="quick-view-og-pricing">
                <ng-container
                  [ngTemplateOutlet]="ogProductPricingCases"
                ></ng-container>
                <ng-container
                  *ngIf="ellipsisClickData?.product?.summary?.breakable"
                  [ngTemplateOutlet]="ogProductPricingEaches"
                ></ng-container>
              </div>
            </ion-row>
            <ion-row>
              <div class="quick-view-og-btns">
                <ng-container *ngIf="selectedGroup$ | async as selectedGroup">
                  <ng-container
                    *ngIf="
                      selectedGroupListItems$ | async as selectedGroupListItems
                    "
                  >
                    <ion-button
                      class="usf-outline-orange-mobile-modal-button"
                      data-cy="quick-view-delete-products-from-list-buttom"
                      (click)="
                        deleteProductFromList(
                          selectedGroup,
                          selectedGroupListItems
                        )
                      "
                    >
                      {{ 'i18n.productDetail.remove' | translate }}
                    </ion-button>
                  </ng-container>
                </ng-container>
                <ion-button
                  *ngIf="isCustomerUnrestricted"
                  class="usf-fill-green-mobile-modal-button"
                  data-cy="quick-view-search-for-replacement-or-subs-button"
                  (click)="
                    searchReplacement(
                      ellipsisClickData?.product?.summary,
                      ellipsisClickData?.product?.trackingAttributes
                    )
                  "
                >
                  {{
                    (ellipsisClickData?.product?.alternative?.type ===
                    productAlternativeTypes.replacement
                      ? 'i18n.productDetail.searchForReplacement'
                      : 'i18n.productDetail.searchForSubstitute'
                    ) | translate
                  }}
                </ion-button>
              </div>
            </ion-row>
          </ng-template>
        </ng-container>

        <ng-template #splitOgProductStatus>
          <ng-container *ngIf="selectedSegment === 'productStatus'">
            <app-usf-product-quick-view-warning
              *ngIf="ellipsisClickData?.product?.quickViewWarning?.message"
              [isProductStatus]="true"
              [hasAlternative]="true"
              [quickViewWarning]="ellipsisClickData?.product?.quickViewWarning"
            ></app-usf-product-quick-view-warning>
            <ion-row class="product-detail-status">
              <div
                data-cy="quick-view-og-product-info-section"
                class="quick-view-og-main-info"
              >
                <p
                  data-cy="quick-view-og-product-brand-text"
                  class="quick-view-reg-txt"
                >
                  {{
                    (ellipsisClickData?.product?.summary?.brand
                      | acronymTitleCase) || ''
                  }}
                </p>
                <p
                  data-cy="quick-view-og-product-description-text"
                  class="quick-view-bold-txt"
                >
                  {{
                    (ellipsisClickData?.product?.summary?.productDescTxtl
                      | acronymTitleCase) || ' '
                  }}
                </p>
              </div>
            </ion-row>
            <div
              class="product-detail-status-tags-info"
              [class.direct-recently-purchased-max-height]="
                hasDirectOrRecentlyPurchasedProp
              "
              [class.expanded]="isProductStatusExpanded"
            >
              <app-product-detail-status-tags-info
                [productDetailStatusData]="ogProdDetailStatusData"
                [isQuickView]="true"
              ></app-product-detail-status-tags-info>
            </div>
            <div *ngIf="showOutOfStockStatusBtn">
              <ion-button
                data-cy="quick-view-show-hide-product-tags-info-button"
                (click)="setIsProductDetailTagsInfoExpanded()"
              >
                {{ productStatusBtnTxt | translate }}
                <ion-icon
                  data-cy="quick-view-show-hide-product-tags-info-button-icon"
                  name="{{
                    isProductStatusExpanded
                      ? 'chevron-up-outline'
                      : 'chevron-down-outline'
                  }}"
                ></ion-icon>
              </ion-button>
            </div>
          </ng-container>
        </ng-template>
      </ion-grid>
    </ng-container>
  </ion-toolbar>
</ion-header>

<ion-content
  class="quick-view_content"
  [class.split-card]="
    ellipsisClickData?.product?.hasAlternative &&
    ellipsisClickData?.product?.alternative
  "
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <!-- ********** QUICK VIEW/SELLER DETAILS/PRODUCT STATUS TOGGLE ********** -->
  <div
    class="quick-view-options"
    [ngClass]="
      (isSellerDetailsVisible$ | async) ? 'full-quick-view-options' : ''
    "
    slot="fixed"
  >
    <ion-segment
      value="{{ selectedSegment }}"
      (ionChange)="setSelectedSegment($event)"
    >
      <ion-segment-button
        value="quickView"
        data-cy="quick-view-open-quick-view-segment-button"
      >
        <span>{{ 'i18n.lists.quickView' | translate }}</span>
      </ion-segment-button>
      <ion-segment-button
        *ngIf="isSellerDetailsVisible$ | async"
        value="sellersQuickView"
        data-cy="seller-details-open-seller-details-view-segment-button"
      >
        <span>{{ 'i18n.productDetail.sellersQuickView' | translate }}</span>
      </ion-segment-button>
      <ion-segment-button
        value="productStatus"
        data-cy="quick-view-open-product-status-segment-button"
      >
        <span>{{ 'i18n.productDetail.productStatus' | translate }}</span>
      </ion-segment-button>
    </ion-segment>
  </div>

  <!-- ********** ORIGINAL PRODUCT CARD - NO ALTERNATIVE ********** -->
  <ng-container *ngIf="!ellipsisClickData?.product?.hasAlternative">
    <ng-container
      *ngIf="selectedSegment === 'quickView'"
      [ngTemplateOutlet]="ogWarningMessage"
    ></ng-container>
    <app-usf-product-quick-view-info
      *ngIf="selectedSegment === 'quickView'; else ogProductStatus"
      [product]="ellipsisClickData?.product"
      [pricingDisabled]="ellipsisClickData?.pricingDisabled"
      [casesOrdered]="(orderItem$ | async)?.unitsOrdered"
      [eachesOrdered]="(orderItem$ | async)?.eachesOrdered"
      [productTags]="ogProductTagsList"
      [disableNotes]="disableNotes"
      [productDetail]="ogProdDetails"
      [claimTags]="ogClaimTags"
      [isOriginalProduct]="true"
      (quantityUpdateEmitter)="onQuantityUpdate($event)"
      [showSearchForSubs]="
        shouldAddSearchForSubs(ellipsisClickData?.product) &&
        !ellipsisClickData?.product?.hasAlternative
      "
      (searchForReplacementEmitter)="
        searchReplacement(
          ellipsisClickData?.product?.summary,
          ellipsisClickData?.product?.trackingAttributes
        )
      "
    >
    </app-usf-product-quick-view-info>

    <ng-template #ogProductStatus>
      <ion-grid
        *ngIf="selectedSegment === 'productStatus'; else ogSellerDetails"
      >
        <ion-row class="product-detail-status">
          <div
            class="quick-view-warning"
            *ngIf="ellipsisClickData?.product?.quickViewWarning?.message"
          >
            <app-usf-product-quick-view-warning
              [quickViewWarning]="ellipsisClickData?.product?.quickViewWarning"
              [isProductStatus]="true"
            ></app-usf-product-quick-view-warning>
          </div>
          <ion-col
            size-xl="5.75"
            size-lg="5.75"
            size-md="5.75"
            size-sm="12"
            size-xs="12"
          >
            <div class="quick-view-og-main-info">
              <p
                class="quick-view-reg-txt"
                data-cy="quick-view-og-product-brand-text"
              >
                {{
                  (ellipsisClickData?.product?.summary?.brand
                    | acronymTitleCase) || ''
                }}
              </p>
              <p
                class="quick-view-bold-txt"
                data-cy="quick-view-og-product-description-text"
              >
                {{
                  (ellipsisClickData?.product?.summary?.productDescTxtl
                    | acronymTitleCase) || ' '
                }}
              </p>
              <p
                *ngIf="
                  vendorMessage && ellipsisClickData?.product?.promotionDetails
                "
                data-cy="quick-view-og-product-vendor-text"
                class="quick-view-vendor-txt"
              >
                {{ vendorMessage }}
              </p>
            </div>
          </ion-col>
          <ion-col
            *ngIf="ellipsisClickData?.product?.promotionDetails"
            offset-xl="0.5"
            offset-lg="0.5"
            offset-md="0.5"
            size-xl="5.75"
            size-lg="5.75"
            size-md="5.75"
            size-sm="12"
            size-xs="12"
          >
            <ion-card
              data-cy="quick-view-og-product-promotions-summary-section"
              class="promotions-full-details"
            >
              <ion-card-header
                data-cy="quick-view-og-product-promotions-summary-header-text"
              >
                {{
                  ellipsisClickData?.product?.promotionDetails?.offerBenefitDesc
                }}
              </ion-card-header>
              <ion-card-content>
                <p data-cy="quick-view-og-product-promotions-summary-subtext">
                  {{
                    ellipsisClickData?.product?.promotionDetails
                      ?.offerValidFromDesc
                  }}&nbsp;
                  {{
                    ellipsisClickData?.product?.promotionDetails
                      ?.offerApplyEachDesc
                  }}
                </p>
                <ion-item-divider></ion-item-divider>
                <p data-cy="quick-view-og-product-promotions-summary-subtext">
                  {{
                    ellipsisClickData?.product?.promotionDetails
                      ?.offerQualification
                  }}
                </p>
                <p data-cy="quick-view-og-product-promotions-summary-subtext">
                  {{
                    ellipsisClickData?.product?.promotionDetails
                      ?.offerLegalDisclaimer
                  }}
                </p>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <app-product-detail-status-tags-info
          [productDetailStatusData]="ogProdDetailStatusData"
          [isQuickView]="true"
        ></app-product-detail-status-tags-info>
      </ion-grid>
    </ng-template>

    <ng-template #ogSellerDetails>
      <ng-container
        *ngIf="ogProdDetails && (sellerPriceDetails$ | async) as sellerPriceMap"
      >
        <ng-container
          *ngTemplateOutlet="
            sellerViewTab;
            context: {
              product: ogProdDetails,
              sellerPriceDetails: sellerPriceMap.get(
                ellipsisClickData?.product?.productNumber
              )?.sellerPriceDetails,
              lastFourBuys: sellerPriceMap.get(
                ellipsisClickData?.product?.productNumber
              )?.lastFourBuys,
              shouldDisplaySearchForSubsBtn: sellerPriceMap.get(
                ellipsisClickData?.product?.productNumber
              )?.shouldDisplaySearchForSubsBtn,
              shouldDisplayAddToListBtn: sellerPriceMap.get(
                ellipsisClickData?.product?.productNumber
              )?.shouldDisplayAddToListBtn,
            }
          "
        >
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-container>

  <!-- ********** SPLIT CARD - ORIGINAL PRODUCT CARD WITH ALTERNATIVE ********** -->
  <ng-container
    *ngIf="
      ellipsisClickData?.product?.hasAlternative &&
      ellipsisClickData?.product?.alternative
    "
  >
    <!-- ********** SPLIT CARD - ALTERNATIVE PRODUCT CARD ********** -->
    <app-usf-product-quick-view-info
      *ngIf="selectedSegment === 'quickView'; else altProductStatus"
      [productCardResolutionMsg]="
        ellipsisClickData?.product?.productCardResolutionMsg
      "
      [product]="ellipsisClickData?.product?.alternative?.product"
      [pricingDisabled]="ellipsisClickData?.pricingDisabled"
      [casesOrdered]="(altOrderItem$ | async)?.unitsOrdered"
      [eachesOrdered]="(altOrderItem$ | async)?.eachesOrdered"
      [productTags]="altProductTagsList"
      [quantityInputsDisabled]="false"
      [productDetail]="altProdDetails"
      [claimTags]="altClaimTags"
      [isOriginalProduct]="false"
      (quantityUpdateEmitter)="onQuantityUpdate($event)"
      [showSearchForSubs]="
        shouldAddSearchForSubs(ellipsisClickData?.product) &&
        !ellipsisClickData?.product?.hasAlternative
      "
      (searchForReplacementEmitter)="
        searchReplacement(
          ellipsisClickData?.product?.summary,
          ellipsisClickData?.product?.trackingAttributes
        )
      "
    ></app-usf-product-quick-view-info>

    <ng-template #altProductStatus>
      <ion-grid
        *ngIf="selectedSegment === 'productStatus'; else altSellerDetails"
      >
        <ion-row class="product-detail-status">
          <ion-col
            size-xl="5.75"
            size-lg="5.75"
            size-md="5.75"
            size-sm="12"
            size-xs="12"
          >
            <div class="quick-view-og-main-info">
              <p
                class="quick-view-reg-txt"
                data-cy="quick-view-alt-product-brand-text"
              >
                {{
                  (ellipsisClickData?.product?.alternative?.product?.summary
                    ?.brand | titlecase) || ''
                }}
              </p>
              <p
                class="quick-view-bold-txt"
                data-cy="quick-view-alt-product-description-text"
              >
                {{
                  (ellipsisClickData?.product?.alternative?.product?.summary
                    ?.productDescTxtl | titlecase) || ' '
                }}
              </p>
              <p
                *ngIf="
                  vendorMessage &&
                  ellipsisClickData?.product?.alternative?.product
                    ?.promotionDetails
                "
                data-cy="quick-view-alt-product-vendor-text"
                class="quick-view-vendor-txt"
              >
                {{ vendorMessage }}
              </p>
            </div>
          </ion-col>
          <ion-col
            *ngIf="
              ellipsisClickData?.product?.alternative?.product?.promotionDetails
            "
            offset-xl="0.5"
            offset-lg="0.5"
            offset-md="0.5"
            size-xl="5.75"
            size-lg="5.75"
            size-md="5.75"
            size-sm="12"
            size-xs="12"
          >
            <ion-card class="promotions-full-details">
              <ion-card-header
                data-cy="quick-view-alt-product-promotions-summary-header-text"
              >
                {{
                  ellipsisClickData?.product?.alternative?.product
                    ?.promotionDetails?.offerBenefitDesc
                }}
              </ion-card-header>
              <ion-card-content>
                <p data-cy="quick-view-alt-product-promotions-summary-subtext">
                  {{
                    ellipsisClickData?.product?.alternative?.product
                      ?.promotionDetails?.offerValidFromDesc
                  }}&nbsp;
                  {{
                    ellipsisClickData?.product?.alternative?.product
                      ?.promotionDetails?.offerApplyEachDesc
                  }}
                </p>
                <ion-item-divider></ion-item-divider>
                <p data-cy="quick-view-alt-product-promotions-summary-subtext">
                  {{
                    ellipsisClickData?.product?.alternative?.product
                      ?.promotionDetails?.offerQualification
                  }}
                </p>
                <p data-cy="quick-view-alt-product-promotions-summary-subtext">
                  {{
                    ellipsisClickData?.product?.alternative?.product
                      ?.promotionDetails?.offerLegalDisclaimer
                  }}
                </p>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <app-product-detail-status-tags-info
          [productDetailStatusData]="altProdDetailStatusData"
          [isQuickView]="true"
        ></app-product-detail-status-tags-info>
      </ion-grid>
    </ng-template>

    <ng-template #altSellerDetails>
      <ng-container
        *ngIf="ogProdDetails && (sellerPriceDetails$ | async) as sellerPriceMap"
      >
        <ng-container
          *ngTemplateOutlet="
            sellerViewTab;
            context: {
              product: ogProdDetails,
              sellerPriceDetails: sellerPriceMap.get(
                ellipsisClickData?.product?.productNumber
              )?.sellerPriceDetails,
              lastFourBuys: sellerPriceMap.get(
                ellipsisClickData?.product?.productNumber
              )?.lastFourBuys,
              shouldDisplaySearchForSubsBtn: sellerPriceMap.get(
                ellipsisClickData?.product?.productNumber
              )?.shouldDisplaySearchForSubsBtn,
              shouldDisplayAddToListBtn: sellerPriceMap.get(
                ellipsisClickData?.product?.productNumber
              )?.shouldDisplayAddToListBtn,
            }
          "
        >
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-container>

  <ng-template
    #sellerViewTab
    let-product="product"
    let-sellerPriceDetails="sellerPriceDetails"
    let-lastFourBuys="lastFourBuys"
    let-shouldDisplaySearchForSubsBtn="shouldDisplaySearchForSubsBtn"
    let-shouldDisplayAddToListBtn="shouldDisplayAddToListBtn"
  >
    <ion-grid
      [attr.data-cy]="'seller-view-tab-grid-' + product?.productNumber"
      *ngIf="
        platformService?.platformType !== platformService?.platformEnum?.mobile
      "
    >
      <ion-row data-cy="seller-view-tab-product-warning-row">
        <ion-col size="6" class="seller-view-tab-product-warning">
          <ng-container [ngTemplateOutlet]="ogWarningMessage"></ng-container>
        </ion-col>
      </ion-row>
      <ion-row data-cy="seller-view-tab-product-overview-row">
        <app-product-detail-summary-seller-overview
          [product]="product"
          [enabledButtons]="{
            createOpportunityBtn: product?.inventory?.isInStock,
            setCprBtn: product?.inventory?.isInStock,
            addToListBtn: shouldDisplayAddToListBtn,
            searchForSubBtn: shouldDisplaySearchForSubsBtn,
          }"
          [columnLayout]="true"
          [displayProductDetails]="true"
          [pricingDisabled]="ellipsisClickData?.pricingDisabled"
          [sellerPriceDetail]="sellerPriceDetails"
          (createOpportunity)="openSellerCreateAnOpportunityModal(product)"
          (setCPR)="
            openCustomPricingRationaleModal(product, sellerPriceDetails)
          "
          (addToList)="openAddToListModal(product)"
          (searchForSubstitute)="
            searchReplacement(product?.summary, product?.trackingAttributes)
          "
        >
        </app-product-detail-summary-seller-overview>
      </ion-row>
      <ion-row
        data-cy="seller-view-tab-product-details-row"
        class="seller-view-tab-row"
      >
        <ion-col data-cy="seller-view-tab-pricing-column" size="4">
          <app-seller-detail-description-pricing
            [product]="product"
            [sellerPriceDetail]="sellerPriceDetails"
          >
          </app-seller-detail-description-pricing>
        </ion-col>
        <ion-col data-cy="seller-view-tab-inventory-column" size="4">
          <app-seller-detail-description-inventory [product]="product">
          </app-seller-detail-description-inventory>
        </ion-col>
        <ion-col
          data-cy="seller-view-tab-next-restock-column"
          size="4"
          *ngIf="product?.summary?.poDates?.length > 0"
        >
          <app-seller-detail-decription-upcoming
            [poDates]="product?.summary?.poDates?.slice(0, 1)"
            [productNumber]="product?.productNumber"
          >
          </app-seller-detail-decription-upcoming>
        </ion-col>
      </ion-row>
      <ion-row
        *ngIf="lastFourBuys?.length"
        data-cy="seller-view-tab-last-buys-row"
      >
        <ng-container
          *ngTemplateOutlet="
            lastFourBuysSection;
            context: {
              lastFourBuys: lastFourBuys,
            }
          "
        >
        </ng-container>
      </ion-row>
      <ion-row data-cy="seller-view-tab-pdp-link-row">
        <span (click)="redirectToPdp(product)" class="seller-view-tab-link">
          {{
            'i18n.inventory.inventoryWorksheet.inventoryItemModal.visitPdp'
              | translate
          }}
        </span>
      </ion-row>
    </ion-grid>
    <ion-grid
      [attr.data-cy]="'seller-view-tab-grid-' + product?.productNumber"
      *ngIf="
        platformService?.platformType === platformService?.platformEnum?.mobile
      "
    >
      <ion-row>
        <ion-col
          class="seller-view-tab-column seller-view-tab-mobile-column"
          data-cy="seller-view-tab-mobile-column"
        >
          <ion-row
            data-cy="seller-view-tab-product-warning-row"
            class="seller-view-tab-product-warning"
          >
            <ng-container [ngTemplateOutlet]="ogWarningMessage"></ng-container>
          </ion-row>
          <ion-row data-cy="seller-view-tab-product-data-row">
            <app-product-detail-summary-seller-overview
              [product]="product"
              [enabledButtons]="{
                createOpportunityBtn: product?.inventory?.isInStock,
                setCprBtn: product?.inventory?.isInStock,
                addToListBtn: shouldDisplayAddToListBtn,
                searchForSubBtn: shouldDisplaySearchForSubsBtn,
              }"
              [columnLayout]="true"
              [displayProductDetails]="true"
              [pricingDisabled]="ellipsisClickData?.pricingDisabled"
              [sellerPriceDetail]="sellerPriceDetails"
              (createOpportunity)="openSellerCreateAnOpportunityModal(product)"
              (setCPR)="
                openCustomPricingRationaleModal(product, sellerPriceDetails)
              "
              (addToList)="openAddToListModal(product)"
              (searchForSubstitute)="
                searchReplacement(product?.summary, product?.trackingAttributes)
              "
            >
            </app-product-detail-summary-seller-overview>
          </ion-row>
          <ion-row data-cy="seller-view-tab-pricing-row">
            <app-seller-detail-description-pricing
              [product]="product"
              [sellerPriceDetail]="sellerPriceDetails"
            >
            </app-seller-detail-description-pricing>
          </ion-row>
          <ion-row data-cy="seller-view-tab-inventory-row">
            <app-seller-detail-description-inventory [product]="product">
            </app-seller-detail-description-inventory>
          </ion-row>
          <ion-row
            data-cy="seller-view-tab-next-restock-row"
            *ngIf="product?.summary?.poDates?.length > 0"
          >
            <app-seller-detail-decription-upcoming
              [poDates]="product?.summary?.poDates?.slice(0, 1)"
              [productNumber]="product?.productNumber"
            >
            </app-seller-detail-decription-upcoming>
          </ion-row>
          <ion-row
            *ngIf="lastFourBuys?.length"
            data-cy="seller-view-tab-last-buys-row"
            class="seller-view-tab-last-buys"
          >
            <ng-container
              *ngTemplateOutlet="
                lastFourBuysSection;
                context: {
                  lastFourBuys: lastFourBuys,
                }
              "
            >
            </ng-container>
          </ion-row>
          <ion-row data-cy="seller-view-tab-pdp-link-row">
            <span (click)="redirectToPdp(product)" class="seller-view-tab-link">
              {{
                'i18n.inventory.inventoryWorksheet.inventoryItemModal.visitPdp'
                  | translate
              }}
            </span>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-template>

  <ng-template #lastFourBuysSection let-lastFourBuys="lastFourBuys">
    <section class="last-4-buys-section" data-cy="last-4-buys-section">
      <div class="last-4-buys-section-header">
        <h4>
          {{
            'i18n.productDetail.sellerDetailMoreInformation.last4Buys'
              | translate
              | titlecase
          }}
        </h4>
        <ion-icon
          *ngIf="
            platformService?.platformType ===
            platformService?.platformEnum?.mobile
          "
          class="last-4-buys-icon"
          slot="end"
          [name]="
            last4BuysExpanded ? 'chevron-up-outline' : 'chevron-down-outline'
          "
          (click)="toggleLast4Buys()"
        ></ion-icon>
      </div>
      <div
        data-cy="last-4-buys-section-body"
        *ngIf="
          last4BuysExpanded ||
          platformService?.platformType !==
            platformService?.platformEnum?.mobile
        "
      >
        <app-seller-detail-more-information-last-four-buys
          [last4ProductsDataSource]="lastFourBuys"
        >
        </app-seller-detail-more-information-last-four-buys>
      </div>
    </section>
  </ng-template>
</ion-content>
