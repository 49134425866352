import { Component, Input } from '@angular/core';
import { IonNav, ModalController, ViewWillEnter } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ListAnalyticsService, ListConstants } from '@usf/ngrx-list';

@Component({
  template: '',
})
export class SortFilterListBaseComponent implements ViewWillEnter {
  @Input() groupNames: string[];
  @Input() productClasses: string[];
  @Input() selectedSort: ListConstants;
  @Input() selectedFilters: ListConstants[];
  @Input() selectedGroup: string = ListConstants.allGroups;
  @Input() isRecentPurchase: boolean;
  @Input() isMasterList: boolean;
  @Input() week52Filter: boolean;
  alphabeticalSelected = false;
  dateSelected = false;
  showProductsFromPastOptions = false;
  showSelectedUsFoodsClassOptions = false;
  listConstants = ListConstants;

  constructor(
    public nav: IonNav,
    private modalController: ModalController,
    readonly platformService: PlatformService,
    private listAnalyticsService: ListAnalyticsService,
  ) {}

  ionViewWillEnter() {
    if (this.selectedSort === ListConstants.alphabetical) {
      this.alphabeticalSelected = true;
    } else if (this.selectedSort === ListConstants.date) {
      this.dateSelected = true;
    }
  }

  get sortFilterType(): typeof ListConstants {
    return ListConstants;
  }

  setSort(sortOption: ListConstants) {
    this.selectedSort = sortOption;
    this.showProductsFromPastOptions = false;
    if (sortOption === ListConstants.alphabetical) {
      this.alphabeticalSelected = true;
      this.dateSelected = false;
      this.showSelectedUsFoodsClassOptions = false;
    } else if (sortOption === ListConstants.date) {
      this.dateSelected = true;
      this.alphabeticalSelected = false;
      this.showSelectedUsFoodsClassOptions = false;
    } else {
      this.selectedGroup = ListConstants.allGroups;
      this.alphabeticalSelected = false;
      this.dateSelected = false;
      this.showSelectedUsFoodsClassOptions = true;
    }
  }

  setFilters(filterOption: ListConstants) {
    this.showProductsFromPastOptions = false;
    this.showSelectedUsFoodsClassOptions = false;
    const index = this.selectedFilters.indexOf(filterOption, 0);
    const selectedFiltersCopy = this.selectedFilters.slice();
    if (index > -1) {
      selectedFiltersCopy.splice(index, 1);
    } else {
      selectedFiltersCopy.push(filterOption);
    }
    this.selectedFilters = selectedFiltersCopy;
  }

  clearAll() {
    this.selectedSort = ListConstants.groupLine;
    if (this.isRecentPurchase) {
      this.selectedSort = ListConstants.date;
    }
    if (this.selectedFilters && this.selectedFilters.length > 0) {
      this.listAnalyticsService.trackListDetailsFilters(null);
    }
    this.selectedFilters = [];
    this.selectedGroup = ListConstants.allGroups;
    this.alphabeticalSelected = false;
    this.dateSelected = true;
    this.showProductsFromPastOptions = false;
    this.showSelectedUsFoodsClassOptions = false;
    this.week52Filter = true;
  }

  async applyAll() {
    this.trackFiltersApplied(this.selectedFilters);
    await this.modalController.dismiss({
      sortMethod: this.selectedSort,
      groupToSort: this.selectedGroup,
      filters: this.selectedFilters,
      week52Filter: this.week52Filter,
    });
    this.showProductsFromPastOptions = false;
    this.showSelectedUsFoodsClassOptions = false;
  }

  trackFiltersApplied(selectedFilters: ListConstants[]) {
    if (selectedFilters && selectedFilters.length > 0) {
      let filterMap = new Map<string, boolean>();
      selectedFilters.forEach(filter => {
        filterMap.set(filter.toString(), true);
      });
      this.listAnalyticsService.trackListDetailsFilters(filterMap);
    }
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }

  onWeeksFilterChange(is52WeeksSelected: boolean) {
    this.week52Filter = is52WeeksSelected;
  }

  toggleProductsFromPast() {
    this.showSelectedUsFoodsClassOptions = false;
    this.showProductsFromPastOptions = !this.showProductsFromPastOptions;
  }
}
