import { BuyListItem } from '@usf/price-types/price';
import { ProductSummary } from '@usf/product-types';
import dayjs from 'dayjs';
import { LastBuy } from '@product-detail/models/last-buy.model';
import { dateIsLessThan, formatDate } from '@product-detail/utils/date.util';
import { FormatCurrencyAndDecimalPipe } from '@shared/pipes/format-currency-and-decimal.pipe';
import { HandPricingDetails } from '@order/models/order-view-model';
import {
  DEFAULT_LAST_FOUR_BUYS_LOOKBACK_DAYS,
  EXTENDED_LAST_FOUR_BUYS_LOOKBACK_DAYS,
} from '@shared/constants/merchandising-constants';

export const isLastBuy = (
  buyListItem: BuyListItem,
  isDynamicLookbackEnabled?: boolean,
) => {
  const date = dayjs.utc(buyListItem?.lastbuyDate?.toString(), 'YYYYMMDD');
  const lookbackPeriodDays = isDynamicLookbackEnabled
    ? EXTENDED_LAST_FOUR_BUYS_LOOKBACK_DAYS
    : DEFAULT_LAST_FOUR_BUYS_LOOKBACK_DAYS;
  return (
    buyListItem?.lastbuyDate > 0 &&
    dateIsLessThan(lookbackPeriodDays, 'days', date)
  );
};

export const formatLast4BuysList = (
  lastBuyList: BuyListItem[],
  productSummary: ProductSummary | HandPricingDetails,
  isDynamicLookbackEnabled?: boolean,
) => {
  const sortedLastBuys = [...lastBuyList].sort(
    (buyListItemA, buyListItemB) =>
      buyListItemB?.lastbuyDate - buyListItemA?.lastbuyDate,
  );
  return sortedLastBuys?.reduce((lastFourBuys, buyListItem) => {
    const date = dayjs.utc(buyListItem?.lastbuyDate?.toString(), 'YYYYMMDD');
    if (isLastBuy(buyListItem, isDynamicLookbackEnabled)) {
      let quantity = `${buyListItem?.lastbuyUnits} ${productSummary?.priceUom}`;
      if (buyListItem?.lastbuyEaches) {
        quantity += ` | ${buyListItem?.lastbuyEaches} ${productSummary?.eachUom}`;
      }

      lastFourBuys.push({
        quantity,
        invoiceDate: formatDate('MM/DD/YY', date),
        wwp:
          buyListItem?.lastbuyWwp?.toUpperCase() === 'CURRENT'
            ? 'Curr'
            : Number(buyListItem?.lastbuyWwp),
        price: `${new FormatCurrencyAndDecimalPipe().transform(buyListItem?.lastbuyPrice)} ${productSummary?.priceUom}`,
        invoiceNumber: buyListItem?.lastbuyInvoiceNbr,
        method:
          {
            0: 'Unknown',
            1: 'OVR',
            2: 'eBKT',
            3: 'CON',
            4: 'CPR',
            5: 'BKT',
            6: 'LST',
            7: 'sBKT',
            8: 'FS',
          }[buyListItem?.lastbuyPriceMethod] ?? 'Unknown',
      });
    }
    return lastFourBuys;
  }, [] as LastBuy[]);
};
