import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-usf-table',
  templateUrl: './usf-table.component.html',
  styleUrls: ['./usf-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UsfTableComponent {
  @Input() columnsConfig: any[] = [];
  @Input() dataSource: any[] = [];
  @Input() visibleColumns: string[] = [];
  @Input() mobileSummary = false;

  constructor() { }
}
