import { Component, Input, OnInit } from '@angular/core';
import { ProductQuickViewWarning } from '@product-detail/models/product-quick-view-warning';

@Component({
  selector: 'app-usf-product-quick-view-warning',
  templateUrl: './usf-product-quick-view-warning.component.html',
  styleUrls: ['./usf-product-quick-view-warning.component.scss'],
})
export class UsfProductQuickViewWarningComponent {
  @Input() quickViewWarning: ProductQuickViewWarning;
  @Input() isProductStatus = false;
  @Input() hasAlternative = false;
  constructor() {}
}
