<div
  class="container"
  [ngClass]="columnLayout ? 'column-layout' : 'container-border'"
>
  <div *ngIf="displayProductDetails" class="product">
    <div class="product-brand-text">
      {{ product?.summary?.brand | acronymTitleCase }}
    </div>
    <div class="product-summary-desc-text">
      {{
        product?.summary?.productDescTxtl ||
          'i18n.productDetail.productDescriptionNotAvailableMessage' | translate
      }}
    </div>
  </div>
  <div
    class="price"
    *ngIf="
      !pricingDisabled &&
      !product?.summary?.properties?.has(propertiesEnum.specialOrder)
    "
  >
    <ng-container *ngIf="hasPrice">
      <span
        appTestId="pdp-summary-seller-cases-price"
        [attr.data-cy]="
          'price-summary-seller-price-details-' +
          product?.productNumber +
          '-text'
        "
        >{{ (product?.pricing?.unitPrice | currency) || 'loading...' }}
        {{
          product?.summary?.catchWeightFlag
            ? '/' + product?.summary?.priceUom
            : product?.summary?.priceUom
        }}</span
      >
      <ng-container
        *ngIf="
          product?.summary?.breakable && product?.pricing?.eachPrice * 1 > 0
        "
      >
        <span>/</span>
        <span
          appTestId="pdp-summary-seller-eaches-price"
          [attr.data-cy]="
            'price-summary-seller-price-details-' +
            product?.productNumber +
            '-text'
          "
        >
          {{
            (product?.pricing?.eachPrice | currency) ||
              'i18n.productDetail.loading' | translate
          }}
          {{
            product?.pricing?.eachPrice
              ? product?.summary?.catchWeightFlag
                ? '/' + product?.summary?.priceUom
                : product?.summary?.eachUom
              : ''
          }}</span
        >
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!hasPrice">
      <span data-cy="no-pice-text">
        {{ 'i18n.lists.noPrice' | translate }}
      </span>
    </ng-container>
  </div>
  <div class="package">
    <span
      appTestId="pdp-summary-seller-product-number"
      [attr.data-cy]="
        'price-summary-seller-product-number-' +
        product?.productNumber +
        '-text'
      "
    >
      #{{ product?.productNumber }}
    </span>
    <span class="vertical-separator" data-cy="seperator-line"></span>
    <ion-icon name="beaker-outline" data-cy="beaker-line"></ion-icon>
    <span
      appTestId="pdp-summary-seller-pack-size"
      [attr.data-cy]="
        'price-summary-seller-sales-pack-size-' +
        product?.productNumber +
        '-text'
      "
    >
      {{ product?.summary?.salesPackSize | lowercase }}
    </span>
    <ng-container *appShowSplit="[pricePerPortionFeatureFlag]">
      <ng-container *ngIf="!!product?.costBreakdown">
        <span class="vertical-separator" data-cy="seperator-line"></span>
        <span
          appTestId="pdp-summary-seller-price-per-portion"
          [attr.data-cy]="
            'price-summary-seller-price-per-portion-' +
            product?.productNumber +
            '-text'
          "
        >
          {{ product?.costBreakdown }}
        </span>
      </ng-container>
    </ng-container>
  </div>
  <div
    class="actions"
    [ngClass]="columnLayout ? 'actions-column-layout' : 'divider'"
    appTestId="pdp-summary-seller-actions"
  >
    <ng-container *ngIf="createOpportunityFeatureEnabled">
      <ion-button
        appTestId="pdp-summary-seller-convert-opp-btn"
        class="usf-fill-green-button"
        data-cy="pdp-summary-seller-create-opp-button"
        type="button"
        *ngIf="
          hasPrice &&
          pricingRelatedActionsAvailable &&
          enabledButtons?.createOpportunityBtn
        "
        (click)="handleCreateOpportunityClick()"
      >
        {{ 'i18n.productDetail.createAnOpportunity' | translate }}
      </ion-button>
    </ng-container>

    <ng-container *appShowSplit="[cprButtonFeatureFlag]">
      <ng-container
        *ngIf="
          enabledButtons?.setCprBtn &&
          pricingRelatedActionsAvailable &&
          sellerPriceDetail?.currentPrice?.priceControls?.cprSetupInd === 'Y'
        "
      >
        <ion-button
          appTestId="pdp-summary-seller-set-cpr"
          class="usf-outline-green-button"
          data-cy="pdp-summary-seller-set-cpr"
          [disabled]="!sellerPriceDetail"
          type="button"
          (click)="handleSetCPRClick()"
        >
          {{ 'i18n.productDetail.setCPR' | translate }}
        </ion-button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="enabledButtons?.addToListBtn">
      <ion-button
        data-cy="pdp-summary-add-to-list-button"
        class="usf-outline-green-button"
        (click)="handleAddToListClick()"
      >
        {{ 'i18n.lists.addToList' | translate }}
      </ion-button>
    </ng-container>

    <ng-container *ngIf="enabledButtons?.searchForSubBtn">
      <ion-button
        data-cy="pdp-summary-search-for-replacement-button"
        class="usf-fill-green-button"
        (click)="handleSearchForSubstituteClick()"
      >
        {{
          (product?.summary?.properties?.has(propertiesEnum.discontinued)
            ? 'i18n.productDetail.searchForReplacement'
            : 'i18n.productDetail.searchForSubstitute'
          ) | translate
        }}
      </ion-button>
    </ng-container>
  </div>
</div>
