import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { TokenAuthOptions, UsfTokenService } from '@panamax/app-state';
import { DeepLinkType } from '@app/login/deep-link-type.enum';

@Injectable({ providedIn: 'root' })
export class LoginPageUtils {
  constructor(private tokenService: UsfTokenService) {}

  public async matchesAlertPreferenceCenterDeepLinkPattern(
    activatedRoute: ActivatedRoute,
  ): Promise<boolean> {
    const params = Capacitor.isNativePlatform()
      ? await this.getNativeAppUrlParams()
      : this.getWebUrlParams(activatedRoute);

    const isAlertPreferenceCenterDeepLink =
      params.originType === DeepLinkType.ALERT &&
      params.originLinkType === DeepLinkType.PREFCENTER;

    console.log(
      `[LoginPage] matchesAlertPreferenceCenterDeepLink: ${isAlertPreferenceCenterDeepLink}`,
    );
    return isAlertPreferenceCenterDeepLink;
  }

  public async matchesDeepLinkPattern(
    activatedRoute: ActivatedRoute,
  ): Promise<boolean> {
    const params = Capacitor.isNativePlatform()
      ? await this.getNativeAppUrlParams()
      : this.getWebUrlParamsForDeeplink(activatedRoute);

    const isDeeplink = params.deeplink === 'true';

    console.log(`[LoginPage] matchesDeepLink: ${isDeeplink}`);
    return isDeeplink;
  }

  private async getNativeAppUrlParams(): Promise<
    Record<string, string | null>
  > {
    const appLaunchUrl = await App.getLaunchUrl();
    return this.extractQueryParamsFromUrl(appLaunchUrl?.url || null);
  }

  private getWebUrlParams(
    activatedRoute: ActivatedRoute,
  ): Record<string, string | null> {
    const queryParams = this.extractQueryParamsFromParamMap(
      activatedRoute.snapshot.queryParamMap,
    );

    return queryParams.originType && queryParams.originLinkType
      ? queryParams
      : this.extractQueryParamsFromUrl(queryParams.returnUrl);
  }

  private getWebUrlParamsForDeeplink(
    activatedRoute: ActivatedRoute,
  ): Record<string, string | null> {
    const queryParams = this.extractQueryParamsFromParamMap(
      activatedRoute.snapshot.queryParamMap,
    );

    return queryParams.deeplink
      ? queryParams
      : this.extractQueryParamsFromUrl(queryParams.returnUrl);
  }

  private extractQueryParamsFromUrl(
    url: string | null,
  ): Record<string, string | null> {
    if (!url) return { originType: null, originLinkType: null };

    const params = new URLSearchParams(url.split('?')[1] || '');
    return {
      originType: params.get('originType'),
      originLinkType: params.get('originLinkType'),
      deeplink: params.get('deeplink'),
    };
  }

  private extractQueryParamsFromParamMap(
    paramMap: Readonly<ParamMap>,
  ): Record<string, string | null> {
    const extractedParams: Record<string, string | null> = {};

    paramMap.keys.forEach(key => {
      extractedParams[key] = paramMap.get(key);
    });

    return extractedParams;
  }

  public async navigateToB2cLoginWithoutClearingLocalStorage(
    options: TokenAuthOptions,
  ) {
    console.log('[LoginPage] Redirecting to B2C policy endpoint');
    await this.tokenService.navigateToLogin(options);
  }
}
