import { Component, Input } from '@angular/core';
import { TagProduct } from './models/tag-product.model';
import { ProductDetailTagsService } from '../../../product-detail/services/product-detail-tags.service';
import { ProductDetailViewModel } from '../../../product-detail/models/product-detail-view-model';
import { ProductTagDisplay } from '@product-detail/models/product-tag.model';
import { ProductAnalyticsService } from '@usf/ngrx-product';

@Component({
  selector: 'app-usf-product-tag-list',
  templateUrl: './usf-product-tag-list.component.html',
  styleUrls: ['./usf-product-tag-list.component.scss'],
})
export class UsfProductTagListComponent {
  @Input() tagList: TagProduct[] = [];
  @Input() infoIconEnabled: boolean;
  @Input() product: ProductDetailViewModel;
  @Input() index: number;
  @Input() maxTags: number;
  @Input() allTagsClickable: boolean;
  @Input() showProductClaims = true;

  hiddenTags: number[] = [];

  constructor(
    private productDetailTagsService: ProductDetailTagsService,
    private analyticsService: ProductAnalyticsService,
  ) {}

  productTagClick(tag: TagProduct) {
    if (this.allTagsClickable) {
      this.trackProductTagClick(tag);
      this.showAllAvailableStatuses();
    } else {
      tag.clickEmitter();
    }
  }

  trackDirectInfoClick() {
    this.analyticsService.trackDirectInfoClick(
      this.product?.summary?.divisionNumber,
      this.product?.productNumber,
      this.product?.trackingAttributes,
      this.product?.resultRank,
    );
  }

  isDirectTag(tag: TagProduct) {
    return tag?.content?.toUpperCase() === ProductTagDisplay.direct;
  }

  trackProductTagClick(tag: TagProduct) {
    if (this.isDirectTag(tag)) {
      this.trackDirectInfoClick();
    }
  }

  infoButtonClick() {
    if (this.tagList.some(tag => this.isDirectTag(tag))) {
      this.trackDirectInfoClick();
    }
    this.showAllAvailableStatuses();
  }

  async showAllAvailableStatuses() {
    await this.productDetailTagsService.showAllAvailableStatuses(
      this.product,
      this.showProductClaims,
    );
  }
}
