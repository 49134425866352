import { Component, Input } from '@angular/core';
import { ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PanAppState, PlatformService, jwtDecode } from '@panamax/app-state';
import { CustomPricingRationaleService } from '@product-detail/services/custom-pricing-rationale.service';
import { SellerCreateOpportunityService } from '@product-detail/services/seller-create-opportunity.service';
import { formatLast4BuysList } from '@product-detail/utils/product-last-buys.util';
import { FEATURES } from '@shared/constants/splitio-features';
import { AddToListModalService } from '@shared/services/dual-pane/add-to-list-modal.service';
import { ToggleNewSearchService } from '@shared/services/toggle-new-search/toggle-new-search.service';
import { ListGroupState, ListItemState } from '@usf/ngrx-list';
import { OrderItem } from '@usf/ngrx-order';
import { SelectPricingService } from '@usf/ngrx-pricing';
import { SellerPriceDetails } from '@usf/price-types';
import { ProductPropertiesEnum, ProductSummary } from '@usf/product-types';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ListAnalyticsHelperService } from '../../../lists/services/list-analytics-helper.service';
import { ListDetailManagementService } from '../../../lists/shared/list-detail-management/services/list-detail-management.service';
import { OrderService } from '../../../order/services/order.service';
import { ProductAlternativeTypeEnum } from '../../../product-detail/models/product-alternative';
import { ProductDetailViewModel } from '../../../product-detail/models/product-detail-view-model';
import { ProductAlternativeSearchService } from '../../../product-detail/services/product-alternative-search.service';
import { ProductDetailTagsService } from '../../../product-detail/services/product-detail-tags.service';
import { ProductDetailService } from '../../../product-detail/services/product-detail.service';
import { productIsInStatus } from '../../../product-detail/utils/product-summary.utils';
import { getProductDetailStatusData } from '../../../product-detail/utils/product-tags-util';
import { OGRestrictionsEnum } from '../../constants/sub-restrictions-enum';
import { UsfProductCardModeEnum } from '../../constants/usf-product-card-mode.enum';
import { Product } from '../../models/product.model';
import { QuantityUpdate } from '../../models/quantity-update';
import { EllipsisClickData } from '../../models/usf-product-card.model';
import { ModalService } from '../../services/modal/modal.service';
import { ProductService } from '../../services/product/product.service';
import { SimpleActionModalComponent } from '../simple-action-modal/simple-action-modal.component';
import { TagProduct } from '../usf-product-tag-list/models/tag-product.model';
import { SellerQuickViewDetails } from './model/usf-seller-quick-view-details.model';
import { PAGE_DETAIL, PAGE_NAME_BASE_PATH } from '@usf/ngrx-product';

@Component({
  selector: 'app-usf-product-quick-view',
  templateUrl: './usf-product-quick-view.component.html',
  styleUrls: ['./usf-product-quick-view.component.scss'],
})
export class UsfProductQuickViewComponent
  implements ViewWillEnter, ViewWillLeave
{
  readonly productAlternativeTypes = ProductAlternativeTypeEnum;

  @Input() ellipsisClickData: EllipsisClickData;
  @Input() originSearchPage?: string;
  @Input() selectedSegment = 'quickView';
  @Input() isGuestUser: boolean;
  @Input() isDynamicLastFourBuysLookbackEnabled: boolean;

  /** ---------- Quick View ---------- **/
  // Common Product Properties
  vendorMessage: string;

  // Product Properties
  ogProductTagsList: TagProduct[] = [];
  ogProdDetails: ProductDetailViewModel;
  ogClaimTags: TagProduct[] = [];
  ogProdDetailStatusData: any;
  disableNotes = false;

  // Alternative Product Properties
  altProductTagsList: TagProduct[] = [];
  altProdDetails: ProductDetailViewModel;
  altClaimTags: TagProduct[] = [];
  altProdDetailStatusData: any;

  // Observables & Subscriptions
  orderItem$: Observable<OrderItem>;
  altOrderItem$: Observable<OrderItem>;
  selectedGroup$: Observable<ListGroupState>;
  selectedGroupListItems$: Observable<ListItemState[]>;
  ogProductDetailSub$: Subscription = new Subscription();
  altProductDetailSub$: Subscription = new Subscription();

  /** ---------- Product Status ---------- **/
  showOutOfStockStatusBtn = false;
  isProductStatusExpanded = false;
  hasDirectOrRecentlyPurchasedProp = false;
  productStatusBtnTxt = 'i18n.productDetail.viewOutOfStockStatus';
  productPropertiesToShow = [
    ProductPropertiesEnum.contract,
    ProductPropertiesEnum.direct,
    ProductPropertiesEnum.recentlyPurchased,
    ProductPropertiesEnum.onMasterList,
    ProductPropertiesEnum.ces,
    ProductPropertiesEnum.dwo,
    ProductPropertiesEnum.onOrderGuideOrOnShoppingList,
    ProductPropertiesEnum.scoop,
    ProductPropertiesEnum.locallySourced,
  ];
  isCustomerUnrestricted: boolean;

  /** ---------- Seller Details ---------- **/
  isSellerDetailsVisible$: Observable<boolean>;
  sellerPriceDetails$: Observable<Map<number, SellerQuickViewDetails>>;
  last4BuysExpanded = true;

  constructor(
    private modalController: ModalController,
    readonly panAppState: PanAppState,
    readonly platformService: PlatformService,
    private translateService: TranslateService,
    private listAnalyticsHelperService: ListAnalyticsHelperService,
    private listDetailManagementService: ListDetailManagementService,
    private modalService: ModalService,
    private productAlternativeSearchService: ProductAlternativeSearchService,
    private productDetailService: ProductDetailService,
    private orderService: OrderService,
    public productDetailTagsService: ProductDetailTagsService,
    private productService: ProductService,
    private readonly toggleNewSearchService: ToggleNewSearchService,
    private selectSellerPricingService: SelectPricingService,
    private sellerCreateOpportunityService: SellerCreateOpportunityService,
    private addToListModalService: AddToListModalService,
    private customPricingRationaleService: CustomPricingRationaleService,
  ) {}
  ionViewWillEnter(): void {
    this.initializeComponentData();
  }

  initializeComponentData() {
    this.setSellerDetailsVisibility();
    this.updateSelectedSegmentInformation();
    this.fetchProductDetails();
    this.setOrderItemData();
    this.setListData();
    this.setProductDetailData();
  }

  isInternalUser(token: string) {
    return jwtDecode(token)?.['usf-claims']?.userType === 'corp-ad';
  }

  setSellerDetailsVisibility() {
    this.isSellerDetailsVisible$ = combineLatest([
      this.panAppState.feature$([FEATURES.split_global_seller_quick_view.name]),
      this.panAppState.accessToken$,
    ]).pipe(map(([flagIsOn, token]) => flagIsOn && this.isInternalUser(token)));
  }

  setSelectedSegment(event: any) {
    if (event) {
      this.selectedSegment = event?.detail?.value;
      this.updateSelectedSegmentInformation();
    }
  }

  updateSelectedSegmentInformation() {
    if (this.selectedSegment === 'sellersQuickView') {
      this.fetchSellerDetails();
      this.productDetailService.trackSellerQuickViewPageLoad(
        this.ellipsisClickData?.product,
      );
    }
  }

  fetchSellerDetails() {
    const productNumbers: number[] = [];

    if (this.ellipsisClickData?.product?.productNumber) {
      productNumbers.push(this.ellipsisClickData?.product?.productNumber);
    }

    if (this.ellipsisClickData?.product?.alternative?.product?.productNumber) {
      productNumbers.push(
        this.ellipsisClickData?.product?.alternative?.product?.productNumber,
      );
    }

    this.sellerPriceDetails$ = this.selectSellerPricingService
      .getSellerPrices(productNumbers)
      .pipe(
        map((sellerPriceDetails: SellerPriceDetails[]) => {
          return new Map(
            sellerPriceDetails.map(detail => [
              detail?.productNumber,
              {
                sellerPriceDetails: detail,
                lastFourBuys: this.formatLast4Buys(detail),
                shouldDisplaySearchForSubsBtn:
                  this.sellerQuickViewShouldDisplaySearchForSubsBtn(
                    detail?.productNumber,
                  ),
                shouldDisplayAddToListBtn:
                  this.sellerQuickViewShouldDisplayAddToListBtn(
                    detail?.productNumber,
                  ),
              },
            ]),
          );
        }),
      );
  }

  fetchProductDetails() {
    const productNumbers: number[] = [];
    if (this.ellipsisClickData?.product?.productNumber) {
      productNumbers.push(this.ellipsisClickData?.product?.productNumber);
    }
    if (this.ellipsisClickData?.product?.alternative?.product?.productNumber) {
      productNumbers.push(
        this.ellipsisClickData?.product?.alternative?.product?.productNumber,
      );
    }
    this.productService.loadProductDetails(productNumbers);
  }

  setOrderItemData() {
    if (this.ellipsisClickData?.product?.productNumber) {
      this.orderItem$ = this.orderService.getOrderItemWithProductNumber$(
        this.ellipsisClickData?.product?.productNumber,
      );
    }
    if (this.ellipsisClickData?.product?.alternative?.product?.productNumber) {
      this.altOrderItem$ = this.orderService.getOrderItemWithProductNumber$(
        this.ellipsisClickData?.product?.alternative?.product?.productNumber,
      );
    }
  }

  setListData() {
    if (this.ellipsisClickData.product.item) {
      this.selectedGroup$ = this.listDetailManagementService.selectListGroup$(
        this.ellipsisClickData.product.item.listGroupKey?.listTypeId,
        this.ellipsisClickData.product.item.listKey?.listId,
        this.ellipsisClickData.product.item.listGroupKey?.listGroupId,
      );

      this.selectedGroupListItems$ =
        this.listDetailManagementService.selectListItemsInGroup$(
          this.ellipsisClickData.product.item.listKey,
          this.ellipsisClickData.product.item.listGroupKey,
        );
    }
  }

  setProductDetailData() {
    if (this.ellipsisClickData.product) {
      this.setOriginalProductDetailData();
      if (
        this.ellipsisClickData.product.hasAlternative &&
        this.ellipsisClickData.product.alternative
      ) {
        this.setAlternativeProductDetailData();
      }
    }
  }

  setOriginalProductDetailData() {
    this.ogProductTagsList = this.productDetailTagsService.getDisplayTags(
      this.ellipsisClickData.product.summary,
      this.ellipsisClickData.product.inventory,
      this.ellipsisClickData.product.contract,
      this.ellipsisClickData.product.customerPill,
      this.ellipsisClickData.product.recentPurchase,
      this.ellipsisClickData.product.trackingAttributes,
      this.ellipsisClickData.product.resultRank,
      false,
      this.isGuestUser,
    );
    this.ogProductDetailSub$ = combineLatest([
      this.productDetailService.getProductDetailViewModel(
        this.ellipsisClickData?.product?.productNumber,
      ),
      this.panAppState.customer$,
      this.panAppState.feature$([
        FEATURES.split_global_direct_allow_returns.name,
      ]),
      this.panAppState.feature$([FEATURES.split_dynamic_proprietary_ui.name]),
    ]).subscribe(
      ([
        ogProdDetails,
        customer,
        isDirectAllowReturnsEnabled,
        isProprietaryUIEnabled,
      ]) => {
        this.ogProdDetails = ogProdDetails;
        this.ogClaimTags = this.productDetailTagsService.getClaimTags(
          this.ogProdDetails,
        );
        this.isCustomerUnrestricted =
          customer?.restrictToOG === OGRestrictionsEnum.UNRESTRICTED;
        const restrictToMsl =
          customer?.restrictToOG === OGRestrictionsEnum.RESTRICT_TO_ML
            ? true
            : false;
        const restrictToOg =
          customer?.restrictToOG === OGRestrictionsEnum.RESTRICT_TO_OG
            ? true
            : false;
        this.ogProdDetailStatusData = this.getDataForProductStatusModal(
          ogProdDetails,
          false,
          restrictToMsl,
          restrictToOg,
          isDirectAllowReturnsEnabled,
          isProprietaryUIEnabled,
        );
      },
    );
    this.vendorMessage = this.ellipsisClickData.product.drawerMsg?.message;
    this.disableNotes = this.shouldDisableProductNotes();
    this.showOutOfStockStatusBtn = this.shouldShowOutOfStockStatusButton(
      this.ellipsisClickData.product.summary?.properties,
    );
  }

  setAlternativeProductDetailData() {
    this.altProductTagsList = this.productDetailTagsService.getDisplayTags(
      this.ellipsisClickData.product.alternative.product?.summary,
      this.ellipsisClickData.product.alternative.product?.inventory,
      this.ellipsisClickData.product.alternative.product?.contract,
      this.ellipsisClickData.product.alternative.product?.customerPill,
      this.ellipsisClickData.product.alternative.product?.recentPurchase,
      this.ellipsisClickData.product.alternative.product?.trackingAttributes,
      this.ellipsisClickData.product.alternative.product?.resultRank,
      false,
      this.isGuestUser,
    );

    this.altProductDetailSub$ = combineLatest([
      this.productDetailService.getProductDetailViewModel(
        this.ellipsisClickData.product.alternative.product?.productNumber,
      ),
      this.panAppState.customer$,
      this.panAppState.feature$([
        FEATURES.split_global_direct_allow_returns.name,
      ]),
      this.panAppState.feature$([FEATURES.split_dynamic_proprietary_ui.name]),
    ]).subscribe(
      ([
        altProdDetails,
        customer,
        isDirectAllowReturnsEnabled,
        isProprietaryUIEnabled,
      ]) => {
        this.altProdDetails = altProdDetails;
        this.altClaimTags = this.productDetailTagsService.getClaimTags(
          this.altProdDetails,
        );
        const restrictToMsl =
          customer?.restrictToOG === OGRestrictionsEnum.RESTRICT_TO_ML
            ? true
            : false;
        const restrictToOg =
          customer?.restrictToOG === OGRestrictionsEnum.RESTRICT_TO_OG
            ? true
            : false;
        this.altProdDetailStatusData = this.getDataForProductStatusModal(
          altProdDetails,
          true,
          restrictToMsl,
          restrictToOg,
          isDirectAllowReturnsEnabled,
          isProprietaryUIEnabled,
        );
      },
    );
  }

  getDataForProductStatusModal(
    productDetail: ProductDetailViewModel,
    isSplitCard: boolean,
    isMslRestricted: boolean,
    isOgRestricted: boolean,
    isDirectAllowReturnsEnabled: boolean,
    isProprietaryUIEnabled: boolean,
  ) {
    const productDetailStatusData = getProductDetailStatusData(
      productDetail,
      isMslRestricted,
      isOgRestricted,
      isDirectAllowReturnsEnabled,
      isProprietaryUIEnabled,
    );
    return {
      ...productDetailStatusData,
      showAlternative: this.ellipsisClickData.product.hasAlternative || false,
      isSplitCard,
      isQuickViewProductStatusModal: true,
    };
  }

  setIsProductDetailTagsInfoExpanded() {
    this.isProductStatusExpanded = this.isProductStatusExpanded ? false : true;
    this.productStatusBtnTxt = this.isProductStatusExpanded
      ? 'i18n.common.hide'
      : 'i18n.productDetail.viewOutOfStockStatus';
  }

  onQuantityUpdate(quantityUpdate: QuantityUpdate) {
    this.listAnalyticsHelperService.quickViewAddToOrder(
      quantityUpdate,
      this.ellipsisClickData?.list,
      this.ellipsisClickData?.product,
      this.ellipsisClickData?.product?.alternative,
    );
  }

  async deleteProductFromList(
    selectedGroup: ListGroupState,
    groupItems: ListItemState[],
  ) {
    this.dismissModal();
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      SimpleActionModalComponent,
      {
        title: this.translateService.instant('i18n.lists.deleteProduct'),
        content: this.translateService.instant(
          'i18n.lists.deleteProductMessage',
          {
            productName:
              this.ellipsisClickData?.product?.summary?.productDescTxtl,
            productNumber: this.ellipsisClickData?.product?.productNumber,
          },
        ),
      },
      'simple-action-modal',
    );
    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    modal.onDidDismiss().then((response: any) => {
      if (response?.data?.dispatchAction) {
        this.listDetailManagementService.deleteProducts(
          this.ellipsisClickData.list,
          { groupState: selectedGroup, items: groupItems, isSelected: true },
          [
            {
              product: this.ellipsisClickData?.product?.summary,
              item: this.ellipsisClickData?.product?.item,
            },
          ],
        );
      }
    });
    await modal.present();
  }

  searchReplacement(
    productSummary: ProductSummary,
    trackingAttributes: string,
  ) {
    this.dismissModal();
    this.listAnalyticsHelperService.trackSearchSubs(
      productSummary,
      trackingAttributes,
      true,
    );
    this.toggleNewSearchService
      .isNewSearchEnabled()
      .pipe(take(1))
      .subscribe(newSearchEnabled => {
        if (newSearchEnabled) {
          const isSubstitute =
            this.ellipsisClickData?.product?.alternative?.type ===
            this.productAlternativeTypes.replacement;
          this.productAlternativeSearchService.newSearchReplacement(
            productSummary,
            !isSubstitute,
            this.originSearchPage,
          );
        } else {
          this.productAlternativeSearchService.searchReplacement(
            productSummary,
            UsfProductCardModeEnum.replacementSearch,
            false,
            null,
            this.originSearchPage,
          );
        }
      });
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }

  ionViewWillLeave(): void {
    if (this.ogProductDetailSub$) {
      this.ogProductDetailSub$.unsubscribe();
    }
    if (this.altProductDetailSub$) {
      this.altProductDetailSub$.unsubscribe();
    }
  }

  shouldDisableProductNotes() {
    return (
      this.ellipsisClickData.mode === UsfProductCardModeEnum.defaultSearch ||
      this.ellipsisClickData.mode ===
        UsfProductCardModeEnum.replacementSearch ||
      this.ellipsisClickData.mode === UsfProductCardModeEnum.addToListSearch ||
      this.ellipsisClickData.mode === UsfProductCardModeEnum.exceptionSearch ||
      this.ellipsisClickData.mode ===
        UsfProductCardModeEnum.cartReplacementSearch
    );
  }

  shouldShowOutOfStockStatusButton(
    productProperties: Set<ProductPropertiesEnum>,
  ) {
    const numProperties =
      this.getNumOriginalProductProperties(productProperties);
    return numProperties > 2;
  }

  getNumOriginalProductProperties(
    productProperties: Set<ProductPropertiesEnum>,
  ) {
    let numProperties = 0;
    if (productProperties && productProperties.size > 0) {
      productProperties.forEach(property => {
        if (this.productPropertiesToShow.includes(property)) {
          numProperties++;
        }
      });
      this.hasDirectOrRecentlyPurchasedProp =
        productProperties.has(ProductPropertiesEnum.direct) ||
        productProperties.has(ProductPropertiesEnum.recentlyPurchased)
          ? true
          : false;
    }

    return numProperties;
  }

  shouldAddSearchForSubs(product: Product) {
    return (
      (product?.summary?.properties?.has(ProductPropertiesEnum.discontinued) ||
        product?.summary?.properties?.has(ProductPropertiesEnum.dwo) ||
        (productIsInStatus(['3', '4'], product) &&
          !product?.inventory?.isInStock) ||
        (productIsInStatus(['0', '2'], product) &&
          !product?.inventory?.isInStock) ||
        (product?.summary?.properties?.has(
          ProductPropertiesEnum.supplierOutOfStock,
        ) &&
          !product?.alternative?.prioritizeAlternative)) &&
      (product.allowSearchForSubs ?? true)
    );
  }

  sellerQuickViewShouldDisplaySearchForSubsBtn(productNumber: number) {
    return productNumber === this.ellipsisClickData?.product?.productNumber
      ? this.shouldAddSearchForSubs(this.ellipsisClickData?.product)
      : false;
  }

  sellerQuickViewShouldDisplayAddToListBtn(productNumber: number) {
    let response = false;

    if (productNumber === this.ellipsisClickData?.product?.productNumber) {
      response =
        this.ellipsisClickData?.product?.inventory?.isInStock &&
        !this.ellipsisClickData?.product?.summary?.properties?.has(
          ProductPropertiesEnum.discontinued,
        ) &&
        this.ellipsisClickData?.product?.drawerMsg?.message !==
          this.translateService.instant(
            'i18n.lists.productCardMsgs.alternateProduct',
          );
    }

    return response;
  }

  formatLast4Buys(sellerPriceDetails: SellerPriceDetails) {
    let last4Buys = [];

    if (
      sellerPriceDetails?.productNumber ===
      this.ellipsisClickData?.product?.productNumber
    ) {
      last4Buys = formatLast4BuysList(
        sellerPriceDetails.lastFourBuys?.lastbuyList,
        this.ellipsisClickData?.product?.summary,
        this.isDynamicLastFourBuysLookbackEnabled,
      );
    }

    return last4Buys;
  }

  toggleLast4Buys() {
    this.last4BuysExpanded = !this.last4BuysExpanded;
  }

  async redirectToPdp(product: Product) {
    await this.modalController.dismiss(product?.productNumber);
  }

  async openSellerCreateAnOpportunityModal(product: ProductDetailViewModel) {
    await this.dismissModal();
    this.sellerCreateOpportunityService.showCreateOpportunityModal(
      product,
      `${PAGE_NAME_BASE_PATH.r4}${PAGE_DETAIL.sellerQuickView}`,
    );
  }

  async openAddToListModal(product: Product) {
    await this.dismissModal();
    this.addToListModalService.openModalViaController(
      [product],
      false,
      false,
      undefined,
      undefined,
      `${PAGE_NAME_BASE_PATH.r4}${PAGE_DETAIL.sellerQuickView}`,
    );
  }

  async openCustomPricingRationaleModal(
    product: ProductDetailViewModel,
    sellerPriceDetail: SellerPriceDetails,
  ) {
    const isCommonCprModalEnabled = await this.panAppState.featureEnabled(
      FEATURES.split_global_cpr_override_common_component.name,
    );

    await this.dismissModal();

    this.customPricingRationaleService.showCustomPricingRationaleModal(
      product,
      sellerPriceDetail,
      isCommonCprModalEnabled,
      `${PAGE_NAME_BASE_PATH.r4}${PAGE_DETAIL.sellerQuickView}`,
    );
  }
}
