import { Component, Input } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ListAnalyticsService } from '@usf/ngrx-list';
import { SortFilterListBaseComponent } from '../../sort-filter-list-base.component';

@Component({
  selector: 'app-sort-filter-list-input',
  templateUrl: './sort-filter-list-input.component.html',
  styleUrls: ['./sort-filter-list-input.component.scss'],
})
export class SortFilterListInputComponent extends SortFilterListBaseComponent {
  @Input() callBack: Function;

  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    listAnalyticsService: ListAnalyticsService,
  ) {
    super(nav, modalController, platformService, listAnalyticsService);
  }
  returnToSortFilterListOptions() {
    if (this.showProductsFromPastOptions) {
      this.callBack(this.week52Filter);
    } else {
      this.callBack(this.selectedSort, this.selectedGroup);
    }
    this.goBack();
  }

  goBack() {
    this.nav.pop();
  }
}
