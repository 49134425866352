import { Component, Input, OnInit } from '@angular/core';
import {
  ProductClaim,
  ProductContract,
  ProductPropertiesEnum,
  ProductSummary,
} from '@usf/product-types';
import { JitConfigMessage } from '../../../product-detail/models/jit-config-message.model';
import { ProductAlertMessage } from '../../../product-detail/models/product-alert-message';
import { RecentPurchaseRecord } from '../../models/recent-purchase.record';
import { ChipTypeEnum } from '../usf-chip/models/chip-type.enum';
import { IconConfig } from '../usf-chip/models/icon-config.model';
import { TagProduct } from '../usf-product-tag-list/models/tag-product.model';
import { ProductShippingPriceService } from '@shared/services/product-shipping-price/product-shipping-price.service';
import { Observable } from 'rxjs';
import { FEATURES } from '@shared/constants/splitio-features';
import { UserKinds } from '@usf/user-types/user';
import { UserService } from '@app/user/services';
import { PlatformService } from '@panamax/app-state';

@Component({
  selector: 'app-product-detail-status-tags-info',
  templateUrl: './product-detail-status-tags-info.component.html',
  styleUrls: ['./product-detail-status-tags-info.component.scss'],
})
export class ProductDetailStatusTagsInfoComponent implements OnInit {
  readonly propertiesEnum = ProductPropertiesEnum;
  readonly chipTypeEnum = ChipTypeEnum;
  readonly userKindsEnum = UserKinds;

  @Input() productDetailStatusData: ProductDetailStatusData;
  // Temp fix to not show warning banner twice on quickView/productStatus modal
  @Input() isQuickView: boolean;

  isProductShippingPriceEnabled$: Observable<boolean>;
  userKind$: Observable<UserKinds>;

  directAllowReturnsFlag = FEATURES.split_global_direct_allow_returns.name;

  scoopConfig: IconConfig = {
    name: 'SCOOP',
    iconWidth: 4.313,
    iconHeight: 1.438,
  };

  constructor(
    private productShippingPriceService: ProductShippingPriceService,
    private userService: UserService,
    readonly platformService: PlatformService,
  ) {}

  ngOnInit(): void {
    this.isProductShippingPriceEnabled$ =
      this.productShippingPriceService.isProductShippingPriceEnabled$();
    this.userKind$ = this.userService.userKind$();
  }
}

export interface ProductDetailStatusData {
  isSplitCard: boolean;
  showAlternative: boolean;
  isQuickViewProductStatusModal: boolean;
  hasPromotions: boolean;
  vendorMessage: string;
  jitConfig: JitConfigMessage;
  contractPill: TagProduct;
  recentPurchaseRecords: RecentPurchaseRecord[];
  isMslRestricted: boolean;
  masterLists: string[];
  lists: string[];
  claims: ProductClaim[];
  alertMessage: ProductAlertMessage;
  contract: ProductContract;
  summary: ProductSummary;
  customerPill: TagProduct;
}
