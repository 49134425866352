import { ProductRow } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { SeperatedColumn } from '@shared/models/seperated-columns';
import { DownloadRequestFileType } from '@usf/list-types';
import { productConversionColumns } from './document-helper-models-and-enums';
import {
  isProductNotAvailable,
  operateOnColumnEachAndCasePrice,
  operateOnColumnImageStatus,
  operateOnColumnImageType,
  operateOnColumnNetWeight,
  operateOnColumnProductBrand,
  operateOnColumnProductDescription,
  operateOnColumnProductNote,
  operateOnColumnProductNumber,
  operateOnColumnProductPackageSize,
  operateOnColumnProductPrice,
  operateOnColumnProductStatus,
  operateOnColumnProductType,
  operateOnColumnProductUOM,
  operateOnColumnStorageDescription,
} from './download-list-helper';
import { downloadableConversionProduct } from '@shared/models/product-conversion.model';
import {
  addValueToColumns,
  getClaims,
  getDocumentBoolean,
  getDocumentCurrency,
  getDocumentRaw,
  getDocumentStatus,
  getDocumentString,
  getDocumentStringForCSV,
  getDocumentTypeContractOnlyText,
  getNutrient,
} from '@shared/helpers/document-creation.helpers';
import { ProductPropertiesEnum } from '@usf/product-types';
import { MakeRed } from '@shared/constants/pdf-colors.enum';

export const claimStrings = [
  'Child Nutrition certificate',
  'Cholesterol free',
  'Low Fat',
  'No Sugar Added',
  'Fat free',
  'Gluten Free',
  'Halal',
  'Kosher',
  'Organic',
  'Certified Angus beef',
  'Hormones',
  'Local',
];

export const nutrientStrings = [
  'Total Fat',
  'Saturated Fat',
  'Trans Fat',
  'Cholesterol',
  'Sodium',
  'Total Carbohydrate',
  'Dietary Fiber',
  'Total Sugar',
  'Added Sugars',
  'Protein',
  'Vitamin D',
  'Calcium',
  'Iron',
  'Potassium',
  'Calories From Fat',
  'Calories',
];

export const operateOnColumnStorageCode = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.summary?.groupStorageCode),
  );
};

export const operateOnIngredientStatement = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.detail.ingredients ?? ''),
  );
};

export const operateOnAdditionalInfo = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.detail.additionalDescription ?? ''),
  );
};

export const operateOnProductStatusCode = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  if (productRow.inventory?.productStatus === undefined) {
    return addValueToColumns(columnName, columnMap, 0);
  }
  const val = Number(productRow.inventory?.productStatus).valueOf();
  return addValueToColumns(columnName, columnMap, `${val}`);
};

// To avoid too much duplicate codes using an index to reference the setup data arays.
export const operateOnNutrition = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
  index: number,
) => {
  if (
    !productRow.detail.listPIMNutrientsProduction ||
    productRow.nutritionFacts?.nutrientIsNotAvailable
  ) {
    return addValueToColumns(columnName, columnMap, '');
  }

  return addValueToColumns(
    columnName,
    columnMap,
    getNutrient(
      productRow.detail.listPIMNutrientsProduction,
      nutrientStrings[index],
    ),
  );
};

// To avoid too much duplicate codes using an index to reference the setup data arays.
export const operateOnClaim = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
  index: number,
) => {
  if (
    productRow.nutritionFacts === undefined ||
    productRow.nutritionFacts?.nutrientIsNotAvailable
  ) {
    return addValueToColumns(columnName, columnMap, '');
  }

  return addValueToColumns(
    columnName,
    columnMap,
    getClaims(claimStrings[index], productRow.detail.claims),
  );
};

export const operateOnOriginality = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
) => {
  const value = productRow.isOriginal ? 'ORIGINAL' : 'REPLACED';
  return addValueToColumns(columnName, columnMap, getDocumentString(value));
};

export const operateOnPDFOriginality = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
) => {
  const value = productRow.isOriginal ? 'Original Product' : 'New Product';
  return addValueToColumns(columnName, columnMap, getDocumentString(value));
};

export const operateOnColumnEachPrice = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
) => {
  if (!productRow?.summary?.breakable) {
    return addValueToColumns(columnName, columnMap, '');
  }
  if (productRow.summary?.properties?.has(ProductPropertiesEnum.specialOrder)) {
    return addValueToColumns(columnName, columnMap, 'Call for price');
  }

  if (
    !(
      productRow.pricing?.eachPrice !== undefined &&
      productRow.pricing?.eachPrice * 1 > 0 &&
      !isProductNotAvailable(productRow)
    )
  ) {
    return addValueToColumns(columnName, columnMap, 'No price');
  }

  let value;
  if (!!productRow.pricing?.eachPrice && productRow.pricing?.eachPrice > 0) {
    value = getDocumentCurrency(productRow.pricing?.eachPrice);
  } else {
    value = `"$0.00"`;
  }
  return addValueToColumns(columnName, columnMap, value);
};

export const operateOnColumnEachUOM = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  if (!productRow?.summary.breakable) {
    return addValueToColumns(columnName, columnMap, '');
  }

  let uom = '';

  if (
    productRow.pricing?.eachPrice !== undefined &&
    productRow.pricing?.eachPrice * 1 > 0 &&
    !isProductNotAvailable(productRow) &&
    !productRow.summary?.properties?.has(ProductPropertiesEnum.specialOrder)
  ) {
    uom = productRow.summary?.eachUom;
  }

  return addValueToColumns(columnName, columnMap, getDocumentString(uom));
};

export const operateOnColumnPortionPrice = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
) => {
  if (productRow.summary?.properties?.has(ProductPropertiesEnum.specialOrder)) {
    return addValueToColumns(columnName, columnMap, 'Call for price');
  }

  if (!productRow.costBreakdown || isProductNotAvailable(productRow)) {
    return addValueToColumns(columnName, columnMap, '');
  } else {
    return addValueToColumns(columnName, columnMap, productRow.costBreakdown);
  }
};

export const operateOnServingSize = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
) => {
  if (!!productRow.nutritionFacts?.nutrientIsNotAvailable) {
    return addValueToColumns(columnName, columnMap, '');
  }
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentRaw(productRow.detail.servingSize),
  );
};

export const operateOnColumnProductStatusNormal = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentStatus(productRow, true),
  );
};

export const operateOnServingPerCase = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
) => {
  if (!!productRow.nutritionFacts?.nutrientIsNotAvailable) {
    return addValueToColumns(columnName, columnMap, '');
  }
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentRaw(productRow.detail.servingsPerCase),
  );
};

export const operateOnServingUOM = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
) => {
  if (!!productRow.nutritionFacts?.nutrientIsNotAvailable) {
    return addValueToColumns(columnName, columnMap, '');
  }
  let value = '';
  if (
    !!productRow.nutritionFacts?.nutrientsData &&
    productRow.nutritionFacts?.nutrientsData.length > 0
  ) {
    value = productRow.nutritionFacts.nutrientsData[0]?.data.servingSizeUOM;
  }
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentStringForCSV(value),
  );
};

export const operateOnGroupDescription = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentStringForCSV(productRow.summary.groupDescription),
  );
};
export const operateOnClassDescription = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentStringForCSV(productRow.summary.classDescription),
  );
};
export const operateOnCategoryDescription = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentStringForCSV(productRow.summary.categoryDescription),
  );
};

export const operateOnGroupCode = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentRaw(productRow.summary.groupCode),
  );
};
export const operateOnClassCode = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentRaw(productRow.summary.classCode),
  );
};
export const operateOnCategoryCode = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentRaw(productRow.summary.categoryCode),
  );
};

export const operateOnContractText = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentTypeContractOnlyText(productRow),
  );
};

export const operateOnDirect = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentBoolean(
      productRow.summary?.properties?.has(ProductPropertiesEnum.direct),
    ),
  );
};

export const operateOnJIT = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentBoolean(
      productRow.summary?.properties?.has(ProductPropertiesEnum.jit),
    ),
  );
};

export const operateOnLocal = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentBoolean(
      productRow.summary?.properties?.has(ProductPropertiesEnum.locallySourced),
    ),
  );
};

export const operateOnCustomerPillText = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentStringForCSV(productRow.customerPill?.pillDescription ?? 'N'),
  );
};

export const operateOnMLM = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentBoolean(
      productRow.summary?.properties?.has(ProductPropertiesEnum.onMasterList),
    ),
  );
};

export const operateOnUpdatedLists = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
) => {
  let value = '';
  (productRow.listsThatAreUpdated ?? []).forEach(list => {
    if (value !== '') {
      value += ', ';
    }
    value += list;
  });
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentStringForCSV(value),
  );
};

export const operateOnColumnPDFProductPackageSize = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: downloadableConversionProduct,
) => {
  let packageSize = isProductNotAvailable(productRow)
    ? ''
    : (productRow.summary?.salesPackSize ?? '');
  if (productRow.samePackSize === false) {
    packageSize = MakeRed + packageSize;
  }
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(packageSize),
  );
};

export const documentFunctionMap = {
  [productConversionColumns.isOriginal]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnOriginality(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.productNumber]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductNumber(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.productDescription]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductDescription(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.productBrand]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductBrand(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.productPackageSize]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductPackageSize(
      columnName,
      columnMap,
      downloadableProduct,
      fileType,
    );
  },

  [productConversionColumns.casePrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductPrice(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.eachPrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnEachPrice(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.caseUOM]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductUOM(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.eachUOM]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnEachUOM(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.portionPrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnPortionPrice(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.statusCode]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnProductStatusCode(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.productStatus]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductStatusNormal(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.updatedLists]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnUpdatedLists(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.mlmProduct]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnMLM(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.storageCode]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnStorageCode(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.usfStorage]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnStorageDescription(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.netWeight]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnNetWeight(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.contractIcon]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnContractText(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.local]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnLocal(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.jit]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnJIT(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.direct]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnDirect(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.customerPill]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnCustomerPillText(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },

  [productConversionColumns.classNum]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClassCode(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.classDesc]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClassDescription(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.categoryNum]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnCategoryCode(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.categoryDesc]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnCategoryDescription(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.groupNum]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnGroupCode(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.groupDesc]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnGroupDescription(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
};

export const nutritonalDocumentMap = {
  [productConversionColumns.servingSize]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnServingSize(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.ServingUOM]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnServingUOM(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.servingPerTrade]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnServingPerCase(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.calories]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnNutrition(columnName, columnMap, downloadableProduct, 15);
  },
  [productConversionColumns.caloriesFat]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnNutrition(columnName, columnMap, downloadableProduct, 14);
  },
  [productConversionColumns.totalFat]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnNutrition(columnName, columnMap, downloadableProduct, 0);
  },
  [productConversionColumns.satFat]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnNutrition(columnName, columnMap, downloadableProduct, 1);
  },
  [productConversionColumns.transFat]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnNutrition(columnName, columnMap, downloadableProduct, 2);
  },
  [productConversionColumns.cholest]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnNutrition(columnName, columnMap, downloadableProduct, 3);
  },
  [productConversionColumns.sodium]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnNutrition(columnName, columnMap, downloadableProduct, 4);
  },
  [productConversionColumns.totalCarb]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnNutrition(columnName, columnMap, downloadableProduct, 5);
  },
  [productConversionColumns.dietFiber]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnNutrition(columnName, columnMap, downloadableProduct, 6);
  },
  [productConversionColumns.totalSug]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnNutrition(columnName, columnMap, downloadableProduct, 7);
  },
  [productConversionColumns.protein]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnNutrition(columnName, columnMap, downloadableProduct, 9);
  },
  // [productConversionColumns.alCrust]: (
  //     columnName: string,
  //     columnMap: Map<string, SeperatedColumn>,
  //     downloadableProduct: downloadableConversionProduct,
  //     fileType: DownloadRequestFileType,
  // ) => {
  //     return temporaryFunction();
  // },
  // [productConversionColumns.alEgg]: (
  //     columnName: string,
  //     columnMap: Map<string, SeperatedColumn>,
  //     downloadableProduct: downloadableConversionProduct,
  //     fileType: DownloadRequestFileType,
  // ) => {
  //     return temporaryFunction();
  // },
  // [productConversionColumns.alFish]: (
  //     columnName: string,
  //     columnMap: Map<string, SeperatedColumn>,
  //     downloadableProduct: downloadableConversionProduct,
  //     fileType: DownloadRequestFileType,
  // ) => {
  //     return temporaryFunction();
  // },
  // [productConversionColumns.alPeanuts]: (
  //     columnName: string,
  //     columnMap: Map<string, SeperatedColumn>,
  //     downloadableProduct: downloadableConversionProduct,
  //     fileType: DownloadRequestFileType,
  // ) => {
  //     return temporaryFunction();
  // },
  // [productConversionColumns.alSoy]: (
  //     columnName: string,
  //     columnMap: Map<string, SeperatedColumn>,
  //     downloadableProduct: downloadableConversionProduct,
  //     fileType: DownloadRequestFileType,
  // ) => {
  //     return temporaryFunction();
  // },
  // [productConversionColumns.alTreeNut]: (
  //     columnName: string,
  //     columnMap: Map<string, SeperatedColumn>,
  //     downloadableProduct: downloadableConversionProduct,
  //     fileType: DownloadRequestFileType,
  // ) => {
  //     return temporaryFunction();
  // },
  // [productConversionColumns.alWheat]: (
  //     columnName: string,
  //     columnMap: Map<string, SeperatedColumn>,
  //     downloadableProduct: downloadableConversionProduct,
  //     fileType: DownloadRequestFileType,
  // ) => {
  //     return temporaryFunction();
  // },
  // [productConversionColumns.alMust]: (
  //     columnName: string,
  //     columnMap: Map<string, SeperatedColumn>,
  //     downloadableProduct: downloadableConversionProduct,
  //     fileType: DownloadRequestFileType,
  // ) => {
  //     return temporaryFunction();
  // },
  [productConversionColumns.childNutrition]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClaim(columnName, columnMap, downloadableProduct, 0);
  },
  [productConversionColumns.cholestFree]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClaim(columnName, columnMap, downloadableProduct, 1);
  },
  [productConversionColumns.fatFree]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClaim(columnName, columnMap, downloadableProduct, 4);
  },
  [productConversionColumns.lowFat]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClaim(columnName, columnMap, downloadableProduct, 2);
  },
  [productConversionColumns.noSug]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClaim(columnName, columnMap, downloadableProduct, 3);
  },
  [productConversionColumns.localSource]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClaim(columnName, columnMap, downloadableProduct, 11);
  },
  [productConversionColumns.kosher]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClaim(columnName, columnMap, downloadableProduct, 7);
  },
  [productConversionColumns.halal]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClaim(columnName, columnMap, downloadableProduct, 6);
  },
  [productConversionColumns.certBeef]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClaim(columnName, columnMap, downloadableProduct, 9);
  },
  [productConversionColumns.organic]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClaim(columnName, columnMap, downloadableProduct, 8);
  },
  [productConversionColumns.noAddedHorm]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnClaim(columnName, columnMap, downloadableProduct, 10);
  },
  [productConversionColumns.addInfo]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnAdditionalInfo(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.ingStatement]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnIngredientStatement(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.productStatusImage]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnImageStatus(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.productPackageSizeForPDF]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnPDFProductPackageSize(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.productType]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnImageType(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.pdfOriginality]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnPDFOriginality(columnName, columnMap, downloadableProduct);
  },
  [productConversionColumns.productNote]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductNote(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [productConversionColumns.eachAndCasePrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: downloadableConversionProduct,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnEachAndCasePrice(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
};
