<div
  class="quick-view-warning"
  [ngClass]="[
    isProductStatus ? 'product-status' : hasAlternative ? '' : 'quick-view',
    hasAlternative ? 'has-alternative' : '',
  ]"
>
  <ion-icon
    data-cy="quick-view-warning-icon"
    name="{{ quickViewWarning?.iconName }}"
  ></ion-icon>
  <p data-cy="quick-view-warning-text">
    {{ quickViewWarning?.message | translate }}
    <span
      *ngIf="quickViewWarning?.secondaryMessage"
      data-cy="quick-view-warning-subtext-1"
    >
      &nbsp;{{ quickViewWarning?.secondaryMessage | translate }}
    </span>
  </p>
</div>
