import { PageTitle } from '@shared/constants/page-titles.enum';

export enum TopBannerType {
  EQUIPMENT_AND_SUPPLIES,
  SERVE_YOU,
  WHILE_SUPPLIES_LAST,
  REV_MAN_WINBACKS,
  PAYMENTS,
  EBCOP, // exclusive brand, center of plate
  EBCORE, // exclusive brand
  CROSS_VALLEY_FARMS,
  SCOOPPAGE,
  NEWSCOOP,
  ALLSCOOP,
  EQUIPMENT_AND_SUPPLIES_PROMO,
}

// TOP SECTION
export const TopSectionSearchText = {
  EQUIPMENT_AND_SUPPLIES: 'eandscoreessentials',
  EQUIPMENT_AND_SUPPLIES_PROMO: 'Es2025q1items',
  SERVE_YOU: 'serveyou',
  SERVE_YOU_SPACE: 'serve you',
  WHILE_SUPPLIES_LAST: 'whilesupplieslast',
  EBCOP: 'EBCOPitems',
  EBCORE: 'EBCore',
  ALLSCOOP: 'allscoopproducts',
  SCOOPBANNER: 'newscoopproducts',
  STOCK_YARDS: 'stock yards',
};

export const TopSectionBrandNames = {
  GLENVIEW_FARMS: 'glenview farms',
  STOCK_YARDS: 'stock yards',
  STOCK_YARDS_ANGUS_BEEF: 'stock yards angus beef',
  CROSS_VALLEY_FARMS: 'cross valley farms',
  DEVONSHIRE: 'devonshire',
  MONARCH: 'monarch',
  RYKOFF_SEXTON: 'rykoff sexton',
  HARBOR_BANKS: 'harbor banks',
  PATUXENT_FARMS: 'patuxent farms',
  HARVEST_VALUE: 'harvest value',
};

export const TOP_SECTION_BRANDS_LIST = [
  TopSectionBrandNames.GLENVIEW_FARMS,
  TopSectionBrandNames.STOCK_YARDS,
  TopSectionBrandNames.STOCK_YARDS_ANGUS_BEEF,
  TopSectionBrandNames.CROSS_VALLEY_FARMS,
  TopSectionBrandNames.DEVONSHIRE,
  TopSectionBrandNames.MONARCH,
  TopSectionBrandNames.RYKOFF_SEXTON,
  TopSectionBrandNames.HARBOR_BANKS,
  TopSectionBrandNames.PATUXENT_FARMS,
  TopSectionBrandNames.HARVEST_VALUE,
];

export const TOP_SECTION_SEARCH_TEXT_LIST = [
  TopSectionSearchText.EQUIPMENT_AND_SUPPLIES,
  TopSectionSearchText.SERVE_YOU,
  TopSectionSearchText.SERVE_YOU_SPACE,
  TopSectionSearchText.WHILE_SUPPLIES_LAST,
  TopSectionSearchText.EBCOP,
  TopSectionSearchText.EBCORE,
  TopSectionSearchText.ALLSCOOP,
  TopSectionSearchText.STOCK_YARDS,
];

export const TOP_SECTION_BACKGROUND_IMAGES_MAP = new Map<string, string>();
export const TOP_SECTION_BACKGROUND_POSITION_MAP = new Map<string, string>();
export const TOP_SECTION_IMAGE_TITLE_MAP = new Map<string, string>();
export const TOP_SECTION_MOBILE_TITLE_MAP = new Map<string, string>();
export const TOP_SECTION_TEXT_TITLE_MAP = new Map<string, string>();
export const TOP_SECTION_TEXT_CONTENT_MAP = new Map<string, string>();

const brandedSearchPages = [
  {
    key: TopSectionSearchText.EQUIPMENT_AND_SUPPLIES,
    backgroundImage: "url('/assets/images/equipment-supplies.png')",
    backgroundPosition: 'top center',
    imageTitle: 'i18n.search.equipmentAndSupplies.imageTitle',
    mobileTitle: PageTitle.equipmentAndSupplies,
    textTitle: 'i18n.search.equipmentAndSupplies.textTitle',
    textContent: 'i18n.search.equipmentAndSupplies.textContent',
  },
  {
    key: TopSectionSearchText.SERVE_YOU,
    backgroundImage: "url('/assets/images/serve-you.jpg')",
    backgroundPosition: 'top center',
    imageTitle: 'i18n.search.serveYou.imageTitle',
    mobileTitle: PageTitle.serveYou,
    textTitle: 'i18n.search.serveYou.textTitle',
    textContent: 'i18n.search.serveYou.textContent',
  },
  {
    key: TopSectionSearchText.SERVE_YOU_SPACE,
    backgroundImage: "url('/assets/images/serve-you.jpg')",
    backgroundPosition: 'top center',
    imageTitle: 'i18n.search.serveYou.imageTitle',
    mobileTitle: PageTitle.serveYou,
    textTitle: 'i18n.search.serveYou.textTitle',
    textContent: 'i18n.search.serveYou.textContent',
  },
  {
    key: TopSectionSearchText.WHILE_SUPPLIES_LAST,
    backgroundImage: "url('/assets/images/while-supplies-last.png')",
    backgroundPosition: 'top center',
    imageTitle: 'i18n.search.whileSuppliesLast.imageTitle',
    mobileTitle: PageTitle.whileSuppliesLast,
    textTitle: 'i18n.search.whileSuppliesLast.textTitle',
    textContent: 'i18n.search.whileSuppliesLast.textContent',
  },
  {
    key: TopSectionSearchText.EBCOP,
    backgroundImage: "url('/assets/images/eb-cop.jpg')",
    backgroundPosition: 'top center',
    imageTitle: 'i18n.search.ebCop.imageTitle',
    mobileTitle: PageTitle.ebCop,
    textTitle: 'i18n.search.ebCop.textTitle',
    textContent: 'i18n.search.ebCop.textContent',
  },
  {
    key: TopSectionSearchText.EBCORE,
    backgroundImage: "url('/assets/images/eb-core.jpg')",
    backgroundPosition: 'top center',
    imageTitle: 'i18n.search.ebCore.imageTitle',
    mobileTitle: PageTitle.ebCore,
    textTitle: 'i18n.search.ebCore.textTitle',
    textContent: 'i18n.search.ebCore.textContent',
  },
  {
    key: TopSectionSearchText.ALLSCOOP,
    backgroundImage: "url('/assets/images/scoop-merch-banner.jpg')",
    backgroundPosition: 'top center',
    imageTitle: 'i18n.search.allscoop.imageTitle',
    mobileTitle: PageTitle.allscoop,
    textTitle: 'i18n.search.allscoop.textTitle',
    textContent: 'i18n.search.allscoop.textContent',
  },
  {
    key: TopSectionBrandNames.GLENVIEW_FARMS,
    backgroundImage: "url('/assets/images/glenview-farms.jpg')",
    backgroundPosition: 'top center',
    imageTitle: 'i18n.search.glenviewFarms.imageTitle',
    mobileTitle: PageTitle.glenviewFarms,
    textTitle: 'i18n.search.glenviewFarms.textTitle',
    textContent: 'i18n.search.glenviewFarms.textContent',
  },
  {
    key: TopSectionBrandNames.STOCK_YARDS,
    backgroundImage: "url('/assets/images/stock-yards.jpg')",
    backgroundPosition: 'top left',
    imageTitle: 'i18n.search.stockYards.imageTitle',
    mobileTitle: PageTitle.stockYards,
    textTitle: 'i18n.search.stockYards.textTitle',
    textContent: 'i18n.search.stockYards.textContent',
  },
  {
    key: TopSectionSearchText.STOCK_YARDS,
    backgroundImage: "url('/assets/images/stock-yards.jpg')",
    backgroundPosition: 'top left',
    imageTitle: 'i18n.search.stockYards.imageTitle',
    mobileTitle: PageTitle.stockYards,
    textTitle: 'i18n.search.stockYards.textTitle',
    textContent: 'i18n.search.stockYards.textContent',
  },
  {
    key: TopSectionBrandNames.CROSS_VALLEY_FARMS,
    backgroundImage: "url('/assets/images/cross-valley-farms.jpg')",
    backgroundPosition: 'top center',
    imageTitle: 'i18n.search.crossValleyFarms.imageTitle',
    mobileTitle: PageTitle.crossValleyFarms,
    textTitle: 'i18n.search.crossValleyFarms.textTitle',
    textContent: 'i18n.search.crossValleyFarms.textContent',
  },
  {
    key: TopSectionBrandNames.DEVONSHIRE,
    backgroundImage: "url('/assets/images/devonshire.jpg')",
    backgroundPosition: 'top right',
    imageTitle: 'i18n.search.devonshire.imageTitle',
    mobileTitle: PageTitle.devonshire,
    textTitle: 'i18n.search.devonshire.textTitle',
    textContent: 'i18n.search.devonshire.textContent',
  },
  {
    key: TopSectionBrandNames.MONARCH,
    backgroundImage: "url('/assets/images/monarch.jpg')",
    backgroundPosition: 'top right',
    imageTitle: 'i18n.search.monarch.imageTitle',
    mobileTitle: PageTitle.monarch,
    textTitle: 'i18n.search.monarch.textTitle',
    textContent: 'i18n.search.monarch.textContent',
  },
  {
    key: TopSectionBrandNames.STOCK_YARDS_ANGUS_BEEF,
    backgroundImage: "url('/assets/images/stock-yards-angus-beef.jpg')",
    backgroundPosition: 'top right',
    imageTitle: 'i18n.search.stockYardsAngusBeef.imageTitle',
    mobileTitle: PageTitle.stockYardsAngusBeef,
    textTitle: 'i18n.search.stockYardsAngusBeef.textTitle',
    textContent: 'i18n.search.stockYardsAngusBeef.textContent',
  },
  {
    key: TopSectionBrandNames.RYKOFF_SEXTON,
    backgroundImage: "url('/assets/images/rykoff-sexton.jpg')",
    backgroundPosition: 'top right',
    imageTitle: 'i18n.search.rykoffSexton.imageTitle',
    mobileTitle: PageTitle.rykoffSexton,
    textTitle: 'i18n.search.rykoffSexton.textTitle',
    textContent: 'i18n.search.rykoffSexton.textContent',
  },
  {
    key: TopSectionBrandNames.HARBOR_BANKS,
    backgroundImage: "url('/assets/images/harbor-banks.jpg')",
    backgroundPosition: 'top right',
    imageTitle: 'i18n.search.harborBanks.imageTitle',
    mobileTitle: PageTitle.harborBanks,
    textTitle: 'i18n.search.harborBanks.textTitle',
    textContent: 'i18n.search.harborBanks.textContent',
  },
  {
    key: TopSectionBrandNames.PATUXENT_FARMS,
    backgroundImage: "url('/assets/images/patuxent-farms.jpg')",
    backgroundPosition: 'top right',
    imageTitle: 'i18n.search.patuxentFarms.imageTitle',
    mobileTitle: PageTitle.patuxentFarms,
    textTitle: 'i18n.search.patuxentFarms.textTitle',
    textContent: 'i18n.search.patuxentFarms.textContent',
  },
  {
    key: TopSectionBrandNames.HARVEST_VALUE,
    backgroundImage: "url('/assets/images/harvest-value.jpg')",
    backgroundPosition: 'top right',
    imageTitle: 'i18n.search.harvestValue.imageTitle',
    mobileTitle: PageTitle.harvestValue,
    textTitle: 'i18n.search.harvestValue.textTitle',
    textContent: 'i18n.search.harvestValue.textContent',
  },
];

brandedSearchPages.forEach(page => {
  TOP_SECTION_BACKGROUND_IMAGES_MAP.set(page.key, page.backgroundImage);
  TOP_SECTION_BACKGROUND_POSITION_MAP.set(page.key, page.backgroundPosition);
  TOP_SECTION_IMAGE_TITLE_MAP.set(page.key, page.imageTitle);
  TOP_SECTION_MOBILE_TITLE_MAP.set(page.key, page.mobileTitle);
  TOP_SECTION_TEXT_TITLE_MAP.set(page.key, page.textTitle);
  TOP_SECTION_TEXT_CONTENT_MAP.set(page.key, page.textContent);
});

// TOP SECTION SINGLE BANNER

export const TopSectionSingleBannerSearchText = {
  WHILE_SUPPLIES_LAST: [
    'promote',
    'deals',
    'sale',
    'clearance',
    'promod',
    'sales',
    'deal',
    'discount',
    'resale items',
    'sale items',
    'resale',
    'point of sale',
    'scoop deals',
    'discounted',
    'promotion',
  ],
  SERVE_YOU: [
    'vegan cheese',
    'vegan mayo',
    'vegan sausage',
    'vegan chicken',
    'vegan butter',
    'vegan egg',
    'vegetarian beans',
    'dairy free yogurt',
    'dairy free cheese',
    'vegan cream cheese',
    'vegetarian chili',
    'vegan yogurt',
    'vegan mozzarella',
    'vegan burger',
    'vegan meat',
    'vegan dessert',
    'plant based chicken',
    'vegetarian sausage',
    'vegan bread',
    'vegan ice cream',
    'vegan chorizo',
    'dairy free ice cream',
    'vegan meatball',
    'vegan cookie',
    'vegan meatballs',
    'vegetarian chicken',
    'veganaise',
    'vegan patty',
    'vegan bacon',
    'vegan cheddar',
    'vegetarian burger',
    'vegan pasta',
    'plant base',
    'vegan eggs',
    'vegan cookies',
    'vegan bun',
    'vegan sour cream',
    'vegetarian baked beans',
    'vegan mozz',
    'vegan soup',
    'vegan nuggets',
    'vegan cake',
    'vegan beef',
    'plant based sausage',
    'vegan hot dog',
    'vegetarian soup',
    'vegan pepperoni',
    'vegetarian patty',
    'vegan mayonnaise',
    'vegan cream',
    'plant base chicken',
    'vegetarian lasagna',
    'vegan chocolate',
    'vegetarian bean',
    'vegan ranch',
    'vegan tenders',
    'vegan crumble',
    'dairy free milk',
    'vegetarian meat',
    'vegan burgers',
    'vegan muffin',
    'vegetarian meatballs',
    'plant based burger',
    'plant based meat',
    'vegan chili',
    'vegan mayonaise',
    'vegan tender',
    'dairy free butter',
    'vegetarian meatball',
    'vegan buns',
    'vegan fish',
    'cheese vegan',
    'vegetarian hot dog',
    'vegan parmesan',
    'vegan breakfast',
    'vegan patties',
    'vegan ravioli',
    'vegan wings',
    'vegan spread',
    'vegan desserts',
    'vegan pesto',
    'vegan feta',
    'vegan breakfast sausage',
    'vegan pizza',
    'vegan chicken nuggets',
    'vegan nugget',
    'vegan parm',
    'vegetarian nuggets',
    'butter vegan',
    'vegetarian bacon',
    'vegan chicken tenders',
    'dairy free dessert',
    'vegan sauce',
    'sausage vegan',
    'plant based cheese',
    'vegan dressing',
    'vegetarian',
    'vegan',
    'dairy free',
    'meat substitute',
    'meatless',
  ],
  REV_MAN_WINBACKS: [
    'bacon',
    'milk',
    'eggs',
    'ketchup',
    'yogurt',
    'mayo',
    'butter',
    'cheese',
    'water',
    'sour cream',
    'orange juice',
    'sausage',
    'sugar',
    'shrimp',
    'chips',
    'fries',
    'cream cheese',
    'egg',
    'ice cream',
    'heavy cream',
    'rice',
    'banana',
    'ranch',
    'gloves',
    'corn',
    'bread',
    'salmon',
    'coffee',
    'cucumber',
    'apple juice',
    'tortilla',
    'syrup',
    'flour',
    'creamer',
    'oil',
    'oranges',
    'cereal',
    'honey',
    'strawberry',
    'salt',
    'mustard',
    'strawberries',
    'french fries',
    'juice',
    'pizza',
    'salsa',
    'cranberry juice',
    'avocado',
    'napkins',
    'coke',
    'tuna',
    'jelly',
    'wings',
    'cod',
    'oatmeal',
    'croissant',
    'bananas',
    'bbq sauce',
    'lemon',
    'peaches',
    'cookies',
    'mozzarella',
    'cheddar',
    'peanut butter',
    'applesauce',
    'hot dog',
    'brown sugar',
    'watermelon',
    'danish',
    'biscuit',
    'grapes',
    'buttermilk',
    'crackers',
    'muffins',
    'orange',
    'muffin',
    'asparagus',
    'marinara',
    'pasta',
    'brisket',
    'shortening',
    'cottage cheese',
    'american cheese',
    'napkin',
    'half and half',
    'french toast',
    'pickles',
    'lemon juice',
    'cake',
    'biscuits',
    'meatballs',
    'lemonade',
    'peas',
    'bleach',
    'pepperoni',
    'black pepper',
    'ribeye',
    'margarine',
    'potato salad',
    'bun',
    'fryer oil',
    'garlic bread',
    'pretzel',
    'blueberries',
    'liquid egg',
    'buns',
    'cucumbers',
    'waffles',
    'shredded cheese',
    'lemons',
    'cups',
    'bagel',
    'sprite',
    'zucchini',
    'olive oil',
    'roast beef',
    'waffle',
    'onion rings',
    'pears',
    'chicken base',
    'cheesecake',
    'soup',
    'pineapple juice',
    'jalapeno',
    'kosher salt',
    'cookie dough',
    'liquid eggs',
    'hot dogs',
    'granola',
    'hummus',
    'gatorade',
    'bagels',
    'baked beans',
    'pudding',
    'tea',
    'fish',
    'parmesan',
    'honey mustard',
    'english muffin',
    'feta',
    'pancake mix',
    'white bread',
    'bbq',
    'almond milk',
    'blueberry',
    'blue cheese',
  ],
  PAYMENTS: [
    'invoices',
    'payment',
    'invoice',
    'pay',
    'payments',
    'pay bill',
    'make a payment',
    'make payment',
    'pay invoice',
    'pay my bill',
    'pay invoices',
    'bill pay',
    'bill',
    'auto pay',
    'bills',
    'autopay',
    'billing',
    'pay bills',
    'pay my invoice',
    'pay online',
    'make payments',
    'paid invoices',
    'my invoices',
    'past invoices',
    'invoice payment',
    'open invoices',
    'how to make payment',
    'all invoices',
    'how do i make a payment',
    'payment methods',
    'paybill',
    'make pay',
    'view invoices',
    'payment info',
    'invoices and payments',
    'payment method',
    'my bills',
  ],
  EB_COP: [
    'chicken',
    'ground beef',
    'ham',
    'chicken breast',
    'turkey',
    'beef',
    'pork',
    'chicken wings',
    'pork loin',
    'salami',
    'pulled pork',
    'beef patty',
    'pork butt',
    'hamburger',
    'pork chop',
    'turkey sausage',
    'hamburger bun',
    'sliced turkey',
    'chicken wing',
    'sliced ham',
    'turkey breast',
    'chicken patty',
    'chicken nuggets',
    'chicken thighs',
    'pork belly',
    'pork chops',
    'chicken strips',
    'beef tenderloin',
    'fried chicken',
    'grilled chicken',
    'breaded chicken',
    'chicken salad',
    'pork ribs',
    'pork tenderloin',
    'diced ham',
    'ground turkey',
    'hamburgers',
    'pork shoulder',
    'beef patties',
    'hamburger patties',
    'turkey bacon',
    'popcorn chicken',
    'turkey burger',
    'hamburger patty',
    'corn beef',
    'ground pork',
    'pulled chicken',
    'beef brisket',
    'pork sausage',
    'chicken gravy',
    'beef tips',
    'whole chicken',
    'chicken nugget',
    'chicken fried steak',
    'chicken sausage',
    'pork rib',
    'chicken patties',
    'deli turkey',
    'beef ground',
    'turkey sliced',
    'ham sliced',
    'chicken strip',
    'deli ham',
    'beef stew',
    'pit ham',
    'fajita chicken',
    'shredded chicken',
    'diced beef',
    'chicken tenderloin',
    'diced turkey',
    'beef roast',
    'chicken fajita',
    'deli meat',
    'chicken legs',
    'buffet ham',
    'pork roast',
    'chicken leg',
    'chicken chunk',
    'chicken breasts',
    'chicken diced',
    'chicken chunk',
    'chicken fingers',
    'diced pork',
    'beef fritter',
    'cooked chicken',
    'chicken fritter',
    'chicken quarter',
    'beef strip',
    'beef steak',
    'sliced roast beef',
    'corned beef hash',
    'bbq pork',
    'beef ribs',
    'breaded chicken breast',
    'turkey patty',
    'chicken cordon bleu',
    'lunch meat',
    'beef crumble',
    'chicken quarters',
    'beef strips',
    'chicken breaded',
    'ground chicken',
    'grilled chicken breast',
    'chicken chunks',
    'porkloin',
    'beef chuck',
    'smoked turkey',
    'turkey ham',
    'beef jerky',
    'turkey roast',
    'tempura chicken',
    'random chicken',
    'turkey burgers',
    'beef fajita',
    'sliced beef',
    'pork patty',
    'beef ribeye',
    'beef short rib',
    'turkey slice',
    'chicken pot pie',
    'beef burger',
    'beef tip',
    'turkey slices',
    'dice chicken',
    'shredded beef',
    'pork butts',
    'chicken philly',
    '8 piece chicken',
    'beef crumbles',
    'beef philly',
    'beef rib',
    'philly beef',
    'half chicken',
    'corn beef hash',
    'pork carnitas',
    'spicy chicken',
    'bbq chicken',
    'boneless chicken',
    'slice turkey',
    'hamburger meat',
    'pork fritter',
    'ground beef patty',
    'beef hot dog',
    'pull pork',
    'stew beef',
    'turkey deli',
    'ham steak',
    'country ham',
    'beef hot dogs',
    'chipped beef',
    'pork shank',
    'cornbeef',
    'bbq beef',
    'breaded chicken patty',
    'beef, ground',
    'chicken finger',
    'roast beef sliced',
    'beef slider',
    'chicken random',
    'ham diced',
    'canned chicken',
    'corned beef',
    'chicken tenders',
    'diced chicken',
    'chicken thigh',
    'chicken tender',
  ],
  EQUIPMENT_AND_SUPPLIES: [
    'forks',
    'spoons',
    'plates',
    'container',
    'spoon',
    'fork',
    'trash bags',
    'knife',
    'paper plates',
    'bag',
    'plate',
    'pan liner',
    'bags',
    'thermometer',
    'labels',
    'broom',
    'trash bag',
    'label',
    'plastic spoons',
    'pan liners',
    'plastic forks',
    'containers',
    'plastic spoon',
    'apron',
    'paper plate',
    'spatula',
    'glass cleaner',
    'tongs',
    'garbage bags',
    'plastic fork',
    'paper bag',
    'pan',
    'to go container',
    'to go containers',
    'paper bags',
    'sandwich bags',
    'foam container',
    'deli container',
    'fuel',
    'sandwich bag',
    'gallon bags',
    'foil pan',
    'portion bags',
    'pan coating',
    'pot and pan',
    'aprons',
    'dust pan',
    'gallon bag',
    'to go bags',
    'pastry bag',
    'disher',
    'grill brush',
    'plastic bags',
    'plastic bag',
    'brush',
    'pans',
    'plastic knife',
    'tong',
    'storage bags',
    'plastic container',
    'foam plates',
    'dispenser',
    'mop bucket',
    'plastic plates',
    'oven mitts',
    'foil pans',
    't shirt bags',
    'oven mitt',
    'thank you bags',
    'garbage bag',
    'aluminum pan',
    'aluminum pans',
    'foam plate',
    'napkin dispenser',
    'knifes',
    'portion bag',
    'brown bag',
    'ramekin',
    'glass',
    'flatware',
    'chef knife',
    'squeeze bottle',
    'scrubber',
    'bucket',
    'pick',
    'steam pan',
    'sheet pan',
    'table pan',
    'towel',
    'apron',
  ],
  CROSS_VALLEY_FARMS: [
    'lettuce',
    'tomato',
    'broccoli',
    'pineapple',
    'carrots',
    'romaine',
    'spinach',
    'tomatoes',
    'potato',
    'onion',
    'celery',
    'green beans',
    'garlic',
    'apples',
    'cilantro',
    'cabbage',
    'potatoes',
    'salad mix',
    'basil',
    'mushroom',
    'cauliflower',
    'carrot',
    'coleslaw',
    'mushrooms',
    'onions',
    'apple',
    'spring mix',
    'parsley',
    'limes',
    'red onion',
    'sweet potato',
    'lime',
    'pepper',
    'mango',
    'green onion',
    'green bean',
    'arugula',
    'mint',
    'cantaloupe',
    'honeydew',
    'shredded lettuce',
    'cole slaw',
    'kale',
    'iceberg',
    'yellow onion',
    'slaw',
    'melon',
    'red potato',
    'cherry tomato',
    'baby carrots',
    'grape tomato',
    'tropical fruit',
    'brussel sprouts',
    'leaf lettuce',
    'sweet potatoes',
    'red potatoes',
    'chives',
    'pico',
    'apple slices',
    'cherry tomatoes',
    'honey dew',
    'red onions',
    'green onions',
    'beans',
    'red peppers',
    'fruit salad',
    'grape tomatoes',
    'diced onion',
    'brussel',
    'salad',
    'romaine lettuce',
    'baby carrot',
    'romain',
    'green leaf',
    'roma',
    'vegetable blend',
    'mixed greens',
    'diced carrots',
    'butternut squash',
    'cantalope',
    'red cabbage',
    'sliced apples',
    'shredded carrots',
    'tomatoe',
    'pineapples',
    'yams',
    'coleslaw mix',
    'tomatos',
    'mixed vegetables',
    'brocoli',
    'yellow onions',
    'scallion',
    'yukon',
    'chive',
    'brocolli',
    'iceburg',
    'sliced tomato',
    'roma tomato',
    'scallions',
    'russet',
    'carrot sticks',
    'pico de gallo',
    'fruit mix',
    'canteloupe',
    'slaw mix',
    'greens',
    'cantelope',
    'white onion',
    'potatos',
    'shredded carrot',
    'fingerling',
    'diced onions',
    'shredded cabbage',
    'iceberg lettuce',
    'idaho',
    'bean',
    'yam',
    'potatoe',
    'roma tomatoes',
    'broc',
    'diced celery',
    'lettuce mix',
    'diced carrot',
    'baby spinach',
    'micro greens',
    'chopped romaine',
    'portabella',
    'celery sticks',
    'peeled garlic',
    'sliced tomatoes',
    'sliced onion',
    'burger lettuce',
    'apple slice',
    'produce',
    'yukon gold',
    'sliced mushrooms',
    'carrot stick',
    'baked potato',
    'califlower',
    'micro',
    'romaine hearts',
    'potato diced',
    'sliced apple',
    'sliced mushroom',
    'russet potato',
    'green cabbage',
    'butter lettuce',
    'tomato diced',
    'melons',
    'idaho potato',
    'mints',
    'peppers and onions',
    'arugala',
    'heirloom',
    'green tomato',
    'crimini',
    'celery stick',
    'plum tomato',
    'broccoli florets',
    'micro green',
    'shred lettuce',
    'tomato fresh',
    'mixed green',
    'mix fruit',
    'cole slaw mix',
    'harvest blend',
    'russet potatoes',
    'mesclun',
    'heirloom tomato',
    'baby kale',
    'fresh broccoli',
    'bibb',
    'fresh garlic',
    'pineapple chunks',
    'sliced onions',
    'dice tomato',
    'onion yellow',
    'onion red',
    'cherry tom',
    'iceburg lettuce',
    'tomato grape',
    'white onions',
    'potato red',
    'leaf',
    'romain lettuce',
    'roasted potatoes',
    'green leaf lettuce',
    'mixed veg',
    'tomato 5x6',
    'fresh spinach',
    'napa cabbage',
    'idaho potatoes',
    'radishes',
    'green apple',
    'salads',
    'portabello',
    'cloves',
    'romaine heart',
    'pine apple',
    '5x6 tomato',
    'diced mango',
    'sweet potatoe',
    'carrot coin',
    'fresh pineapple',
    'ice berg',
    'fingerling potato',
    'fingerling potatoes',
    'veggies',
    'onion diced',
    'greenbeans',
    'fresh tomato',
    'yukon potato',
    'tom',
    'granny smith',
    'cantaloup',
    'fresh mint',
    'garlic peeled',
    'tomato sliced',
    'lettuce shred',
    'vegetable mix',
    'broccolli',
    'bibb lettuce',
    'letuce',
    'clove',
    'mix salad',
    'mushroom sliced',
    'garden salad',
    'apple sliced',
    'tomato cherry',
    'lettuce shredded',
    'bean sprout',
    'fresh tomatoes',
    'mix greens',
    'pineapple fresh',
  ],
  ALLSCOOP: ['allscoopproducts'],
  SCOOP: ['scoop'],
  EQUIPMENT_AND_SUPPLIES_PROMO: ['eandscoreessentials'],
};

// SCOOP SINGLE BANNER
export const SCOOP_SINGLE_BANNER = {
  primaryBanner: {
    desktopUrl: '/assets/images/scoop-banner-desktop.jpg',
    tabletUrl: '/assets/images/scoop-banner-tablet.jpg',
    mobileUrl: '/assets/images/scoop-banner-mobile.jpg',
  },
  secondaryBanner: {
    desktopUrl: '/assets/images/scoop-secondary-test-banner-desktop.jpg',
    tabletUrl: '/assets/images/scoop-secondary-test-banner-tablet.jpg',
    mobileUrl: '/assets/images/scoop-secondary-test-banner-mobile.jpg',
  },
};
