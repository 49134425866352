import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE_KEYS } from '@panamax/app-state';

@Injectable({
  providedIn: 'root',
})
export class I18nConfigService {
  currentLang: string;

  constructor(private translateService: TranslateService) {}

  languageInitialization(): void {
    this.translateService.addLangs(['en', 'es']);
  }

  /**
   * Find out default browser or device language
   *
   * @returns languageCode
   * Using ngx-translate api, we check PWA browser/device language.
   */
  getDefaultLanguage() {
    this.languageInitialization();
    if (
      localStorage.getItem('primaryLanguage') !== null ||
      localStorage.getItem('appLanguage') === null
    ) {
      this.currentLang = this.translateService.getBrowserLang();

      if (
        this.translateService.getLangs().find(lang => lang === this.currentLang)
      ) {
        this.translateService.use(this.currentLang);
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.displayLanguage,
          this.currentLang,
        );
      } else {
        this.translateService.use('en');
        localStorage.setItem(LOCAL_STORAGE_KEYS.displayLanguage, 'en');
      }
    } else {
      this.currentLang = localStorage.getItem('appLanguage');
      this.translateService.setDefaultLang(this.currentLang);
      this.translateService.use(this.currentLang);
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.displayLanguage,
        this.currentLang,
      );
    }
  }

  /**
   * setting up the application language
   *
   * @param setLang
   */
  setLanguage(langCode: string): void {
    this.translateService.use(langCode.match(/en|es/) ? langCode : 'en');
  }

  useBrowserDefault() {
    localStorage.removeItem('appLanguage');
    const browserLang = this.translateService.getBrowserLang();
    if (this.translateService.getLangs().find(lang => lang === browserLang)) {
      this.translateService.use(browserLang);
      localStorage.setItem(LOCAL_STORAGE_KEYS.displayLanguage, browserLang);
    } else {
      this.translateService.use('en');
      localStorage.setItem(LOCAL_STORAGE_KEYS.displayLanguage, 'en');
    }
  }
}
