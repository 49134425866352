<section>
  <header>
    <h2
      class="title"
      data-cy="create-opportunity-header-title-text"
      appTestId="create-opportunity-header-title"
    >
      {{ 'i18n.productDetail.customPricingRationaleModal.title' | translate }}
    </h2>
  </header>
  <ion-grid
    [class.desktop]="
      platformService?.platformType === platformService?.platformEnum?.desktop
    "
  >
    <ion-row
      class="ion-justify-content-around"
      *ngIf="displayPriceNotOptimizedNote"
    >
      <div class="cpr-note-banner">
        <strong>{{
          'i18n.productDetail.customPricingRationaleModal.note' | translate
        }}</strong>
        <span>{{
          'i18n.productDetail.customPricingRationaleModal.priceNotOptimizedMessage'
            | translate
        }}</span>
      </div>
    </ion-row>
    <ion-row class="ion-justify-content-around">
      <ion-col size="12" size-sm="5">
        <div class="product-card">
          <div class="product-image" appTestId="cpr-summary-image">
            <img
              src="{{ thumbnailImageURL }}"
              data-cy="cpr-product-image"
              (error)="
                this.thumbnailImageURL =
                  getAlternativeThumbnail(thumbnailImageURL)
              "
            />
          </div>
          <div class="product-info">
            <span
              class="brand"
              [attr.data-cy]="
                'cpr-summary-brand-' + product?.productNumber + '-text'
              "
              appTestId="cpr-summary-brand"
              >{{ product?.summary?.brand | acronymTitleCase }}</span
            >
            <span
              class="product-info-title"
              [attr.data-cy]="
                'cpr-product-description-' + product?.productNumber + '-text'
              "
              appTestId="cpr-summary-product-desc"
              >{{
                product?.summary?.productDescTxtl ||
                  'i18n.productDetail.productDescriptionNotAvailableMessage'
                  | translate
              }}</span
            >
            <div class="additional-info">
              <span
                class="product-number"
                [attr.data-cy]="
                  'cpr-product-number-' + product?.productNumber + '-text'
                "
                appTestId="cpr-summary-product-number"
                >#{{ product?.summary?.productNumber }}</span
              >
              <span class="separator" data-cy="separator-line">|</span>
              <span
                class="pack-size"
                data-cy="product-pack-size-text"
                appTestId="cpr-summary-pack-size"
                >{{ product?.summary?.salesPackSize | lowercase }}</span
              >
              <ng-container
                *ngIf="
                  sellerPriceDetail &&
                  sellerPriceDetail.lastFourBuys?.mostRecentWwp
                "
              >
                <span class="separator" data-cy="separator-line">|</span>
                <span
                  class="pack-size"
                  data-cy="product-wwp-text"
                  appTestId="cpr-summary-wwp"
                  >{{ sellerPriceDetail.lastFourBuys.mostRecentWwp }}</span
                >
              </ng-container>
            </div>
          </div>

          <div
            class="product-info-price"
            appTestId="cpr-summary-price-container"
          >
            <span class="price-label" data-cy="cpr-current-price-text">
              {{
                'i18n.productDetail.customPricingRationaleModal.originalPrice'
                  | translate
              }}:&nbsp;</span
            >
            <span
              data-cy="cpr-price-details-text"
              *ngIf="product?.pricing?.unitPrice * 1 > 0"
              appTestId="cpr-summary-unit-price"
              >{{ product?.pricing?.unitPrice | currency }}&nbsp;{{
                priceUom
              }}</span
            >
            <ng-container *ngIf="product?.summary?.breakable">
              <span
                data-cy="cpr-price-details-text"
                *ngIf="
                  product?.pricing?.unitPrice * 1 > 0 &&
                  product?.pricing?.eachPrice * 1 > 0
                "
                >,&nbsp;</span
              >
              <span
                data-cy="cpr-price-details-text"
                *ngIf="product?.pricing?.eachPrice * 1 > 0"
                appTestId="cpr-summary-each-price"
                >{{ product?.pricing?.eachPrice | currency }}&nbsp;{{
                  product?.summary?.catchWeightFlag
                    ? '/' + product?.summary?.priceUom
                    : product?.summary?.eachUom
                }}</span
              >
            </ng-container>
          </div>
        </div>

        <ion-accordion-group value="additional-properties">
          <ion-accordion value="additional-properties">
            <ion-item slot="header">
              <ion-label>{{
                'i18n.handPricing.additionalPricing.title' | translate
              }}</ion-label>
            </ion-item>
            <div slot="content">
              <section class="pricing-section-one">
                <dl>
                  <article>
                    <dt
                      [attr.data-cy]="'pricing-section-two-current-week-text'"
                    >
                      {{
                        'i18n.productDetail.customPricingRationaleModal.currentWeek'
                          | translate
                      }}:
                    </dt>
                    <dd
                      [attr.data-cy]="'pricing-section-two-current-week-value'"
                    >
                      {{ sellerPriceDetail?.currentPrice.unitPrice | currency }}
                      {{ priceUom }}
                    </dd>
                  </article>

                  <article>
                    <dt [attr.data-cy]="'pricing-section-two-last-week-text'">
                      {{
                        'i18n.productDetail.customPricingRationaleModal.lastWeek'
                          | translate
                      }}:
                    </dt>
                    <dd [attr.data-cy]="'pricing-section-two-last-week-value'">
                      {{ sellerPriceDetail?.lastPrice.unitPrice | currency }}
                      {{ priceUom }}
                    </dd>
                  </article>

                  <article>
                    <dt
                      [attr.data-cy]="'pricing-section-two-price-change-text'"
                    >
                      {{
                        'i18n.productDetail.customPricingRationaleModal.priceChange'
                          | translate
                      }}:
                    </dt>
                    <dd
                      [ngClass]="
                        priceChange >= 0 ? 'price-raised' : 'price-drop'
                      "
                      [attr.data-cy]="'pricing-section-two-price-change-value'"
                    >
                      {{ priceChange | currency }} {{ priceUom }}
                      <ion-icon
                        [name]="
                          'arrow-' +
                          (priceChange >= 0 ? 'up' : 'down') +
                          '-outline'
                        "
                      ></ion-icon>
                      <span
                        >({{
                          'i18n.productDetail.customPricingRationaleModal.comparisonNotice'
                            | translate
                        }})</span
                      >
                    </dd>
                  </article>

                  <article *appHideSplit="[hideMarketChangeFeatureFlag]">
                    <dt
                      [attr.data-cy]="'pricing-section-two-market-change-text'"
                    >
                      {{
                        'i18n.productDetail.customPricingRationaleModal.marketChange'
                          | translate
                      }}:
                    </dt>
                    <dd
                      [attr.data-cy]="'pricing-section-two-market-change-value'"
                    >
                      {{
                        sellerPriceDetail?.currentPrice.marketChange | currency
                      }}
                      {{ priceUom }}
                    </dd>
                  </article>
                  <article *ngIf="lastBuy">
                    <dt
                      [attr.data-cy]="'pricing-section-two-last-purchase-text'"
                    >
                      {{
                        'i18n.productDetail.customPricingRationaleModal.lastPurchase'
                          | translate
                      }}:
                    </dt>
                    <dd
                      [attr.data-cy]="'pricing-section-two-last-purchase-value'"
                    >
                      {{ lastBuy.price }} - {{ lastBuy.invoiceDate }}
                    </dd>
                  </article>
                </dl>
              </section>
              <section class="pricing-section-two">
                <dl>
                  <ng-container *ngFor="let data of pricingDetailsTwo">
                    <article *ngIf="data.value">
                      <dt
                        [attr.data-cy]="
                          'pricing-section-two-' + data?.keyLabel + '-text'
                        "
                      >
                        {{ data.keyLabel | translate }}:
                      </dt>
                      <dd
                        [attr.data-cy]="
                          'pricing-section-two-' + data?.keyLabel + '-value'
                        "
                      >
                        {{ data.value }}
                      </dd>
                    </article>
                  </ng-container>
                </dl>
              </section>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-col>
      <ion-col size="12" size-sm="7" class="set-price-column">
        <div class="set-price">
          <h5 class="title">
            {{
              'i18n.productDetail.customPricingRationaleModal.setCPRPrice'
                | translate
            }}
          </h5>
          <span class="notice-message">
            {{
              'i18n.productDetail.customPricingRationaleModal.noticeMsg'
                | translate
            }}
          </span>
          <span *ngIf="includeAscCharge" class="asc-label">{{
            'i18n.productDetail.customPricingRationaleModal.ascDisclaimer'
              | translate
          }}</span>
          <span class="selectOne-label">{{
            'i18n.productDetail.customPricingRationaleModal.selectOne'
              | translate
          }}</span>
          <ion-list class="ion-content-cpr-desktop">
            <ion-radio-group
              [(ngModel)]="priceRadioSelected"
              appTestId="cpr-update-price"
              class="radio-group"
              data-cy="cpr-price-radio-button"
            >
              <div class="cpr-radio-wrapper">
                <ion-item-divider
                  *ngIf="sellerPriceDetail?.districtNgpCprInd !== 'Y'"
                ></ion-item-divider>
                <div
                  *ngIf="sellerPriceDetail?.districtNgpCprInd !== 'Y'"
                  class="cpr-radio-row"
                  (click)="radioPriceChange(RadioOptions.minimum)"
                >
                  <div class="cpr-radio-column1">
                    <ion-label>
                      {{
                        priceRadios?.get(RadioOptions.minimum).label | translate
                      }}
                    </ion-label>
                  </div>
                  <div class="cpr-radio-column2">
                    <ion-label id="price-uom">
                      {{
                        priceRadios?.get(RadioOptions.minimum).price
                          | formatCurrencyAndDecimalPipe
                      }}
                      {{ this.product?.summary?.priceUom }}
                    </ion-label>
                  </div>
                  <div class="cpr-radio-column3">
                    <ion-radio
                      aria-labelledby="price-uom"
                      mode="md"
                      slot="end"
                      value="{{ priceRadios?.get(RadioOptions.minimum).id }}"
                    ></ion-radio>
                  </div>
                </div>
                <ion-item-divider></ion-item-divider>
                <div
                  *ngIf="sellerPriceDetail?.districtNgpCprInd === 'Y'"
                  class="cpr-radio-row"
                  (click)="radioPriceChange(RadioOptions.maximum)"
                >
                  <div class="cpr-radio-column1">
                    <ion-label>
                      {{
                        priceRadios?.get(RadioOptions.maximum).label | translate
                      }}
                    </ion-label>
                  </div>
                  <div class="cpr-radio-column2">
                    <ion-label id="price-max">
                      {{
                        priceRadios?.get(RadioOptions.maximum).price
                          | formatCurrencyAndDecimalPipe
                      }}
                      {{ this.product?.summary?.priceUom }}
                    </ion-label>
                  </div>
                  <div class="cpr-radio-column3">
                    <ion-radio
                      aria-labelledby="price-max"
                      mode="md"
                      slot="end"
                      value="{{ priceRadios?.get(RadioOptions.maximum).id }}"
                    ></ion-radio>
                  </div>
                </div>
                <ion-item-divider
                  *ngIf="sellerPriceDetail?.districtNgpCprInd === 'Y'"
                ></ion-item-divider>
                <div
                  class="cpr-radio-row"
                  (click)="radioPriceChange(RadioOptions.target)"
                >
                  <div class="cpr-radio-column1">
                    <ion-label>
                      {{
                        priceRadios?.get(RadioOptions.target).label | translate
                      }}
                    </ion-label>
                  </div>
                  <div class="cpr-radio-column2">
                    <ion-label id="target-price">
                      {{
                        priceRadios?.get(RadioOptions.target).price
                          | formatCurrencyAndDecimalPipe
                      }}
                      {{ this.product?.summary?.priceUom }}
                    </ion-label>
                  </div>
                  <div class="cpr-radio-column3">
                    <ion-radio
                      aria-labelledby="target-price"
                      mode="md"
                      slot="end"
                      value="{{ priceRadios?.get(RadioOptions.target).id }}"
                    ></ion-radio>
                  </div>
                </div>
                <ion-item-divider></ion-item-divider>
                <div
                  *ngIf="sellerPriceDetail?.districtNgpCprInd !== 'Y'"
                  class="cpr-radio-row"
                  (click)="radioPriceChange(RadioOptions.maximum)"
                >
                  <div class="cpr-radio-column1">
                    <ion-label>
                      {{
                        priceRadios?.get(RadioOptions.maximum).label | translate
                      }}
                    </ion-label>
                  </div>
                  <div class="cpr-radio-column2">
                    <ion-label id="price-max">
                      {{
                        priceRadios?.get(RadioOptions.maximum).price
                          | formatCurrencyAndDecimalPipe
                      }}
                      {{ this.product?.summary?.priceUom }}
                    </ion-label>
                  </div>
                  <div class="cpr-radio-column3">
                    <ion-radio
                      aria-labelledby="price-max"
                      mode="md"
                      slot="end"
                      value="{{ priceRadios?.get(RadioOptions.maximum).id }}"
                    ></ion-radio>
                  </div>
                </div>
                <ion-item-divider
                  *ngIf="sellerPriceDetail?.districtNgpCprInd !== 'Y'"
                ></ion-item-divider>
                <div
                  class="cpr-radio-row"
                  (click)="radioPriceChange(RadioOptions.custom)"
                >
                  <div class="cpr-radio-column1">
                    <ion-label id="custom">
                      {{
                        priceRadios?.get(RadioOptions.custom).label | translate
                      }}
                    </ion-label>
                  </div>
                  <div class="cpr-radio-column2">
                    <div class="cpr-input-container">
                      <div>$</div>
                      <ion-input
                        aria-labelledby="custom"
                        class="cpr-input-cl"
                        [(ngModel)]="priceInputSelected"
                        (ngModelChange)="radioPriceChange(RadioOptions.custom)"
                        placeholder="00.00 CS"
                        appTestId="cpr-input-price"
                        data-cy="cpr-price-input"
                        [disabled]="isInputDisabled"
                        inputmode="decimal"
                        currency
                      >
                      </ion-input>
                    </div>
                  </div>
                  <div class="cpr-radio-column3">
                    <ion-radio
                      mode="md"
                      slot="end"
                      value="{{ priceRadios?.get(RadioOptions.custom).id }}"
                    ></ion-radio>
                  </div>
                </div>
                <ion-item-divider></ion-item-divider>
                <!-- Hide Target Calculations fields, if these fields are required again change hide by Y -->
                <div
                  *ngIf="sellerPriceDetail?.districtNgpCprInd === 'hide'"
                  class="target-difference"
                >
                  <p class="target-difference-dollar">
                    <span>
                      {{
                        'i18n.productDetail.customPricingRationaleModal.targetDifference$'
                          | translate
                      }}
                    </span>
                    {{
                      targetDifference < 0
                        ? '(' +
                          (targetDifference * -1
                            | formatCurrencyAndDecimalPipe) +
                          ')'
                        : (targetDifference | formatCurrencyAndDecimalPipe)
                    }}
                  </p>

                  <span class="vertical-separator" data-cy="seperator-line">
                  </span>

                  <p class="target-difference-percentage">
                    <span>{{
                      'i18n.productDetail.customPricingRationaleModal.targetDifference%'
                        | translate
                    }}</span>
                    {{
                      targetDifferencePercentage < 0
                        ? '(' +
                          (targetDifferencePercentage * -1 | number: '1.2-2') +
                          ')'
                        : (targetDifferencePercentage | number: '1.2-2')
                    }}%
                  </p>
                </div>
              </div>
            </ion-radio-group>
          </ion-list>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-footer class="ion-footer-modal">
    <ion-toolbar>
      <ion-buttons class="cpr-footer-buttons">
        <ion-button
          id="cancel-modal-btn"
          [ngClass]="
            platformService?.platformType !==
            platformService?.platformEnum?.mobile
              ? 'usf-outline-green-dsktp-tblt-modal-button'
              : 'usf-outline-green-button'
          "
          data-cy="cancel-modal-btn"
          (click)="closeModal()"
        >
          {{ 'i18n.common.cancel' | translate }}
        </ion-button>

        <ion-button
          class="usf-fill-green-dsktp-tblt-modal-button"
          id="update-modal-btn"
          [ngClass]="
            platformService?.platformType !==
            platformService?.platformEnum?.mobile
              ? 'usf-fill-green-dsktp-tblt-modal-button'
              : 'usf-fill-green-button'
          "
          data-cy="update-modal-btn"
          (click)="updatePrice()"
          [disabled]="!userHasSelectedPrice"
        >
          {{ 'i18n.common.update' | translate }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</section>
