import { Injectable } from '@angular/core';
import { UserService } from '@app/user/services';
import { Store } from '@ngrx/store';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import { ProductService } from '@shared/services/product/product.service';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import {
  SellerShowcaseUpdates,
  SellerShowcaseViewModel,
} from '../models/seller-showcase.model';
import { selectSellerShowcase } from '../selector/seller-showcase.selector';
import { ProductShippingPriceService } from '@shared/services/product-shipping-price/product-shipping-price.service';

@Injectable({
  providedIn: 'root',
})
export class SellerShowcaseService {
  private _sellerShowcaseViewModel$: Observable<SellerShowcaseViewModel>;
  private _updates$ = new BehaviorSubject<SellerShowcaseUpdates>({});
  private _hasLoaded = false;

  constructor(
    private store: Store,
    private panAppState: PanAppState,
    private platformService: PlatformService,
    private productService: ProductService,
    private productShippingPriceService: ProductShippingPriceService,
    private userService: UserService,
  ) {
    this.initializeSellerShowcaseViewModelObservable();
  }

  private initializeSellerShowcaseViewModelObservable() {
    this._sellerShowcaseViewModel$ = combineLatest([
      this.panAppState.feature$([
        FEATURES.split_global_seller_showcase_products.name,
      ]),
      this.productShippingPriceService?.isProductShippingPriceEnabled$(),
      this.panAppState.accessToken$,
      this._updates$,
    ]).pipe(
      switchMap(
        ([sellerShowcaseFlag, shippingPriceFlag, accessToken, updates]) => {
          if (
            sellerShowcaseFlag &&
            this.userService.isInternalUser(accessToken) &&
            !this._hasLoaded // Avoid triggering new load of the showcase for filtering
          ) {
            return this.productService.loadSellerShowcase$().pipe(
              tap(() => (this._hasLoaded = true)),
              switchMap(() =>
                this.store.select(
                  selectSellerShowcase(
                    this.platformService?.platformType,
                    shippingPriceFlag,
                    updates,
                  ),
                ),
              ),
            );
          } else {
            return this.store.select(
              selectSellerShowcase(
                this.platformService?.platformType,
                shippingPriceFlag,
                updates,
              ),
            );
          }
        },
      ),
    );
  }

  loadProducts(productNumbers: number[]) {
    return this.productService.loadProducts(productNumbers);
  }

  getSellerShowcaseViewModel$(): Observable<SellerShowcaseViewModel> {
    return this._sellerShowcaseViewModel$;
  }

  resetSellerShowcase(): void {
    this._hasLoaded = false;
    this._updates$.next({});
  }

  setUpdates(updates: SellerShowcaseUpdates) {
    this._updates$.next(updates);
  }
}
