<section class="upcoming-section" *ngIf="poDates?.length > 0">
  <h4 data-cy="seller-detail-next-expected-stock-text">
    {{ 'i18n.productDetail.sellerDetail.nextExpectedStock' | translate }}
  </h4>
  <dl
    class="upcoming-list"
    *ngFor="let poDate of poDates"
    [attr.data-cy]="'upcoming-list-' + productNumber + '-text'"
  >
    <dt data-cy="seller-detail-next-po-text">
      {{ 'i18n.productDetail.sellerDetail.nextPo' | translate }}
    </dt>
    <dd data-cy="seller-detail-next-po-arrival-date-text">
      {{ poDate.formattedArrivalDate }}
    </dd>
    <dt data-cy="seller-detail-number-of-cases-text">
      {{ 'i18n.productDetail.sellerDetail.numberOfCases' | translate }}
    </dt>
    <dd data-cy="seller-detail-number-of-cases-value-text">
      {{ poDate.casesOrdered }}
    </dd>
    <dt data-cy="seller-detail-upcoming-po-number">
      {{ 'i18n.productDetail.sellerDetail.upcomingPoNo' | translate }}
    </dt>
    <dd>#{{ poDate.poNumber }}</dd>
  </dl>
</section>
