import { Dictionary } from '@ngrx/entity';
import { PlatformEnum } from '@panamax/app-state';
import { ListGroupKey, ListItemKey, ListKey } from '@usf/list-types';
import {
  ListConstants,
  ListGroupState,
  ListItemState,
  ListSearchSortFilterOptions,
} from '@usf/ngrx-list';
import { ProductState } from '@usf/ngrx-product';
import { ProductPropertiesEnum, ProductSummary } from '@usf/product-types';
import { ProductAlternative } from '../../../../../product-detail/models/product-alternative';
import { ProductTagDisplay } from '../../../../../product-detail/models/product-tag.model';
import {
  calculateDesktopProductHeight,
  calculateMobileProductHeight,
  calculateTabletProductHeight,
} from '../../../../../product-detail/utils/product-card.util';
import { TagProduct } from '../../../../../shared/components/usf-product-tag-list/models/tag-product.model';
import { HotKeys } from '../../../../../shared/constants/hot-key.enum';
import { UsfListDetailManagementHeights } from '../../../../../shared/constants/lists-constants';
import { Product } from '../../../../../shared/models/product.model';
import {
  GroupRow,
  ItemTypes,
  ListGroupsAndItems,
  ListRow,
  ProductRow,
} from '../../model/list-detail-management-view.model';
import {
  UsfBetterBuyCardHeights,
  UsfProductCardDesktopHeights,
  UsfProductCardMobileHeights,
  UsfProductCardTabletHeights,
} from '../../../../../shared/models/usf-product-card.model';

export const createListKey = (listKey: ListKey) =>
  [listKey.listTypeId, listKey.listId].join('-');

export const createListGroupKey = (
  listKey: ListKey,
  listGroupKey: ListGroupKey,
) =>
  [listKey?.listTypeId, listKey?.listId, listGroupKey?.listGroupId].join('-');

export const createListItemKey = (
  listKey: ListKey,
  listGroupKey: ListGroupKey,
  listItemKey: ListItemKey,
) =>
  [
    listKey?.listTypeId,
    listKey?.listId,
    listGroupKey?.listGroupId,
    listItemKey?.listItemId,
  ].join('-');

export const findComparisonString = (listRow: ProductRow): string => {
  let str: string;
  const productRow = listRow as ProductRow;
  if (productRow?.summary?.productDescTxtl) {
    str = productRow?.summary?.productDescTxtl?.toLocaleLowerCase();
  }
  return str;
};

export const sortListRowsAlphabetically = (items: ListRow[]): ListRow[] => {
  const sortedItems = items.sort((a, b) => {
    let compAString = findComparisonString(a as ProductRow);
    let compBString = findComparisonString(b as ProductRow);
    return compAString > compBString ? 1 : -1;
  });
  return sortedItems;
};

export const productIsInSearchAndFilter = (
  productRow: ProductRow,
  listSearchSortFilterOptions: ListSearchSortFilterOptions,
): boolean => {
  if (!productRow?.summary) return false;
  return (
    isInFilter(productRow.summary, listSearchSortFilterOptions.filters) &&
    productMatchesKeyword(
      listSearchSortFilterOptions.searchKey,
      productRow.summary,
      productRow.alternative,
      productRow.customerProductNumber,
    )
  );
};

export const alphabeticalSort = (
  listGroupsAndItems: ListGroupsAndItems,
  products: Map<number, Product>,
  listSearchSortFilterOptions: ListSearchSortFilterOptions,
): ListRow[] => {
  let items: ListRow[] = [];
  const listItemKeys: ListItemKey[] = [];
  const productClassGroup = {
    groupName: 'Alphabetical',
    listGroupKey: { listGroupId: 'Alphabetical' },
  } as ListGroupState;
  listGroupsAndItems?.listItems?.forEach(item => {
    const productRow = createProductRow(item, productClassGroup, products);
    if (productIsInSearchAndFilter(productRow, listSearchSortFilterOptions)) {
      items.push(productRow);
      listItemKeys.push(productRow.item.listItemKey);
    }
  });
  items = sortListRowsAlphabetically(items);
  if (listItemKeys.length > 0) {
    items.unshift({
      itemType: ItemTypes.group,
      groupName: ListConstants.alphabetical,
      itemCount: listItemKeys?.length,
    });
  }
  return items;
};

export const usFoodsClassesSort = (
  products: Map<number, Product>,
  productClasses: string[],
  itemsInClasses: Dictionary<ListItemState[]>,
  listSearchSortFilterOptions: ListSearchSortFilterOptions,
): ListRow[] => {
  let sortedItems: ListRow[] = [];
  productClasses?.forEach(productClass => {
    const productClassGroup = {
      groupName: productClass,
      listGroupKey: { listGroupId: productClass },
    } as ListGroupState;
    let items: ListRow[] = [];
    const listItemKeys: ListItemKey[] = [];
    const sortAllGroupsOrThisOne =
      listSearchSortFilterOptions.groupToSort === ListConstants.allGroups ||
      productClass === listSearchSortFilterOptions.groupToSort;

    if (sortAllGroupsOrThisOne) {
      itemsInClasses[productClass].forEach(item => {
        const productRow = createProductRow(item, productClassGroup, products);

        if (
          productIsInSearchAndFilter(productRow, listSearchSortFilterOptions)
        ) {
          items.push(productRow);
          listItemKeys.push(productRow.item.listItemKey);
        }
      });
      items = sortListRowsAlphabetically(items);
      if (listItemKeys.length > 0) {
        items.unshift({
          itemType: ItemTypes.group,
          groupName: productClass,
          itemCount: listItemKeys?.length,
        });
      }

      if (
        productClass === listSearchSortFilterOptions.groupToSort ||
        listSearchSortFilterOptions.groupToSort === ListConstants.allGroups
      ) {
        sortedItems = sortedItems.concat(items);
      }
    }
  });
  return sortedItems;
};

export const groupAlphaSort = (
  listGroupsAndItems: ListGroupsAndItems,
  products: Map<number, Product>,
  listSearchSortFilterOptions: ListSearchSortFilterOptions,
): ListRow[] => {
  let sortedItems: ListRow[] = [];
  let i = 0;
  listGroupsAndItems?.groups?.forEach(group => {
    let items: ListRow[] = [];
    const listItemKeys: ListItemKey[] = [];
    const groupIsNotEmpty = group.listItemKeys?.length > 0;
    const sortAllGroupsOrThisOne =
      listSearchSortFilterOptions.groupToSort === ListConstants.allGroups ||
      group.groupName === listSearchSortFilterOptions.groupToSort;
    let groupToLoop: ListGroupState;
    if (groupIsNotEmpty && sortAllGroupsOrThisOne) {
      groupToLoop = group;
    }
    if (groupToLoop) {
      let j = i;
      if (groupIsNotEmpty) {
        j = i + group.listItemKeys.length;
      }
      for (i; i < j; i++) {
        const item = listGroupsAndItems.listItems[i];
        const productRow = createProductRow(item, groupToLoop, products);
        if (
          productIsInSearchAndFilter(productRow, listSearchSortFilterOptions)
        ) {
          items.push(productRow);
          listItemKeys.push(productRow.item.listItemKey);
        }
      }
      items = sortListRowsAlphabetically(items);
      if (listItemKeys.length > 0) {
        items.unshift({
          itemType: ItemTypes.group,
          groupName: group.groupName,
          itemCount: listItemKeys?.length,
        });
      }

      if (
        group.groupName === listSearchSortFilterOptions.groupToSort ||
        listSearchSortFilterOptions.groupToSort === ListConstants.allGroups
      ) {
        sortedItems = sortedItems.concat(items);
      }
    } else {
      i += group.listItemKeys?.length || 0;
    }
  });

  return sortedItems;
};

export const groupLineSort = (
  listGroupsAndItems: ListGroupsAndItems,
  products: Map<number, Product>,
  listSearchSortFilterOptions: ListSearchSortFilterOptions,
): ListRow[] => {
  let items: ListRow[] = [];
  let i = 0;
  listGroupsAndItems?.groups.forEach(group => {
    const newSetOfItems: ListRow[] = [];
    const groupIsNotEmpty = group.listItemKeys?.length > 0;
    const sortAllGroupsOrThisOne =
      listSearchSortFilterOptions.groupToSort === ListConstants.allGroups ||
      group.groupName === listSearchSortFilterOptions.groupToSort;

    const shouldAddItemToViewModel = groupIsNotEmpty && sortAllGroupsOrThisOne;
    let j = i + group?.listItemKeys?.length || 0;
    let itemCount = 0;

    for (i; i < j; i++) {
      const item = listGroupsAndItems?.listItems[i];
      const productRow = createProductRow(item, group, products);

      if (productIsInSearchAndFilter(productRow, listSearchSortFilterOptions)) {
        if (shouldAddItemToViewModel) {
          newSetOfItems.push(productRow);
        }
        itemCount++;
      }
    }
    if (itemCount > 0) {
      newSetOfItems.unshift({
        itemType: ItemTypes.group,
        groupName: group?.groupName,
        itemCount,
      });
    }
    if (
      group.groupName === listSearchSortFilterOptions.groupToSort ||
      listSearchSortFilterOptions.groupToSort === ListConstants.allGroups
    ) {
      items = items.concat(newSetOfItems);
    }
  });
  return items;
};

export const createProductRow = (
  item: ListItemState,
  group: ListGroupState,
  products: Map<number, Product>,
): ProductRow => {
  let product: Product = products.get(item?.productNumber);
  let updatedProduct: Product = { ...product };
  if (!isOrderGuideItem(item)) {
    updatedProduct = removeOnMyListsPill(updatedProduct);
  }

  return {
    ...updatedProduct,
    itemType: ItemTypes.product,
    item,
    groupName: group?.groupName,
    groupId: group?.listGroupKey?.listGroupId,
    expanded: false,
    isChecked: item?.isSelected ? item?.isSelected : false,
    sequenceNumber: item?.sequenceNumber,
    groupSequenceNumber: item?.groupSequenceNumber,
    listId: String(item?.listKey?.listId),
  } as ProductRow;
};

export const createGroupRow = (
  group: ListGroupState,
  itemCount: number,
): GroupRow => {
  return {
    itemType: ItemTypes.group,
    groupName: group?.groupName,
    itemCount,
    listGroupKey: group?.listGroupKey,
  } as GroupRow;
};

export const removeOnMyListsPill = (product: Product): Product => {
  let productTags: TagProduct[] = product?.productTags;
  productTags = productTags?.filter(
    tag => tag.content !== ProductTagDisplay.onMyLists,
  );
  if (product?.alternative) {
    let alternativeProductTags: TagProduct[] =
      product.alternative?.product?.productTags;
    alternativeProductTags = alternativeProductTags?.filter(
      tag => tag.content !== ProductTagDisplay.onMyLists,
    );
    return {
      ...product,
      alternative: {
        ...product?.alternative,
        product: {
          ...product?.alternative?.product,
          productTags: alternativeProductTags,
        },
      },
      productTags,
    } as Product;
  }
  return {
    ...product,
    productTags,
  } as Product;
};

export const isOrderGuideItem = (item: ListItemState): boolean => {
  return item?.listKey?.listTypeId === 'OG';
};

/**
 * @remarks
 * This method is used to see if a product matches the keyword
 */
export const productMatchesKeyword = (
  keyword: string,
  productSummary: ProductState,
  alternative: ProductAlternative,
  productCustomerNumber?: string,
): boolean => {
  if (!keyword || keyword === '') return true;
  let searchDataArray: Array<string> = new Array<string>();

  // Original Product
  if (!!productSummary) {
    const productNumber = productSummary.productNumber
      ?.toString()
      .toLowerCase();
    if (productNumber) searchDataArray.push(productNumber);
    const productDescription =
      productSummary.productDescTxtl ?? productSummary.productDescLong;
    const productDescForPush = productDescription?.toLowerCase().split(' ');
    if (productDescForPush) searchDataArray.push(...productDescForPush);
    const brand = productSummary.brand?.toLowerCase().split(' ');
    if (brand) searchDataArray.push(...brand);
  }

  // Alternative product
  if (alternative?.product?.summary) {
    if (alternative?.required && alternative?.available) {
      const altProductNumber = alternative.product?.summary?.productNumber
        ?.toString()
        .toLowerCase();
      if (altProductNumber) searchDataArray.push(altProductNumber);

      const productDescription =
        alternative?.product?.summary.productDescTxtl ??
        alternative?.product?.summary.productDescLong;
      const productDescForPush = productDescription?.toLowerCase().split(' ');
      if (productDescForPush) searchDataArray.push(...productDescForPush);
      const altBrand = alternative.product?.summary?.brand
        ?.toLowerCase()
        .split(' ');
      if (altBrand) searchDataArray.push(...altBrand);
    }
  }

  if (productCustomerNumber) {
    const custProdNumber = productCustomerNumber.toLowerCase().split(' ');
    if (custProdNumber) {
      searchDataArray.push(...custProdNumber);
    }
  }
  const matchDataArray: Array<string> = keyword.toLowerCase().split(' ');
  return matchDataArray.every(
    matchData =>
      !!searchDataArray.find(searchData => {
        return !!~searchData?.indexOf(matchData);
      }),
  );
};

/**
 * @remarks
 * This method is used to see if a product should be returned based on what filters are selected
 */
export const isInFilter = (
  product: ProductState,
  filters: string[],
): boolean => {
  if (!!product?.properties) {
    if (
      filters.includes(ListConstants.recentlyPurchased) &&
      filters.includes(ListConstants.discontinued) &&
      (product.properties.has(ProductPropertiesEnum.recentlyPurchased) ||
        product.properties.has(ProductPropertiesEnum.discontinued))
    ) {
      return true;
    } else if (
      (filters.includes(ListConstants.recentlyPurchased) &&
        !product.properties.has(ProductPropertiesEnum.recentlyPurchased)) ||
      (filters.includes(ListConstants.discontinued) &&
        !product.properties.has(ProductPropertiesEnum.discontinued))
    ) {
      return false;
    }
  }
  return true;
};

/**
 * @remarks
 * This method takes in list items and a map of product data and returns two things,
 * productClasses which is a string array of classes
 * and a dictionary which has keys as the product classes and values and array of items that belong to those classes
 */
export const findProductClasses = (
  listItems: ListItemState[],
  products: Map<number, Product>,
): {
  productClasses: string[];
  itemsInClasses: Dictionary<ListItemState[]>;
} => {
  const productClasses: string[] = [];
  const itemsInClasses: Dictionary<ListItemState[]> = {};
  for (const item of listItems) {
    const product: ProductSummary =
      products.get(item.productNumber)?.summary || null;
    if (!product) {
      continue;
    }
    if (
      product.classDescription &&
      !productClasses.includes(product.classDescription)
    ) {
      productClasses.push(product.classDescription);
      itemsInClasses[product.classDescription] = [item];
    } else if (product.classDescription) {
      itemsInClasses[product.classDescription].push(item);
    }
  }
  productClasses.sort((a, b) => (a > b ? 1 : -1));
  return { productClasses, itemsInClasses };
};

export const calculateItemHeightsAndHotkeyIds = (
  platformType: PlatformEnum,
  items: ListRow[],
  listTypeId: string,
) => {
  let itemHeights: number[] = [];
  let productCardIds: Set<string> = new Set();
  if (platformType === PlatformEnum.desktop) {
    const ret = calculateDesktopItemHeightsAndIds(items, listTypeId);
    itemHeights = ret.itemHeights;
    productCardIds = ret.productCardIds;
  } else if (platformType === PlatformEnum.tablet) {
    const ret = calculateTabletItemHeightsAndIds(items);
    itemHeights = ret.itemHeights;
    productCardIds = ret.productCardIds;
  } else if (platformType === PlatformEnum.mobile) {
    itemHeights = calculateMobileItemHeights(items, listTypeId);
  }
  return { itemHeights, productCardIds };
};

export const calculateDesktopListManagementHeights = (
  items: ListRow[],
): number[] => {
  let itemHeights: number[] = [];
  itemHeights.push(
    UsfListDetailManagementHeights.desktopListManagementHeaderHeight,
  );
  items?.forEach(item => {
    if (item.itemType === ItemTypes.group) {
      itemHeights.push(UsfListDetailManagementHeights.groupHeightDesktop);
    } else if (item.itemType === ItemTypes.product) {
      const itemHeight = calculateDesktopListManagementHeight(
        item as ProductRow,
      );
      itemHeights.push(itemHeight);
    }
  });
  itemHeights.push(
    UsfListDetailManagementHeights.footerWithMarginHeightDesktop,
  );
  return itemHeights;
};

export const calculateDesktopListManagementHeight = (
  productRow: ProductRow,
): number => {
  let height;
  if (productRow?.hasAlternative) {
    height = UsfProductCardDesktopHeights.splitListManagementHeight;
    if (productRow?.drawerMsg?.message) {
      height += UsfProductCardDesktopHeights.drawerHeight;
    }
  } else if (productRow?.notFound) {
    height = UsfProductCardDesktopHeights.notFoundListManagementHeight;
  } else {
    if (productRow?.productTags?.length > 0) {
      height = UsfProductCardDesktopHeights.tagsListManagementHeight;
    } else if (productRow?.summary?.breakable) {
      height = UsfProductCardDesktopHeights.breakableListManagementHeight;
    } else {
      height = UsfProductCardDesktopHeights.baseListManagementHeight;
    }
    if (productRow?.drawerMsg?.message) {
      height += UsfProductCardDesktopHeights.drawerHeight;
    }
    if (productRow?.drawerMsg?.isWarning) {
      height += UsfProductCardDesktopHeights.warningBorderHeight;
    }
  }
  if (!!productRow?.item?.forcedUpdate) {
    height += UsfBetterBuyCardHeights.drawerHeight;
  }
  return height;
};

export const calculateTabletListManagementHeights = (
  items: ListRow[],
): number[] => {
  let itemHeights: number[] = [];
  itemHeights.push(
    UsfListDetailManagementHeights.tabletListManagementHeaderHeight,
  );
  items?.forEach(item => {
    if (item.itemType === ItemTypes.group) {
      itemHeights.push(UsfListDetailManagementHeights.groupHeightTablet);
    } else if (item.itemType === ItemTypes.product) {
      const itemHeight = calculateTabletListManagementHeight(
        item as ProductRow,
      );
      itemHeights.push(itemHeight);
    }
  });
  return itemHeights;
};

export const calculateTabletListManagementHeight = (
  productRow: ProductRow,
): number => {
  let height;
  if (productRow?.hasAlternative) {
    height = UsfProductCardTabletHeights.splitListManagementHeight;
    if (productRow?.alternative?.product?.productTags?.length > 0) {
      height += UsfProductCardTabletHeights.alternativeTagsHeight;
    }
    if (productRow?.drawerMsg?.message) {
      height += UsfProductCardTabletHeights.drawerHeight;
    }
  } else if (productRow?.notFound) {
    height = UsfProductCardTabletHeights.notFoundListManagementHeight;
  } else {
    height = UsfProductCardTabletHeights.baseListManagementHeight;
    if (productRow?.drawerMsg?.message) {
      height += UsfProductCardTabletHeights.drawerHeight;
    }
    if (productRow?.drawerMsg?.isWarning) {
      height += UsfProductCardTabletHeights.warningBorderHeight;
    }
  }
  if (!!productRow?.item?.forcedUpdate) {
    height += UsfBetterBuyCardHeights.drawerHeight;
  }

  return height;
};

export const calculateMobileListManagementHeights = (
  items: ListRow[],
): number[] => {
  let itemHeights: number[] = [];
  itemHeights.push(
    UsfListDetailManagementHeights.mobileListManagementHeaderHeight,
  );
  items?.forEach(item => {
    if (item.itemType === ItemTypes.group) {
      itemHeights.push(UsfListDetailManagementHeights.groupHeightMobile);
    } else if (item.itemType === ItemTypes.product) {
      const itemHeight = calculateMobileListManagementHeight(
        item as ProductRow,
      );
      itemHeights.push(itemHeight);
    }
  });
  return itemHeights;
};

export const calculateMobileListManagementHeight = (
  productRow: ProductRow,
): number => {
  let height;
  if (productRow?.hasAlternative) {
    height = UsfProductCardMobileHeights.splitListManagementHeight;
    if (productRow?.alternative?.product?.productTags?.length > 0) {
      height += UsfProductCardMobileHeights.alternativeTagsHeight;
    }
    if (productRow?.drawerMsg?.message) {
      height += UsfProductCardMobileHeights.drawerHeight;
    }
  } else if (productRow?.notFound) {
    height = UsfProductCardMobileHeights.notFoundListManagementHeight;
  } else {
    height = UsfProductCardMobileHeights.baseListManagementHeight;
    if (productRow?.productTags?.length > 0) {
      height += UsfProductCardMobileHeights.tagsHeight;
    }
    if (productRow?.drawerMsg?.message) {
      height += UsfProductCardMobileHeights.drawerHeight;
    }
    if (productRow?.drawerMsg?.isWarning) {
      height += UsfProductCardMobileHeights.warningBorderHeight;
    }
  }
  if (!!productRow?.item?.forcedUpdate) {
    height += UsfBetterBuyCardHeights.drawerHeight;
  }

  return height;
};

export const calculateDesktopItemHeightsAndIds = (
  listRows: ListRow[],
  listTypeId: string,
) => {
  let itemHeights = [];
  // Add heights for banner and search/sort section
  itemHeights.push(UsfListDetailManagementHeights.bannerHeightDesktop);
  itemHeights.push(UsfListDetailManagementHeights.searchSortFilterHeight);
  if (listRows?.length === 0) {
    itemHeights.push(
      UsfListDetailManagementHeights.orderInfoSidebarDesktopHeight,
    );
  }
  let productCardIds = new Set<string>();
  // Loop through listRows (groups and products) and add heights.
  listRows?.forEach((item, i) => {
    if (item.itemType === ItemTypes.group) {
      itemHeights.push(UsfListDetailManagementHeights.groupHeightDesktop);
    } else if (item.itemType === ItemTypes.product) {
      item = item as ProductRow;
      const itemHeight = calculateDesktopProductHeight(item, listTypeId);
      itemHeights.push(itemHeight);
      createProductIdsForHotkeys(productCardIds, item, i);
    }
  });
  itemHeights.push(
    UsfListDetailManagementHeights.footerWithMarginHeightDesktop,
  );
  return { itemHeights, productCardIds };
};

export const calculateTabletItemHeightsAndIds = (listRows: ListRow[]) => {
  let itemHeights = [];
  // Add heights for banner and search/sort section
  itemHeights.push(UsfListDetailManagementHeights.bannerHeightTablet);
  itemHeights.push(UsfListDetailManagementHeights.searchSortFilterHeight);
  let productCardIds = new Set<string>();
  // Loop through listRows (groups and products) and add heights.
  listRows?.forEach((item, i) => {
    if (item.itemType === ItemTypes.group) {
      itemHeights.push(UsfListDetailManagementHeights.groupHeightTablet);
    } else if (item.itemType === ItemTypes.product) {
      item = item as ProductRow;
      const itemHeight = calculateTabletProductHeight(item);
      itemHeights.push(itemHeight);
      createProductIdsForHotkeys(productCardIds, item, i);
    }
  });
  return { itemHeights, productCardIds };
};

export const calculateMobileItemHeights = (
  listRows: ListRow[],
  listTypeId: string,
) => {
  let itemHeights = [];
  // Add heights for banner and search/sort section
  itemHeights.push(UsfListDetailManagementHeights.bannerHeightMobile);
  itemHeights.push(UsfListDetailManagementHeights.searchSortFilterHeight);
  // Loop through listRows (groups and products) and add heights.
  listRows?.forEach(item => {
    if (item.itemType === ItemTypes.group) {
      itemHeights.push(UsfListDetailManagementHeights.groupHeightMobile);
    } else if (item.itemType === ItemTypes.product) {
      item = item as ProductRow;
      const itemHeight = calculateMobileProductHeight(item, listTypeId);
      itemHeights.push(itemHeight);
    }
  });
  return itemHeights;
};

// Creates a composite id of productId + listId + index
// Assigns this composite id back to productRow so it can be attached to quanity input
// Returns a Set of composite ids that hotkey service will use to find the next id to focus
export const createProductIdsForHotkeys = (
  productCardIds: Set<string>,
  item: ProductRow,
  index: number,
): Set<string> => {
  let listId = item?.listId;
  item?.hotkeyIds?.forEach(id => {
    const compositeId = id + listId + '-' + index;
    productCardIds.add(compositeId);
  });
  item?.alternative?.product?.hotkeyIds?.forEach(id => {
    const compositeId = id + listId + '-' + index;
    productCardIds.add(compositeId);
  });
  item?.betterBuy?.product?.hotkeyIds?.forEach(id => {
    const compositeId = id + listId + '-' + index;
    productCardIds.add(compositeId);
  });
  return productCardIds;
};

export const getSortAndFilterCount = (
  sortFilterOptions: ListSearchSortFilterOptions,
  defaultSortType = ListConstants.groupLine,
): number => {
  let count = sortFilterOptions.filters.filter(str => str.length > 0).length;
  if (
    sortFilterOptions.sortType !== defaultSortType ||
    sortFilterOptions.groupToSort !== ListConstants.allGroups
  ) {
    count += 1;
  }
  // Count "Show Products From Past" when 26 weeks is selected (week52Filter is false)
  if (sortFilterOptions.week52Filter === false) {
    count += 1;
  }
  return count;
};

export const getListSearchSortFilterOptions = (
  optionsFromState: ListSearchSortFilterOptions,
  optionsFromPreferences: {
    sortOption?: string;
    sortBy?: string;
    filterOption?: string;
  },
) => {
  let searchSortFilterOptionsToReturn: ListSearchSortFilterOptions =
    {} as ListSearchSortFilterOptions;

  if (optionsFromPreferences) {
    const filters = optionsFromPreferences.filterOption.split(',');
    searchSortFilterOptionsToReturn = {
      searchKey: optionsFromState.searchKey,
      sortType: optionsFromPreferences.sortOption,
      groupToSort: optionsFromPreferences.sortBy,
      filters,
      week52Filter: optionsFromState.week52Filter,
    };
  } else {
    searchSortFilterOptionsToReturn = {
      ...optionsFromState,
    };
  }

  return searchSortFilterOptionsToReturn;
};
