export const highlightText = (
  text: string,
  highlights: { offset: number; length: number }[],
): string => {
  if (!highlights || highlights.length === 0) {
    return titleCase(text);
  }

  let result = '';
  let currentIndex = 0;

  highlights.forEach(({ offset, length }) => {
    if (offset < 0 || length <= 0 || offset >= text.length) {
      return;
    }

    const start = text.substring(currentIndex, offset);
    const highlight = text.substring(offset, offset + length);

    result += titleCase(start);
    result += `<strong>${titleCase(highlight)}</strong>`;

    currentIndex = offset + length;
  });

  if (currentIndex < text.length) {
    result += titleCase(text.substring(currentIndex));
  }

  return result;
};

export const titleCase = (text: string): string => {
  return text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

export const highlightedTitleCase = (text: string): string => {
  text = text.replace(
    /(<strong>)(\w)(\w*)(<\/strong>)/gi,
    (match, p1, p2, p3, p4) => {
      return `${p1}${p2.toUpperCase()}${p3.toLowerCase()}${p4}`;
    },
  );

  return text;
};
