import { Product } from '@shared/models/product.model';
import { Customer } from '@usf/customer-types';
import { SortDirections } from '@usf/ngrx-inventory';
import { DivisionalPromotion, PromotionDetail } from '@usf/product-types';

export interface SellerShowcaseViewModel {
  divisionalPromotions: SellerShowcaseDivisionalPromotion[];
  selectedCustomer: Customer;
  loadingPromotionalProducts: boolean;
  allProductsAvailable: boolean;
  noPromotionsFound: boolean;
  brandsNames?: string[];
  promoTypes?: string[];
  exclusiveBrandValues?: string[];
  productClasses?: string[];
}

export interface SellerShowcaseDivisionalPromotion extends DivisionalPromotion {
  key: string;
  promotionalProducts: DivisionalPromotionProduct[];
  productsAreAvailable: boolean;
  products?: Product[];
  itemHeights?: number[];
  isExpanded?: boolean;
  sortBy?: DivisionalPromotionSorting;
}

export interface DivisionalPromotionProduct extends PromotionDetail {
  product: Product;
}

export interface DivisionalPromotionEventData {
  promotionalProduct: DivisionalPromotionProduct;
  event?: any;
  isImageClick?: boolean;
  merchRank?: number;
}

export interface DivisionalPromotionProductClickData
  extends DivisionalPromotionEventData {
  isTouch?: boolean;
}

export interface DivisionalPromotionSortingClickData {
  divisionalPromotion: SellerShowcaseDivisionalPromotion;
}

export interface DivisionalPromotionExpansionClickData {
  divisionalPromotion: SellerShowcaseDivisionalPromotion;
}

export enum DivisionalPromotionSectionHeaders {
  image = 'image',
  productNumber = 'productNumber',
  productDescription = 'productDescription',
  brand = 'brand',
  actions = 'actions',
  quantity = 'quantity',
}

export enum DivisionalPromotionFilterType {
  brand = 'brand',
  promoType = 'promo type',
  exclusiveBrand = 'exclusive brand',
  productClass = 'product class',
  searchWithin = 'search within',
}

export enum DivisionalPromotionFilterValues {
  allSelected = 'ALL SELECTED',
  yes = 'YES',
  no = 'NO',
}

export enum SellerShowcaseProductCardDesktopHeight {
  baseProduct = 108,
  border = 2,
  drawer = 40,
  margin = 10,
}

export enum SellerShowcaseProductCardTouchHeight {
  baseProduct = 195.602,
  border = 2,
  drawer = 40,
  margin = 24,
  separateShippingPrice = 19.602,
}

export interface DivisionalPromotionSorting {
  headerName: DivisionalPromotionSectionHeaders;
  sortDirection: SortDirections;
}

export interface SellerShowcaseUpdates {
  expansion?: Map<string, boolean>;
  filters?: Map<DivisionalPromotionFilterType, string>;
  sorting?: Map<string, DivisionalPromotionSorting>;
}
