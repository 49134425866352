import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PanAppStateModule, TrackingService } from '@panamax/app-state';
import { WebSocketModule } from '@panamax/websocket';
import { NgRxAlertsModule } from '@usf/ngrx-alerts';
import { NgRxFileShareModule } from '@usf/ngrx-file-share';
import { NgRxInventoryModule } from '@usf/ngrx-inventory';
import { NgRxListModule } from '@usf/ngrx-list';
import { NgRxPromotionsModule } from '@usf/ngrx-promotions';
import { NgRxOrderModule } from '@usf/ngrx-order';
import { NgRxPricingModule } from '@usf/ngrx-pricing';
import { NgRxProductModule } from '@usf/ngrx-product';
import { NgRxGlModule } from '@usf/ngrx-gl';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogoutPageModule } from './logout/logout.module';
import { CustomerModule } from './customer/customer.module';
import { LoginPageModule } from './login/login.module';
import { HeaderModule } from './shared/components/header/header.module';
import { NG_RX_ALERTS_LIB_CONFIG } from './shared/constants/ngrx-alerts-lib-config';
import { NG_RX_FILESHARE_LIB_CONFIG } from './shared/constants/ngrx-file-share-lib-config';
import { NG_RX_LIST_LIB_CONFIG } from './shared/constants/ngrx-list-lib-config';
import { NG_RX_ORDER_LIB_CONFIG } from './shared/constants/ngrx-order-lib-config';
import { NG_RX_PRICING_LIB_CONFIG } from './shared/constants/ngrx-pricing-lib-config';
import { NG_RX_PRODUCT_LIB_CONFIG } from './shared/constants/ngrx-product-lib.config';
import { PAN_APP_STATE_LIB_CONFIG } from './shared/constants/pan-app-state-lib-config';
import { NG_RX_USER_LIB_CONFIG } from '@shared/constants/ngrx-user-lib-config';
import { WEB_SOCKET_CONFIG } from './shared/constants/websocket-lib-config';
import { HttpErrorInterceptor } from './shared/interceptor/http-error.interceptor';
import {
  initializeProductScoop,
  ProductService,
} from './shared/services/product/product.service';
import { SharedModule } from './shared/shared.module';
import { getMetaReducers, ROOT_REDUCERS } from './store';
import { UserModule } from './user/user.module';
import { LoginErrorModule } from './login-error/login-error.module';
import { NG_RX_PROMOTIONS_LIB_CONFIG } from './shared/constants/ngrx-promotions-lib.config';
import { NG_RX_TOOLS_LIB_CONFIG } from '@shared/constants/ngrx-tools-lib.config';
import { NgRxPaymentModule } from '@usf/ngrx-payment';
import { NG_RX_PAYMENT_LIB_CONFIG } from './shared/constants/ngrx-payment-lib-config';
import { TranslationResolverService } from './shared/services/translation-resolver.service';
import { ClientDealsEffects } from './deals/effects/client-deals.effects';
import { ClientMasterListEffects } from './lists/effects/master-list/client-master-list.effects';
import { PriceNotificationEffects } from '@shared/effects/pricing/price-notification.effects';
import { NG_RX_INVENTORY_LIB_CONFIG } from '@shared/constants/ngrx-inventory-lib.config';
import { buildNumber as appVersion } from 'src/buildNumber';
import { PlatformEffects } from './shared/effects/platform/platform.effects';
import { ClientServiceRequestEffects } from '@service-request/effects/client-service-request.effects';
import { ClientBetterBuyEffects } from './better-buys/effects/client-better-buy.effects';
import { PunchoutTracingEffects } from '@shared/tracking/tracing/punchout/punchout-tracing.effects';
import { NewSearchPageEffects } from './new-search/effects/new-search-page.effects';
import { NG_RX_GL_LIB_CONFIG } from '@shared/constants/ngrx-gl-lib.config';
import { NextDeliveryEffects } from '@shared/effects/next-delivery/next-delivery-effects';
import { VisualPunchThruEffects } from '@shared/effects/visual-punchthru/visual-punchthru.effects';
import { ClientScoopEffects } from './scoop/effects/client-scoop.effects';
import { NgRxToolsModule } from '@usf/ngrx-tools';
import { NgRxAIListModule } from '@usf/ngrx-list-ai';
import { NG_RX_AI_LIST_LIB_CONFIG } from './shared/constants/ngrx-ai-list-lib-config';
import { ClientSellerShowcaseEffects } from './seller-showcase/effects/client-seller-showcase.effects';
import { NgrxCustomerReportingModule } from '@usf/ngrx-customer-reporting';
import { NG_RX_CUSTOMER_REPORTING_LIB_CONFIG } from '@shared/constants/ngrx-customer-reporting-lib.config';

/**
 * For AOT language compilation:
 *
 * @param httpClient
 * @returns instance of the translation loader service which takes 3 params:
 *    1) httpClient
 *    2) location of the folder to access stored translation file (i18n)
 *    3) type of the translation file (JSON)
 */
export const httpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, 'assets/i18n/', `.json?v=${appVersion}`);

const isCurrentPageLogout = () => {
  return window.location.pathname === '/logout';
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
      innerHTMLTemplatesEnabled: true,
    }),
    FormsModule,
    ReactiveFormsModule,
    LoginPageModule,
    LogoutPageModule,
    LoginErrorModule,
    SharedModule,
    UserModule,
    HeaderModule,
    CustomerModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      // metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateSerializability: true,
      },
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
          features: {
            persist: true,
          },
          stateSanitizer: state =>
            state.orders ? { ...state, orders: undefined } : state,
        })
      : [],
    EffectsModule.forRoot([
      ClientBetterBuyEffects,
      ClientDealsEffects,
      ClientMasterListEffects,
      ClientServiceRequestEffects,
      ClientScoopEffects,
      ClientSellerShowcaseEffects,
      PriceNotificationEffects,
      PlatformEffects,
      PunchoutTracingEffects,
      NewSearchPageEffects,
      NextDeliveryEffects,
      VisualPunchThruEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    NgRxListModule.forRoot(NG_RX_LIST_LIB_CONFIG),
    NgRxAIListModule.forRoot(NG_RX_AI_LIST_LIB_CONFIG),
    NgrxCustomerReportingModule.forRoot(NG_RX_CUSTOMER_REPORTING_LIB_CONFIG),
    NgRxProductModule.forRoot(NG_RX_PRODUCT_LIB_CONFIG),
    NgRxPricingModule.forRoot(NG_RX_PRICING_LIB_CONFIG),
    NgRxOrderModule.forRoot(NG_RX_ORDER_LIB_CONFIG),
    NgRxAlertsModule.forRoot(NG_RX_ALERTS_LIB_CONFIG),
    NgRxFileShareModule.forRoot(NG_RX_FILESHARE_LIB_CONFIG),
    NgRxPromotionsModule.forRoot(NG_RX_PROMOTIONS_LIB_CONFIG),
    NgRxPaymentModule.forRoot(NG_RX_PAYMENT_LIB_CONFIG),
    NgRxToolsModule.forRoot(NG_RX_TOOLS_LIB_CONFIG),
    NgRxInventoryModule.forRoot(NG_RX_INVENTORY_LIB_CONFIG),
    NgRxGlModule.forRoot(NG_RX_GL_LIB_CONFIG),
    UserModule.forRoot(NG_RX_USER_LIB_CONFIG),
    WebSocketModule.forRoot(WEB_SOCKET_CONFIG),
    BrowserAnimationsModule,
    PanAppStateModule.forRoot(PAN_APP_STATE_LIB_CONFIG, isCurrentPageLogout),
    SharedModule,
    AppRoutingModule,
    ServiceWorkerModule.register('extendable-service-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    /*Internationalization*/
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    TranslationResolverService,
    TranslateService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: Window, useValue: window },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeProductScoop,
      deps: [ProductService],
      multi: true,
    },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      deps: [TrackingService],
      useFactory: getMetaReducers,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
