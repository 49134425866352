/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[currency]',
})
export class CurrencyDirective {
  constructor(private el: ElementRef) {}
  @HostListener('input', ['$event'])
  onInput(event: any) {
    const initialValue = event.target.value;
    let replacedValue = initialValue
      .replace(',', '.')
      .replace(/[^0-9\.]*/g, '');

    let parts = replacedValue.split('.');
    if (parts.length > 1) {
      replacedValue = parts[0] + '.' + parts[1].slice(0, 2);
    } else {
      replacedValue = parts[0];
    }
    this.el.nativeElement.value = replacedValue;
    if (initialValue !== this.el.nativeElement.value) {
      event.target.value = replacedValue;
    }
  }
}
