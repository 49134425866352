import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  SuggestionTypeAheadItem,
  TypeaheadProduct,
} from '@app/new-search/models/new-search-typeahead.model';
import { SpecificFacetSearchResult } from '@coveo/headless';
import { NavigationHelperService } from '@shared/helpers/navigation.helpers.service';
import { CoveoService } from '@shared/services/coveo/coveo.service';
import { TypeaheadService } from '@shared/services/typeahead/typeahead.service';
import { Product } from '@coveo/headless/commerce';
import { SEARCH_PAGES_ENUM } from '@shared/constants/search-pages.enum';
import { SEARCH_TYPE } from '@usf/ngrx-product';
import { COVEO_STANDALONE_SEARCH_BOX_DATA } from '@shared/constants/coveo-constants';
import { SearchAnalyticsService } from '@search/services/search-analytics.service';
import { PlatformService } from '@panamax/app-state';
import { BRAND_FACET } from '@app/new-search/constants/new-search-constants';

@Component({
  selector: 'app-new-search-typeahead',
  templateUrl: './new-search-typeahead.component.html',
  styleUrls: ['./new-search-typeahead.component.scss'],
})
export class NewSearchTypeaheadComponent implements OnInit, OnDestroy {
  @Input() searchCatalog: any;
  @Output() isTypeaheadVisible = new EventEmitter<boolean>();

  constructor(
    private navigationHelper: NavigationHelperService,
    readonly coveoService: CoveoService,
    readonly typeaheadService: TypeaheadService,
    private searchAnalyticsService: SearchAnalyticsService,
    readonly platformService: PlatformService,
  ) {}

  ngOnInit() {
    this.typeaheadService.initializeTypeAhead();
  }

  handleProductSuggestionClick(item: Product, rank: number) {
    if (!item) {
      return;
    }
    this.typeaheadService.selectInstantProduct(item);
    const productNumber = Number(item.ec_product_id);
    const queryParams = {
      lnksrc: SEARCH_PAGES_ENUM.productTypeAhead,
      rank,
    };
    this.navigationHelper.routeToProductDetailsQueryParams(
      productNumber,
      queryParams,
    );
    this.isTypeaheadVisible.emit(false);
  }

  handleBrandsItemClick(item: SpecificFacetSearchResult) {
    const updatedFacetFilters = BRAND_FACET + ':' + item.rawValue;
    this.typeaheadService.selectBrandSuggetion(item);
    this.navigationHelper.routeToNewSearch({
      facetFilters: updatedFacetFilters,
      originSearchPage: SEARCH_PAGES_ENUM.typeAhead,
    });
    this.isTypeaheadVisible.emit(false);
  }

  handleSearchBySuggestions(suggestionValue: SuggestionTypeAheadItem): void {
    this.typeaheadService.selectSearchTermSuggetion(
      suggestionValue.displayValue,
    );
    this.navigationHelper.routeToNewSearch({
      searchText: suggestionValue.displayValue,
      originSearchPage: SEARCH_PAGES_ENUM.typeAhead,
    });
    this.isTypeaheadVisible.emit(false);
  }

  viewAll(searchCatalog: any) {
    const searchText = searchCatalog?.value?.trim();
    if (searchText?.trim().length > 0) {
      this.searchAnalyticsService.trackSearchSubmit(
        { searchText },
        SEARCH_TYPE.catalogSearch,
        true,
      );
      const data = {
        value: searchText,
        analytics: {},
      };
      localStorage.setItem(
        COVEO_STANDALONE_SEARCH_BOX_DATA,
        JSON.stringify(data),
      );
      this.navigationHelper.routeToNewSearch({ searchText });
      this.isTypeaheadVisible.emit(false);
    }
  }

  ngOnDestroy() {
    this.typeaheadService.disableTypeahead();
  }
}
