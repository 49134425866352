import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CustomerStoreService } from '@app/ngrx-customer/services';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { ImageVariantEnum } from '@product-detail/models/image-variant.enum';
import { LastBuy } from '@product-detail/models/last-buy.model';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { ShippingChargeEnum } from '@product-detail/models/shipping-charge.enum';
import { CustomPricingRationaleManualModalService } from '@product-detail/services/custom-pricing-rationale-manual-modal.service';
import { CustomPricingRationaleService } from '@product-detail/services/custom-pricing-rationale.service';
import { roundPrice } from '@product-detail/utils/cpr.util';
import {
  getAlternativeThumbnail,
  getFirstImageURL,
} from '@product-detail/utils/product-images.util';
import { formatLast4BuysList } from '@product-detail/utils/product-last-buys.util';
import { ProductPriceUtil } from '@product-detail/utils/product-price.util';
import { FEATURES } from '@shared/constants/splitio-features';
import { KeyValue } from '@shared/models/key-value.model';
import { FormatCurrencyAndDecimalPipe } from '@shared/pipes/format-currency-and-decimal.pipe';
import { LoadingSpinnerService } from '@shared/services/loading-spinner/loading-spinner.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { CprPricingService, CprRequestTypeEnum } from '@usf/ngrx-pricing';
import { CprPricingState } from '@usf/ngrx-pricing/lib/models/cpr-pricing.model';
import { SellerPriceDetails } from '@usf/price-types';
import { combineLatest, Subscription, take } from 'rxjs';

type RadioGroup = {
  label: string;
  price: number;
  id: string;
};

const RadioOptions = {
  minimum: 'minimum',
  maximum: 'maximum',
  target: 'target',
  custom: 'custom',
  makeCpr: 'makeCpr',
};

const ErrorLegacyText = {
  below: 'ENTERED PRICE BELOW LIMIT - CPR NOT UPDATED',
  above: 'ENTERED PRICE ABOVE LIMIT - CPR NOT UPDATED',
};

@Component({
  selector: 'app-cpr-modal',
  templateUrl: './cpr-modal.component.html',
  styleUrls: ['./cpr-modal.component.scss'],
})
export class CPRModalComponent implements OnInit, OnDestroy {
  @Input() product: ProductDetailViewModel;
  @Input() sellerPriceDetail: SellerPriceDetails;
  @Input() alternativeProduct: ProductDetailViewModel;
  @Input() alternativeSellerPriceDetail: SellerPriceDetails;
  @Input() isOnlyCPR = false;
  @Input() pageName?: string;

  lowLimitFeatureFlag = FEATURES.split_global_ngp_cpr_low_limit.name;
  hideMarketChangeFeatureFlag = FEATURES.split_dynamic_hide_market_change.name;

  pricingDetailsTwo: KeyValue[];
  priceUom: string;
  priceChange: number;
  targetDifference: number;
  lastBuy: LastBuy;
  targetDifferencePercentage = 0;
  thumbnailImageURL: string;
  cprPricingServiceSub$: Subscription = new Subscription();
  response;
  // RADIO GROUP AND INPUT
  private readonly EMPTY_VALUE: string = '';
  isInputDisabled = true;
  isLowLimitEnabled = false;
  priceRadios: Map<string, RadioGroup>;
  priceRadioSelected: string;
  priceInputSelected: string;
  displayPriceNotOptimizedNote = false;
  userHasSelectedPrice = false;
  isSubstitute = false;
  includeAscCharge: boolean;
  tandemSystem: string;
  currentDivisionSub$: Subscription = new Subscription();
  featureFlagsSub$: Subscription = new Subscription();

  activeProduct: ProductDetailViewModel;
  activeSellerPriceDetail: SellerPriceDetails;
  currentTab = 1;
  isMakeCprSelected = false;

  constructor(
    private modalController: ModalController,
    public readonly platformService: PlatformService,
    private readonly cprPricingService: CprPricingService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly customPricingRationaleManualModalService: CustomPricingRationaleManualModalService,
    private readonly customerStoreService: CustomerStoreService,
    private panAppState: PanAppState,
    private toastService: ToastService,
    private translateService: TranslateService,
    private customPricingRationaleService: CustomPricingRationaleService,
  ) {}
  ngOnDestroy(): void {
    this.currentDivisionSub$.unsubscribe();
    this.featureFlagsSub$?.unsubscribe();
  }

  ngOnInit() {
    this.activeProduct = this.product;
    this.activeSellerPriceDetail = this.sellerPriceDetail;
    this.priceRadioSelected = this.EMPTY_VALUE;
    this.includeAscCharge =
      this.activeSellerPriceDetail?.customerAscInd ==
      ShippingChargeEnum.included;
    this.getPricesForPriceRadios();
    this.thumbnailImageURL = getFirstImageURL(
      this.activeProduct?.summary,
      ImageVariantEnum.Medium,
    );
    if (this.activeSellerPriceDetail) {
      this.setPriceUom();
      this.setPriceChange();
      this.checkPriceOptimized();
      this.setCustomPriceTargetDifference();
    }
    this.featureFlagsSub$ = combineLatest([
      this.panAppState.feature$([this.lowLimitFeatureFlag]),
      this.panAppState.feature$([FEATURES.split_global_cpr_last_puchase.name]),
    ])
      .pipe(take(1))
      .subscribe(([isLowLimitEnabled, isRecentPurchaseEnabled]) => {
        this.isLowLimitEnabled = isLowLimitEnabled;
        if (this.activeSellerPriceDetail) {
          this.updateSecondPricingDetails();
        }
        if (isRecentPurchaseEnabled && this.getDistrictNgpCprInd() === 'Y') {
          this.lastBuy = formatLast4BuysList(
            this.activeSellerPriceDetail?.lastFourBuys?.lastbuyList,
            this.activeProduct?.summary,
          )[0];
        }
      });
    this.loadDivision();
  }

  getAlternativeThumbnail = (thumbnailImageURL: string): string => {
    return getAlternativeThumbnail(
      this.activeProduct?.summary,
      thumbnailImageURL,
    );
  };

  closeModal() {
    if (this.cprPricingServiceSub$) {
      this.cprPricingServiceSub$.unsubscribe();
    }
    this.modalController.dismiss();
  }

  async updatePrice() {
    if (this.isOnlyCPR || this.isMakeCprSelected) {
      this.customPricingRationaleService.trackCustomPricingRationaleClickButton(
        this.product,
        this.priceRadioSelected,
        this.pageName,
      );
    }
    const priceToUpdate = this.getPriceToUpdate();
    this.getRequestType(this.priceRadioSelected);
    if (priceToUpdate) {
      if (this.isOnlyCPR || this.isMakeCprSelected) {
        this.loadingSpinnerService.createSpinnerModal().then(() => {
          this.cprPricingServiceSub$ = this.cprPricingService
            .updateCpr(
              this.activeSellerPriceDetail?.productNumber,
              +priceToUpdate,
              this.getRequestType(this.priceRadioSelected),
              this.getDistrictNgpCprInd(),
              this.activeProduct?.summary?.priceUom,
              false,
              this.tandemSystem,
            )
            .subscribe((resp: CprPricingState) => {
              this.handleResponseUpdatePrice(resp);
            });
        });
      } else {
        let priceOverrideWithAsc =
          this.activeSellerPriceDetail?.customerAscInd ===
            ShippingChargeEnum.included && this.priceRadioSelected !== 'custom'
            ? priceToUpdate +
                this.activeSellerPriceDetail?.currentPrice?.autoShippingCharges
                  ?.unitAscRate || 0
            : priceToUpdate;
        this.unitPriceOverride(priceOverrideWithAsc);
      }
    }

    if (!this.userHasSelectedPrice) {
      return;
    }
    this.closeModal();
  }

  private handleResponseUpdatePrice(resp: CprPricingState): void {
    if (this.getDistrictNgpCprInd() === 'Y') {
      if (resp.unifiedResponseCode === 300) {
        this.handleZilliantManual();
      } else {
        this.handleErrorZilliant(resp.unifiedResponseCode);
      }
    } else if (this.getDistrictNgpCprInd() === 'N') {
      this.handleErrorLegacy(resp);
    }
    this.loadingSpinnerService.dismissSpinnerModal();
  }

  async handleZilliantManual() {
    const priceToUpdate = this.getPriceToUpdate();
    await this.customPricingRationaleManualModalService.openModal(
      priceToUpdate,
      true,
    );
    const confirmManual =
      this.customPricingRationaleManualModalService.isManual;
    const reason = this.customPricingRationaleManualModalService.reason;

    if (confirmManual) {
      this.cprPricingServiceSub$ = this.cprPricingService
        .updateCpr(
          this.activeSellerPriceDetail?.productNumber,
          +priceToUpdate,
          this.getRequestType(this.priceRadioSelected),
          this.getDistrictNgpCprInd(),
          this.activeProduct?.summary?.priceUom,
          true,
          this.tandemSystem,
          reason,
        )
        .subscribe((resp: CprPricingState) => {
          this.handleErrorZilliantManual(resp);
        });
    } else {
      this.presentErrorToastMessage(
        'i18n.productDetail.customPricingRationaleModal.manualErrorMessage',
      );
    }
  }

  async handleErrorZilliantManual(resp: CprPricingState) {
    if (resp.unifiedResponseCode === 201) {
      this.presentSuccessToastMessage(
        'i18n.productDetail.customPricingRationaleModal.manualSuccessMessage',
      );
    } else {
      this.presentErrorToastMessage(
        'i18n.productDetail.customPricingRationaleModal.manualErrorMessage',
      );
    }
  }

  private handleErrorZilliant(unifiedResponseCode: number): void {
    switch (unifiedResponseCode) {
      case 200: {
        this.presentSuccessToastMessage(
          'i18n.productDetail.customPricingRationaleModal.submissionSuccessMessage',
        );
        break;
      }
      case 400: {
        this.presentErrorToastMessage(
          'i18n.productDetail.customPricingRationaleModal.hardFloorErrorMessage',
        );
        break;
      }
      case 401: {
        this.presentErrorToastMessage(
          'i18n.productDetail.customPricingRationaleModal.hardCeilingErrorMessage',
        );
        break;
      }
      default: {
        this.presentErrorToastMessage(
          'i18n.productDetail.customPricingRationaleModal.submissionErrorMessage',
        );
        break;
      }
    }
  }

  private handleErrorLegacy(resp: CprPricingState): void {
    switch (resp?.unifiedResponseCode) {
      case 0:
        this.presentSuccessToastMessage(
          'i18n.productDetail.customPricingRationaleModal.legacySuccessMessage',
        );
        break;
      case 98:
        const errorText = resp?.customerList[0]?.productList[0]?.returnText;
        if (errorText === ErrorLegacyText.below) {
          this.presentErrorToastMessage(
            'i18n.productDetail.customPricingRationaleModal.legacyBelowErrorMessage',
          );
        } else if (errorText === ErrorLegacyText.above) {
          this.presentErrorToastMessage(
            'i18n.productDetail.customPricingRationaleModal.legacyAboveErrorMessage',
          );
        } else {
          this.presentErrorToastMessage(
            'i18n.productDetail.customPricingRationaleModal.legacyDefaultMessage',
          );
        }
        break;
      default:
        this.presentErrorToastMessage(
          'i18n.productDetail.customPricingRationaleModal.legacyDefaultMessage',
        );
        break;
    }
  }

  private getRequestType(selectedValue: string): CprRequestTypeEnum {
    // if legacy experience and
    // cookbook prices are not available (locally priced == Y) make the request of type custom 'Y'
    const cookbook = this.activeSellerPriceDetail?.currentPrice?.cookbookPrices;
    switch (selectedValue) {
      case RadioOptions.maximum:
        return cookbook?.cookbookMaximumPrice > 0 && this.isLegacy()
          ? CprRequestTypeEnum.maximum
          : !this.isLegacy()
            ? CprRequestTypeEnum.maximum
            : CprRequestTypeEnum.custom;
      case RadioOptions.minimum:
        return cookbook?.cookbookMinimumPrice > 0 && this.isLegacy()
          ? CprRequestTypeEnum.minimum
          : CprRequestTypeEnum.custom;
      case RadioOptions.target:
        return cookbook?.cookbookTargetPrice > 0 && this.isLegacy()
          ? CprRequestTypeEnum.target
          : !this.isLegacy()
            ? CprRequestTypeEnum.target
            : CprRequestTypeEnum.custom;
      default:
        return CprRequestTypeEnum.custom;
    }
  }

  setPriceUom() {
    const {
      summary: { catchWeightFlag, priceUom },
    } = this.activeProduct;
    this.priceUom = catchWeightFlag ? '/' + priceUom : priceUom;
  }

  setPriceChange() {
    const { currentPrice, lastPrice } = this.activeSellerPriceDetail;
    this.priceChange = currentPrice.unitPrice - lastPrice.unitPrice;
  }

  setCustomPriceTargetDifference() {
    if (this.priceInputSelected) {
      const customPrice = parseFloat(this.priceInputSelected);
      const targetPrice = this.priceRadios?.get(RadioOptions?.target).price;
      this.targetDifference = customPrice - targetPrice;
      this.targetDifferencePercentage = (customPrice / targetPrice - 1) * 100;
    }
  }

  updateSecondPricingDetails(): void {
    const {
      currentPrice: {
        autoShippingCharges,
        grossProfitPercent,
        grossProfitDollars,
        cookbookPrices: { cookbookMinimumPrice },
      },
      customerAscInd,
    } = this.activeSellerPriceDetail;

    const applyFlag = autoShippingCharges?.ascApplyFlag;
    const customerNumberAscInd =
      customerAscInd === ShippingChargeEnum.included ||
      customerAscInd === ShippingChargeEnum.notIncluded;
    const ascCharge =
      applyFlag === ShippingChargeEnum.included ||
      applyFlag === ShippingChargeEnum.notIncluded;

    const decimalPipe = new FormatCurrencyAndDecimalPipe();
    const isDistrictNgpN = this.getDistrictNgpCprInd() !== 'Y';

    const ascPriceAmount = autoShippingCharges?.unitAscRate;

    const commissionAmount =
      !isDistrictNgpN && this.isLowLimitEnabled
        ? this.activeSellerPriceDetail?.zilliantDetails?.cprLowLimit
        : this.activeSellerPriceDetail?.currentPrice?.divisionCosts
            ?.commissionAmount;

    const commissionPrice = this.includeAscCharge
      ? commissionAmount + ascPriceAmount
      : commissionAmount;

    const pricingDetailsLabel =
      !isDistrictNgpN && this.isLowLimitEnabled
        ? 'i18n.productDetail.customPricingRationaleModal.lowLimit'
        : 'i18n.productDetail.customPricingRationaleModal.sellerFloor';

    this.pricingDetailsTwo = [
      {
        keyLabel: isDistrictNgpN ? 'COM' : pricingDetailsLabel,
        value: `${decimalPipe.transform(commissionPrice)}`,
      },
      {
        keyLabel: 'Asc Ind',
        value: customerNumberAscInd ? applyFlag : ShippingChargeEnum.noCharge,
      },
      {
        keyLabel: 'Asc Charge',
        value:
          ascCharge && customerNumberAscInd
            ? `${decimalPipe.transform(ascPriceAmount)}`
            : '',
      },
      {
        keyLabel: 'GPP',
        value: `${grossProfitPercent}%`,
      },
      {
        keyLabel: 'GPD',
        value: `${decimalPipe.transform(grossProfitDollars)}`,
      },
    ].filter(Boolean);
  }

  radioPriceChange(type: string) {
    this.priceRadioSelected = type;

    const maximumPrice = this.priceRadios?.get(RadioOptions.maximum).price;
    const minimumPrice = this.priceRadios?.get(RadioOptions.minimum).price;
    const targetPrice = this.priceRadios?.get(RadioOptions.target).price;

    if (this.priceRadioSelected !== RadioOptions.custom) {
      this.disableCustomPriceFieldAndEnableUpdateButton();

      if (this.priceRadioSelected === RadioOptions.target) {
        this.targetDifferencePercentage = 0;
        this.targetDifference = 0;
      } else if (this.priceRadioSelected === RadioOptions.maximum) {
        this.targetDifference = maximumPrice - targetPrice;
        this.targetDifferencePercentage =
          (maximumPrice / targetPrice - 1) * 100;
      } else if (this.priceRadioSelected === RadioOptions.minimum) {
        this.targetDifference = minimumPrice - targetPrice;
        this.targetDifferencePercentage =
          (minimumPrice / targetPrice - 1) * 100;
      }
    } else {
      if (this.priceInputSelected?.split('.')[1]?.length > 2) {
        return;
      }
      this.isInputDisabled = false;
      this.userHasSelectedPrice = parseFloat(this.priceInputSelected) > 0;
      if (this.userHasSelectedPrice) {
        this.setCustomPriceTargetDifference();
      }
    }
  }

  private disableCustomPriceFieldAndEnableUpdateButton() {
    this.isInputDisabled = true;
    this.priceInputSelected = '';

    this.userHasSelectedPrice = true;
  }

  getPricesForPriceRadios() {
    if (this.activeSellerPriceDetail) {
      let { maximum, minimum, target } = ProductPriceUtil.getMaxMinTargetPrices(
        this.activeSellerPriceDetail,
      );

      if (this.includeAscCharge) {
        const {
          currentPrice: { autoShippingCharges },
        } = this.activeSellerPriceDetail;
        const ascChargeValue = autoShippingCharges?.unitAscRate ?? 0;
        maximum += ascChargeValue;
        minimum += ascChargeValue;
        target += ascChargeValue;
      }

      const maximumLabel =
        this.activeSellerPriceDetail.districtNgpCprInd === 'Y'
          ? 'i18n.productDetail.customPricingRationaleModal.changeToOptimal'
          : 'i18n.productDetail.customPricingRationaleModal.changeToMaximum';

      const map = new Map();
      map.set(RadioOptions.minimum, {
        label: 'i18n.productDetail.customPricingRationaleModal.changeToMinimum',
        price: minimum,
        id: RadioOptions.minimum,
      });
      map.set(RadioOptions.target, {
        label: 'i18n.productDetail.customPricingRationaleModal.changeToTarget',
        price: target,
        id: RadioOptions.target,
      });
      map.set(RadioOptions.maximum, {
        label: maximumLabel,
        price: maximum,
        id: RadioOptions.maximum,
      });
      map.set(RadioOptions.custom, {
        label: 'i18n.productDetail.customPricingRationaleModal.customPrice',
        price: 0,
        id: RadioOptions.custom,
      });
      this.priceRadios = map;
    }
  }

  public get RadioOptions() {
    return RadioOptions;
  }

  getPriceToUpdate(): number {
    let priceToUpdate = this.priceRadios.get(this.priceRadioSelected)?.price
      ? this.priceRadios.get(this.priceRadioSelected)?.price
      : +this.priceInputSelected;
    if (this.includeAscCharge) {
      const {
        currentPrice: { autoShippingCharges },
      } = this.activeSellerPriceDetail;
      const ascChargeValue = autoShippingCharges?.unitAscRate ?? 0;
      const updatedPrice = priceToUpdate - ascChargeValue;
      priceToUpdate = Math.round(updatedPrice * 1e2) / 1e2;
    }
    return priceToUpdate;
  }

  getDistrictNgpCprInd(): string {
    return this.activeSellerPriceDetail?.districtNgpCprInd
      ? this.activeSellerPriceDetail?.districtNgpCprInd
      : 'N';
  }

  checkPriceOptimized() {
    if (this.isLegacy()) {
      this.displayPriceNotOptimizedNote =
        this.activeSellerPriceDetail?.currentPrice?.priceControls?.locallyPricedInd?.toUpperCase() ===
        'Y';
    } else {
      this.displayPriceNotOptimizedNote =
        this.activeSellerPriceDetail?.zilliantDetails?.priceOptimizedInd?.toUpperCase() ===
        'N';
    }
  }

  isLegacy(): boolean {
    return this.getDistrictNgpCprInd() === 'N';
  }

  loadDivision() {
    this.currentDivisionSub$ = this.customerStoreService
      .loadSelectedCustomerDivision$()
      .subscribe(division => {
        this.tandemSystem = division?.tandemSystem;
      });
  }

  toggleTab(tab: number) {
    if (tab === 2) {
      this.activeProduct = this.alternativeProduct;
      this.activeSellerPriceDetail = this.alternativeSellerPriceDetail;
    } else {
      this.activeProduct = this.product;
      this.activeSellerPriceDetail = this.sellerPriceDetail;
    }
    if (this.currentTab !== tab) {
      this.currentTab = tab !== 2 ? 1 : 2;
      this.isSubstitute = !this.isSubstitute;
    }
    this.getPricesForPriceRadios();
    this.updateSecondPricingDetails();
    this.thumbnailImageURL = getFirstImageURL(
      this.activeProduct?.summary,
      ImageVariantEnum.Medium,
    );
  }
  selectMakeCpr() {
    this.isMakeCprSelected = !this.isMakeCprSelected;
  }

  unitPriceOverride(priceToUpdate: number): void {
    const ascCharge =
      this.activeSellerPriceDetail?.currentPrice?.autoShippingCharges
        ?.unitAscRate || 0;
    let newPrice;
    if (
      this.activeSellerPriceDetail?.customerAscInd ===
      ShippingChargeEnum.included
    ) {
      newPrice = priceToUpdate - ascCharge < 0 ? ascCharge : priceToUpdate;
    } else {
      newPrice = priceToUpdate < 0 ? 0 : priceToUpdate;
    }
    let unitPriceOverride =
      this.activeSellerPriceDetail?.customerAscInd ===
      ShippingChargeEnum.included
        ? priceToUpdate - ascCharge
        : priceToUpdate;
    const priceUpdateData = {
      productNumber: this.activeProduct?.productNumber,
      unitPrice: roundPrice(newPrice),
      unitPriceOverride: roundPrice(unitPriceOverride),
    };
    this.loadingSpinnerService.dismissSpinnerModal();
    this.modalController.dismiss(priceUpdateData);
  }

  presentSuccessToastMessage(translateMessage: string): void {
    this.toastService.presentToastMsg(
      this.translateService.instant(translateMessage),
      'green-toast',
      MessageTypeEnum.success,
      [],
    );
  }

  presentErrorToastMessage(translateMessage: string): void {
    this.toastService.presentToastMsg(
      this.translateService.instant(translateMessage),
      'red-toast',
      MessageTypeEnum.failure,
      [],
    );
  }
}
