import { Directive, ElementRef, Input } from '@angular/core';

/**
 * Sets the id and data-cy attribute required for automated testing
 * @example Usage and Result
 * // <button appTestId='btn-feature-x'>...</button>
 * // Results in:
 * // <button id='btn-feature-x' data-cy='btn-feature-x'>...
 */
@Directive({
  selector: '[appTestId]',
})
export class TestIdDirective {
  @Input()
  set appTestId(id: string) {
    this.el.nativeElement.id = id;
    this.el.nativeElement.setAttribute('data-cy', id);
  }

  constructor(private el: ElementRef) {}
}
