import { selectedCustomer } from '@app/ngrx-customer/store';
import { createSelector } from '@ngrx/store';
import { productWithAlternativesSelector } from '@shared/selectors/product.selectors';
import { LoadingState, selectSellerShowcaseState } from '@usf/ngrx-product';
import {
  DivisionalPromotionFilterValues,
  SellerShowcaseUpdates,
  SellerShowcaseViewModel,
} from '../models/seller-showcase.model';
import {
  arePromotionProductsAvailable,
  decorateDivisionalPromotionsWithProductsExpansionAndHeights,
  getSortedAndFilteredDivisionalPromotions,
  getSortedDivisionalPromotions,
  processSellerDivisionalPromotions,
} from './helpers/seller-showcase.selector.helpers';
import { PlatformEnum } from '@panamax/app-state';

export const selectSellerShowcase = (
  platform: PlatformEnum,
  shippingPriceFlag: boolean,
  updates?: SellerShowcaseUpdates,
) =>
  createSelector(
    selectSellerShowcaseState,
    productWithAlternativesSelector,
    selectedCustomer,
    (
      sellerShowcaseState,
      products,
      selectedCustomer,
    ): SellerShowcaseViewModel => {
      if (sellerShowcaseState?.loadingState === LoadingState.loading) {
        return {
          divisionalPromotions: [],
          selectedCustomer,
          loadingPromotionalProducts: true,
          allProductsAvailable: false,
          noPromotionsFound: false,
        };
      } else if (
        (sellerShowcaseState?.loadingState === LoadingState.loaded &&
          !sellerShowcaseState?.divisionalPromotions?.length) ||
        sellerShowcaseState?.loadingState === LoadingState.error
      ) {
        return {
          divisionalPromotions: [],
          selectedCustomer,
          loadingPromotionalProducts: false,
          allProductsAvailable: false,
          noPromotionsFound: true,
        };
      }

      // Validate if all promotions products are loaded in the products map yet
      const firstPromotionProductsAvailable = arePromotionProductsAvailable(
        getSortedDivisionalPromotions(
          sellerShowcaseState.divisionalPromotions,
        )?.[0],
        products,
      );

      if (!firstPromotionProductsAvailable) {
        return {
          divisionalPromotions: [],
          selectedCustomer,
          loadingPromotionalProducts: true,
          allProductsAvailable: false,
          noPromotionsFound: false,
        };
      }

      // Complete all promotions with their products when all products are available
      const { sellerDivisionalPromotions, brandsNames, productClasses } =
        processSellerDivisionalPromotions(
          sellerShowcaseState?.divisionalPromotions,
          products,
          updates,
        );

      let divisionalPromotions = getSortedAndFilteredDivisionalPromotions(
        sellerDivisionalPromotions,
        updates?.filters,
      );

      divisionalPromotions =
        decorateDivisionalPromotionsWithProductsExpansionAndHeights(
          divisionalPromotions,
          platform,
          shippingPriceFlag,
          selectedCustomer,
          updates?.expansion,
        );

      return {
        divisionalPromotions,
        brandsNames,
        productClasses,
        exclusiveBrandValues: [
          DivisionalPromotionFilterValues.yes,
          DivisionalPromotionFilterValues.no,
        ],
        promoTypes: sellerDivisionalPromotions.map(
          divisionalPromotion => divisionalPromotion.promotionDescription,
        ),
        selectedCustomer,
        loadingPromotionalProducts: false,
        allProductsAvailable: divisionalPromotions?.every(
          promotion => promotion?.productsAreAvailable,
        ),
        noPromotionsFound: false,
      };
    },
  );
