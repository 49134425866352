import { selectedCustomer } from '@app/ngrx-customer/store';
import { createSelector } from '@ngrx/store';
import { FrequentlyBoughtTogetherViewModel } from '@order/models/frequently-bought-together-view-model';
import { selectAllFeaturesEnabled } from '@panamax/app-state';
import { isProprietaryProduct } from '@product-detail/utils/product-summary.utils';
import {
  FREQUENTLY_BOUGHT_TOGETHER_EXPERIENCE,
  MERCH_FBT_MAX_PRODUCTS,
  MONETATE_VARIANT,
} from '@shared/constants/merchandising-constants';
import { ProductFilteringEnum } from '@shared/constants/product-filtering.enum';
import { FEATURES } from '@shared/constants/splitio-features';
import { ProductFilter } from '@shared/helpers/product-filter.helpers';
import { MerchandisingProductViewModel } from '@shared/models/merchandising-product-view.model';
import { productWithAlternativesSelector } from '@shared/selectors/product.selectors';
import {
  LoadingState,
  selectMerchandisingResponseById,
} from '@usf/ngrx-product';

export const selectFrequentlyBoughtTogetherViewModel = (
  isFeatureEnabled: boolean,
) =>
  createSelector(
    selectedCustomer,
    selectMerchandisingResponseById(FREQUENTLY_BOUGHT_TOGETHER_EXPERIENCE),
    productWithAlternativesSelector,
    selectAllFeaturesEnabled([FEATURES.split_dynamic_proprietary_ui.name]),
    (
      selectedCustomer,
      merchandisingState,
      products,
      isProprietaryUIEnabled,
    ): FrequentlyBoughtTogetherViewModel => {
      if (!isFeatureEnabled) {
        return {
          displayText: '',
          products: [],
          isFeatureEnabled,
        };
      }

      const productViewModels: MerchandisingProductViewModel[] = [];

      if (
        merchandisingState?.products?.every(
          product => products.get(product?.productNumber) !== undefined,
        )
      ) {
        merchandisingState?.products?.forEach(merchandisingProduct => {
          const product = products?.get(merchandisingProduct?.productNumber);

          if (
            !!product &&
            ProductFilter.applyFilter(
              product,
              selectedCustomer,
              ProductFilteringEnum.FREQUENTLY_BOUGHT_TOGETHER,
            ) &&
            !isProprietaryProduct(product, isProprietaryUIEnabled)
          ) {
            if (!!product?.summary?.properties) {
              product.summary.properties = new Set([
                ...product?.summary?.properties,
                ...product?.summary?.additionalProperties,
              ]);
            }
            const productInfo: MerchandisingProductViewModel = {
              ...product,
              selectedCustomer,
              recToken: merchandisingProduct?.recToken,
              variant: merchandisingProduct?.recToken?.startsWith('rt.1.')
                ? MONETATE_VARIANT
                : merchandisingProduct?.recToken,
            } as MerchandisingProductViewModel;
            productViewModels.push(productInfo);
          }
        });
      }

      const viewModel: FrequentlyBoughtTogetherViewModel = {
        displayText: 'i18n.orderPage.frequentlyBoughtTogether',
        products: productViewModels.slice(0, MERCH_FBT_MAX_PRODUCTS),
        isFeatureEnabled,
      };
      return viewModel;
    },
  );

export const selectFrequentlyBoughtTogetherProductNumbers = createSelector(
  selectMerchandisingResponseById(FREQUENTLY_BOUGHT_TOGETHER_EXPERIENCE),
  (merchandisingState): number[] => {
    if (
      [LoadingState.loaded, LoadingState.error, LoadingState.notFound].includes(
        merchandisingState?.loadingState,
      )
    ) {
      return !!merchandisingState?.products
        ? merchandisingState.products.map(product => product?.productNumber)
        : [];
    } else {
      return null;
    }
  },
);
