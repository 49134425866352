import { Injectable } from '@angular/core';
import { selectCustomMessage, selectInteractBanners } from '@app/user/store';
import { Store } from '@ngrx/store';
import {
  jwtDecode,
  PanAppState,
  PlatformEnum,
  PlatformService,
  SelectedCustomerState,
} from '@panamax/app-state';
import { Note } from '@usf/alert-types';
import { Customer } from '@usf/customer-types';
import { DeliveryDetailsState, Order } from '@usf/ngrx-order';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CustomerStoreService } from 'src/app/ngrx-customer/services';
import { CustomerSelectionOption } from '../../customer/models/customer-selection-option.model';
import { CustomerSelectionVM } from '../../customer/models/customer-selection-vm.model';
import Swiper from 'swiper';
import { DealsService } from '../../deals/services/deals.service';
import { ListsService } from '../../lists/services/lists.service';
import {
  selectAppStateSelectedCustomer,
  selectedCustomer,
} from '../../ngrx-customer/store';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { FEATURES } from '../../shared/constants/splitio-features';
import { WMTService } from '../../shared/services/wmt/wmt.service';
import { HomeAnalyticsService } from '../analytics/home-analytics.service';
import { CustomMessageBannerInfo } from '../models/custom-message-banner-info.model';
import { HomePageViewModel } from '../models/home-page-view-model.model';
import { InteractBannerInfo } from '../models/interact-banner-info.model';
import { RecommendedContent } from '../models/tile.model';
import { UserCustomerInfo } from '../models/user-customer-info.model';
import { getUserCustomerInfo } from '../selectors/home.selectors';
import { UserService } from '@app/user/services';
import { SiteCustomizationProfile } from '@usf/user-types/site-customization';
import { CustomizationOptions } from '@shared/constants/customizations.enum';
import { UserActions } from '@app/user/store/actions/action-types';
import { BetterBuysService } from '../../better-buys/services/better-buys.service';
import { DeliveryDetails } from '@usf/order-types';
import { ToggleNewSearchService } from '@shared/services/toggle-new-search/toggle-new-search.service';
import { PATHS } from '@shared/constants/paths';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  homeSwiperRef$: BehaviorSubject<Swiper> = new BehaviorSubject(null);

  constructor(
    private store: Store,
    private homeAnalytics: HomeAnalyticsService,
    private customerStoreService: CustomerStoreService,
    readonly platformService: PlatformService,
    private dealsService: DealsService,
    private wmtService: WMTService,
    private notificationService: NotificationsService,
    private panAppState: PanAppState,
    private userService: UserService,
    private listsService: ListsService,
    private betterBuysService: BetterBuysService,
    private readonly toggleNewSearchService: ToggleNewSearchService,
  ) {}

  getHomePageViewModel$(): Observable<HomePageViewModel> {
    const selectedCustomer$ = this.loadSelectedCustomerState$();
    this.getCustomMessage$();
    const dealsFlag$ = this.dealsService.getDealsFlag();
    const newsPageData$ = this.loadCustomizationData$();
    const tmNotesState$ = this.notificationService.loadTmNotes();
    const customMessageFlag = this.panAppState.feature$([
      FEATURES.split_global_alerts_custom_message.name,
    ]);
    const newsPageFeatureFlag = this.panAppState.feature$([
      FEATURES.split_global_user_customizations.name,
    ]);
    const getCustomMessageBannerInfo = this.loadCustomMessage$();
    const listsDataForHomePage$ = this.listsService.listsForHomePageTile$();
    const betterBuysFlag = this.betterBuysService.getBetterBuysFlag();
    const totalAnnualSavings = this.betterBuysService.totalAnnualSavings$();
    const wmtTileOrderData$ = this.wmtService.getDeliveryDetailsFromOrders$();
    const newSearchEnabled$ =
      this.toggleNewSearchService.newSearchEnabledObservable$;

    return combineLatest([
      selectedCustomer$,
      dealsFlag$,
      newsPageData$,
      tmNotesState$,
      customMessageFlag,
      getCustomMessageBannerInfo,
      listsDataForHomePage$,
      newsPageFeatureFlag,
      betterBuysFlag,
      totalAnnualSavings,
      wmtTileOrderData$,
      newSearchEnabled$,
    ]).pipe(
      map(
        ([
          selectedCustomer,
          dealsFlag,
          newsPageData,
          tmNotesState,
          customMessageFlag,
          customMessageBannerInfo,
          listsDataForHomePage,
          newsPageFeatureFlag,
          betterBuysFlag,
          totalAnnualSavings,
          wmtTileOrderDataState,
          newSearchEnabled,
        ]) => {
          const trackingIsReady = tmNotesState?.loaded;
          let deliveryDetails: DeliveryDetails | Order = null;
          deliveryDetails = wmtTileOrderDataState?.wmtTileOrderData;
          const customMessage = this.getCustomMessage(
            customMessageBannerInfo,
            selectedCustomer,
          );
          const newsFlag =
            this.displayNewsPage(newsPageData) && newsPageFeatureFlag;
          const recommendedDetails = this.getRecommendedDetails(tmNotesState);
          const searchPath = newSearchEnabled ? PATHS.NEW_SEARCH : PATHS.SEARCH;
          const viewModel = {
            deliveryDetails,
            dealsFlag,
            newsFlag,
            newsPageData,
            newsPageFeatureFlag,
            customMessageFlag,
            customMessage,
            recommendedDetails,
            listsDataForHomePage,
            betterBuysFlag,
            totalAnnualSavings,
            trackingIsReady,
            searchPath,
          } as HomePageViewModel;
          return viewModel;
        },
      ),
    );
  }

  loadCustomizationData$(): Observable<SiteCustomizationProfile> {
    try {
      return this.userService.userCustomizations$();
    } catch (err) {
      console.log(err);
    }
  }

  loadCustomer$(): Observable<Customer> {
    return this.store.select(selectedCustomer);
  }

  getCustomerWithCustomerNumber(customerNumber: number): Observable<Customer> {
    return this.customerStoreService.customerWithNumber$(customerNumber);
  }

  loadUserCustomer$(): Observable<UserCustomerInfo> {
    return this.store.select(getUserCustomerInfo);
  }

  loadSelectedCustomerState$(): Observable<SelectedCustomerState> {
    return this.store.select(selectAppStateSelectedCustomer);
  }

  trackPageLoad(
    noteIds: string | null,
    delivery: number | null,
    dealsTileDisplayed: number | null,
    newsPageDisplayed: number | null,
  ): void {
    this.homeAnalytics.trackPageLoad(
      noteIds,
      delivery,
      dealsTileDisplayed,
      newsPageDisplayed,
    );
  }

  trackInteractBannerClick(bannerId: string, bannerUrl?: string): void {
    this.homeAnalytics.trackInteractBannerClick(bannerId, bannerUrl);
  }

  getInteractBanners$(): Observable<InteractBannerInfo[]> {
    return this.store.select(selectInteractBanners);
  }

  public getCustomerSelectionVM$(
    searchText$: Observable<string>,
  ): Observable<CustomerSelectionVM> {
    return combineLatest([
      this.customerStoreService.loadCustomers$(),
      searchText$,
      this.loadSelectedCustomerState$(),
    ]).pipe(
      map(([customers, searchText, selectedCustomerState]) => {
        return this.constructCustomerSelectionVM(
          customers,
          searchText,
          selectedCustomerState,
        );
      }),
    );
  }

  private constructCustomerSelectionVM(
    customers: Customer[],
    searchText: string,
    selectedCustomerState: SelectedCustomerState,
  ) {
    const isDesktop =
      this.platformService.platformType === PlatformEnum.desktop;
    const customerSelectionOptions: CustomerSelectionOption[] = [];
    const rowHeights: number[] = [];

    customers
      .filter(
        customer =>
          customer.customerName
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          customer.customerNumber
            ?.toString()
            .includes(searchText.toLowerCase()) ||
          customer.city?.toLowerCase().includes(searchText.toLowerCase()) ||
          customer.state?.toLowerCase().includes(searchText.toLowerCase()),
      )
      .forEach(customer => {
        this.getCustomersList(
          isDesktop,
          customerSelectionOptions,
          rowHeights,
          customer,
          selectedCustomerState,
        );
        this.getDepartmentsList(
          isDesktop,
          customerSelectionOptions,
          rowHeights,
          customer,
          selectedCustomerState,
        );
      });
    const vm: CustomerSelectionVM = {
      customerSelectionOptions,
      rowHeights,
      platformType: this.platformService.platformType,
    };
    return vm;
  }

  getCustomersList(
    isDesktop: boolean,
    customerSelectionOptions: CustomerSelectionOption[],
    rowHeights: number[],
    customer: Customer,
    selectedCustomerState: SelectedCustomerState,
  ) {
    const customerRowHeight = isDesktop ? 54 : 57.55;
    const customerName = customer.customerName;
    const title =
      customerName !== null && customerName !== undefined
        ? customerName + ' (' + customer.customerNumber + ')'
        : 'No Customer Name';
    const state =
      customer.state !== null && customer.state !== undefined
        ? customer.state.toUpperCase()
        : '';
    const city =
      customer.city !== null && customer.city !== undefined
        ? customer.city
            .split(' ')
            .filter(word => word.length > 0)
            .map(
              word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ')
        : '';
    const subtitle = state.length
      ? (city.length ? city + ', ' : '') + state
      : city.length
        ? city
        : '';
    const alphabets = title.match('[a-zA-Z]{1}');
    const customerInfo: CustomerSelectionOption = {
      title,
      subtitle,
      firstLetter: !!alphabets?.length ? alphabets[0] : '',
      customerName,
      customerNumber: customer.customerNumber,
      hasDepartments: customer.departments.length ? true : false,
      departmentNumber: '0',
      isDepartment: false,
      isSelected:
        customer?.customerNumber === selectedCustomerState.customerNumber &&
        !customer?.departments?.length,
    };
    customerSelectionOptions.push(customerInfo);
    rowHeights.push(customerRowHeight);
  }

  getDepartmentsList(
    isDesktop: boolean,
    customerSelectionOptions: CustomerSelectionOption[],
    rowHeights: number[],
    customer: Customer,
    selectedCustomerState: SelectedCustomerState,
  ) {
    const departmentRowHeight = isDesktop ? 40 : 44;
    customer.departments?.forEach(department => {
      const departmentInfo: CustomerSelectionOption = {
        title:
          department.departmentName + ' (' + department.departmentNumber + ')',
        customerNumber: customer.customerNumber,
        departmentNumber: department.departmentNumber,
        isDepartment: true,
        isSelected:
          customer.customerNumber === selectedCustomerState.customerNumber &&
          department.departmentNumber ===
            selectedCustomerState.departmentNumber?.toString(),
      };
      customerSelectionOptions.push(departmentInfo);
      rowHeights.push(departmentRowHeight);
    });
  }

  getCustomMessage$() {
    this.store.dispatch(UserActions.getCustomMessage());
  }

  loadCustomMessage$(): Observable<CustomMessageBannerInfo> {
    return this.store.select(selectCustomMessage);
  }

  displayNewsPage(newsPage: SiteCustomizationProfile): boolean {
    let newsPageExists = false;
    newsPage?.properties?.forEach(customizations => {
      if (customizations.name === CustomizationOptions.newsMenu) {
        newsPageExists = true;
      }
    });
    return newsPageExists;
  }

  getSelectedCustomer(): Observable<Customer> {
    return this.store.select(selectedCustomer);
  }

  trackInteractBannersInViewport(banners: string) {
    this.homeAnalytics.trackInteractBannersInViewport(banners);
  }

  isInternalUser(): Observable<boolean> {
    return this.panAppState?.accessToken$.pipe(
      switchMap(token => {
        return of(jwtDecode(token)?.['usf-claims']?.userType === 'corp-ad');
      }),
    );
  }

  private getCustomMessage(
    customMessageBannerInfo?: CustomMessageBannerInfo,
    selectedCustomer?: SelectedCustomerState,
  ) {
    if (
      customMessageBannerInfo &&
      ((customMessageBannerInfo?.customerNumber === 999999 &&
        customMessageBannerInfo?.divisionNumber === 999999) ||
        (customMessageBannerInfo?.customerNumber ===
          selectedCustomer?.customerNumber &&
          customMessageBannerInfo?.divisionNumber ===
            selectedCustomer?.divisionNumber))
    ) {
      return customMessageBannerInfo;
    }
  }

  private getRecommendedDetails(tmNotesState?: any) {
    let recommendedDetails: RecommendedContent;
    if (!tmNotesState?.loading) {
      recommendedDetails = null;
      if (tmNotesState.notes?.length > 0) {
        let notes: Note[] = tmNotesState?.notes;
        if (notes?.filter(x => x.read === false)?.length > 0) {
          recommendedDetails = new RecommendedContent();
          notes = notes?.filter(x => x.read === false);
          recommendedDetails.unreadCount = notes?.length;
          recommendedDetails.noteId = notes[0].noteId?.toString();
          recommendedDetails.recommendedTitle = notes[0]?.title;
          recommendedDetails.recommendedDate = notes[0]?.updDate;
          recommendedDetails.recommendedDescription = notes[0]?.text;
          if (recommendedDetails.recommendedTitle?.length > 25) {
            recommendedDetails.recommendedTitle =
              recommendedDetails.recommendedTitle.slice(0, 25) + '...';
          }
          if (recommendedDetails.recommendedDescription?.length > 85) {
            recommendedDetails.recommendedDescription =
              recommendedDetails.recommendedDescription.slice(0, 85) + '...';
          }
        }
      }
    }
    return recommendedDetails;
  }
}
