import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { IonNav, ModalController, ViewWillEnter } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ProductDetailViewModel } from '../../models/product-detail-view-model';
import {
  getAlternativeThumbnail,
  getFirstImageURL,
} from '../../utils/product-images.util';
import { ImageVariantEnum } from '../../models/image-variant.enum';
import {
  ProductOpportunityRequest,
  ProductPropertiesEnum,
} from '@usf/product-types';
import {
  OpportunityFormSteps,
  OpportunityModalActions,
  OpportunityPublishOpportunity,
} from '../../models/create-opportunity.enum';
import { SellerCreateOpportunityService } from '../../services/seller-create-opportunity.service';
import dayjs from 'dayjs';
import { YES_VALUE } from './step1/seller-create-opportunity-form/seller-create-opportunity-form.component';

@Component({
  selector: 'app-seller-create-opportunity',
  templateUrl: './seller-create-opportunity.component.html',
  styleUrls: ['./seller-create-opportunity.component.scss'],
})
export class SellerCreateOpportunityComponent
  implements OnInit, OnDestroy, ViewWillEnter
{
  @Input() product: ProductDetailViewModel;
  @Input() sourcePageName: string;

  thumbnailImageURL: string;

  isTouchExperience: boolean;
  isTouchExperience$: Subscription;
  validSubmission = false;
  submitOpportunityData: ProductOpportunityRequest;
  pricingDisabled: boolean;
  formStep = OpportunityFormSteps.step1;
  publishOpportunity = OpportunityPublishOpportunity.YES;
  informationToggle = true;
  additionalInformation: {
    publishOpportunity;
    opportunityName;
    weeklyCases;
    weeklyEaches;
    weeklyPotential;
    estimatedShipDate;
  };
  selectedTitleInput: string;

  constructor(
    readonly platformService: PlatformService,
    private modalController: ModalController,
    private sellerCreateOpportunityService: SellerCreateOpportunityService,
    readonly panAppState: PanAppState,
    public nav: IonNav,
  ) {}
  ionViewWillEnter(): void {
    if (this.nav?.rootParams?.selectedTitleInput) {
      this.submitOpportunityData = {
        ...this.submitOpportunityData,
        title: this.nav?.rootParams?.selectedTitleInput,
      };
      this.selectedTitleInput = this.nav?.rootParams?.selectedTitleInput;
    }
  }

  ngOnInit() {
    this.isTouchExperience$ = this.platformService.isTouchExperience$.subscribe(
      isTouchExperience => (this.isTouchExperience = isTouchExperience),
    );

    this.thumbnailImageURL = getFirstImageURL(
      this.product?.summary,
      ImageVariantEnum.Medium,
    );
    this.pricingDisabled =
      this.product?.selectedCustomer?.ogPrintPriceInd === 'N' ||
      this.product?.summary?.properties?.has(
        ProductPropertiesEnum.specialOrder,
      ) ||
      this.product?.pricing?.unitPrice * 1 === 0;
    this.sellerCreateOpportunityService.setProduct(this.product);
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.isTouchExperience$?.unsubscribe();
  }

  toggleInformation() {
    this.informationToggle = !this.informationToggle;
  }

  getAlternativeThumbnail = (thumbnailImageURL: string): string => {
    return getAlternativeThumbnail(this.product?.summary, thumbnailImageURL);
  };

  async createOpportunity() {
    this.sellerCreateOpportunityService.trackUserSubmitOpportunityEvent(
      this.submitOpportunityData.publishOpportunity,
      this.sourcePageName,
    );
    this.sellerCreateOpportunityService.createOpportunity(
      this.submitOpportunityData,
      this.product,
    );
    await this.closeModalAfterCreateOpportunity(
      OpportunityModalActions.SUBMIT.toString(),
    );
  }

  async closeModalAfterCreateOpportunity(flag: string) {
    await this.modalController.dismiss(flag);
  }

  continue() {
    this.sellerCreateOpportunityService.trackOpportunityContinue();
    this.publishOpportunity = OpportunityPublishOpportunity.YES;
    this.formStep = OpportunityFormSteps.step2;
    this.validSubmission = false;
  }

  formValidity(formData) {
    // this.product.summary.breakable
    this.validSubmission = formData.isValid;
    this.publishOpportunity = formData.publishOpportunity;
    this.submitOpportunityData = {
      ...this.submitOpportunityData,
      ...formData.form,
    };
    if (this.formStep == OpportunityFormSteps.step1 && formData.form) {
      this.additionalInformation = formData.form;
      if (this.additionalInformation.publishOpportunity) {
        this.additionalInformation.publishOpportunity = YES_VALUE;
      }
      this.additionalInformation.estimatedShipDate = dayjs(
        this.additionalInformation.estimatedShipDate,
      )
        .utc()
        .format('M/D/YYYY');
    }
  }

  public get OpportunityFormSteps() {
    return OpportunityFormSteps;
  }

  public get OpportunityPublishOpportunity() {
    return OpportunityPublishOpportunity;
  }
}
