import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Script } from '../models/script.model';
import { PwrRenderOptions } from '../models/power-reviews/pwr-render-options';
import { PanAppState } from '@panamax/app-state';
import { FEATURES } from '../constants/splitio-features';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { FeedlessProduct } from '../../product-detail/models/feedless-product.model';
import { ProductDetailViewModel } from '../../product-detail/models/product-detail-view-model';
import { getFirstImageURL } from '../../product-detail/utils/product-images.util';
import { ImageVariantEnum } from '../../product-detail/models/image-variant.enum';
import { ProductStateService } from '@usf/ngrx-product';
import { Observable } from 'rxjs';
import { PowerReviewRollupModel } from '@usf/ngrx-product/lib/models/power-review-product.model';

@Injectable({
  providedIn: 'root',
})
export class PowerReviewService {
  powerReviewScript: Script = {
    name: 'powerReview',
    src: 'https://ui.powerreviews.com/stable/4.1/ui.js',
  };

  constructor(
    private panAppState: PanAppState,
    private productStateService: ProductStateService,
  ) {}

  public init() {
    this.panAppState
      .feature$(
        [
          FEATURES.split_global_product_reviews.name,
          FEATURES.split_global_product_questions.name,
        ],
        false,
      )
      .pipe(distinctUntilChanged())
      .subscribe(async enabled => {
        if (enabled) await this.loadPowerReviewScript();
      });
  }

  async loadPowerReviewScript(): Promise<Script> {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.powerReviewScript.src;
      script.onload = () => {
        resolve({ loaded: true, name: this.powerReviewScript.name });
      };

      script.onerror = error =>
        resolve({ loaded: false, name: this.powerReviewScript.name });

      document.getElementsByTagName('body')[0].appendChild(script);
    });
  }

  getBaseRenderOptions(): PwrRenderOptions {
    const {
      apiKey,
      locale,
      merchantGroupId,
      merchantId,
      reviewWrapperUrl,
      styleSheet,
    } = environment?.powerReview;

    return {
      api_key: apiKey,
      locale,
      merchant_group_id: merchantGroupId,
      merchant_id: merchantId,
      review_wrapper_url: reviewWrapperUrl,
      style_sheet: styleSheet,
    };
  }

  renderComponents(options: PwrRenderOptions): void {
    const pwr = window['pwr'];
    if (!pwr) {
      console.error(
        'Cannot render power reviews. Power Reviews function is not available!',
      );
      return;
    }

    // call the pwr render function
    pwr('render', options);
  }

  buildFeedlessProduct(product: ProductDetailViewModel): FeedlessProduct {
    const feedlessProductDefaultImageUrl =
      'https://nonprodb2ccustomui.blob.core.windows.net/src/assets/logo.svg';

    return {
      name: product?.summary?.productDescTxtl ?? `${product?.productNumber}`,
      url: this.getFeedlessProductUrl(product?.productNumber),
      image_url:
        getFirstImageURL(product?.summary, ImageVariantEnum.Thumbnail) ??
        feedlessProductDefaultImageUrl,
      description: product?.detail?.additionalDescription ?? 'n/a',
      category_name: product?.summary?.categoryDescription ?? 'default',
      brand_name:
        product?.summary?.brand ?? product?.detail?.manufacturerName ?? 'n/a',
    };
  }

  getFeedlessProductUrl(
    productNumber: number,
    location = window.location,
  ): string {
    const origin =
      location.protocol === 'https:'
        ? location.origin
        : 'https://ecomr4-sit.ama-nonprod.usfoods.com';
    return `${origin}/product/${productNumber}`;
  }

  loadPowerReviewsProduct(
    pageIds: string,
  ): Observable<Map<string, PowerReviewRollupModel>> {
    return this.productStateService.loadPowerReviewsProduct$(pageIds);
  }

  updatePowerReviewsProduct(
    pageIds: string,
  ): Observable<Map<string, PowerReviewRollupModel>> {
    return this.productStateService.updatePowerReviewsProduct$(pageIds);
  }
  loadPowerReviewsProductFromStore(): Observable<
    Map<string, PowerReviewRollupModel>
  > {
    return this.productStateService.loadPowerReviewsProductFromStore$();
  }
}
