<!-- Product Card Info -->
<div
  class="usf-product-card-info"
  [class.split-card]="hasAlternative"
  [class.is-og-and-has-alt]="hasAlternative && isOriginalProduct"
  [class.og-promos-split-card]="
    hasAlternative && isOriginalProduct && product?.promotionDetails
  "
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'tablet'
      : 'mobile'
  "
>
  <div class="usf-product-card-column">
    <!-- Product Card Warning Message -->
    <div
      *ngIf="
        product?.productCardWarningMsg?.display &&
        isOriginalProduct &&
        hasAlternative
      "
      data-cy="product-card-warning-message-section"
      class="usf-product-card-warning-msg"
    >
      <p
        [attr.data-cy]="
          'product-warning-message-' + product?.productNumber + '-text'
        "
      >
        <ion-icon
          [attr.data-cy]="
            'product-warning-message-' + product?.productNumber + '-icon'
          "
          name="close-circle-outline"
        ></ion-icon>
        {{ product?.productCardWarningMsg?.primaryText | translate }}
        <span
          [attr.data-cy]="
            'product-warning-message-' + product?.productNumber + '-subtext1'
          "
        >
          {{ product?.productCardWarningMsg?.secondaryText | translate }}
        </span>
        <span
          [attr.data-cy]="
            'product-warning-message-' + product?.productNumber + '-subtext2'
          "
        >
          {{ product?.productCardWarningMsg?.secondaryTextDate | translate }}
        </span>
        <span
          [attr.data-cy]="
            'product-warning-message-' + product?.productNumber + '-boldtext1'
          "
        >
          <b> {{ product?.productCardWarningMsg?.boldedText | translate }}</b>
        </span>
      </p>
    </div>

    <!-- Product Card Resolution Message -->
    <div
      *ngIf="productCardResolutionMsg && !isOriginalProduct && !isGuestUser"
      data-cy="product-card-resolution-message-section"
      class="usf-product-card-resolution-msg"
    >
      <p
        [attr.data-cy]="
          'product-resolution-message-' + product?.productNumber + '-text'
        "
        [class.extended-message]="!!productCardResolutionMsg?.secondaryText"
      >
        <ion-icon
          [attr.data-cy]="
            'product-resolution-message-' + product?.productNumber + '-icon'
          "
          name="sync-outline"
        ></ion-icon>
        <span>
          {{ productCardResolutionMsg?.primaryText | translate }}
          <span
            *ngIf="!!productCardResolutionMsg?.secondaryText"
            class="secondary-text"
          >
            <span class="bold">
              {{ productCardResolutionMsg?.secondaryText | translate }}
            </span>
            <span *ngIf="!!productCardResolutionMsg?.tertiaryText">
              {{ productCardResolutionMsg?.tertiaryText | translate }}
            </span>
            <span
              *ngIf="!!productCardResolutionMsg?.tertiaryTextDate"
              [ngStyle]="productCardResolutionMsg?.tertiaryTextDateStyle"
            >
              {{ productCardResolutionMsg?.tertiaryTextDate | translate }}
            </span>
          </span>
        </span>
      </p>
    </div>

    <!-- Product Card Info -->
    <div class="usf-product-card-row">
      <!-- Product Card Thumbnail -->
      <div class="image-content">
        <div
          *ngIf="(isOriginalProduct && !hasAlternative) || !isOriginalProduct"
          class="usf-product-card-img"
          (click)="clickable ? productImageClick() : undefined"
        >
          <img
            *ngIf="
              (panAppState.online$ | async) === true &&
              !!product?.imageThumbnail
            "
            [attr.data-cy]="'product-' + product?.productNumber + '-image'"
            src="{{ product?.imageThumbnail }}"
            (error)="
              product.imageThumbnail = getAternativeThumbnail(
                product?.imageThumbnail
              )
            "
          />
          <ion-icon
            *ngIf="
              (panAppState.online$ | async) === false ||
              !product?.imageThumbnail
            "
            data-cy="product-card-no-image-icon"
            class="no-image"
            name="restaurant"
          ></ion-icon>
        </div>
        <app-power-custom-review-rating-stars
          *ngIf="
            mode === productCardModes.defaultSearch &&
            powerReviewRollupModel &&
            isOriginalProduct &&
            !hasAlternative
          "
          [powerReviewRollupModel]="powerReviewRollupModel"
          [ratingStarsCSSEnum]="ratingStarsCSSEnum.ORIGINAL_PRODUCT"
          [ratingAverageCSSEnum]="ratingAverageCSSEnum.ORIGINAL_PRODUCT"
        ></app-power-custom-review-rating-stars>
        <app-power-custom-review-rating-stars
          *ngIf="
            mode === productCardModes.defaultSearch &&
            alternatePowerReviewRollupModel &&
            !isOriginalProduct &&
            hasAlternative
          "
          [powerReviewRollupModel]="alternatePowerReviewRollupModel"
          [ratingStarsCSSEnum]="ratingStarsCSSEnum.ALTERNATIVE_PRODUCT"
          [ratingAverageCSSEnum]="ratingAverageCSSEnum.ALTERNATIVE_PRODUCT"
        ></app-power-custom-review-rating-stars>
      </div>
      <!-- Product Card Brand, Description, Number, Customer Product Number/GL Number, Sales Pack Price, CS/EA Pricing Info -->
      <div
        class="usf-product-card-text"
        [class.not-split]="!hasAlternative"
        [class.has-cust-prod-number]="product?.customerProductNumber"
      >
        <div
          *ngIf="
            !product?.notFound &&
            (!isOriginalProduct ||
              (isOriginalProduct && !product?.hasAlternative))
          "
          [attr.data-cy]="'product-brand-' + product?.productNumber + '-text'"
          class="usf-product-card-brand-text"
        >
          {{ product?.summary?.brand || ' ' | acronymTitleCase }}
        </div>
        <div
          *ngIf="!product?.notFound"
          data-cy="product-description-text"
          class="usf-product-card-description-text"
          (click)="clickable && productClick()"
        >
          {{ product?.summary?.productDescTxtl || ' ' | acronymTitleCase }}
        </div>
        <app-power-custom-review-rating-stars
          *ngIf="
            mode === productCardModes.defaultSearch &&
            powerReviewRollupModel &&
            isOriginalProduct &&
            hasAlternative
          "
          [powerReviewRollupModel]="powerReviewRollupModel"
          [ratingStarsCSSEnum]="ratingStarsCSSEnum.ORIGINAL_ALTERNATIVE_PRODUCT"
          [ratingAverageCSSEnum]="
            ratingAverageCSSEnum.ORIGINAL_ALTERNATIVE_PRODUCT
          "
        ></app-power-custom-review-rating-stars>
        <div class="usf-product-card-bottom-text">
          <div
            *ngIf="product?.customerProductNumber; else prodNumOnly"
            data-cy="product-card-numbers-dropdown"
            class="usf-product-card-prod-numbers-section"
            [class.is-gl-customer]="product?.isGLCustomer"
            (click)="setProductCardNumSectionExpanded()"
          >
            <div
              data-cy="product-card-numbers-dropdown-top-section"
              class="product-number-top"
              [class.minimized]="!isProductCardNumSectionExpanded"
              (click)="setSelectedNumberToShow($event)"
            >
              <div data-cy="product-card-numbers-dropdown-text-section">
                <p [attr.data-cy]="'product-' + numToShowTrimmed + '-text'">
                  {{ numToShow }}
                </p>
              </div>
              <ion-icon
                *ngIf="!isProductCardNumSectionExpanded"
                [attr.data-cy]="
                  'product-card-gl-cust-prod-number-' + numToShow + '-icon'
                "
                name="caret-down-outline"
              ></ion-icon>
            </div>
            <div
              *ngIf="isProductCardNumSectionExpanded"
              data-cy="product-card-numbers-dropdown-bottom-section"
              class="product-number-bottom"
              (click)="setSelectedNumberToShow($event)"
            >
              <p [attr.data-cy]="'product-' + numToHideTrimmed + '-text'">
                {{ numToHide }}
              </p>
            </div>
          </div>
          <ng-template #prodNumOnly>
            <p
              class="usf-product-card-num-text"
              [attr.data-cy]="
                'product-number-' + product?.productNumber + '-text'
              "
              [class.not-found]="product?.notFound"
            >
              #{{ product?.productNumber || ' ' }}
            </p>
          </ng-template>
          <img
            *ngIf="!product?.notFound"
            data-cy="product-card-separator-image"
            src="assets/images/separator.png"
          />
          <p
            [attr.data-cy]="
              'product-packsize-' + product?.productNumber + '-text'
            "
            data-cy="product-card-sales-pack-price-text"
          >
            {{ product?.summary?.salesPackSize }}
          </p>
          <ng-container
            *appShowSplit="[split_global_product_price_per_portion_card_flag]"
          >
            <p
              *ngIf="product?.pricePerPortion"
              [attr.data-cy]="'product-' + product?.pricePerPortion + '-text'"
            >
              &nbsp;({{ product?.pricePerPortion }})
            </p>
          </ng-container>
          <ng-container
            *ngIf="isOriginalProduct && hasAlternative && !isGuestUser"
          >
            <img
              data-cy="product-card-separator-image"
              src="assets/images/separator.png"
            />
            <p
              data-cy="product-card-pricing-text"
              class="usf-product-card-pring-text"
            >
              <app-price-display
                [disabled]="pricingDisabled"
                [price]="product?.pricing"
                [displayCases]="true"
                [catchWeightFlag]="product?.summary?.catchWeightFlag"
                [priceUom]="product?.summary?.priceUom"
              ></app-price-display>
              <app-price-display
                *ngIf="product?.summary?.breakable"
                [disabled]="pricingDisabled"
                [price]="product?.pricing"
                [catchWeightFlag]="product?.summary?.catchWeightFlag"
                [displayCases]="false"
                [eachUom]="
                  product?.summary?.catchWeightFlag
                    ? product?.summary?.priceUom
                    : product?.summary?.eachUom
                "
              >
                <span>&nbsp;/</span>
              </app-price-display>
            </p>
          </ng-container>
          <ng-container
            *ngIf="
              platformService?.platformType ===
                platformService?.platformEnum?.tablet &&
              isOriginalProduct &&
              showNotes
            "
            [ngTemplateOutlet]="note"
          >
            <img
              *ngIf="!product?.notFound"
              data-cy="product-card-separator-image"
              src="assets/images/separator.png"
            />
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Note, Promotions, Pricing (Mobile) -->
    <ng-container
      *ngIf="
        platformService?.platformType === platformService?.platformEnum?.mobile
      "
    >
      <ng-container
        *ngIf="isOriginalProduct && showNotes"
        [ngTemplateOutlet]="note"
      ></ng-container>
      <ng-container [ngTemplateOutlet]="promotions"></ng-container>
      <ng-container
        *ngIf="
          !isGuestUser &&
          ((isOriginalProduct && !product?.hasAlternative) ||
            (isOriginalProduct && mode !== productCardModes.listManagement) ||
            !isOriginalProduct)
        "
        [ngTemplateOutlet]="pricing"
      ></ng-container>
    </ng-container>

    <!-- Promotions, Pricing (Tablet - Original Product w/o Alt, Alt) -->
    <ng-container
      *ngIf="
        !isGuestUser &&
        platformService?.platformType ===
          platformService?.platformEnum?.tablet &&
        ((isOriginalProduct && !product?.hasAlternative) ||
          (isOriginalProduct && mode !== productCardModes.listManagement) ||
          !isOriginalProduct) &&
        !product?.notFound
      "
    >
      <div class="usf-product-card-pricing-promos">
        <ng-container [ngTemplateOutlet]="pricing"></ng-container>
        <ng-container
          *ngIf="
            !isGuestUser &&
            ((isOriginalProduct && !product?.hasAlternative) ||
              !isOriginalProduct)
          "
          [ngTemplateOutlet]="promotions"
        ></ng-container>
      </div>
    </ng-container>

    <div
      *ngIf="showAddTolistButton === true"
      class="add-to-list-button-container"
    >
      <ion-button
        class="usf-outline-green-button"
        (click)="openAddToListModal()"
      >
        <div class="pc-icon-button">
          <ion-icon name="add-outline"></ion-icon>
          {{ 'i18n.search.addToList' | translate }}
        </div>
      </ion-button>
    </div>
    <div
      *ngIf="
        (isOriginalProduct &&
          product?.productTags?.length > 0 &&
          !product?.hasAlternative) ||
        (!isOriginalProduct && product?.productTags?.length > 0) ||
        ((mode === productCardModes.listManagement ||
          mode === productCardModes.listDetail ||
          mode === productCardModes.productNumberSearch) &&
          isOriginalProduct)
      "
      class="tags-line-number-row"
      [class.is-split-original-tags-line-row]="
        isOriginalProduct && product?.hasAlternative
      "
      [class.mobile-not-split-tags-line-row]="
        isOriginalProduct &&
        !product?.hasAlternative &&
        platformService?.platformType === platformService?.platformEnum?.mobile
      "
    >
      <!-- Product Tags -->
      <div
        *ngIf="
          (isOriginalProduct &&
            product?.productTags?.length > 0 &&
            !product?.hasAlternative) ||
          (!isOriginalProduct && product?.productTags?.length > 0)
        "
        class="usf-product-card-tags"
      >
        <app-usf-product-tag-list
          [tagList]="product?.productTags"
          [infoIconEnabled]="false"
          [allTagsClickable]="true"
          [product]="transformProductToProductDetail()"
          [maxTags]="
            platformService?.platformType ===
            platformService?.platformEnum?.tablet
              ? mode !== productCardModes.promotions
                ? 3
                : 5
              : 2
          "
        >
        </app-usf-product-tag-list>
      </div>

      <!-- Line # -->
      <div
        *ngIf="
          (mode === productCardModes.listManagement ||
            mode === productCardModes.listDetail ||
            mode === productCardModes.productNumberSearch) &&
          isOriginalProduct
        "
        class="order-line-num"
      >
        <app-product-card-line-number
          [sequenceNumber]="sequenceNumber"
          [isListManagement]="mode === productCardModes.listManagement"
          (resequenceProductEmitter)="resequenceProduct($event)"
        >
        </app-product-card-line-number>
      </div>
    </div>
    <!-- Confirm Unusual Quantities -->
    <div *ngIf="showConfirmQuantityButton">
      <ion-button
        class="usf-fill-green-button confirm-quantity-button"
        (click)="confirmUnusualQuantity(product.productNumber)"
      >
        {{ 'i18n.orderPage.confirmQuantities' | translate }}
      </ion-button>
    </div>
    <!-- Ellipsis  -->
    <ng-container
      *ngIf="
        orderService.restrictNavigationForCurrentOrder$ | async;
        else enableEllipsisClick
      "
    >
      <div
        *ngIf="
          !isGuestUser &&
          mode !== productCardModes.tmNoteDetails &&
          mode !== productCardModes.addToInventory &&
          mode !== productCardModes.productNumberSearch &&
          (!isOriginalProduct ||
            (isOriginalProduct && !product?.hasAlternative)) &&
          mode !== productCardModes.worksWellWith &&
          mode !== productCardModes.modifyVS
        "
        data-cy="product-card-ellipsis-section"
        class="ellipsis"
        [class.disabled]="true"
      >
        <ion-icon
          data-cy="product-card-ellipsis-icon"
          name="ellipsis-vertical-circle-sharp"
        ></ion-icon>
      </div>
    </ng-container>
    <ng-template #enableEllipsisClick>
      <div
        *ngIf="
          !isGuestUser &&
          mode !== productCardModes.tmNoteDetails &&
          mode !== productCardModes.addToInventory &&
          mode !== productCardModes.productNumberSearch &&
          (!isOriginalProduct ||
            (isOriginalProduct && !product?.hasAlternative)) &&
          mode !== productCardModes.worksWellWith &&
          mode !== productCardModes.modifyVS
        "
        data-cy="product-card-ellipsis-section"
        class="ellipsis"
        [class.disabled]="product?.notFound"
        (click)="!product?.notFound ? ellipsisClick($event) : null"
      >
        <ion-icon
          data-cy="product-card-ellipsis-icon"
          name="ellipsis-vertical-circle-sharp"
        ></ion-icon>
      </div>
    </ng-template>
  </div>

  <!-- Promotions, Pricing (Tablet - Original Product w/ Alt) -->
  <div
    *ngIf="
      platformService.platformType === platformService?.platformEnum?.tablet &&
      isOriginalProduct &&
      product?.hasAlternative
    "
    class="usf-product-card-promos-column"
  >
    <ng-container [ngTemplateOutlet]="promotions"></ng-container>
  </div>
</div>
<!-- PRICING TEMPLATE -->
<ng-template #pricing>
  <div
    *ngIf="
      (!isOriginalProduct ||
        (isOriginalProduct && !product?.hasAlternative) ||
        product?.productType === productTypes.stat0OutOfStock) &&
      !product?.notFound
    "
    data-cy="product-card-pricing-section"
    class="usf-product-card-price"
    [ngClass]="{
      'list-management-price': mode === productCardModes.listManagement,
    }"
  >
    <div class="usf-product-card-case-container">
      <div
        data-cy="product-card-pricing-cases-section"
        class="usf-product-card-case"
      >
        <app-quantity-input
          *ngIf="
            !(mode === productCardModes.listManagement) &&
            !product?.hideQuantityBoxes
          "
          [productNumber]="product?.productNumber"
          [quantity]="
            useOrderObservable
              ? (orderItem$ | async)?.unitsOrdered
              : product?.casesOrdered
          "
          [isDisabled]="
            (false === (panAppState.online$ | async) &&
              false === (offlineQtyBoxEnableFlag$ | async)) ||
            product?.summary?.properties?.has(propertiesEnum.specialOrder) ||
            (isOriginalProduct && !product?.orderable) ||
            product?.notFound
          "
          [isOffline]="false === (panAppState.online$ | async)"
          [isEaches]="false"
          [platform]="this.platformService?.platformType"
          (quantityUpdateEmitter)="changeQuantityHandler($event)"
          (quantityElementEmitter)="quantityInputUpdate($event)"
          [id]="
            product?.hotkeyIds
              ? product?.hotkeyIds[0] + listId + '-' + index
              : undefined
          "
        >
        </app-quantity-input>
        <div
          data-cy="product-card-pricing-cases-text-section"
          class="price-text"
        >
          <div
            *ngIf="
              !product?.notFound &&
              !product?.summary?.properties?.has(propertiesEnum.specialOrder)
            "
            data-cy="product-card-pricing-cases-text"
            (mouseover)="handleMouseOver($event)"
            (mouseleave)="handleMouseLeave()"
          >
            <app-price-display
              [disabled]="pricingDisabled"
              [price]="product?.pricing"
              [displayCases]="true"
              [catchWeightFlag]="product?.summary?.catchWeightFlag"
              [priceUom]="product?.summary?.priceUom"
            ></app-price-display>
          </div>
          <span
            *ngIf="
              product?.summary?.properties?.has(propertiesEnum.specialOrder)
            "
            [attr.data-cy]="
              'product-special-order-message-' +
              product?.productNumber +
              '-text'
            "
          >
            {{ 'i18n.lists.specialOrderProductMessage' | translate }}
          </span>
        </div>
      </div>
      <div class="product-shipping-price-text">
        <app-product-shipping-price
          *ngIf="
            !product?.notFound &&
            !product?.summary?.properties?.has(propertiesEnum.specialOrder) &&
            product?.summary?.properties?.has(propertiesEnum.direct) &&
            (isProductShippingPriceEnabled$ | async)
          "
          [platformType]="platformService?.platformType"
          [pricing]="product?.pricing"
          [disabled]="pricingDisabled"
        ></app-product-shipping-price>
      </div>
    </div>
    <span
      *ngIf="
        mode === productCardModes.listManagement && product?.summary?.breakable
      "
      data-cy="product-card-separator-text"
    >
      &nbsp;/&nbsp;
    </span>
    <div
      *ngIf="product?.summary?.breakable && !product?.notFound"
      data-cy="product-card-pricing-eaches-section"
      class="usf-product-card-each"
    >
      <app-quantity-input
        *ngIf="
          !(mode === productCardModes.listManagement) &&
          !product?.hideQuantityBoxes
        "
        [productNumber]="product?.productNumber"
        [quantity]="
          useOrderObservable
            ? (orderItem$ | async)?.eachesOrdered
            : product?.eachesOrdered
        "
        [isDisabled]="
          (false === (panAppState.online$ | async) &&
            false === (offlineQtyBoxEnableFlag$ | async)) ||
          product?.summary?.properties?.has(propertiesEnum.specialOrder) ||
          (isOriginalProduct && !product?.orderable)
        "
        [isOffline]="false === (panAppState.online$ | async)"
        [isEaches]="true"
        [platform]="this.platformService?.platformType"
        (quantityUpdateEmitter)="changeQuantityHandler($event)"
        (quantityElementEmitter)="quantityInputUpdate($event)"
        [id]="
          product?.hotkeyIds
            ? product?.hotkeyIds[1] + listId + '-' + index
            : undefined
        "
      >
      </app-quantity-input>

      <div
        data-cy="product-card-pricing-eaches-text-section"
        class="price-text"
      >
        <div
          *ngIf="
            !product?.summary?.properties?.has(propertiesEnum.specialOrder)
          "
          data-cy="product-card-pricing-eaches-text"
        >
          <app-price-display
            [disabled]="pricingDisabled"
            [price]="product?.pricing"
            [catchWeightFlag]="product?.summary?.catchWeightFlag"
            [displayCases]="false"
            [eachUom]="
              product?.summary?.catchWeightFlag
                ? product?.summary?.priceUom
                : product?.summary?.eachUom
            "
          ></app-price-display>
        </div>
        <span
          *ngIf="product?.summary?.properties?.has(propertiesEnum.specialOrder)"
          [attr.data-cy]="
            'product-special-order-message-' + product?.productNumber + '-text'
          "
        >
          {{ 'i18n.lists.specialOrderProductMessage' | translate }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<!-- PROMOTIONS TEMPLATE -->
<ng-template #promotions>
  <div
    *ngIf="showPromotionsBanner()"
    data-cy="product-card-promotions-banner"
    class="usf-product-card-promotions"
    (click)="!product?.notFound ? promotionClick($event) : null"
  >
    <span
      [attr.data-cy]="
        'product-promotions-banner-' + product?.productNumber + '-text'
      "
    >
      {{ product?.promotionDetails?.offerBenefitDesc }}
    </span>
  </div>
</ng-template>

<!-- NOTE TEMPLATE -->
<ng-template #note>
  <div data-cy="product-card-note-section" class="product-note">
    <ion-icon
      data-cy="product-card-note-icon"
      src="assets/icon/notes-icon.svg"
      (click)="itemNoteClick()"
    ></ion-icon>
    <p
      (click)="itemNoteClick()"
      [attr.data-cy]="'product-note-' + product?.productNumber + '-text'"
    >
      {{
        (product.item
          ? product?.item?.note
          : product?.masterListItem?.referenceComment
        )
          | truncateWithEllipsis
            : (platformService?.platformType ===
              platformService?.platformEnum?.tablet
                ? 25
                : 30)
      }}
    </p>
  </div>
</ng-template>
