import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PlatformService, Tracking } from '@panamax/app-state';
import { SELLER_RECENT_PURCHASE_ACTIONS } from '@usf/ngrx-list';
import {
  MERCH_FEATURE,
  ProductAnalyticsService,
  ProductTrackingInfo,
} from '@usf/ngrx-product';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, first, tap } from 'rxjs/operators';
import { PdpLnkSrc } from 'src/app/shared/constants/pdp-lnksrc.enum';
import { NavigationHelperService } from 'src/app/shared/helpers/navigation.helpers.service';
import { Product } from 'src/app/shared/models/product.model';
import { QuantityUpdate } from 'src/app/shared/models/quantity-update';
import { OrderItemService } from 'src/app/shared/services/order-item.service';
import { ProductService } from '@shared/services/product/product.service';
import { PRODUCT_DETAIL, SELLER_PRODUCT_DETAIL } from '../constants/constants';
import { ProductDetailViewModel } from '../models/product-detail-view-model';
import { productDetailViewModelSelector } from '../selectors/product-detail.selector';
import { ModalWithNavComponent } from '@shared/components/modal-with-nav/modal-with-nav.component';
import { ModalController } from '@ionic/angular';
import { ModalService } from '@shared/services/modal/modal.service';
import { ProductDetailCarouselComponent } from '@product-detail/components/product-detail-carousel/product-detail-carousel.component';
import Swiper from 'swiper';
import { appStateForProductsSelector } from '@shared/selectors/product.selectors';
import { AppStateForProduct } from '@shared/models/app-state-for-product';
import { PriceViewModel } from '@shared/models/price-view.model';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailService {
  loading = false;
  productSwiperRef$: BehaviorSubject<Swiper> = new BehaviorSubject(null);

  constructor(
    private store: Store,
    private productService: ProductService,
    private orderItemService: OrderItemService,
    private analyticsService: ProductAnalyticsService,
    private navigationHelperService: NavigationHelperService,
    private modalController: ModalController,
    private modalService: ModalService,
    readonly platformService: PlatformService,
  ) {}

  loadProductDetail$(productNumber: number) {
    return this.productService.loadProduct$(productNumber).pipe(
      first(dispatched => dispatched),
      tap(_ => {
        this.productService.loadProductDetail(productNumber);
        this.store.dispatch(
          SELLER_RECENT_PURCHASE_ACTIONS.loadRecentPurchase({ productNumber }),
        );
      }),
    );
  }

  getProductDetailViewModel(
    productNumber: number,
    isDebounce = true,
  ): Observable<ProductDetailViewModel> {
    return this.store
      .select(productDetailViewModelSelector(productNumber))
      .pipe(
        filter(productDetail => !!productDetail),
        // Small buffer to reduce updates due to selector changes
        debounceTime(isDebounce ? 500 : 0),
      );
  }

  getAppStateForProduct(): Observable<AppStateForProduct> {
    return this.store
      .select(appStateForProductsSelector)
      .pipe(filter(appStateForProduct => !!appStateForProduct));
  }

  trackPageLoad(
    productNumber: number,
    productAttributeList?: string,
    betterBuyProductNumber?: number,
    betterBuyProductAttributeList?: string,
    pricing?: PriceViewModel,
  ) {
    this.analyticsService.trackProductDetailPageLoad(
      productNumber,
      productAttributeList,
      betterBuyProductNumber,
      betterBuyProductAttributeList,
      pricing?.unitPrice ?? pricing?.eachPrice,
    );
  }

  trackFeatureLoad(
    productNumber: number,
    productAttributeList?: string,
    customersAlsoBought?: ProductTrackingInfo[],
    mayAlsoNeed?: ProductTrackingInfo[],
    productRating?: number,
    questionCount?: number,
    betterBuyProductNumber?: number,
    betterBuyProductAttributeList?: string,
  ) {
    this.analyticsService.trackProductDetailFeatureLoad(
      productNumber,
      productAttributeList,
      customersAlsoBought,
      mayAlsoNeed,
      productRating,
      questionCount,
      betterBuyProductNumber,
      betterBuyProductAttributeList,
    );
  }

  trackSellerDetailPageLoad(
    productNumber: number,
    productAttributeList: string,
  ) {
    this.analyticsService.trackSellerDetailPageLoad(
      productNumber,
      productAttributeList,
    );
  }

  trackSubstituteProduct(
    productInfo: ProductTrackingInfo,
    parentProductInfo: ProductTrackingInfo,
    isSellerCustomer: boolean,
  ) {
    this.analyticsService.trackSubstituteProductsInPDP(
      productInfo,
      parentProductInfo,
      isSellerCustomer,
    );
  }

  trackAndChangeQuantity(
    quantityUpdate: QuantityUpdate,
    product: ProductDetailViewModel,
    isSellerCustomer: boolean,
    averageRating: number,
    variant?: string,
  ) {
    let trackingDetail: {
      [key: string]: string | number;
    } = {
      divisionApn:
        product?.summary?.divisionNumber + '-' + quantityUpdate.productNumber,
      quantityUnits: quantityUpdate.isEaches
        ? product?.summary?.catchWeightFlag && product?.summary?.breakable
          ? product?.summary.priceUom
          : product?.summary?.eachUom
        : product?.summary?.salesUom,
      addMethod: isSellerCustomer ? SELLER_PRODUCT_DETAIL : PRODUCT_DETAIL,
      attributes: product?.trackingAttributes,
      category: `${product?.summary?.classDescription}; ${product?.summary?.classDescription} |  ${product?.summary?.categoryDescription}; ${product?.summary?.classDescription} |  ${product?.summary?.categoryDescription} | ${product?.summary?.groupDescription}`,
      name: product?.summary?.productDescTxtl,
      brand: product?.summary?.brand,
      price: quantityUpdate.isEaches
        ? product?.pricing?.eachPrice
        : product?.pricing?.unitPrice,
    };
    const products = [];

    // This is main product and no alternative
    if (
      quantityUpdate.productNumber == product?.productNumber &&
      !!product.alternative?.product
    ) {
      trackingDetail = {
        ...trackingDetail,
        orderedOriginal: '1',
        primaryProduct: 1,
      };
      trackingDetail = this.addAverageRatingToTrackingDetail(
        averageRating,
        trackingDetail,
      );
      products.push(trackingDetail);
      // This is alternative product
    } else if (
      !!product.alternative?.product &&
      quantityUpdate.productNumber ==
        product?.alternative?.product?.productNumber
    ) {
      trackingDetail = {
        divisionApn:
          product?.alternative?.product?.summary?.divisionNumber +
          '-' +
          quantityUpdate.productNumber,
        quantityUnits: quantityUpdate.isEaches
          ? product?.alternative?.product?.summary?.catchWeightFlag &&
            product?.alternative?.product?.summary?.breakable
            ? product?.alternative?.product?.summary.priceUom
            : product?.alternative?.product?.summary?.eachUom
          : product?.alternative?.product?.summary?.salesUom,
        addMethod: isSellerCustomer ? SELLER_PRODUCT_DETAIL : PRODUCT_DETAIL,
        attributes: product?.alternative?.product?.trackingAttributes,
        subIn: '1',
        substitutionParentApn:
          product?.summary?.divisionNumber + '-' + product?.productNumber,
        substituteType: !!product?.alternative?.substituteInfo
          ? product.alternative.substituteInfo.substituteType
          : product?.alternative?.type,
        substitutePriority: !!product?.alternative?.substituteInfo
          ? product.alternative.substituteInfo.substitutePriority
          : 1,
        category: `${product?.summary?.classDescription}; ${product?.summary?.classDescription} |  ${product?.summary?.categoryDescription}; ${product?.summary?.classDescription} |  ${product?.summary?.categoryDescription} | ${product?.summary?.groupDescription}`,
        name: product?.summary?.productDescTxtl,
        brand: product?.summary?.brand,
        price: quantityUpdate.isEaches
          ? product?.pricing?.eachPrice
          : product?.pricing?.unitPrice,
      };
      trackingDetail = this.addAverageRatingToTrackingDetail(
        averageRating,
        trackingDetail,
      );
      products.push(trackingDetail);
      products.push({
        divisionApn:
          product?.summary?.divisionNumber + '-' + product?.productNumber,
        attributes: product?.trackingAttributes,
        subOut: '1',
      });
    } else {
      trackingDetail = this.addAverageRatingToTrackingDetail(
        averageRating,
        trackingDetail,
      );
      if (!!variant) {
        trackingDetail['variant'] = variant;
      }
      trackingDetail = {
        ...trackingDetail,
        primaryProduct: 1,
      };
      products.push(trackingDetail);
    }

    const trackingData: Tracking = {
      analytics: {
        data: { products },
      },
    };

    this.orderItemService.changeQuantity(
      quantityUpdate.productNumber,
      quantityUpdate.quantity,
      quantityUpdate.isEaches,
      trackingData,
    );
  }

  trackRelatedProductChangeQuantity(
    quantityUpdate: QuantityUpdate,
    product: Product,
    merch: string,
    rank: number,
    tmNoteId: number = 0,
    mapType: string = '',
    variant: string = '',
  ) {
    const activePromoParams =
      MERCH_FEATURE.tmNotesName === merch
        ? { activePromo: { promoId: tmNoteId } }
        : {};
    const rankParams = rank > 0 ? { merch: { resultRank: rank } } : {};
    let trackingDetail: {
      [key: string]: string | number | object;
    } = {
      divisionApn:
        product?.summary?.divisionNumber + '-' + quantityUpdate.productNumber,
      quantityUnits: quantityUpdate.isEaches
        ? product?.summary?.catchWeightFlag && product?.summary?.breakable
          ? product?.summary.priceUom
          : product?.summary?.eachUom
        : product?.summary?.salesUom,
      addMethod: merch,
      merchFeatureName: merch,
      attributes: product?.trackingAttributes,
      quantity: quantityUpdate.quantity,
    };
    const products = [];
    if (merch === MERCH_FEATURE.youMayAlsoNeedName) {
      trackingDetail['mapType'] = mapType;
      products.push(trackingDetail);
    } else if (merch === MERCH_FEATURE.customerAlsoBoughtName && !!variant) {
      trackingDetail['variant'] = variant;
      products.push(trackingDetail);
    } else if (merch === MERCH_FEATURE.tmNotesName) {
      if (
        quantityUpdate.productNumber == product?.productNumber &&
        !!product.alternative?.product
      ) {
        trackingDetail = {
          ...trackingDetail,
          orderedOriginal: '1',
          primaryProduct: 1,
        };
        products.push(trackingDetail);
      } else if (
        !!product.alternative?.product &&
        quantityUpdate.productNumber ==
          product?.alternative?.product?.productNumber
      ) {
        trackingDetail = {
          divisionApn:
            product?.alternative?.product?.summary?.divisionNumber +
            '-' +
            quantityUpdate.productNumber,
          quantity: quantityUpdate.quantity,
          quantityUnits: quantityUpdate.isEaches
            ? product?.alternative?.product?.summary?.catchWeightFlag &&
              product?.alternative?.product?.summary?.breakable
              ? product?.alternative?.product?.summary.priceUom
              : product?.alternative?.product?.summary?.eachUom
            : product?.alternative?.product?.summary?.salesUom,
          addMethod: merch,
          merchFeatureName: merch,
          attributes: product?.alternative?.product?.trackingAttributes,
          subIn: '1',
          substitutionParentApn:
            product?.summary?.divisionNumber + '-' + product?.productNumber,
          substituteType: !!product?.alternative?.substituteInfo
            ? product.alternative.substituteInfo.substituteType
            : product?.alternative?.type,
          substitutePriority: !!product?.alternative?.substituteInfo
            ? product.alternative.substituteInfo.substitutePriority
            : 1,
        };
        const originalProductTracking = {
          divisionApn:
            product?.summary?.divisionNumber + '-' + product?.productNumber,
          attributes: product?.trackingAttributes,
          subOut: '1',
        };
        products.push(trackingDetail);
        products.push(originalProductTracking);
      } else {
        products.push(trackingDetail);
      }
    } else {
      products.push(trackingDetail);
    }

    const trackingData: Tracking = {
      analytics: {
        data: {
          products,
          ...rankParams,
          ...activePromoParams,
        },
      },
    };

    this.orderItemService.changeQuantity(
      quantityUpdate.productNumber,
      quantityUpdate.quantity,
      quantityUpdate.isEaches,
      trackingData,
    );
  }

  addAverageRatingToTrackingDetail(averageRating, trackingDetail) {
    if (averageRating > 0) {
      return { ...trackingDetail, productRating: averageRating };
    }
    return trackingDetail;
  }

  routeToPDP(
    productNumber: number,
    isQuickView: boolean,
    isTMNoteProductCard: boolean,
  ) {
    if (isQuickView || isTMNoteProductCard) {
      const lnksrc = isQuickView
        ? PdpLnkSrc.quickView.toString()
        : PdpLnkSrc.tmNotes.toString();
      this.navigationHelperService.routeToProductDetailsQueryParams(
        productNumber,
        {
          lnksrc,
        },
      );
    } else {
      this.navigationHelperService.routeToProductDetails(productNumber);
    }
  }

  async showCarouselImagesModal(
    product: ProductDetailViewModel,
    activeSlide: number = 0,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage: ProductDetailCarouselComponent,
        rootPageParams: {
          product,
          activeSlide,
        },
      },
      'modal-desktop-size-xl',
    );
    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    await modal.present();
  }

  trackSellerQuickViewPageLoad(product: Product) {
    this.analyticsService.trackSellerQuickViewPageLoad(
      product?.summary?.divisionNumber,
      product?.summary?.productNumber,
      product?.trackingAttributes,
    );
  }
}
