import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { OrderControlDataService } from '../../services/order-control-data.service';
import { ORDER_CONTROL_ACTIONS } from '@usf/ngrx-order';

@Injectable({
  providedIn: 'root',
})
export class OrderControlEffects {
  loadOrderControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ORDER_CONTROL_ACTIONS.loadOrderControl),
      exhaustMap(() =>
        this.orderControlDataService.retrieveOrderControl().pipe(
          map(orderControls =>
            ORDER_CONTROL_ACTIONS.loadOrderControlSuccess({
              orderControls,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ORDER_CONTROL_ACTIONS.loadOrderControlFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private orderControlDataService: OrderControlDataService<any>,
  ) {}
}
