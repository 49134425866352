import { Component, Input, OnInit } from '@angular/core';
import { PanAppState, PlatformEnum } from '@panamax/app-state';
import { CustomerSelectionOption } from '@app/customer/models/customer-selection-option.model';
import { Observable } from 'rxjs';
import { FEATURES } from '@shared/constants/splitio-features';

@Component({
  selector: 'app-super-user-customer-option',
  templateUrl: './super-user-customer-option.component.html',
  styleUrls: ['./super-user-customer-option.component.scss'],
})
export class SuperUserCustomerOptionComponent implements OnInit {
  readonly platformEnum = PlatformEnum;

  @Input() platform: PlatformEnum;
  @Input() customer: CustomerSelectionOption;
  @Input() customerOptionIndex: number;

  superUserFavoriteCustomerFeatureFlag$: Observable<boolean>;

  constructor(readonly panAppState: PanAppState) {}

  ngOnInit() {
    this.superUserFavoriteCustomerFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_super_user_favorite_customer.name,
    ]);
  }
}
