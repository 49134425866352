import { Observable, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailVideoPlayerService {
  pauseVideoSubject: Subject<string> = new Subject<string>();
  pauseVideo$: Observable<string> = this.pauseVideoSubject.asObservable();

  nextPausevideo(videoId: string) {
    this.pauseVideoSubject.next(videoId);
  }
}
