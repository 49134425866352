<section
  data-cy="summary-view-section"
  [ngClass]="{ 'summary-view-section': mobileSummary }"
>
  <table data-cy="summary-view-table" mat-table [dataSource]="dataSource">
    <div *ngFor="let cell of columnsConfig; index as i">
      <ng-container matColumnDef="{{ cell.cellReference }}">
        <th
          mat-header-cell
          *matHeaderCellDef
          [attr.data-cy]="'summary-view-header-cell-' + cell.title"
        >
          {{ cell.title }}
        </th>
        <td
          mat-cell
          [style.width]="100 / visibleColumns.length + '%'"
          *matCellDef="let element"
          [attr.data-cy]="'summary-view-cell-' + element[cell.cellReference]"
        >
          {{ element[cell.cellReference] }}
        </td>
      </ng-container>
    </div>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: visibleColumns"></tr>
  </table>
  <section
    *ngIf="mobileSummary"
    class="summary-list-container"
    data-cy="summary-list-container"
  >
    <div
      *ngFor="let data of dataSource; let i = index"
      [attr.data-cy]="'summary-list-' + i"
      class="summary-list"
    >
      <div class="label-section">
        <h5
          *ngFor="let header of columnsConfig"
          [attr.data-cy]="'summary-list-' + i + '-title-' + header.title"
        >
          {{ header.title }}
        </h5>
      </div>
      <div class="value-section">
        <h5
          *ngFor="let header of columnsConfig"
          [attr.data-cy]="
            'summary-list-' + i + '-value-' + data[header.cellReference]
          "
        >
          {{ data[header.cellReference] }}
        </h5>
      </div>
    </div>
  </section>
</section>
