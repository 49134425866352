import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Tracking, TrackingData } from '@panamax/app-state';
import {
  defTraceTransFunc,
  defSuccessTraceTransFunc,
  defTrackTransFunc,
  upcLinkTransformerFunc,
  inviteUserModalOpen,
  inviteUserModalSubmit,
} from './profile-analytics.transformers';
import { PROFILE_ANALYTICS_ACTIONS } from './profile-analytics.actions';
import {
  clearAppDataSuccessTrace,
  clearAppDataTrace,
  logoutAllSessionsTrace,
  logoutAllSessionsTraceSuccess,
} from './profile-analytics.trace';
import { ProfileAnalyticsMessages } from './profile-analytics.constants';
import { TEAM_CAPABILITY } from '@usf/ngrx-product';

@Injectable({
  providedIn: 'root',
})
export class ProfileAnalyticsService {
  constructor(private store: Store) {}

  trackPageLoad(): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          pageName: ProfileAnalyticsMessages.pageName,
          event: ProfileAnalyticsMessages.pageLoadedEvent,
          capability: ProfileAnalyticsMessages.capability,
        },
        transformFunc: defTrackTransFunc,
      },
    };
    this.store.dispatch(PROFILE_ANALYTICS_ACTIONS.trackPageLoad({ tracking }));
  }

  trackUPCLink(url: string): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          linkUrl: url,
          event: ProfileAnalyticsMessages.upcLink,
          capability: ProfileAnalyticsMessages.capability,
        },
        transformFunc: upcLinkTransformerFunc,
      },
    };
    this.store.dispatch(PROFILE_ANALYTICS_ACTIONS.trackUPCLink({ tracking }));
  }

  traceLogoutSession(userId: number): void {
    const tracking: Tracking = JSON.parse(
      JSON.stringify(logoutAllSessionsTrace),
    );
    tracking.tracing.data.attributes.userID = userId;
    tracking.tracing.transformFunc = defTraceTransFunc;

    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.logoutAllSessions({ tracking }),
    );
  }

  traceLogoutSessionSuccess(userId: number): void {
    const tracking: Tracking = JSON.parse(
      JSON.stringify(logoutAllSessionsTraceSuccess),
    );
    tracking.tracing.data.attributes.userID = userId;
    tracking.tracing.transformFunc = defSuccessTraceTransFunc;

    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.logoutAllSessionsSuccess({ tracking }),
    );
  }

  clearAppDataTracking(): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          pageName: ProfileAnalyticsMessages.pageName,
          event: ProfileAnalyticsMessages.clearAppDataTrackingEvent,
          capability: ProfileAnalyticsMessages.capability,
        },
        transformFunc: defTrackTransFunc,
      },
    };
    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.clearAppDataTracking({ tracking }),
    );
  }

  clearAppData(userId?: number): void {
    const tracking: Tracking = JSON.parse(JSON.stringify(clearAppDataTrace));
    tracking.tracing.data.attributes.userID = userId || undefined;
    tracking.tracing.transformFunc = defTraceTransFunc;

    this.store.dispatch(PROFILE_ANALYTICS_ACTIONS.clearAppData({ tracking }));
  }

  clearAppDataSuccess(userId?: number): void {
    const tracking: Tracking = JSON.parse(
      JSON.stringify(clearAppDataSuccessTrace),
    );
    tracking.tracing.data.attributes.userID = userId || undefined;
    tracking.tracing.transformFunc = defSuccessTraceTransFunc;

    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.clearAppDataSuccess({ tracking }),
    );
  }

  trackInviteUserModalOpen(isNew: boolean): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          modal: {
            name: isNew
              ? ProfileAnalyticsMessages.inviteNewUserModalName
              : ProfileAnalyticsMessages.inviteExistingUserModalName,
          },
          event: ProfileAnalyticsMessages.inviteUserModalEvent,
          capability: ProfileAnalyticsMessages.capability,
        },
        transformFunc: inviteUserModalOpen,
      },
    };
    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.trackInviteUserModalOpen({ tracking }),
    );
  }

  trackInviteUserModalSubmit(isNew: boolean, customerCount: number): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          event: isNew
            ? ProfileAnalyticsMessages.inviteNewUserModalName
            : ProfileAnalyticsMessages.inviteExistingUserModalName,
          capability: ProfileAnalyticsMessages.capability,
          customerCount: customerCount,
        },
        transformFunc: inviteUserModalSubmit,
      },
    };
    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.trackInviteUserModalSubmit({ tracking }),
    );
  }

  trackLanguageOverrideSelection(selectedLanguage: string): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          event: ProfileAnalyticsMessages.buttonClickEvent,
          capability: TEAM_CAPABILITY,
          button: {
            buttonName: `Language Override: ${selectedLanguage}`,
          },
        },
        transformFunc: (trackingData: TrackingData) => trackingData.data,
      },
    };
    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.trackLanguageOverrideSelection({ tracking }),
    );
  }
}
