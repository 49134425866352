import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PanAppState, PlatformService, Tracking } from '@panamax/app-state';
import {
  BetterBuysFacadeService,
  BetterBuysState,
  betterBuysLoaded,
  estimatedAllTimeSavings,
  estimatedTodaySavings,
  estimatedYearToDateSavings,
  ProductAnalyticsService,
  BetterBuysActions,
} from '@usf/ngrx-product';
import { combineLatest, Observable } from 'rxjs';
import { FEATURES } from '@shared/constants/splitio-features';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import {
  BetterBuyProductDetail,
  BetterBuysPageViewModel,
  BetterBuysPageViewModelV2,
} from '../models/better-buys.model';
import { Product } from '@shared/models/product.model';
import { ProductUpdateRequest } from '@usf/list-types';
import {
  BetterBuyDeclineEvent,
  BetterBuyProduct,
} from '@shared/models/better-buy-card.model';
import { UsfBetterBuyDeclineModalComponent } from '@shared/components/usf-better-buy-decline-modal/usf-better-buy-decline-modal.component';
import { LIST_ACTIONS } from '@usf/ngrx-list';
import { selectedCustomer } from '@app/ngrx-customer/store';
import { LoadingSpinnerService } from '@shared/services/loading-spinner/loading-spinner.service';
import {
  calculateTotalAnnualSavings,
  selectBetterBuyDetailsById,
  selectBetterBuyListItems,
  selectBetterBuysViewModelV2,
  selectBetterBuysWithDetails,
} from '../selectors/better-buys-selectors';
import { ProductService } from '@shared/services/product/product.service';
import { BetterBuysConstants } from '../constants/better-buys-constants.enum';
import { ModalService } from '@shared/services/modal/modal.service';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { SimpleActionModalComponent } from '@shared/components/simple-action-modal/simple-action-modal.component';
import { Location } from '@angular/common';
import { ProductConversionContainer } from '@shared/models/product-conversion.model';
import { ProductConversionOrigin } from '@app/lists/model/list-updates.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BetterBuysService {
  constructor(
    private panAppState: PanAppState,
    private store: Store,
    private betterBuysFacadeService: BetterBuysFacadeService,
    private productService: ProductService,
    private platformService: PlatformService,
    private loadingSpinnerService: LoadingSpinnerService,
    private modalService: ModalService,
    private modalCtrl: ModalController,
    private location: Location,
    private analyticsService: ProductAnalyticsService,
    private actionsheetController: ActionSheetController,
    private translateService: TranslateService,
  ) {}

  getBetterBuysFlag(): Observable<boolean> {
    return combineLatest([
      this.getBetterBuysFeatureFlag(),
      this.betterBuysFacadeService.allBetterBuys$(),
      this.getBetterBuysLoaded(),
    ]).pipe(
      map(([betterBuysFeatureFlag, betterBuys, betterBuysLoaded]) => {
        const betterBuysExist = betterBuys ? betterBuys.length > 0 : false;
        return betterBuysFeatureFlag && betterBuysExist && betterBuysLoaded;
      }),
    );
  }

  getBetterBuysLoaded = (): Observable<boolean> =>
    this.store.select(betterBuysLoaded);

  getBetterBuysFeatureFlag = (): Observable<boolean> =>
    this.panAppState.feature$([FEATURES.split_global_better_buys.name]);

  getBetterBuys = (): Observable<BetterBuysState[]> =>
    this.betterBuysFacadeService.allBetterBuys$();

  // TODO Remove
  getBetterBuysWithDetails = (
    productNumbers: number[],
  ): Observable<BetterBuysPageViewModel> =>
    this.store.select(selectBetterBuysWithDetails(productNumbers));

  selectBetterBuysViewModel$ = (
    productNumbers: number[],
  ): Observable<BetterBuysPageViewModelV2> =>
    this.store.select(selectBetterBuysViewModelV2(productNumbers));

  totalAnnualSavings$ = (): Observable<number> => {
    return this.store.select(calculateTotalAnnualSavings);
  };

  estimatedAllTimeSavings$ = (): Observable<number> => {
    return this.store.select(estimatedAllTimeSavings);
  };

  estimatedTodaySavings$ = (): Observable<number> => {
    return this.store.select(estimatedTodaySavings);
  };

  estimatedYearToDateSavings$ = (): Observable<number> => {
    return this.store.select(estimatedYearToDateSavings);
  };

  getBetterBuyById(
    betterBuyProductId: number,
  ): Observable<BetterBuyProductDetail> {
    return this.store.select(selectBetterBuyDetailsById(betterBuyProductId));
  }

  loadBetterBuyDetails(originalProductId: number) {
    const betterBuysAreLoaded$ = this.getBetterBuysLoaded();

    this.betterBuysFacadeService
      .betterBuyById$(originalProductId)
      .pipe(
        withLatestFrom(betterBuysAreLoaded$),
        filter(([betterBuy, betterBuysAreLoaded]) => betterBuysAreLoaded),
        take(1),
      )
      .subscribe(([betterBuy, loaded]) => {
        if (betterBuy) {
          this.productService.loadProductDetail(
            betterBuy.betterBuyProductNumber,
          );
        }
      });
  }

  declineBetterBuy = (
    originalProduct: Product,
    betterBuyProduct: BetterBuyProduct,
    code: number,
    declineCodeText?: string,
  ) => {
    combineLatest([this.store.select(selectedCustomer), this.panAppState.user$])
      .pipe(take(1))
      .subscribe(([customer, user]) => {
        const betterBuyItems: ProductUpdateRequest[] = [];
        let item = {
          declined: 'Y',
          decRsnCd: Number(code ?? 8),
          updtUserId: user.userId,
          custNbr: Number(customer.customerNumber),
          divNbr: Number(customer.divisionNumber),
          oldProdNbr: Number(originalProduct.productNumber),
          newProdNbr: Number(betterBuyProduct.product.productNumber),
          oldProdCasePrc: Number(originalProduct?.pricing?.unitPrice),
          newProdCasePrc: Number(betterBuyProduct.product?.pricing?.unitPrice),
          oldProdEachPrc: Number(originalProduct?.pricing?.eachPrice),
          newProdEachPrc: Number(betterBuyProduct.product?.pricing?.eachPrice),
          savings: Number(betterBuyProduct.savings ?? 0),
          anulSavings: Number(betterBuyProduct.annualSavings ?? 0),
          savingsUom: betterBuyProduct.savingsUom ?? '',
          anulSavingsUom: betterBuyProduct.annualSavingsUom ?? '',
        };

        betterBuyItems.push(item);

        this.dispatchDeclineBetterBuy(betterBuyItems);

        this.analyticsService.trackBetterBuysReject({
          originalProduct: originalProduct,
          newProduct: betterBuyProduct.product,
          capability: BetterBuysConstants.listsCapability,
          originalProductAttributes: originalProduct?.trackingAttributes,
          newProductAttributes: betterBuyProduct?.product?.trackingAttributes,
          rejectedReasonCode: code,
          rejectedReasonText: declineCodeText,
        });
      });
  };

  private dispatchDeclineBetterBuy = (
    betterBuyItems: ProductUpdateRequest[],
  ) => {
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(LIST_ACTIONS.betterBuyDecline({ betterBuyItems }));
    });
  };

  async openDeclineModal(betterBuyDeclineEvent: BetterBuyDeclineEvent) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      UsfBetterBuyDeclineModalComponent,
      {
        platform: this.platformService.platformType,
        betterBuy: betterBuyDeclineEvent.betterBuy,
        oldProduct: betterBuyDeclineEvent.original,
      },

      'modal-desktop-size-xl',
    );
    const modal = await this.modalCtrl.create({
      ...this.modalService.modalOptions,
      mode: 'md',
    });
    return await modal.present();
  }
  async openAcceptSwapModal(
    product: Product,
    goBack: boolean = false,
    capability?: string,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      SimpleActionModalComponent,
      {
        title:
          'Accepting this swap will replace your current product across all lists. Continue?',
        leftButtonText: 'Cancel',
        rightButtonText: 'Continue',
      },
      'simple-action-modal',
    );

    const modal = await this.modalCtrl.create(this.modalService.modalOptions);

    await modal.present();
    modal.onDidDismiss().then(value => {
      const shouldAcceptSwap = value?.data?.dispatchAction;
      if (shouldAcceptSwap) {
        this.acceptSwap(product, capability);
        if (goBack) {
          this.location.back();
        }
      }
    });
  }

  openEllipsis = async (
    productConversionContainers: ProductConversionContainer[],
  ) => {
    const buttons = [
      {
        text: this.translateService.instant('i18n.common.download'),
        handler: () => {
          this.downloadBetterBuys(productConversionContainers);
        },
      },
      {
        text: this.translateService.instant('i18n.common.print'),
        handler: () => {
          this.printBetterBuys(productConversionContainers);
        },
      },
      {
        text: this.translateService.instant('i18n.common.cancel'),
        role: 'cancel',
        cssClass: 'cancel-button',
      },
    ];
    const actionSheet = await this.actionsheetController.create({
      cssClass: 'action-sheet-touch',
      mode: 'ios',
      buttons,
    });
    await actionSheet.present();
  };

  acceptSwap(product: Product, capability?: string) {
    const betterBuyListItems$ = this.store.select(
      selectBetterBuyListItems(product?.productNumber),
    );
    combineLatest([
      betterBuyListItems$,
      this.panAppState.user$,
      this.panAppState.customer$,
    ])
      .pipe(take(1))
      .subscribe(([listItems, user, customer]) => {
        const updateProductRequest: ProductUpdateRequest = {
          declined: BetterBuysConstants.accepted,
          updtUserId: user.userId,
          oldProdCasePrc: Number(product?.pricing?.unitPrice),
          newProdCasePrc: Number(
            product?.betterBuy?.product?.pricing?.unitPrice,
          ),
          oldProdEachPrc: Number(product?.pricing?.eachPrice),
          newProdEachPrc: Number(
            product?.betterBuy?.product?.pricing?.eachPrice,
          ),
          savings: Number(product?.betterBuy?.savings ?? 0),
          anulSavings: Number(product?.betterBuy?.annualSavings ?? 0),
          savingsUom: product?.betterBuy?.savingsUom ?? '',
          anulSavingsUom: product?.betterBuy?.annualSavingsUom ?? '',
          custNbr: Number(customer?.customerNumber),
          divNbr: Number(customer?.divisionNumber),
          oldProdNbr: Number(product?.productNumber),
          newProdNbr: Number(product?.betterBuy?.product?.productNumber),
        } as ProductUpdateRequest;
        this.loadingSpinnerService.createSpinnerModal().then(() => {
          if (capability) {
            this.analyticsService.trackBetterBuysAccept(
              this.productsForAnalyticsSwap(product, capability),
            );
          }
          this.store.dispatch(
            LIST_ACTIONS.betterBuyAccept({
              betterBuyItems: [updateProductRequest],
              listItems,
            }),
          );
        });
      });
  }

  trackBetterBuysPageLoad() {
    this.analyticsService.trackBetterBuysPageLoad();
  }

  createProductsForTracking(
    betterBuyDetails: ProductConversionContainer,
  ): Tracking {
    return {
      analytics: {
        data: {
          products: [
            {
              substitutionParentApn: `${betterBuyDetails?.originalProduct?.summary?.divisionNumber}-${betterBuyDetails?.originalProduct.productNumber}`,
              addMethod: BetterBuysConstants.betterBuys,
              merchFeatureName: BetterBuysConstants.betterBuys,
              bbSwapIn: 1,
            },
            {
              divisionApn: `${betterBuyDetails?.originalProduct?.summary?.divisionNumber}-${betterBuyDetails?.originalProduct.productNumber}`,
              attributes: betterBuyDetails?.originalProduct?.trackingAttributes,
              bbSwapOut: 1,
            },
          ],
        },
      },
    };
  }

  private productsForAnalyticsSwap(product: Product, capability: string): any {
    return {
      originalProduct: product,
      newProduct: product.betterBuy?.product,
      capability: capability,
      originalProductAttributes: product?.trackingAttributes,
      newProductAttributes: product?.betterBuy?.product?.trackingAttributes,
    };
  }

  downloadBetterBuys = (
    productConversionContainers: ProductConversionContainer[],
  ) => {
    const products = productConversionContainers.map(product => {
      return {
        originalProduct: product.originalProduct.productNumber,
        replacementProduct: product.replacementProduct.productNumber,
      };
    });
    this.store.dispatch(
      BetterBuysActions.downloadProductConversion({
        products,
        fileType: 'CSV',
        origin: ProductConversionOrigin.betterBuy,
      }),
    );
  };

  printBetterBuys = (
    productConversionContainers: ProductConversionContainer[],
  ) => {
    const products = productConversionContainers.map(product => {
      return {
        originalProduct: product.originalProduct.productNumber,
        replacementProduct: product.replacementProduct.productNumber,
      };
    });
    this.store.dispatch(
      BetterBuysActions.downloadProductConversion({
        products,
        fileType: 'PDF',
        origin: ProductConversionOrigin.betterBuy,
      }),
    );
  };
}
