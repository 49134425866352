import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginLog } from '../models/login-log';

@Injectable({
  providedIn: 'root',
})
export class ServiceHandlerService {
  constructor(private http: HttpClient) {}

  clickStream(payload: LoginLog): Observable<any> {
    return of({ status: '200', message: 'OK' });
  }

  /**
   * @param url relative path of the resource
   * @param params optional additional HttpParams
   */
  get<T>(
    url: string,
    params?: HttpParams,
    headers?: HttpHeaders,
  ): Observable<T> {
    return this.http
      .get<T>(url, {
        params,
        headers,
      })
      .pipe(catchError(this.handleError));
  }

  /**
   * @param url relative path of the resource
   * @param body the body of the put call
   * @param params optional additional HttpParams
   */
  put<T>(
    url: string,
    body?: any,
    params?: HttpParams,
    responseType: 'json' | 'text' = 'json',
  ): Observable<T> {
    return this.http
      .put<T>(url, body || {}, {
        params,
        responseType: responseType as 'json',
      })
      .pipe(catchError(this.handleError));
  }

  /**
   * @param url relative path of the resource
   * @param body the body of the post call
   * @param params optional additional HttpParams
   */
  post<T>(
    url: string,
    body?: any,
    params?: HttpParams,
    headers?: HttpHeaders | { [header: string]: string | string[] },
  ): Observable<T> {
    return this.http
      .post<T>(url, body || {}, {
        headers,
        params,
      })
      .pipe(catchError(this.handleError));
  }

  /**
   * @param url relative path of the resource
   * @param body the body of the delete call
   */
  delete<T>(url: string, body?: any): Observable<T> {
    return this.http
      .request<T>('delete', url, { body })
      .pipe(catchError(this.handleError));
  }

  /**
   * @param error - error response received from the api request
   * @returns empty Observable
   */
  private handleError(error: Response | any) {
    return throwError(() => error);
  }
}
