import { Injectable, OnDestroy } from '@angular/core';
import {
  Appcues,
  AppcuesPlugin,
  DidHandleURLResponse,
} from '@appcues/capacitor';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  combineLatestWith,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  mergeMap,
  Observable,
  of,
  retry,
  Subscription,
  switchMap,
  take,
  tap,
  timeout,
} from 'rxjs';
import { Script } from '../models/script.model';
import { Customer } from '@usf/customer-types';
import { PanAppState, PlatformService, UserState } from '@panamax/app-state';
import { SiteCustomizationProfile } from '@usf/user-types/site-customization';
import { FEATURES } from '../constants/splitio-features';
import { CustomerStoreService } from '../../ngrx-customer/services';
import { UserService } from '../../user/services';
import { ProfileService } from '../../profile/services/profile.service';

export type AppcuesStatus = 'UNAVAILABLE' | 'INITIALIZING' | 'READY' | 'FAILED';

interface ActiveUserSession {
  userName: string;
  ecomUserId: number;
  userType: string;
  selectedCustomerId: number;
  division: number;
  department: number;
  startTime: string;
  isGuest: boolean;
}

interface IdentifyOptionsProperties {
  userName: string;
  isTMUser: string;
  customerNumber: string;
  divisionNumber: string;
  customerType: string;
  siteCustomization: string;
  userKind: string;
  surveyUrl: string;
}

interface IdentifyOptions {
  userId: string;
  properties: IdentifyOptionsProperties;
}

interface IdentifyOptionsNative {
  userId: string;
  userName: string;
  isTMUser: string;
  customerNumber: string;
  divisionNumber: string;
  customerType: string;
  siteCustomization: string;
  userKind: string;
  surveyUrl: string;
  properties: IdentifyOptionsProperties;
}

interface IdentifySourceData {
  activeUserSession?: ActiveUserSession;
  customer?: Customer;
  userState?: UserState;
  profileName?: string;
  surveyLink?: string;
}

interface AppcuesWeb extends AppcuesPlugin {
  page(): void;
}

/**
 * Service to manage Appcues integration for both web and native environments.
 * Provides methods to initialize, identify users, track pages/screens, and handle URLs.
 */
@Injectable({
  providedIn: 'root',
})
export class AppcuesService implements OnDestroy {
  private static SCRIPT_TAG_NAME_ATTR = 'appcuesTag';
  private readonly _initialized$: Observable<AppcuesStatus>;
  private readonly _initializedSubject: BehaviorSubject<AppcuesStatus> =
    new BehaviorSubject<AppcuesStatus>('UNAVAILABLE');
  private _appcuesInstance!: AppcuesPlugin | AppcuesWeb;
  private _isPlatformTouch: boolean;

  private _appcuesSubscription = new Subscription();
  private _activeCustomer$!: Observable<Customer>;
  private _userCustomizations$!: Observable<SiteCustomizationProfile>;

  constructor(
    private readonly _customerStoreService: CustomerStoreService,
    private readonly _userService: UserService,
    private readonly _panAppState: PanAppState,
    private readonly _platformService: PlatformService,
    private readonly _profileService: ProfileService,
  ) {
    this._initialized$ = this._initializedSubject.asObservable();
    // initialize customer observable
    this._activeCustomer$ = this._customerStoreService.loadSelectedCustomer$();
    // get a single reference to customizations observable
    this._userCustomizations$ = this._userService.userCustomizations$();
    // should only need to do this once
    firstValueFrom(this._platformService.isTouch).then(isTouch => {
      this._isPlatformTouch = isTouch;
    });
    // initialize when an identify() call is needed
    this._initializeIdentifyFlow();
  }

  ngOnDestroy(): void {
    if (this._appcuesSubscription) {
      this._appcuesSubscription.unsubscribe();
    }
  }

  /**
   * Initializes Appcues for a web environment by appending a script tag.
   * @param window The browser Window object
   * @param srcLocation URL of the Appcues script to load
   */
  public initializeWeb(window: Window, srcLocation: string) {
    if (this._isInitializable()) {
      this._initializedSubject.next('INITIALIZING');
      this._appendToDocument(window.document, srcLocation).then(
        (script: Script) => {
          if (script.loaded) {
            // Safely access the Appcues instance from window
            const appcuesInstance = window['Appcues'] as AppcuesWeb;
            if (appcuesInstance) {
              this._appcuesInstance = appcuesInstance;
              this._initializedSubject.next('READY');
            } else {
              console.error('Appcues script loaded but instance not found');
              this._initializedSubject.next('FAILED');
            }
          } else {
            this._initializedSubject.next('FAILED');
          }
        },
      );
    }
  }

  /**
   * Initializes Appcues for a native environment.
   * @param accountId The Appcues account ID
   * @param applicationId The Appcues application ID
   * @param loggingEnabled Whether to enable logging in the Appcues config
   */
  public initializeNative(
    accountId: string,
    applicationId: string,
    loggingEnabled: boolean,
  ) {
    if (this._isInitializable()) {
      this._initializedSubject.next('INITIALIZING');
      this._getAppcuesPlugin()
        .initialize({
          accountId,
          applicationId,
          config: { logging: loggingEnabled },
        })
        .then(() => {
          this._appcuesInstance = Appcues;
          this._initializedSubject.next('READY');
        })
        .catch(error => {
          this._initializedSubject.next('FAILED');
          throw error;
        });
    }
  }

  /**
   * Gets the current status of the Appcues instance.
   * @returns The current Appcues status
   */
  get currentStatus(): AppcuesStatus {
    return this._initializedSubject.getValue();
  }

  /**
   * Gets an observable of the Appcues status.
   * @returns Observable that emits the Appcues status whenever it changes
   */
  public statusAsObservable(): Observable<AppcuesStatus> {
    return this._initialized$;
  }

  /**
   * Checks if Appcues can handle a specific URL.
   * @param url The URL to check
   * @returns Promise that resolves to true if Appcues handled the URL, false otherwise
   */
  public didHandleUrl(url: string): Promise<boolean> {
    return this.currentStatus === 'READY'
      ? this._appcuesInstance
          .didHandleURL({ url })
          .then((response: DidHandleURLResponse) => response.handled)
          .finally(() => {
            console.log('Appcues didHandleUrl() called');
          })
      : Promise.resolve(false);
  }

  /**
   * Identifies the current user to Appcues.
   * @param properties The user identification properties
   * @returns Promise that resolves when identification is complete
   */
  public identify(
    properties: IdentifyOptions | IdentifyOptionsNative,
  ): Promise<void> {
    return this.currentStatus === 'READY'
      ? this._appcuesInstance.identify(properties)
      : Promise.resolve();
  }

  /**
   * Tracks a page view in the web environment.
   * Only works when using Appcues in a web context.
   */
  public page(): void {
    if (
      this.currentStatus === 'READY' &&
      this._isWebProxy(this._appcuesInstance)
    ) {
      console.log('Appcues page() tracking called');
      return this._appcuesInstance.page();
    }
  }

  /**
   * Resets the Appcues instance.
   * @returns Promise that resolves when reset is complete
   */
  public reset(): Promise<void> {
    return this.currentStatus === 'READY'
      ? this._appcuesInstance.reset()
      : Promise.resolve();
  }

  /**
   * Tracks a screen view in the native environment.
   * @param screenTitle The title of the screen
   * @returns Promise that resolves when screen tracking is complete
   */
  public screen(screenTitle: string): Promise<void> {
    return this.currentStatus === 'READY' &&
      this._isNativePlugin(this._appcuesInstance)
      ? this._appcuesInstance
          .screen({
            title: screenTitle,
          })
          .finally(() => {
            console.log('Appcues screen() tracking called');
          })
      : Promise.resolve();
  }

  /**
   * Notifies Appcues of a screen/page change.
   * Calls both page() for web and screen() for native platforms.
   * @param url The URL or screen identifier to track
   */
  public notifyAppcues(url: string) {
    try {
      this.page();
      this.screen(url);
    } catch (error) {
      console.error('Error handling Appcues screen:', error);
    }
  }

  /** Checks if Appcues can be initialized (only if currently unavailable or failed) */
  private _isInitializable() {
    const status: AppcuesStatus = this._initializedSubject.getValue();
    return status === 'UNAVAILABLE' || status === 'FAILED';
  }

  /** Protected function to return Appcues static import to help with testing */
  protected _getAppcuesPlugin() {
    return Appcues;
  }

  /**
   * Initializes the identify flow to track and identify users in Appcues.
   * Sets up subscription to monitor changes in user session, customer, and user state.
   */
  private _initializeIdentifyFlow() {
    this._appcuesSubscription = this._initialized$
      .pipe(
        distinctUntilChanged(),
        filter((status: AppcuesStatus) => status !== 'READY'),
        switchMap(() => this._getIdentityDataStream()),
        distinctUntilChanged((previous, current) =>
          this._isSameIdentity(previous, current),
        ),
        map((identifySource: IdentifySourceData) =>
          this._createIdentifyOptions(identifySource),
        ),
      )
      .subscribe((identifyOptions: IdentifyOptions | IdentifyOptionsNative) => {
        this.appcuesIdentify(identifyOptions);
      });
  }

  /**
   * Creates an observable stream of user identity data for Appcues.
   * Combines session, customer, user state, profile name, and survey link data.
   * @returns Observable of IdentifySourceData containing all information needed for Appcues identification
   */
  private _getIdentityDataStream(): Observable<IdentifySourceData> {
    return this._panAppState.session$.pipe(
      distinctUntilChanged(),
      tap(() => this.reset()),
      filter((session: ActiveUserSession) => !session.isGuest),
      combineLatestWith(
        this._activeCustomer$.pipe(filter(customer => !!customer)),
        this._panAppState.user$.pipe(filter(user => !!user)),
      ),
      map(([activeUserSession, customer, userState]) => ({
        activeUserSession,
        customer,
        userState,
      })),
      distinctUntilChanged((previous, current) =>
        this._isSameIdentity(previous, current),
      ),
      switchMap((identifySource: IdentifySourceData) =>
        combineLatest([
          of(identifySource.activeUserSession),
          of(identifySource.customer),
          of(identifySource.userState),
          this._getProfileName(),
          this._getSurveyLink(),
        ]),
      ),
      map(
        ([
          activeUserSession,
          customer,
          userState,
          profileName,
          surveyLink,
        ]) => ({
          activeUserSession,
          customer,
          userState,
          profileName,
          surveyLink,
        }),
      ),
    );
  }

  /**
   * Retrieves the user's profile name from site customizations.
   * @returns Observable string of the user's profile name
   */
  private _getProfileName(): Observable<string> {
    return this._userCustomizations$.pipe(
      distinctUntilChanged(),
      filter(custom => !!custom),
      map(
        (customization: SiteCustomizationProfile) => customization.profileName,
      ),
    );
  }

  /**
   * Retrieves the survey link based on feature flag settings.
   * If the survey feature is enabled, attempts to get the survey link with timeout and retry logic.
   * @returns Observable string of the survey link or empty string if unavailable
   */
  private _getSurveyLink(): Observable<string> {
    return this._panAppState
      .feature$([FEATURES['split_global_alerts_survey'].name])
      .pipe(
        distinctUntilChanged(),
        mergeMap((surveyFlag: boolean) => {
          if (surveyFlag) {
            return this._profileService.getSurveyLink().pipe(
              take(1),
              timeout(5000),
              retry(2),
              catchError(() => of('')),
            );
          }
          return of('');
        }),
      );
  }

  /**
   * Creates appropriate identify options based on platform type.
   * @param identifySource Data containing user, customer, and session information
   * @returns Platform-specific identify options for Appcues
   */
  private _createIdentifyOptions(
    identifySource: IdentifySourceData,
  ): IdentifyOptions | IdentifyOptionsNative {
    return this._isPlatformTouch
      ? this._buildTouchIdentifyOptions(identifySource)
      : this._buildNonTouchIdentifyOptions(identifySource);
  }

  /**
   * Determines if two identity sources represent the same user identity.
   * Used for distinctUntilChanged to prevent unnecessary Appcues identify calls.
   * @param previous Previous identity source data
   * @param current Current identity source data
   * @returns True if identities match, false otherwise
   */
  private _isSameIdentity(
    previous: IdentifySourceData,
    current: IdentifySourceData,
  ): boolean {
    return (
      previous.activeUserSession.ecomUserId ===
        current.activeUserSession.ecomUserId &&
      previous.activeUserSession.userName ===
        current.activeUserSession.userName &&
      previous.customer.customerNumber === current.customer.customerNumber &&
      previous.userState.ecomUserId === current.userState.ecomUserId
    );
  }

  /**
   * Builds identify options for touch platforms (mobile).
   * @param identifySource Data containing user, customer, and session information
   * @returns IdentifyOptions formatted for touch platforms
   */
  private _buildTouchIdentifyOptions(
    identifySource: IdentifySourceData,
  ): IdentifyOptions {
    return {
      userId: identifySource.activeUserSession.ecomUserId.toString(),
      properties: {
        userName: identifySource.activeUserSession.userName,
        isTMUser: identifySource.userState.tmUser,
        customerNumber:
          identifySource.activeUserSession?.selectedCustomerId.toString(),
        divisionNumber: identifySource.activeUserSession?.division?.toString(),
        customerType: identifySource.customer?.customerType?.toString(),
        siteCustomization: identifySource?.profileName,
        userKind: identifySource.userState.userKind,
        surveyUrl: identifySource.surveyLink || '',
      },
    };
  }

  /**
   * Builds identify options for non-touch platforms (web/desktop).
   * @param identifySource Data containing user, customer, and session information
   * @returns IdentifyOptionsNative formatted for non-touch platforms
   */
  private _buildNonTouchIdentifyOptions(
    identifySource: IdentifySourceData,
  ): IdentifyOptionsNative {
    const properties = {
      userName: identifySource.activeUserSession.userName.toString(),
      isTMUser: identifySource.userState.tmUser,
      customerNumber:
        identifySource.activeUserSession?.selectedCustomerId?.toString(),
      divisionNumber: identifySource.activeUserSession?.division?.toString(),
      customerType: identifySource.customer?.customerType?.toString(),
      siteCustomization: identifySource?.profileName,
      userKind: identifySource.userState.userKind,
      surveyUrl: identifySource.surveyLink,
    };

    return {
      userId: identifySource.activeUserSession.ecomUserId.toString(),
      userName: identifySource.activeUserSession.userName.toString(),
      isTMUser: identifySource.userState.tmUser,
      customerNumber:
        identifySource.activeUserSession?.selectedCustomerId?.toString(),
      divisionNumber: identifySource.activeUserSession?.division?.toString(),
      customerType: identifySource.customer?.customerType?.toString(),
      siteCustomization: identifySource?.profileName,
      userKind: identifySource.userState.userKind,
      surveyUrl: identifySource.surveyLink,
      properties,
    };
  }

  /**
   * Calls the identify method on the Appcues instance with provided options.
   * @param identifyOptions Options for identifying the user in Appcues
   */
  private appcuesIdentify(
    identifyOptions: IdentifyOptions | IdentifyOptionsNative,
  ) {
    this.identify(identifyOptions);
  }

  /**
   * Appends the Appcues script to the document head.
   * @param document The DOM Document object
   * @param srcLocation The script source URL
   * @returns Promise resolving with script load status
   */
  private _appendToDocument(
    document: Document,
    srcLocation: string,
  ): Promise<Script> {
    return new Promise((resolve, _reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = srcLocation;
      document.getElementsByTagName('head')[0].appendChild(script);
      script.onload = () => {
        resolve({ loaded: true, name: AppcuesService.SCRIPT_TAG_NAME_ATTR });
      };

      script.onerror = () =>
        resolve({ loaded: false, name: AppcuesService.SCRIPT_TAG_NAME_ATTR });
    });
  }

  /**
   * Type guard to ensure the Appcues instance is for web.
   * @param appcues The Appcues instance to check
   * @returns True if the instance is AppcuesWeb
   */
  private _isWebProxy(
    appcues: AppcuesPlugin | AppcuesWeb,
  ): appcues is AppcuesWeb {
    return 'page' in appcues;
  }

  /**
   * Type guard to ensure the Appcues instance is for native.
   * @param appcues The Appcues instance to check
   * @returns True if the instance is AppcuesPlugin
   */
  // type guard to make sure screen() is only called on Appcues plugin
  private _isNativePlugin(
    appcues: AppcuesPlugin | AppcuesWeb,
  ): appcues is AppcuesPlugin {
    return !('page' in appcues);
  }
}
