import { Injectable } from '@angular/core';
import { CoveoService } from '../coveo/coveo.service';
import {
  SpecificFacetSearchResult,
  Suggestion,
  Unsubscribe,
} from '@coveo/headless';
import {
  buildInstantProducts,
  FieldSuggestionsGenerator,
  InstantProducts,
  Product,
  buildFieldSuggestionsGenerator,
  GeneratedFieldSuggestionsControllers,
  FieldSuggestionsState,
  StandaloneSearchBox,
  buildStandaloneSearchBox,
  FieldSuggestions,
  InteractiveProduct,
} from '@coveo/headless/commerce';
import { filter, Subscription, take } from 'rxjs';
import { AcronymTitleCasePipe } from '@shared/pipes/acronym-title-case.pipe';
import {
  SuggestionTypeAheadItem,
  TypeaheadProduct,
} from '@app/new-search/models/new-search-typeahead.model';
import {
  highlightText,
  highlightedTitleCase,
} from '@app/new-search/utils/new-search-typeahead.util';

@Injectable({
  providedIn: 'root',
})
export class TypeaheadService {
  constructor(
    private coveoService: CoveoService,
    public acronymTitleCasePipe: AcronymTitleCasePipe,
  ) {}

  fieldSuggestionsGenerator: FieldSuggestionsGenerator;
  instantProductsController: InstantProducts;
  searchBoxController: StandaloneSearchBox;
  fieldSuggestionControllers: GeneratedFieldSuggestionsControllers;
  fieldSuggestionsGeneratorController: FieldSuggestionsGenerator;
  fieldSuggestionsState!: FieldSuggestionsState;
  private searchBoxId = 'header-search-box'; //make this an input to the initialize function
  fieldSuggestionController: FieldSuggestions;
  searchBoxSubscription: Unsubscribe;
  instantProductsUnsubscribe: Unsubscribe;
  commerceEngineSubscription$: Subscription;
  private typeAheadActive: Boolean;
  newProductsTypeahead: TypeaheadProduct[] = [];
  brandsTypeahead: SpecificFacetSearchResult[] = [];
  suggestedSearchTypeahead: SuggestionTypeAheadItem[] = [];

  initializeTypeAhead() {
    if (this.typeAheadActive) return;

    this.typeAheadActive = true;
    this.commerceEngineSubscription$ = this.coveoService
      .getCommerceEngine$()
      .pipe(
        take(1),
        filter(engine => !!engine),
      )
      .subscribe(engine => {
        this.searchBoxController = buildStandaloneSearchBox(engine, {
          options: {
            id: this.searchBoxId,
            highlightOptions: {
              exactMatchDelimiters: { open: '<strong>', close: '</strong>' },
            },
            redirectionUrl: '/search2',
          },
        });
        this.fieldSuggestionsGeneratorController =
          buildFieldSuggestionsGenerator(engine);

        this.instantProductsController = buildInstantProducts(engine, {
          options: {
            searchBoxId: this.searchBoxId,
          },
        });

        this.searchBoxSubscription = this.searchBoxController.subscribe(() => {
          const suggestions: Suggestion[] =
            this.searchBoxController.state.suggestions;
          this.suggestedSearchTypeahead.length = 0;
          if (suggestions?.length) {
            this.suggestedSearchTypeahead.length = 0;
            suggestions.slice(0, 5).map(suggestion =>
              this.suggestedSearchTypeahead.push({
                ...suggestion,
                displayValue: suggestion.rawValue,
                highlightedValue: highlightedTitleCase(
                  suggestion.highlightedValue,
                ),
              }),
            );
          }
          const state = this.searchBoxController.state;

          this.fieldSuggestionControllers =
            this.fieldSuggestionsGeneratorController.fieldSuggestions;

          this.fieldSuggestionControllers.forEach(
            fieldSuggestionControllerParam => {
              this.fieldSuggestionController =
                fieldSuggestionControllerParam as FieldSuggestions;
              this.fieldSuggestionController.subscribe(() => {
                this.brandsTypeahead.length = 0;
                this.fieldSuggestionsState =
                  this.fieldSuggestionController.state;
                this.fieldSuggestionsState.values
                  .slice(0, 5)
                  .map(fieldSuggestion =>
                    this.brandsTypeahead.push({
                      ...fieldSuggestion,
                      rawValue: this.acronymTitleCasePipe.transform(
                        fieldSuggestion.rawValue,
                      ),
                    }),
                  );
              });
            },
          );
        });

        this.instantProductsUnsubscribe =
          this.instantProductsController.subscribe(() => {
            this.newProductsTypeahead.length = 0;
            const products: Product[] =
              this.instantProductsController.state.products;
            if (products?.length) {
              products.slice(0, 10).map(prod =>
                this.newProductsTypeahead.push({
                  ...prod,
                  productName: highlightText(prod.ec_name, prod.nameHighlights),
                }),
              );
            }
          });
      });
  }

  selectSearchTermSuggetion(searchTerm: string) {
    this.searchBoxController.selectSuggestion(searchTerm);
  }

  selectBrandSuggetion(brand: SpecificFacetSearchResult) {
    this.fieldSuggestionController.singleSelect(brand);
  }

  selectInstantProduct(product: Product) {
    product.ec_price = 0;
    const interactiveProduct: InteractiveProduct =
      this.instantProductsController.interactiveProduct({
        options: {
          product: product,
        },
      });
    interactiveProduct.select();
  }

  submitSearch() {
    this.searchBoxController?.submit();
  }

  searchNavigationCompleted() {
    this.searchBoxController?.afterRedirection();
  }

  disableTypeahead() {
    this.commerceEngineSubscription$?.unsubscribe();
    if (!!this.searchBoxSubscription) {
      this.searchBoxSubscription();
    }
    if (!!this.instantProductsUnsubscribe) {
      this.instantProductsUnsubscribe();
    }
    this.typeAheadActive = false;
    this.newProductsTypeahead = [];
    this.brandsTypeahead = [];
    this.suggestedSearchTypeahead = [];
  }
}
