import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ProductDetailViewModel } from '../../models/product-detail-view-model';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import { FEATURES } from '../../../shared/constants/splitio-features';
import { PanAppState } from '@panamax/app-state';
import { Subscription } from 'rxjs';
import { SellerPriceDetails } from '@usf/price-types';
import { SellerOverviewButtonsConfig } from '@product-detail/models/seller-overview-buttons-config.model';

@Component({
  selector: 'app-product-detail-summary-seller-overview',
  templateUrl: './product-detail-summary-seller-overview.component.html',
  styleUrls: ['./product-detail-summary-seller-overview.component.scss'],
})
export class ProductDetailSummarySellerOverviewComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() product: ProductDetailViewModel;
  @Input() pricingDisabled: boolean;
  @Input() sellerPriceDetail: SellerPriceDetails;
  @Input() displayProductDetails = false;
  @Input() columnLayout = false;
  @Input() enabledButtons: SellerOverviewButtonsConfig = {};
  @Output() createOpportunity = new EventEmitter();
  @Output() setCPR = new EventEmitter();
  @Output() addToList = new EventEmitter();
  @Output() searchForSubstitute = new EventEmitter();

  hasPrice: boolean;
  pricingRelatedActionsAvailable = false;
  createOpportunityFeatureFlag$: Subscription;
  customPricingRationaleFlag: boolean;

  propertiesEnum = ProductPropertiesEnum;
  createOpportunityFeatureEnabled: boolean;
  pricePerPortionFeatureFlag = FEATURES.split_global_price_per_portion.name;
  cprButtonFeatureFlag = FEATURES.split_global_cpr_button.name;

  constructor(readonly panAppState: PanAppState) {}

  handleCreateOpportunityClick(): void {
    this.createOpportunity.emit();
  }

  handleSetCPRClick(): void {
    this.setCPR.emit();
  }

  handleAddToListClick(): void {
    this.addToList.emit();
  }

  handleSearchForSubstituteClick(): void {
    this.searchForSubstitute.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // When the data changes, reevaluate if actions are displayable
    // this will show hide the 'actions' section if it's empty
    // REMEMBER TO REGISTER NEW ACTIONS HERE FIRST!!
    this.checkProductHasPriceCase();
    this.pricingRelatedActionsAvailable =
      this.hasPricingRelatedActionsEnabled();
  }

  private hasPricingRelatedActionsEnabled() {
    return this.createOpportunityFeatureEnabled && this.hasPrice;
  }

  ngOnInit(): void {
    this.createOpportunityFeatureFlag$ = this.panAppState
      .feature$([FEATURES.split_global_create_opportunity.name])
      .subscribe(enabled => {
        this.createOpportunityFeatureEnabled = enabled;
        this.checkProductHasPriceCase();
        this.pricingRelatedActionsAvailable =
          this.hasPricingRelatedActionsEnabled();
      });
  }

  ngOnDestroy(): void {
    this.createOpportunityFeatureFlag$?.unsubscribe();
  }

  checkProductHasPriceCase(): void {
    if (
      !this.pricingDisabled &&
      this.product?.pricing?.unitPrice != undefined &&
      this.product?.pricing?.unitPrice * 1 > 0
    ) {
      this.hasPrice = true;
      this.pricingRelatedActionsAvailable =
        this.hasPricingRelatedActionsEnabled();
    } else if (
      this.pricingDisabled ||
      this.product?.pricing?.unitPrice * 1 === 0
    ) {
      this.hasPrice = false;
    }
  }
}
