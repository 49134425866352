import { Injectable, OnDestroy } from '@angular/core';
import { PanAppState } from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToggleNewSearchService implements OnDestroy {
  public static readonly NEW_SEARCH_ENABLED = 'toggle-new-search';
  private newSearchEnabledSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  private newSearchEnabled$: Observable<boolean> =
    this.newSearchEnabledSubject?.asObservable();
  private configSubscription: Subscription;

  constructor(private panAppState: PanAppState) {
    this.init();
  }

  ngOnDestroy(): void {
    this.configSubscription?.unsubscribe();
  }

  private init() {
    this.configSubscription = combineLatest([
      this.panAppState.feature$([
        FEATURES.split_dynamic_new_search_enabled.name,
      ]),
      this.panAppState.feature$([FEATURES.split_user_toggle_new_search.name]),
    ])?.subscribe(([newSearchFeatureFlag, newSearchToggleFlag]) => {
      if (newSearchFeatureFlag && !newSearchToggleFlag) {
        this.newSearchEnabledSubject.next(true);
      } else if (newSearchFeatureFlag && newSearchToggleFlag) {
        const enabled = this.getNewSearchEnabled(true);
        this.newSearchEnabledSubject.next(enabled);
      } else if (!newSearchFeatureFlag && newSearchToggleFlag) {
        const enabled = this.getNewSearchEnabled(false);
        this.newSearchEnabledSubject.next(enabled);
      }
    });
  }

  private getNewSearchEnabled(defaultValue: boolean) {
    const toggleValue = sessionStorage.getItem(
      ToggleNewSearchService.NEW_SEARCH_ENABLED,
    );
    return toggleValue !== null ? toggleValue === 'true' : defaultValue;
  }

  toggle() {
    const isToggleOn = !this.newSearchEnabledSubject.value;
    sessionStorage.setItem(
      ToggleNewSearchService.NEW_SEARCH_ENABLED,
      isToggleOn.toString(),
    );
    this.newSearchEnabledSubject.next(isToggleOn);
  }

  isNewSearchEnabled(): Observable<boolean> {
    return this.newSearchEnabledObservable$;
  }

  public get newSearchEnabledObservable$(): Observable<boolean> {
    return this.newSearchEnabled$;
  }
}
