<ion-header class="ion-no-border ion-header-modal-mobile">
  <ion-toolbar>
    <ion-label data-cy="sort-filter-label">
      {{ 'i18n.lists.sortAndFilter' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<!-- SORT & FILTER OPTIONS -->
<ion-content class="ion-content-modal-mobile">
  <!-- SORT -->
  <div class="sort-options">
    <div class="sort-filter-list-options-header">
      <ion-label data-cy="sort-by-label">
        {{ 'i18n.common.sortBy' | translate }}
      </ion-label>
    </div>
    <ion-item-divider></ion-item-divider>
    <app-highlighted-selectable-item
      *ngIf="!isRecentPurchase"
      [label]="'i18n.lists.groupLine'"
      [value]="
        selectedSort === sortFilterType.groupLine
          ? selectedGroup === listConstants.allGroups
            ? 'All Groups'
            : selectedGroup
          : 'All Groups'
      "
      [selected]="selectedSort === sortFilterType.groupLine"
      [index]="1"
      (clickValueEmitter)="setSort(sortFilterType.groupLine)"
    ></app-highlighted-selectable-item>
    <app-highlighted-selectable-item
      *ngIf="!isRecentPurchase"
      [label]="'i18n.lists.groupAlpha'"
      [value]="
        selectedSort === sortFilterType.groupAlpha
          ? selectedGroup === listConstants.allGroups
            ? 'All Groups'
            : selectedGroup
          : 'All Groups'
      "
      [selected]="selectedSort === sortFilterType.groupAlpha"
      [index]="2"
      (clickValueEmitter)="setSort(sortFilterType.groupAlpha)"
    ></app-highlighted-selectable-item>
    <app-highlighted-selectable-item
      [label]="'i18n.lists.usFoodsClass'"
      [value]="
        selectedSort === sortFilterType.usFoodsClass
          ? selectedGroup === listConstants.allGroups
            ? 'All US Foods Class'
            : selectedGroup?.toLowerCase()
          : 'All US Foods Class'
      "
      [selected]="selectedSort === sortFilterType.usFoodsClass"
      [index]="3"
      (clickValueEmitter)="setSort(sortFilterType.usFoodsClass)"
    ></app-highlighted-selectable-item>
    <ion-item
      [class.selected]="selectedSort === sortFilterType.alphabetical"
      (click)="setSort(sortFilterType.alphabetical)"
      data-cy="click-sort-alphabetical-item"
    >
      <ion-label
        id="alphabetical"
        class="sort-filter-list-option"
        data-cy="alphabetical-label"
      >
        {{ 'i18n.common.alphabetical' | translate }}
      </ion-label>
      <app-usf-checkbox
        aria-labelledby="alphabetical"
        [invertColors]="true"
        [isSelected]="alphabeticalSelected"
        data-cy="select-alphabetical-checkbox"
      >
      </app-usf-checkbox>
    </ion-item>
    <ion-item
      *ngIf="isRecentPurchase"
      [class.selected]="selectedSort === sortFilterType.date"
      (click)="setSort(sortFilterType.date)"
      data-cy="click-sort-date-item"
    >
      <ion-label
        id="sort-by-date"
        class="sort-filter-list-option"
        data-cy="sort-date-label"
      >
        {{ 'i18n.lists.sortByDate' | translate }}
      </ion-label>
      <app-usf-checkbox
        aria-labelledby="sort-by-date"
        [invertColors]="true"
        [isSelected]="dateSelected"
        data-cy="select-date-checkbox"
      >
      </app-usf-checkbox>
    </ion-item>
  </div>
  <!-- FILTER -->
  <div class="filter-options">
    <div class="sort-filter-list-options-header filter-header">
      <ion-label data-cy="filter-by-label">
        {{ 'i18n.common.filterBy' | translate }}
      </ion-label>
    </div>
    <ion-item-divider></ion-item-divider>
    <app-highlighted-selectable-item
      *ngIf="isRecentPurchase"
      [label]="'i18n.lists.showProductsFromPast' | translate"
      [value]="
        week52Filter
          ? ('i18n.lists.filterBy52Weeks' | translate)
          : ('i18n.lists.filterBy26Weeks' | translate)
      "
      [selected]="showProductsFromPastOptions"
      [index]="3"
      (clickValueEmitter)="toggleProductsFromPast()"
    >
    </app-highlighted-selectable-item>
    <ion-item
      *ngIf="!isRecentPurchase"
      [class.selected]="
        selectedFilters?.includes(sortFilterType.recentlyPurchased)
      "
      (click)="setFilters(sortFilterType.recentlyPurchased)"
      data-cy="click-sort-discontinued-item"
    >
      <ion-label
        id="recently-purchased"
        class="sort-filter-list-option"
        data-cy="recently-purchased-label"
      >
        {{ 'i18n.lists.recentlyPurchased' | translate }}
      </ion-label>
      <app-usf-checkbox
        aria-labelledby="recently-purchased"
        [invertColors]="true"
        [isSelected]="
          selectedFilters?.includes(sortFilterType.recentlyPurchased)
        "
        data-cy="click-sort-recently-purchased-checkbox"
      ></app-usf-checkbox>
    </ion-item>
    <ion-item
      *ngIf="!isMasterList"
      [class.selected]="selectedFilters?.includes(sortFilterType.discontinued)"
      (click)="setFilters(sortFilterType.discontinued)"
      data-cy="click-sort-discontinued-item"
    >
      <ion-label
        id="discontinued"
        class="sort-filter-list-option"
        data-cy="sort-discontinued-label"
      >
        {{ 'i18n.lists.discontinued' | translate }}
      </ion-label>
      <app-usf-checkbox
        aria-labelledby="discontinued"
        [invertColors]="true"
        [isSelected]="selectedFilters?.includes(sortFilterType.discontinued)"
        data-cy="select-discontinued-checkbox"
      ></app-usf-checkbox>
    </ion-item>
  </div>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="clear-all-modal-btn"
        data-cy="clear-all-filter-modal-button"
        class="usf-outline-green-mobile-modal-button"
        (click)="clearAll()"
      >
        {{ 'i18n.common.clearAll' | translate }}
      </ion-button>
      <ion-button
        id="apply-modal-btn"
        data-cy="apply-filter-modal-button"
        class="usf-fill-green-mobile-modal-button"
        (click)="applyAll()"
      >
        {{ 'i18n.common.apply' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
