import { MyOrdersService } from '@app/my-orders/services/my-orders.service';
import { selectUserCustomizations } from '@app/user/store';
import { createSelector } from '@ngrx/store';
import { RecentOrderTileViewModel } from '@order/models/recent-order-tile-view-model';
import { OrderHeaderService } from '@order/services/order-header.service';
import {
  selectAllFeaturesEnabled,
  SelectedCustomerState,
} from '@panamax/app-state';
import {
  RecentOrderTileBannerMessage,
  RecentOrderTileActionLinkText,
} from '@shared/constants/homepage-recent-order.enum';
import { OrderStatusDetails } from '@shared/constants/order-status-title.enum';
import { FEATURES } from '@shared/constants/splitio-features';
import { Customer } from '@usf/customer-types';
import {
  Order,
  orderSelectors,
  OrderStatus,
  partnerSelectors,
} from '@usf/ngrx-order';
import { OrderState } from '@usf/ngrx-order';
import { PartnerState } from '@usf/ngrx-order/lib/models/state/partner-state';
import { SiteCustomizationProfile } from '@usf/user-types/site-customization';
import moment from 'moment';

import {
  getCustomers,
  selectAppStateSelectedCustomer,
} from 'src/app/ngrx-customer/store';
import { selectOrderStatusDisplayEnabled } from './my-orders-view-model.selectors';

export const selectRecentOrderViewModel = (keyword: string) =>
  createSelector(
    selectAppStateSelectedCustomer,
    orderSelectors.selectOrderContextState,
    getCustomers,
    partnerSelectors.selectPartner,
    selectUserCustomizations,
    selectAllFeaturesEnabled([FEATURES.split_global_modify_vs_order.name]),
    selectOrderStatusDisplayEnabled(),

    (
      selectedCustomerState: SelectedCustomerState,
      orderState: OrderState,
      customers: Customer[],
      partnerState: PartnerState,
      siteCustomizations: SiteCustomizationProfile,
      modifyVSOrdersEnabled: boolean,
      orderStatusDisplayEnabled: boolean,
    ) => {
      const customerHasOrderOfPriority = {
        priorityOne: false,
        priorityTwo: false,
        priorityThree: true,
        priorityFour: false,
      };

      let customersOrders: Order[] = [];
      if (!!orderState.ids.length) {
        for (let orderId of orderState.ids) {
          const order = orderState?.entities[orderId];
          const orderBelongsToCustomer =
            order?.orderHeader?.customerNumber ===
              selectedCustomerState?.customerNumber &&
            order?.orderHeader?.departmentNumber * 1 ===
              selectedCustomerState?.departmentNumber * 1 &&
            !!order;

          // Do not show VS orders in the recent order tile
          const isVSOrderType = order?.orderHeader?.orderType === 'VS';

          if (
            orderBelongsToCustomer &&
            !!order.orderItems.length &&
            !isVSOrderType
          ) {
            customersOrders.push(order);

            //---Priority 1: customer has a submitted & modifiable order---
            if (orderPriority1(order, modifyVSOrdersEnabled)) {
              customerHasOrderOfPriority.priorityOne = true;
            }

            //---Priority 2: customer has an in progress order with order items---
            if (orderPriority2(order)) {
              customerHasOrderOfPriority.priorityTwo = true;
            }

            //---Priority 4: customer has submitted but not modifiable orders---
            if (orderPriority4(order, modifyVSOrdersEnabled)) {
              customerHasOrderOfPriority.priorityFour = true;
            }
          }
        }

        // ---Priority 3: No Order cards---
        if (
          !customerHasOrderOfPriority.priorityOne &&
          !customerHasOrderOfPriority.priorityTwo &&
          !!selectedCustomerState?.daysToDeliver
        ) {
          customerHasOrderOfPriority.priorityThree = customerHasNoOrderCard(
            selectedCustomerState,
            customersOrders,
          );

          if (customerHasOrderOfPriority.priorityThree) {
            customerHasOrderOfPriority.priorityFour = false;
          }
        } else {
          customerHasOrderOfPriority.priorityThree = false;
        }
      }

      if (
        customerHasOrderOfPriority.priorityThree &&
        !!selectedCustomerState?.daysToDeliver
      ) {
        const nextDeliveryDate = MyOrdersService.getNextDeliveryDate(
          selectedCustomerState,
        );

        const nextDeliveryDateKey =
          MyOrdersService.getDateKey(nextDeliveryDate);
        const orderStatusToDisplay =
          'No Order Started for delivery on ' + nextDeliveryDateKey;
        const recentOrderVM: RecentOrderTileViewModel = {
          recentOrder: {} as Order,
          noOrderCard: true,
          isEditable: false,
          orderStatusDisplayTitle: orderStatusToDisplay,
          orderStatusIcon: OrderStatusDetails.NO_ORDER.icon,
          orderStatusDisplayType: OrderStatusDetails.NO_ORDER.displayType,
          bannerMessage: RecentOrderTileBannerMessage.noOrderStarted,
          totalEaches: 0,
          totalCases: 0,
          orderPlacedDate: new Date(),
          orderTotal: 0,
          hasShippingType: null,
        };

        return recentOrderVM;
      } else if (
        customerHasOrderOfPriority.priorityOne ||
        customerHasOrderOfPriority.priorityTwo ||
        customerHasOrderOfPriority.priorityFour
      ) {
        let latestOrder: Order;
        if (customerHasOrderOfPriority.priorityOne) {
          latestOrder = customersOrders
            .filter(order => orderPriority1(order, modifyVSOrdersEnabled))
            .reduce((a, b) =>
              a?.orderHeader?.updateDtm > b?.orderHeader?.updateDtm ? a : b,
            );
        } else if (customerHasOrderOfPriority.priorityTwo) {
          latestOrder = customersOrders
            .filter(order => orderPriority2(order))
            .reduce((a, b) =>
              a?.orderHeader?.updateDtm > b?.orderHeader?.updateDtm ? a : b,
            );
        } else if (customerHasOrderOfPriority.priorityFour) {
          latestOrder = customersOrders
            .filter(order => orderPriority4(order, modifyVSOrdersEnabled))
            .reduce((a, b) =>
              a?.orderHeader?.updateDtm > b?.orderHeader?.updateDtm ? a : b,
            );
        }

        let tomorrow = moment().utc().add(1, 'days');

        let bannerMessage: RecentOrderTileBannerMessage;
        let actionLinkText: string;
        switch (orderStatusForBanner(latestOrder)) {
          case OrderStatus.SUBMITTED:
            if (
              OrderHeaderService.isOrderEditable(
                latestOrder?.orderHeader,
                modifyVSOrdersEnabled,
              )
            ) {
              bannerMessage =
                RecentOrderTileBannerMessage.submittedModifiableOrder;
              actionLinkText =
                latestOrder?.orderHeader?.orderStatus ===
                OrderStatus.SUBMITTED_WITH_EXCEPTIONS
                  ? RecentOrderTileActionLinkText.resolve
                  : partnerState?.punchoutProfile?.updateButton
                    ? partnerState.punchoutProfile.updateButton
                    : RecentOrderTileActionLinkText.modify;
            } else {
              bannerMessage =
                RecentOrderTileBannerMessage.submittedNotModifiable;
              actionLinkText = RecentOrderTileActionLinkText.createOrder;
            }
            break;
          case OrderStatus.IN_PROGRESS:
            const confirmedDeliveryDateKey = MyOrdersService.getDateKey(
              new Date(latestOrder?.orderHeader?.confirmedDeliveryDate),
            );
            const tomorrowDateKey = MyOrdersService.getDateKey(
              tomorrow.toDate(),
            );
            bannerMessage =
              confirmedDeliveryDateKey === tomorrowDateKey
                ? RecentOrderTileBannerMessage.inProgressDayBeforeDelivery
                : RecentOrderTileBannerMessage.inProgressNotDayBeforeDelivery;
            actionLinkText = RecentOrderTileActionLinkText.goToCart;
        }
        let orderStatusDetails = OrderHeaderService.orderIsPreparingToShip(
          latestOrder?.orderHeader,
        )
          ? OrderStatusDetails.PREPARING_TO_SHIP
          : OrderStatusDetails[latestOrder.orderHeader.orderStatus];

        let orderStatusToDisplay = OrderHeaderService.getDisplayTitle(
          orderStatusDetails,
          null,
          null,
          null,
          null,
          latestOrder,
          siteCustomizations,
          partnerState,
        );

        orderStatusToDisplay =
          orderStatusDisplayEnabled &&
          latestOrder?.orderHeader?.orderStatus === OrderStatus.IN_PROGRESS
            ? 'i18n.orderStatusDisplayTitles.notSubmittedItemsInCart'
            : orderStatusToDisplay;

        const fullOrderId =
          `${latestOrder?.orderHeader?.customerNumber}:` +
          `${latestOrder?.orderHeader?.departmentNumber}:` +
          `${latestOrder?.orderHeader?.orderId}`;

        const orderPlacedDate = isNaN(
          new Date(latestOrder?.orderHeader?.orderDate).getTime(),
        )
          ? null
          : new Date(latestOrder?.orderHeader?.orderDate);
        let currentCustomer: Customer;
        customers.find(c => {
          if (c.customerNumber === selectedCustomerState.customerNumber) {
            currentCustomer = c;
          }
        });
        const recentOrderVM: RecentOrderTileViewModel = {
          recentOrder: latestOrder,
          noOrderCard: false,
          isEditable: OrderHeaderService.isOrderEditable(
            latestOrder?.orderHeader,
            modifyVSOrdersEnabled,
          ),
          orderStatusDisplayTitle: orderStatusToDisplay,
          orderStatusIcon: orderStatusDetails?.icon,
          orderStatusDisplayType: orderStatusDetails?.displayType,
          bannerMessage: bannerMessage,
          actionButtonText: actionLinkText,
          totalEaches: latestOrder?.orderHeader?.totalEaches || 0,
          totalCases: latestOrder?.orderHeader?.totalUnits || 0,
          orderPlacedDate: orderPlacedDate,
          orderTotal: latestOrder?.orderHeader?.totalPrice,
          hasShippingType:
            latestOrder?.orderHeader?.orderStatus !== OrderStatus.IN_PROGRESS,
          fullOrderId: fullOrderId,
          currentCustomer: currentCustomer,
        };

        return recentOrderVM;
      } else {
        const recentOrderVM: RecentOrderTileViewModel = {
          noRecentOrder: true,
        };
        return recentOrderVM;
      }
    },
  );

export const orderPriority1 = (
  order: Order,
  modifyVSOrdersEnabled: boolean,
) => {
  return (
    (order?.orderHeader?.orderStatus === OrderStatus.SUBMITTED ||
      order?.orderHeader?.orderStatus ===
        OrderStatus.SUBMITTED_WITH_EXCEPTIONS ||
      order?.orderHeader?.orderStatus === OrderStatus.SUBMITTED_CREDIT_HOLD) &&
    order?.orderHeader?.updateDtm &&
    OrderHeaderService.isOrderEditable(
      order?.orderHeader,
      modifyVSOrdersEnabled,
    )
  );
};

export const orderPriority2 = (order: Order) => {
  return (
    order?.orderHeader?.orderStatus === OrderStatus.IN_PROGRESS &&
    order?.orderHeader?.updateDtm &&
    !!order?.orderItems.length
  );
};

//Priority 3 - customer has no order card
export const customerHasNoOrderCard = (
  selectedCustomerState: SelectedCustomerState,
  customerOrders: Order[],
) => {
  let hasRecentOrder = false;

  if (
    !!selectedCustomerState?.daysToDeliver &&
    MyOrdersService.customerHasRecentOrder(selectedCustomerState)
  ) {
    const nextDeliveryDate = MyOrdersService.getNextDeliveryDate(
      selectedCustomerState,
    );
    if (nextDeliveryDate) {
      const nextDeliveryDateKey = MyOrdersService.getDateKey(nextDeliveryDate);
      hasRecentOrder = customerOrders.some(order => {
        if (order?.orderHeader?.confirmedDeliveryDate) {
          const confirmedDeliveryDateKey = MyOrdersService.getDateKey(
            new Date(order?.orderHeader?.confirmedDeliveryDate),
          );

          return confirmedDeliveryDateKey == nextDeliveryDateKey;
        }
      });
    }
  } else return false;

  return !hasRecentOrder;
};

export const orderPriority4 = (
  order: Order,
  modifyVSOrdersEnabled: boolean,
) => {
  return (
    (order?.orderHeader?.orderStatus === OrderStatus.SUBMITTED ||
      order?.orderHeader?.orderStatus ===
        OrderStatus.SUBMITTED_WITH_EXCEPTIONS ||
      order?.orderHeader?.orderStatus === OrderStatus.SUBMITTED_CREDIT_HOLD) &&
    order?.orderHeader?.updateDtm &&
    !OrderHeaderService.isOrderEditable(
      order?.orderHeader,
      modifyVSOrdersEnabled,
    )
  );
};

export const orderStatusForBanner = order => {
  let orderStatus = order?.orderHeader?.orderStatus;
  if (
    orderStatus === OrderStatus.SUBMITTED ||
    orderStatus === OrderStatus.SUBMITTED_WITH_EXCEPTIONS ||
    orderStatus === OrderStatus.SUBMITTED_CREDIT_HOLD
  ) {
    return OrderStatus.SUBMITTED;
  } else if (orderStatus === OrderStatus.IN_PROGRESS) {
    return OrderStatus.IN_PROGRESS;
  }
};
