import { createSelector } from '@ngrx/store';
import {
  IListState,
  selectListsState,
  recentPurchaseAdapter,
  selectListItemsState,
  selectRecentPurchaseState,
  listItemAdapter,
  ListItemState,
  selectMasterListsState,
  IMasterListState,
  selectMasterListItemState,
  IMasterListItemProductState,
  MasterListState,
  ListConstants,
  selectOrderGuideState,
  IOrderGuideState,
  selectIsRecentPurchaseListSelected,
  selectListsWithUpdatedCounts,
} from '@usf/ngrx-list';
import { Dictionary } from '@ngrx/entity';
import { ListsViewModel } from '../model/lists-view.model';
import { List, ListItem } from '@usf/list-types';
import {
  selectUserKind,
  selectUserPreferences,
  showOrderGuides,
} from 'src/app/user/store';
import { UserKinds } from '@usf/user-types/user';
import { ReplaceItemsViewModel } from '../shared/list-detail-management/model/replace-items-view.model';
import { selectAppStateSelectedCustomer } from '@app/ngrx-customer/store';
import { PlatformEnum, selectAllFeaturesEnabled } from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import {
  ListOfListsHeaders,
  ListOfListsSort,
  ListState,
} from '@usf/ngrx-list/lib/models/list/list.model';
import { AllLists, ListsDataForHomePage } from '../model/lists-page.model';
import {
  SortableSectionHeader,
  SortableSectionViewModel,
} from '../../shared/components/sortable-section/models/sortable-section.model';
import { selectListStatesAreLoaded } from '../shared/list-detail-management/selectors/list-detail-management-view-model.selector';
import { ListTypes } from '@shared/constants/lists-constants';
import { selectMslProductStateLoaded } from '@usf/ngrx-product';
import { selectAiListsAsListViewModels } from './ai-list-view-model.selector';

export const selectListOfListsSort = () =>
  createSelector(selectListsState, (listsState: IListState) => {
    return listsState.listOfListsSort;
  });

export const selectLists = (keyword?: string, overrideShowOGToHide?: boolean) =>
  createSelector(
    selectListsWithUpdatedCounts,
    showOrderGuides,
    (entities: Dictionary<List>, showOG) => {
      return Object.values(entities)
        .filter(list => {
          if (
            (!showOG || overrideShowOGToHide) &&
            list?.listKey?.listTypeId.toUpperCase() === 'OG'
          ) {
            return false;
          }
          return list?.listName
            ?.toUpperCase()
            .includes((keyword || '').toUpperCase());
        })
        .sort((a, b) =>
          a?.listName?.toUpperCase() > b?.listName?.toUpperCase() ? 1 : -1,
        );
    },
  );

export const selectOrderGuides = () =>
  createSelector(selectOrderGuideState, (orderGuideState: IOrderGuideState) => {
    return Object.values(orderGuideState.entities);
  });

export const selectMasterLists = () =>
  createSelector(
    selectMasterListsState,
    (masterListState: IMasterListState) => {
      return Object.values(masterListState.entities);
    },
  );

export const selectMasterListItems = () =>
  createSelector(
    selectMasterListItemState,
    (masterListItemState: IMasterListItemProductState) => {
      return Object.values(masterListItemState.entities);
    },
  );

export const selectRecentlyPurchased = () =>
  createSelector(
    recentPurchaseAdapter.getSelectors(selectRecentPurchaseState).selectTotal,
    selectIsRecentPurchaseListSelected,
    selectAllFeaturesEnabled([FEATURES.split_global_download_print.name]),
    (recentlyPurchasedCount, isSelected, downloadPrintFlag) => {
      return {
        listKey: { listTypeId: ListTypes.recentlyPurchased },
        listName: 'Recently Purchased',
        listItemCount: recentlyPurchasedCount,
        discontinuedCount: 0,
        showEllipsis: downloadPrintFlag,
        isSelected,
        downloadPrintFlag: downloadPrintFlag,
      } as ListsViewModel;
    },
  );

// TODO: Refactor inside service to no longer use deprecated selectAllFeaturesEnabled
export const selectManagedByUsfLists = (keyword?: string) =>
  createSelector(
    selectLists(),
    selectOrderGuides(),
    selectRecentlyPurchased(),
    selectAppStateSelectedCustomer,
    selectAllFeaturesEnabled([FEATURES.split_global_master_lists.name]),
    selectMasterListsState,
    selectAllFeaturesEnabled([FEATURES.split_global_download_print.name]),
    selectAllFeaturesEnabled([FEATURES.split_global_order_guide_filter.name]),
    showOrderGuides,
    (
      lists,
      orderGuides,
      recentlyPurchased,
      selectedCustomer,
      mslFlag,
      selectedMasterLists,
      downloadPrintFlag,
      orderGuideFilterFF,
      showOG,
    ) => {
      const listsToTraverseForOgs = orderGuideFilterFF ? orderGuides : lists;
      const managedByUsfLists: ListsViewModel[] = [];
      if (!mslFlag || showOG) {
        listsToTraverseForOgs.forEach(list => {
          if (
            list?.listKey?.listTypeId?.toUpperCase() === 'OG' &&
            listNameHasSearchKey(
              'ORDER GUIDE #' + list?.listKey?.listId,
              keyword,
            )
          ) {
            managedByUsfLists.push({
              ...list,
              listName: `Order Guide #${list.listKey.listId}`,
              showEllipsis: downloadPrintFlag,
              downloadPrintFlag,
              updatedByUser: {
                firstName: list.updatedByUser?.firstName,
                lastName: list.updatedByUser?.lastName,
                userName: '',
              },
            });
          }
        });
      }
      if (
        recentlyPurchased?.listItemCount > 0 &&
        listNameHasSearchKey(recentlyPurchased?.listName, keyword)
      ) {
        managedByUsfLists.push(recentlyPurchased);
      }
      if (mslFlag) {
        managedByUsfLists.push(
          ...convertMasterListsType(
            selectedMasterLists,
            downloadPrintFlag,
            selectedCustomer?.customerNumber,
            keyword,
          ),
        );
      }
      return managedByUsfLists.sort((a, b) =>
        a?.listName?.toUpperCase() > b?.listName?.toUpperCase() ? 1 : -1,
      );
    },
  );

export const convertMasterListsType = (
  masterListState: IMasterListState,
  downloadPrintFlag: boolean,
  customerNumber: number,
  searchKey: string,
) => {
  const convertedMasterLists: ListsViewModel[] = [];
  const sortedMasterLists = sortMasterLists(masterListState);
  let conversion: ListsViewModel;
  sortedMasterLists.forEach(masterList => {
    if (listNameHasSearchKey(masterList?.listName, searchKey)) {
      convertedMasterLists.push({
        ...conversion,
        ...masterList,
        listKey: {
          listId: masterList?.listId,
          listTypeId: 'ML',
        },
        updatedByUser: {
          firstName: masterList?.mlmUpdatedByUser?.firstName ?? '',
          lastName: masterList?.mlmUpdatedByUser?.lastName ?? '',
          userName: masterList?.mlmUpdateUserName?.replace('[AD_AUTO]', ''),
        },
        listItemCount: masterList.primaryCount,
        showEllipsis: downloadPrintFlag,
        downloadPrintFlag,
        customerNumber,
      });
    }
  });
  return convertedMasterLists;
};

const sortMasterLists = (masterListState: IMasterListState) => {
  const masterListsToSort: MasterListState[] = [];
  for (const id of masterListState.ids) {
    masterListsToSort.push(masterListState.entities[id]);
  }
  const sortedMasterLists = masterListsToSort.sort((a, b) =>
    a?.listName?.toUpperCase() > b?.listName?.toUpperCase() ? 1 : -1,
  );
  return sortedMasterLists;
};

// TODO: Refactor inside service to no longer use deprecated selectAllFeaturesEnabled
export const selectRecentlyViewedLists = (keyword?: string) =>
  createSelector(
    selectLists(keyword, true),
    selectUserPreferences,
    selectRecentlyPurchased(),
    selectManagedByUsfLists(keyword),
    selectAiListsAsListViewModels,
    selectAllFeaturesEnabled([FEATURES.split_global_download_print.name]),
    (
      lists: List[],
      preferences,
      recentlyPurchased,
      managedByUsfLists,
      aiLists,
      downloadPrintFlag,
    ) => {
      const recentlyViewedLists: ListsViewModel[] = [];
      let allLists = lists.concat(managedByUsfLists);

      allLists.forEach(list => {
        const tempListKey =
          list?.listKey?.listTypeId + '-' + list?.listKey?.listId;
        const listTypeId = list?.listKey?.listTypeId;
        if (preferences?.preferences?.recentlyViewed?.length === 2) {
          if (preferences?.preferences?.recentlyViewed[1] === tempListKey) {
            recentlyViewedLists.push({
              ...list,
              showEllipsis: listTypeId !== 'SL' ? downloadPrintFlag : true,
              downloadPrintFlag,
            });
          } else if (
            preferences?.preferences?.recentlyViewed[0] === tempListKey
          ) {
            recentlyViewedLists.unshift({
              ...list,
              showEllipsis: listTypeId !== 'SL' ? downloadPrintFlag : true,
              downloadPrintFlag,
            });
          }
        } else if (preferences?.preferences?.recentlyViewed?.length === 1) {
          if (preferences?.preferences?.recentlyViewed[0] === tempListKey) {
            recentlyViewedLists.push({
              ...list,
              showEllipsis: listTypeId !== 'SL' ? downloadPrintFlag : true,
              downloadPrintFlag,
            });
          }
        }
      });

      if (
        preferences?.preferences?.recentlyViewed?.includes(
          'recentlyPurchased',
        ) &&
        listNameHasSearchKey(recentlyPurchased?.listName, keyword)
      ) {
        if (preferences?.preferences?.recentlyViewed?.length === 2) {
          if (
            preferences?.preferences?.recentlyViewed[1] === 'recentlyPurchased'
          ) {
            recentlyViewedLists.push(recentlyPurchased);
          } else if (
            preferences?.preferences?.recentlyViewed[0] === 'recentlyPurchased'
          ) {
            recentlyViewedLists.unshift(recentlyPurchased);
          }
        } else if (preferences?.preferences?.recentlyViewed?.length === 1) {
          recentlyViewedLists.push(recentlyPurchased);
        }
      }

      aiLists.forEach(aiList => {
        recentlyViewedLists.unshift(aiList);
      });

      return recentlyViewedLists;
    },
  );

export const selectAllLists = (keyword?: string) =>
  createSelector(
    selectLists(keyword),
    selectRecentlyPurchased(),
    selectManagedByUsfLists(keyword),
    selectRecentlyViewedLists(keyword),
    (
      lists: List[],
      recentlyPurchased: ListsViewModel,
      managedByUsfLists: ListsViewModel[],
      recentlyViewedLists: ListsViewModel[],
    ): AllLists => {
      return {
        lists,
        recentlyPurchased,
        managedByUsfLists,
        recentlyViewedLists,
      };
    },
  );

// TODO: Refactor inside service to no longer use deprecated selectAllFeaturesEnabled
export const listsView = (platform?: PlatformEnum, keyword?: string) =>
  createSelector(
    selectListOfListsSort(),
    selectAllLists(keyword),
    selectUserKind,
    selectListStatesAreLoaded(),
    selectAllFeaturesEnabled([FEATURES.split_global_download_print.name]),
    selectMslProductStateLoaded,
    (
      listOfListsSort,
      allLists: AllLists,
      userKind: UserKinds,
      isListDataLoaded: boolean,
      downloadPrintFlag: boolean,
      mslProductStateLoaded: boolean,
    ) => {
      const publicLists: ListsViewModel[] = [];
      const internalLists: ListsViewModel[] = [];
      const privateLists: ListsViewModel[] = [];
      let lastSelectedList: ListsViewModel;
      let numberOfEditableLists = 0;

      allLists?.lists?.forEach(list => {
        const tempList = list as any;
        if (tempList?.isLastSelected) {
          lastSelectedList = tempList;
        }
        const listState = list?.listState?.toUpperCase();
        if (listState === 'PUBLIC') {
          publicLists.push({
            ...list,
            showEllipsis: true,
            downloadPrintFlag,
          });
        } else if (list?.listState?.toUpperCase() === 'INTERNAL') {
          internalLists.push({
            ...list,
            showEllipsis: true,
            downloadPrintFlag,
          });
        } else if (list?.listState?.toUpperCase() === 'PRIVATE') {
          privateLists.push({
            ...list,
            showEllipsis: true,
            downloadPrintFlag,
          });
        }
      });

      const sortedLists = sortAllLists(
        listOfListsSort,
        publicLists,
        internalLists,
        privateLists,
        allLists.managedByUsfLists,
        allLists.recentlyViewedLists,
      );

      const convertedListOfListsSort = convertListOfListsSort(
        platform,
        listOfListsSort,
      );

      if (userKind === UserKinds.Internal) {
        numberOfEditableLists =
          publicLists.length + internalLists.length + privateLists.length;
      } else {
        numberOfEditableLists = publicLists.length;
      }

      return {
        listOfListsSort: convertedListOfListsSort,
        lists: {
          publicLists: sortedLists.publicLists,
          internalLists: sortedLists.internalLists,
          privateLists: sortedLists.privateLists,
          recentlyViewedLists: sortedLists.recentlyViewedLists,
        },
        managedByUsfLists: sortedLists.managedByUsfLists,
        userKind,
        recentlyPurchasedCount: allLists.recentlyPurchased.listItemCount,
        lastSelectedList,
        isListDataLoaded,
        masterListItemsAreLoaded: mslProductStateLoaded,
        numberOfEditableLists,
      };
    },
  );

const sortAllLists = (
  listOfListsSort: ListOfListsSort,
  publicLists: ListsViewModel[],
  internalLists: ListsViewModel[],
  privateLists: ListsViewModel[],
  managedByUsfLists: ListsViewModel[],
  recentlyViewedLists: ListsViewModel[],
) => {
  let selectedHeader: string;
  if (publicLists) {
    selectedHeader = listOfListsSort.publicLists.selectedHeader;
    publicLists = setValuesForComparison(
      selectedHeader,
      publicLists,
      listOfListsSort.publicLists.headers[selectedHeader].sortDirection,
    );
  }
  if (internalLists) {
    selectedHeader = listOfListsSort.internalLists.selectedHeader;
    internalLists = setValuesForComparison(
      selectedHeader,
      internalLists,
      listOfListsSort.internalLists.headers[selectedHeader].sortDirection,
    );
  }
  if (privateLists) {
    selectedHeader = listOfListsSort.privateLists.selectedHeader;
    privateLists = setValuesForComparison(
      selectedHeader,
      privateLists,
      listOfListsSort.privateLists.headers[selectedHeader].sortDirection,
    );
  }
  if (managedByUsfLists) {
    selectedHeader = listOfListsSort.managedByUsfLists.selectedHeader;
    managedByUsfLists = setValuesForComparison(
      selectedHeader,
      managedByUsfLists,
      listOfListsSort.managedByUsfLists.headers[selectedHeader].sortDirection,
    );
  }
  if (recentlyViewedLists) {
    selectedHeader = listOfListsSort.recentlyViewedLists.selectedHeader;
    if (selectedHeader) {
      recentlyViewedLists = setValuesForComparison(
        selectedHeader,
        recentlyViewedLists,
        listOfListsSort.recentlyViewedLists.headers[selectedHeader]
          .sortDirection,
      );
    }
  }

  return {
    publicLists,
    internalLists,
    privateLists,
    managedByUsfLists,
    recentlyViewedLists,
  };
};

const setCollator = (numeric: boolean) => {
  return new Intl.Collator('en', {
    numeric,
    caseFirst: 'upper',
    sensitivity: 'base',
  });
};

const setValuesForComparison = (
  headerName: string,
  lists: ListsViewModel[],
  sortDirection: string,
) => {
  const collator = setCollator(
    headerName === ListConstants.numProducts ||
      headerName === ListConstants.numDiscontinued
      ? true
      : false,
  );

  return lists.sort((a, b) => {
    let valueA: any;
    let valueB: any;
    let listNameA = a[ListConstants.listName];
    let listNameB = b[ListConstants.listName];

    switch (headerName) {
      case ListConstants.listName:
        valueA = a[headerName];
        valueB = b[headerName];
        break;
      case ListConstants.numProducts:
      case ListConstants.numDiscontinued:
        valueA = Math.sign(a[headerName]) === -1 ? undefined : a[headerName];
        valueB = Math.sign(b[headerName]) === -1 ? undefined : b[headerName];
        break;
      case ListConstants.lastUpdatedBy:
        valueA =
          a.updatedByUser && a.updatedByUser.firstName
            ? a.updatedByUser.firstName + a.updatedByUser.lastName
            : 'System';
        valueB =
          b.updatedByUser && b.updatedByUser.firstName
            ? b.updatedByUser.firstName + b.updatedByUser.lastName
            : 'System';
        break;
    }

    return sort(collator, valueA, valueB, sortDirection, listNameA, listNameB);
  });
};

const sort = (
  collator: Intl.Collator,
  valueA: any,
  valueB: any,
  sortDirection: string,
  listNameA: string,
  listNameB: string,
) => {
  let diff =
    sortDirection === ListConstants.asc
      ? collator.compare(valueA || 0, valueB || 0)
      : collator.compare(valueB || -Infinity, valueA || -Infinity);

  // if values are the same, use secondary sort (list name)
  if (diff === 0) {
    const nonNumericCollator = setCollator(false);
    return nonNumericCollator.compare(listNameA, listNameB);
  }

  return diff;
};

const convertListOfListsSort = (
  platform: PlatformEnum,
  listOfListsSort: ListOfListsSort,
) => {
  let convertedListOfListsSort: SortableSectionViewModel = {};
  const listSections = Object.keys(listOfListsSort);

  listSections?.forEach(listSection => {
    const existingHeaders = listOfListsSort[listSection]?.headers;
    const updatedHeaders =
      platform !== PlatformEnum.mobile
        ? convertListOfListsHeadersDesktopTablet(existingHeaders)
        : convertListOfListsHeadersMobile(existingHeaders);
    convertedListOfListsSort = {
      ...convertedListOfListsSort,
      [listSection]: {
        selectedHeader: listOfListsSort[listSection]?.selectedHeader,
        headers: updatedHeaders,
      },
    };
  });

  return convertedListOfListsSort;
};

const convertListOfListsHeadersDesktopTablet = (
  existingHeaders: ListOfListsHeaders,
): SortableSectionHeader[] => {
  const updatedHeaders: SortableSectionHeader[] = [];
  // key: headerName, value: displayText, sortDirection
  // size + offset values add up to 12
  for (const [key, value] of Object.entries(existingHeaders)) {
    if (key === ListConstants.listName) {
      updatedHeaders.push({
        headerName: key,
        displayText: value.displayText,
        sortDirection: value.sortDirection,
        size: 4.05,
        offset: 0.25,
      });
    } else if (key === ListConstants.lastUpdatedBy) {
      updatedHeaders.push({
        headerName: key,
        displayText: value.displayText,
        sortDirection: value.sortDirection,
        size: 3,
        offset: 0.15,
      });
    } else if (key === ListConstants.numProducts) {
      updatedHeaders.push({
        headerName: key,
        displayText: value.displayText,
        sortDirection: value.sortDirection,
        size: 1.75,
        offset: 0.15,
      });
    } else if (key === ListConstants.numDiscontinued) {
      updatedHeaders.push({
        headerName: key,
        displayText: value.displayText,
        sortDirection: value.sortDirection,
        size: 2.5,
        offset: 0.15,
      });
    }
  }

  return updatedHeaders;
};

const convertListOfListsHeadersMobile = (
  existingHeaders: ListOfListsHeaders,
): SortableSectionHeader[] => {
  const updatedHeaders: SortableSectionHeader[] = [];
  // key: headerName, value: displayText, sortDirection
  // size + offset values add up to 12
  for (const [key, value] of Object.entries(existingHeaders)) {
    if (key === ListConstants.listName) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
      });
    } else if (key === ListConstants.lastUpdatedBy) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
      });
    } else if (key === ListConstants.numProducts) {
      updatedHeaders.push({
        headerName: key,
        displayText: value.displayText,
        sortDirection: value.sortDirection,
        size: 2,
        offset: 0.65,
      });
    } else if (key === ListConstants.numDiscontinued) {
      updatedHeaders.push({
        headerName: key,
        displayText: value.displayText,
        sortDirection: value.sortDirection,
        size: 3.7,
        offset: 0.65,
      });
    }
  }
  return updatedHeaders;
};

export const selectListsForHomePageTile = () =>
  createSelector(
    selectLists('', true),
    selectManagedByUsfLists(),
    selectRecentlyViewedLists(),
    selectListStatesAreLoaded(),
    selectUserKind,
    (
      lists: List[],
      managedByUsfLists: ListsViewModel[],
      recentlyViewedLists: ListsViewModel[],
      isListDataLoaded: boolean,
      userKind: UserKinds,
    ): ListsDataForHomePage => {
      const dataToReturn = {
        allLists: lists,
        recentlyViewedLists,
        managedByUsfLists,
        isListDataLoaded,
        userKind,
      };
      return dataToReturn;
    },
  );

export const selectListItemsWithSameProductNumber = (
  productNumber: number,
  listStatusIndicator: string,
) =>
  createSelector(
    listItemAdapter.getSelectors(selectListItemsState).selectEntities,
    selectListsWithUpdatedCounts,
    (listItems: Dictionary<ListItemState>, lists: Dictionary<List>) => {
      const iterableItems = Object.values(listItems);
      const returnableItems: ListItem[] = [];
      const listIds: number[] = [];
      iterableItems.forEach(item => {
        if (
          lists[item?.listKey?.listTypeId + '-' + item?.listKey?.listId]
            ?.listState === listStatusIndicator
        ) {
          if (
            !listIds.includes(item?.listKey?.listId) &&
            item?.productNumber === productNumber
          ) {
            listIds.push(item?.listKey?.listId);
          }
          if (item?.productNumber === productNumber) {
            returnableItems.push(item);
          }
        }
      });

      return {
        listItems: returnableItems,
        listIds,
      } as ReplaceItemsViewModel;
    },
  );

export const listsForInventory = (keyword?: string) =>
  createSelector(
    selectLists(keyword, true),
    selectManagedByUsfLists(keyword),
    (lists: List[], managedByUsfLists: ListsViewModel[]) => {
      return (lists as ListsViewModel[])
        .concat(managedByUsfLists)
        .sort((a, b) =>
          a?.listName?.toUpperCase() > b?.listName?.toUpperCase() ? 1 : -1,
        );
    },
  );

export const selectListNameBlackList = createSelector(
  selectListsWithUpdatedCounts,
  selectOrderGuides(),
  showOrderGuides,
  (
    lists: Dictionary<ListState>,
    orderGuides: ListState[],
    showOrderGuides: boolean,
  ): Set<string> => {
    const listNameBlackList: Set<string> = new Set();
    if (showOrderGuides) {
      orderGuides?.forEach((orderguide: ListsViewModel) => {
        if (
          orderguide?.listKey?.listTypeId === ListConstants.orderGuideListType
        ) {
          listNameBlackList.add(`orderguide#${orderguide.listKey.listId}`);
        }
      });
    }
    for (let key in lists) {
      let list = lists[key];
      if (!!list?.listName) {
        listNameBlackList.add(list.listName.toLowerCase());
      }
    }
    return listNameBlackList;
  },
);

const listNameHasSearchKey = (listName: string, searchKey: string) => {
  return listName?.toUpperCase().includes((searchKey || '').toUpperCase());
};
