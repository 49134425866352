<section class="create-opportunity-form-container">
  <form [formGroup]="createOpportunityForm">
    <div class="oportunity-note-title-control">
      <label class="is-required" data-cy="create-opportunity-modal-form2-text">
        {{
          'i18n.productDetail.createAnOpportunityModal.form2.noteTitle'
            | translate
        }}</label
      >
      <ion-select
        *ngIf="(platformService?.isTouchExperience$ | async) === false"
        [interfaceOptions]="{ cssClass: 'opportunity-popover-desktop' }"
        interface="popover"
        formControlName="noteTitle"
        placeholder="{{
          'i18n.productDetail.createAnOpportunityModal.form2.selectTitle'
            | translate
        }}"
        [ngClass]="'popover-closed'"
      >
        <ion-select-option *ngFor="let title of titleOptions" [value]="title">
          {{ title }}
        </ion-select-option>
      </ion-select>
      <br />
      <ion-button
        *ngIf="platformService?.isTouchExperience$ | async"
        fill="clear"
        (click)="openTitleOptionsInTouchMode()"
        data-cy="create-opportunity-modal-form2-selected-title-button"
      >
        <span data-cy="create-opportunity-modal-form2-selected-title-text">
          {{
            (selectedTitle
              ? selectedTitle
              : 'i18n.productDetail.createAnOpportunityModal.form2.selectTitle'
            ) | translate
          }}
        </span>
        <ion-icon
          name="chevron-forward-outline"
          data-cy="create-opportunity-modal-form2-selected-title-icon"
        ></ion-icon>
      </ion-button>
    </div>

    <div class="oportunity-note-customer-control">
      <label
        class="is-required"
        data-cy="create-opportunity-modal-form2-note-to-customer-text"
        >{{
          'i18n.productDetail.createAnOpportunityModal.form2.noteToCustomer'
            | translate
        }}</label
      >
      <ion-textarea
        formControlName="noteToCustomer"
        maxlength="490"
        type="text"
        appTestId="create-opportunity-noteToCustomer"
        autoGrow="true"
        rows="1"
        [class.invalid-input]="isNoteToCustomerInvalid"
      ></ion-textarea>
      <p
        class="additional-text text-align-right"
        data-cy="additional-text-note-to-customer-value-text"
      >
        {{ form?.noteToCustomer?.value?.length }}/490
      </p>
    </div>

    <div class="opportunity-calendar-control">
      <label
        class="title is-required"
        data-cy="create-opportunity-modal-form2-valid-through-text"
        >{{
          'i18n.productDetail.createAnOpportunityModal.form2.validThrough'
            | translate
        }}</label
      >
      <section class="date-selection">
        <ion-icon
          name="calendar-outline"
          data-cy="date-selection-icon"
        ></ion-icon>
        <label data-cy="valid-through-date-selected-text">{{
          'i18n.productDetail.createAnOpportunityModal.form2.validThroughSelected'
            | translate
        }}</label>
        {{ form?.validThrough?.value | date: 'M/dd/yyyy' : 'GMT' }}
      </section>
      <ion-datetime
        id="date"
        slot="content"
        presentation="date"
        [min]="minDate"
        [max]="maxDate"
        [value]="selectedValidThrough"
        (ionChange)="updateFormDate($event)"
      ></ion-datetime>
    </div>
  </form>
</section>
