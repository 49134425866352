import {
  MyOrdersRowType,
  NoOrderRow,
  OrderRow,
} from '@shared/models/my-orders-view.model';
import { OrderHeader, OrderStatus } from '@usf/ngrx-order';
import { OrderReviewPreferences } from '@usf/order-types';
import { UserKinds } from '@usf/user-types/user';

export class OrderReviewedService {
  /**
   * Checks if orders have been viewed within the My Orders Page
   * based off of the given order and reviewed order entries
   *
   * @param userKind for assessing if the user is internal
   * @param orderHeader contains several fields for orderViewed assesment
   * @param orderRow given full order row
   * @param reviewedOrders all order which have been reviewed
   * @returns true if the order has been viewed, changed and userKind is internal
   */
  static isMyOrderViewed = (
    userKind: UserKinds,
    orderHeader: OrderHeader,
    orderRow: OrderRow,
    reviewedOrders?: OrderReviewPreferences[],
  ): boolean => {
    let viewedFlag = false;

    if (userKind !== UserKinds.Internal) {
      return viewedFlag;
    } else if (
      typeof orderHeader.tandemOrderNumber !== undefined &&
      Array.isArray(reviewedOrders) &&
      reviewedOrders?.length > 0
    ) {
      const reviewedIndex = reviewedOrders?.findIndex(
        reviewedOrder =>
          reviewedOrder?.tandemOrderNumber == orderHeader?.tandemOrderNumber,
      );
      if (reviewedIndex >= 0) {
        const reviewedOrder = reviewedOrders[reviewedIndex];
        viewedFlag =
          OrderReviewedService.checkForStatusChange(
            orderHeader,
            reviewedOrder,
          ) &&
          orderHeader?.totalDollars == reviewedOrder?.totalDollars &&
          reviewedOrder?.totalEachesOrdered ==
            orderRow?.tallies?.totalEachesOrdered &&
          reviewedOrder?.totalEachesReserved ==
            orderRow?.tallies?.totalEachesReserved &&
          reviewedOrder?.totalUnitsOrdered ==
            orderRow?.tallies?.totalCasesOrdered &&
          reviewedOrder?.totalUnitsReserved ==
            orderRow?.tallies?.totalCasesReserved &&
          orderHeader?.purchaseOrderNumber ==
            reviewedOrder?.purchaseOrderNumber &&
          OrderReviewedService.didDatesNotChange(orderHeader, reviewedOrder);
      }
    }
    return viewedFlag;
  };

  /**
   * Checks if orders have been viewed within the Order Status Page
   * based off of the given order and its corresponding reviewed order entry
   *
   * @param userKind for assessing if the user is internal
   * @param order given order
   * @param reviewedOrders corresponding reviewed order entry
   * @returns true if the order has been viewed, changed and userKind is internal
   */
  static isOrderStatusRowViewed = (
    userKind: UserKinds,
    order: OrderRow | NoOrderRow,
    reviewedOrders?: OrderReviewPreferences[],
  ): boolean => {
    if (order.rowType === MyOrdersRowType.OrderRow) {
      return OrderReviewedService.isMyOrderViewed(
        userKind,
        order.orderHeader,
        order as OrderRow,
        reviewedOrders,
      );
    }

    return false;
  };

  /**
   * Checks if the order status has changed.
   *
   * @param order given order to check order status
   * @param reviewedOrder corresponding reviewed order entry
   * @returns true if the order status fields have not changed
   */
  static checkForStatusChange = (
    order: OrderHeader,
    reviewedOrder: OrderReviewPreferences,
  ): boolean => {
    if (
      (order?.orderStatus == OrderStatus.SHIPPED ||
        order?.orderStatus == OrderStatus.SUBMITTED_CREDIT_HOLD) &&
      reviewedOrder?.orderStatus == OrderStatus.SUBMITTED
    ) {
      return true;
    }

    return order?.orderStatus === reviewedOrder?.orderStatus;
  };

  /**
   * Checks if any date field has changed based off of the given order,
   * and its corresponding reviewed order entry
   *
   * @param order given order
   * @param reviewedOrder corresponding reviewed order entry
   * @returns true if the date fields have not changed
   */
  static didDatesNotChange = (
    order: OrderHeader,
    reviewedOrder: OrderReviewPreferences,
  ): boolean => {
    const orderConfirmedDeliverDate = OrderReviewedService.getFormattedDate(
      order?.confirmedDeliveryDate.toString(),
    );
    const orderRequestedDeliveryDate = OrderReviewedService.getFormattedDate(
      order?.requestedDeliveryDate.toString(),
    );
    const reviewedConfirmedDate = OrderReviewedService.getFormattedDate(
      reviewedOrder?.confirmedDeliveryDate.toString(),
    );
    const reviewedRequestedDate = OrderReviewedService.getFormattedDate(
      reviewedOrder?.requestedDeliveryDate.toString(),
    );

    return (
      orderConfirmedDeliverDate === reviewedConfirmedDate &&
      orderRequestedDeliveryDate === reviewedRequestedDate
    );
  };

  /**
   * Helper function to remove the time section of a date string
   *
   * @param date to change formatting
   * @returns a date string in the formatting of mm-dd-yyy
   */
  static getFormattedDate = (date: string): string => {
    return date.split('T', 2)[0];
  };
}
