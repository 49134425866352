<section
  *ngIf="minimum > 0 || target > 0 || maximum > 0"
  class="pricings-section"
>
  <h4 data-cy="seller-detail-pricing-text">
    {{ 'i18n.productDetail.sellerDetail.pricing' | translate }}
  </h4>
  <dl>
    <dt
      *ngIf="sellerPriceDetail?.districtNgpCprInd !== 'Y'"
      data-cy="seller-detail-minimum-pricing-text"
    >
      {{ 'i18n.productDetail.sellerDetail.minimum' | translate }}
    </dt>
    <dd
      *ngIf="sellerPriceDetail?.districtNgpCprInd !== 'Y'"
      data-cy="seller-detail-minimum-pricing-value-text"
    >
      {{ minimum | formatCurrencyAndDecimalPipe }}
      {{ this.product?.summary?.priceUom }}
    </dd>
    <dt data-cy="seller-detail-target-pricing-text">
      {{ 'i18n.productDetail.sellerDetail.target' | translate }}
    </dt>
    <dd data-cy="seller-detail-target-pricing-value-text">
      {{ target | formatCurrencyAndDecimalPipe }}
      {{ this.product?.summary?.priceUom }}
    </dd>
    <dt data-cy="seller-detail-maximum-pricing-text">
      {{
        (sellerPriceDetail?.districtNgpCprInd === 'Y'
          ? 'i18n.productDetail.sellerDetail.optimal'
          : 'i18n.productDetail.sellerDetail.maximum'
        ) | translate
      }}
    </dt>
    <dd data-cy="seller-detail-maximum-pricing-value-text">
      {{ maximum | formatCurrencyAndDecimalPipe }}
      {{ this.product?.summary?.priceUom }}
    </dd>
    <ng-container *ngFor="let data of pricingDetailsTwo; let i = index">
      <ng-container *ngIf="data.value">
        <dt
          [class.pricing-section-two]="i === 0"
          [attr.data-cy]="'pricing-section-two-' + data?.keyLabel + '-text'"
        >
          {{ data.keyLabel }}:
        </dt>
        <dd
          [class.pricing-section-two]="i === 0"
          [attr.data-cy]="'pricing-section-two-' + data?.keyLabel + '-text'"
        >
          {{ data.value }}
        </dd>
      </ng-container>
    </ng-container>
  </dl>
</section>
