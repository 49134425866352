<section class="create-opportunity-form-container">
  <form [formGroup]="createOpportunityForm">
    <div class="publish-opportunity-control">
      <label data-cy="create-opportunity-modal-form-text">{{
        'i18n.productDetail.createAnOpportunityModal.form.publishOpportunity'
          | translate
      }}</label>
      <ion-radio-group
        appTestId="create-opportunity-publish"
        formControlName="publishOption"
        class="radio-group"
        data-cy="create-opportunity-publish-radio-button"
      >
        <div class="publish-control">
          <ng-container *ngFor="let group of publishOptions">
            <ion-radio
              mode="md"
              value="{{ group.text }}"
              data-cy="publish-options-radio-button"
            ></ion-radio>
            <ion-label data-cy="publish-options-label-text">{{
              group.text
            }}</ion-label>
          </ng-container>
        </div>
      </ion-radio-group>
    </div>
    <div class="oportunity-name-control">
      <label
        id="opportunity-name"
        class="is-required"
        data-cy="oportunity-name-control-text"
      >
        {{
          'i18n.productDetail.createAnOpportunityModal.form.opportunityName'
            | translate
        }}</label
      >
      <ion-textarea
        aria-labelledby="opportunity-name"
        formControlName="name"
        type="text"
        appTestId="create-opportunity-name"
        maxlength="120"
        autoGrow="true"
        rows="1"
        [class.invalid-input]="
          (createOpportunityForm.controls['name'].touched ||
            createOpportunityForm.controls['name'].dirty) &&
          !invalidName
        "
      >
      </ion-textarea>

      <span
        class="char-count"
        data-cy="char-count-text"
        *ngIf="
          !!createOpportunityForm.controls['name'].touched ||
          !!createOpportunityForm.controls['name'].value
        "
        >{{ createOpportunityForm.controls['name'].value?.length }}/120
      </span>
    </div>

    <div class="weekly-quantity-control">
      <label
        id="opportunity-weekly-quantity"
        class="is-required"
        data-cy="weekly-quantity-control-text"
        >{{
          'i18n.productDetail.createAnOpportunityModal.form.opportunityWeeklyQuantity'
            | translate
        }}</label
      >
      <span
        class="weekly-quantity-error-label"
        data-cy="weekly-quantity-error-label-text
"
        *ngIf="
          (createOpportunityForm.controls['weeklyCases'].touched ||
            createOpportunityForm.controls['weeklyCases'].dirty ||
            createOpportunityForm.controls['weeklyEaches']?.touched ||
            createOpportunityForm.controls['weeklyEaches']?.dirty) &&
          invalidQuantity
        "
      >
        {{
          'i18n.productDetail.createAnOpportunityModal.form.opportunityQuantityError'
            | translate
        }}</span
      >
      <div class="input-container">
        <ion-input
          aria-labelledby="opportunity-weekly-quantity"
          appTestId="create-opportunity-weekly-cases"
          formControlName="weeklyCases"
          numeric
          [class.invalid-input]="
            (createOpportunityForm.controls['weeklyCases'].touched ||
              createOpportunityForm.controls['weeklyCases'].dirty ||
              createOpportunityForm.controls['weeklyEaches'].touched ||
              createOpportunityForm.controls['weeklyEaches'].dirty) &&
            invalidQuantity
          "
        >
        </ion-input>

        <span
          class="unit-text-helper"
          data-cy="create-opportunity-form-cases-text"
          >{{
            'i18n.productDetail.createAnOpportunityModal.form.cs' | translate
          }}</span
        >
      </div>
      <ng-container *ngIf="showEaches">
        <div class="input-container">
          <ion-input
            appTestId="create-opportunity-weekly-eaches"
            formControlName="weeklyEaches"
            numeric
            [class.invalid-input]="
              (createOpportunityForm.controls['weeklyCases'].touched ||
                createOpportunityForm.controls['weeklyCases'].dirty ||
                createOpportunityForm.controls['weeklyEaches'].touched ||
                createOpportunityForm.controls['weeklyEaches'].dirty) &&
              invalidQuantity
            "
          >
          </ion-input>
          <span
            class="unit-text-helper"
            data-cy="create-opportunity-form-eaches-text"
            >{{
              'i18n.productDetail.createAnOpportunityModal.form.ea' | translate
            }}</span
          >
        </div>
      </ng-container>
    </div>
    <div class="weekly-potential-control">
      <label data-cy="opportunity-weekly-potential-text">{{
        'i18n.productDetail.createAnOpportunityModal.form.opportunityWeeklyPotential'
          | translate
      }}</label>
      <div class="input-container">
        <img src="assets/icon/currency-prefix.svg" />
        <ion-input
          appTestId="create-opportunity-weekly-potential"
          formControlName="weeklyPotential"
          decimal
          readonly
        >
        </ion-input>
      </div>
      <p
        class="additional-text"
        data-cy="opportunity-weekly-potential-message-text"
      >
        {{
          'i18n.productDetail.createAnOpportunityModal.form.opportunityWeeklyPotentialMessage'
            | translate
        }}
      </p>
    </div>
    <div class="opportunity-calendar-control">
      <label
        class="title is-required"
        data-cy="opportunity-estimated-ship-date-text"
        >{{
          'i18n.productDetail.createAnOpportunityModal.form.opportunityEstimatedShipDate'
            | translate
        }}</label
      >
      <section class="date-selection">
        <ion-icon
          name="calendar-outline"
          data-cy="date-selection-icon"
        ></ion-icon>
        <label data-cy="opportunity-selected-ship-date-text">{{
          'i18n.productDetail.createAnOpportunityModal.form.opportunitySelectedShipDate'
            | translate
        }}</label>
        {{ form?.shipDate?.value | date: 'M/dd/yyyy':'GMT' }}
      </section>
      <ion-datetime
        id="date"
        slot="content"
        presentation="date"
        [min]="minDate"
        [max]="maxDate"
        [value]="selectedShipDate"
        (ionChange)="updateFormDate($event)"
      ></ion-datetime>
    </div>
  </form>
</section>
