import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  MessageTypeEnum,
  UpsertTypeEnum,
} from 'src/app/ngrx-message/constants/messageTypeEnum';
import { Message } from 'src/app/ngrx-message/models/client/message';
import { MessageStoreService } from 'src/app/ngrx-message/services/message/message-store.service';
import { PATHS } from '../constants/paths';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  currentUrl$: any;
  currentUrl: string;
  previousUrl$: any;
  prevuiousUrl: string;
  constructor(
    private messageStoreService: MessageStoreService,
    private router: Router,
  ) {}
  /** On error, sends error message to message service,
   * forces logout when authentication error occurs,
   * emits error otherwise
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        let errorValues = {};
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          if (error?.error?.code === '1005') {
            // access denied to server - should no longer happen w/ R4 no longer being restricted
            errorMessage = `Error Code: ${error.error.code}\nMessage: ${error.error.message}`;
            errorValues = {
              status: Number(error.error.code),
              message: error.error.message,
            };
          }
          if (error?.status === 418) {
            // Using 418 since it's an obscure error code, when you need to see the error response form the api.
            errorMessage = `Error Code: ${error.error.code}\nMessage: ${error.error.message}`;
            errorValues = {
              status: Number(error.error.code),
              message: error.error.message,
            };
          } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            errorValues = {
              status: error.status,
              message: error.message,
            };
          }
          if (error.status === 401) {
            this.router.navigate([PATHS.LOGIN_ERROR], {
              queryParams: { errorStatus: error?.status },
            });
            console.log(
              '***** [HttpErrorInterceptor] NAVIGATED TO ERROR PAGE CALLED',
            );
          }
        }

        const message = {
          type: MessageTypeEnum.error,
          display: errorMessage,
          stack: new Error(error.message).stack,
          watermark: new Date().toUTCString(),
          read: true,
        } as Message;
        this.messageStoreService.upsertMessage(message, UpsertTypeEnum.http);
        return throwError(() => errorValues);
      }),
    );
  }
}
