import { Injectable } from '@angular/core';
import { getAvailableCases, getAvailableEaches } from '@usf/ngrx-product';
import {
  ProductInventory,
  ProductPropertiesEnum,
  ProductSummary,
} from '@usf/product-types';
import { TranslateService } from '@ngx-translate/core';
import {
  daysInTheFuture,
  isCurrentDate,
} from '@product-detail/utils/po-dates-util';

@Injectable({
  providedIn: 'root',
})
export class ProductInventoryService {
  productEnum = ProductPropertiesEnum;

  constructor(private translateService: TranslateService) {}

  getInventoryValueFormat(
    productInventory: ProductInventory,
    displayEaches: boolean,
  ): string {
    if (displayEaches && !productInventory?.eachConversionFactor) {
      return '0';
    } else {
      return this.formatAvailableItems(
        this.getAvailableItems(productInventory, displayEaches),
      );
    }
  }

  public getAvailableItems(
    productInventory: ProductInventory,
    displayEaches: boolean,
  ): number {
    if (!!productInventory) {
      return displayEaches
        ? getAvailableEaches(productInventory)
        : getAvailableCases(productInventory);
    }
    return 0;
  }

  public formatAvailableItems(availableItems: number): string {
    if (!availableItems || availableItems < 0) {
      return '0';
    }
    return availableItems > 999 ? `999+` : `${availableItems}`;
  }

  defineInventoryLabel(
    displayEaches: boolean,
    productProperties: Set<ProductPropertiesEnum>,
    productSummary: ProductSummary,
    productInventory: ProductInventory,
    isRestock?: boolean,
  ): string {
    const { supplierOutOfStock } = this.productEnum;
    let inventoryLabel = displayEaches
      ? 'i18n.productDetail.inventoryInfo.availableEaches'
      : isRestock
        ? ''
        : 'i18n.productDetail.inventoryInfo.availableCases';

    if (
      isRestock &&
      (productProperties?.has(supplierOutOfStock) ||
        !productInventory?.isInStock)
    ) {
      const nextRestockDate = productSummary?.poDates[0] ?? null;
      const timeAhead = daysInTheFuture(nextRestockDate);
      if (
        productSummary?.properties?.has(ProductPropertiesEnum.direct) &&
        isRestock &&
        timeAhead > 0 &&
        timeAhead <= 30
      ) {
        inventoryLabel = 'i18n.productDetail.inventoryInfo.nextPoDate';
      }
      if (
        productSummary?.properties?.has(ProductPropertiesEnum.direct) &&
        isRestock &&
        isCurrentDate(nextRestockDate)
      ) {
        inventoryLabel = 'i18n.productDetail.inventoryInfo.nextPoDate';
      }
      if (
        !productSummary?.properties?.has(ProductPropertiesEnum.direct) &&
        productSummary?.poDates?.length > 0
      ) {
        inventoryLabel = 'i18n.productDetail.inventoryInfo.nextExpectedRestock';
      }
    } else if (productSummary?.properties?.has(ProductPropertiesEnum.direct)) {
      return '';
    }
    return inventoryLabel;
  }

  checkIsPoDate(
    productProperties: Set<ProductPropertiesEnum>,
    productSummary: ProductSummary,
    productInventory: ProductInventory,
  ): boolean {
    const { supplierOutOfStock } = this.productEnum;

    if (
      productProperties?.has(supplierOutOfStock) ||
      !productInventory?.isInStock
    ) {
      if (productSummary?.poDates?.length > 0) {
        return true;
      }
    }
    return false;
  }

  // isRestock indicates if this is for the restock message or the cases/eaches message
  inventoryQuantityMessage(
    inventoryValue: string,
    displayEaches: boolean,
    productProperties: Set<ProductPropertiesEnum>,
    productSummary: ProductSummary,
    productInventory: ProductInventory,
    hideOutOfStock: boolean,
    isRestock?: boolean,
  ): string {
    const { direct, jit, ces, specialOrder, supplierOutOfStock } =
      this.productEnum;

    if (
      productProperties?.has(supplierOutOfStock) ||
      !productInventory?.isInStock
    ) {
      let timeAhead = 0;
      if (!!productSummary?.poDates && productSummary?.poDates.length > 0) {
        timeAhead = daysInTheFuture(productSummary.poDates[0]);
      }

      if (
        !!productSummary?.poDates &&
        isCurrentDate(productSummary?.poDates[0]) &&
        isRestock
      ) {
        return this.translateService.instant(
          'i18n.productDetail.inventoryInfo.today',
        );
      } else if (
        productProperties?.has(direct) &&
        isRestock &&
        timeAhead > 0 &&
        timeAhead <= 30
      ) {
        return productSummary.poDates[0].formattedArrivalDate;
      } else if (productProperties?.has(direct)) {
        return '';
      } else if (
        !productProperties?.has(direct) &&
        isRestock &&
        productSummary?.poDates?.length > 0
      ) {
        return productSummary.poDates[0].formattedArrivalDate;
      } else if (
        displayEaches &&
        productSummary.breakable &&
        +inventoryValue > 0
      ) {
        return inventoryValue;
      } else if (!isRestock && !hideOutOfStock) {
        return this.translateService.instant('i18n.productDetail.notAvailable');
      } else if (!isRestock && hideOutOfStock) {
        return this.translateService.instant('i18n.productDetail.available');
      }
    } else if (
      productProperties?.has(jit) ||
      productProperties?.has(ces) ||
      productProperties?.has(specialOrder)
    ) {
      return this.translateService.instant('i18n.productDetail.available');
    } else if (productProperties?.has(direct) && inventoryValue) {
      return '';
    } else {
      if (displayEaches && (!inventoryValue || inventoryValue === '0')) {
        return this.translateService.instant('i18n.productDetail.available');
      } else {
        return inventoryValue;
      }
    }
  }
}
