<div
  class="newtypeahead"
  *ngIf="
    this.typeaheadService.suggestedSearchTypeahead?.length > 0 ||
    this.typeaheadService.brandsTypeahead?.length > 0 ||
    this.typeaheadService.newProductsTypeahead?.length > 0
  "
>
  <div
    class="suggested"
    *ngIf="this.typeaheadService.suggestedSearchTypeahead?.length > 0"
  >
    <h3 class="h3-tile">
      {{ 'i18n.header.suggestedSearches' | translate }}
    </h3>
    <ul class="ul-cls">
      <li
        *ngFor="
          let item of this.typeaheadService.suggestedSearchTypeahead;
          let i = index
        "
        (click)="handleSearchBySuggestions(item)"
        [attr.data-cy]="'click-item-' + item"
        [class.active]="false"
        class="active"
      >
        <div [innerHTML]="item.highlightedValue">
          {{ item.displayValue }}
        </div>
      </li>
    </ul>
    <div
      class="divider"
      *ngIf="
        this.typeaheadService.newProductsTypeahead?.length > 0 ||
        (this.typeaheadService.brandsTypeahead?.length > 0 &&
          platformService?.platformType !==
            platformService?.platformEnum?.desktop)
      "
    ></div>
  </div>

  <div class="brands" *ngIf="this.typeaheadService.brandsTypeahead?.length > 0">
    <div
      class="divider"
      *ngIf="
        this.typeaheadService.newProductsTypeahead?.length > 0 &&
        platformService?.platformType !== platformService?.platformEnum?.desktop
      "
    ></div>
    <h3 class="h3-tile">{{ 'i18n.header.brands' | translate }}</h3>
    <ul class="ul-cls">
      <li
        *ngFor="
          let item of this.typeaheadService.brandsTypeahead;
          let i = index
        "
        (click)="handleBrandsItemClick(item)"
        [attr.data-cy]="'click-item-' + item.rawValue"
        [class.active]="false"
        class="active"
      >
        {{ item.rawValue }}
      </li>
    </ul>
  </div>

  <div
    class="products"
    *ngIf="this.typeaheadService.newProductsTypeahead?.length > 0"
    [style.border-left]="
      platformService?.platformType ===
        platformService?.platformEnum?.desktop &&
      (this.typeaheadService.brandsTypeahead?.length > 0 ||
        this.typeaheadService.suggestedSearchTypeahead?.length > 0)
        ? 'solid #b6b6b6 0.084rem'
        : null
    "
  >
    <div class="products-header">
      <h3 class="h3-tile">{{ 'i18n.header.products' | translate }}</h3>
      <ion-button
        (click)="viewAll(searchCatalog)"
        class="usf-green-link-button"
        expand="block"
        *ngIf="
          platformService?.platformType ===
          platformService?.platformEnum?.desktop
        "
      >
        {{ 'i18n.header.viewAll' | translate }}
      </ion-button>
    </div>

    <ul class="ul-cls">
      <li
        *ngFor="
          let item of this.typeaheadService.newProductsTypeahead;
          let i = index
        "
        (click)="handleProductSuggestionClick(item, i + 1)"
        [attr.data-cy]="'click-item-' + item.ec_product_id"
        [class.active]="false"
        class="active"
      >
        <div [innerHTML]="item.productName"></div>
      </li>
    </ul>
  </div>
</div>
