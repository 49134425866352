import { ListsPageData } from '../model/lists-page.model';

export const doesListNameExist = (
  listPageData: ListsPageData,
  listName: string,
) => {
  const trimmedName = listName.toLowerCase().replace(/\s/g, '');
  if (trimmedName === 'orderguide' || trimmedName === 'recentlypurchased') {
    return true;
  }
  if (listPageData?.managedByUsfLists?.length > 0) {
    if (
      trimmedName ===
      `orderguide#${listPageData.managedByUsfLists[0].listKey.listId}`
    ) {
      return true;
    }
  }
  const listNames: string[] = [];
  listPageData?.lists?.publicLists.forEach(list => {
    listNames.push(list.listName.toLowerCase());
  });
  listPageData?.lists?.privateLists.forEach(list => {
    listNames.push(list.listName.toLowerCase());
  });
  listPageData?.lists?.internalLists.forEach(list => {
    listNames.push(list.listName.toLowerCase());
  });
  return listNames.includes(listName.toLowerCase());
};

// TODO: Refactor places that use doesListNameExist to use this new method doesListNameExistV2
// Above method reconstructs black list every time we validate the list name
// Should only have to contruct the black list once and then pass it to this function
export const doesListNameExistV2 = (
  rawListNameInput: string,
  listNameBlackList: Set<string>,
) => {
  const listNameWithoutSpaces = rawListNameInput
    ?.toLowerCase()
    ?.replace(/\s/g, '');
  const lowerCaseNameWithoutTrailingLeadingSpaces = rawListNameInput
    ?.trim()
    ?.toLowerCase();
  const isOrderGuideWithExactNumber =
    listNameBlackList.has(listNameWithoutSpaces) &&
    listNameWithoutSpaces?.includes('orderguide');
  const isOrderGuideOrRecentlyPurchased =
    listNameWithoutSpaces === 'orderguide' ||
    listNameWithoutSpaces === 'recentlypurchased';
  return (
    listNameBlackList.has(lowerCaseNameWithoutTrailingLeadingSpaces) ||
    isOrderGuideWithExactNumber ||
    isOrderGuideOrRecentlyPurchased
  );
};
