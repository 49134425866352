<div class="loading-spinner-container">
  {{ loadingSpinnerModalMessage | translate }}
  <ion-spinner name="circles" data-cy="loading-spinner-icon"></ion-spinner>
</div>
<ion-button
  *ngIf="!!cancelButton"
  class="usf-fill-orange-button"
  data-cy="cancel-submitted-order-button"
  (click)="handleCancelClick()"
>
  {{ 'i18n.orderPage.cancel' | translate }}
</ion-button>
