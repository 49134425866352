export enum OpportunityFormSteps {
  step1 = 1,
  step2 = 2,
}

export enum OpportunityPublishOpportunity {
  YES = 'Yes',
  NO = 'No',
}

export enum OpportunityModalActions {
  SUBMIT = 'SUBMIT',
  CLOSE = 'CLOSE',
  CANCEL = 'CANCEL',
}
