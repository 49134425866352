import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DyfProductInfo } from '@order/models/dyf-view-model';
import { Shipment } from '@order/models/order-view-model';
import { PanAppState, Tracking } from '@panamax/app-state';
import { productWithAlternativesSelector } from '@shared/selectors/product.selectors';
import { OrderHeader, OrderItem } from '@usf/ngrx-order';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { Product } from 'src/app/shared/models/product.model';
import { SharedTrackingService } from 'src/app/shared/services/analytics/shared-tracking.service';
import { ORDER_ANALYTICS_ACTIONS } from './order-analytics.actions';
import { OrderAnalyticsMessages } from './order-analytics.constants';
import { OrderItemState } from '@usf/ngrx-order';
import {
  handPricingModalOpen,
  handPricingValueChange,
  defaultTransformer,
  orderPageLoad,
  shareViaEmailOrderOpenModal,
  shareViaEmailOrder,
} from './order-analytics.transformers';
import { InspiredPicksViewModel } from '@order/models/inspired-picks-view-model';
import { TrackingTypeEnum } from '@app/did-you-forget/tracking/shared/models/tracking-type-enum';
import { genericAnalyticsTransformer } from '@shared/tracking/analytics/generic-analytics-transformer';
import { CART_ANALYTICS_ACTIONS } from './cart/cart-analytics.actions';
import { submitOrder } from '../tracing/order-tracing.transformers';
import { ORDER_TRACING_ACTIONS } from '../tracing/order-tracing.actions';
import { genericTracingTransformer } from '@shared/tracking/tracing/generic-tracing-transformer';
import { OrderTracingService } from '../tracing/order-tracing.service';
import { ImportOrderAnalyticsMessages } from '@shared/components/import-order/tracking/analytics/import-order-upload-analytics.constants';
import { IMPORT_ORDER_ANALYTICS_ACTIONS } from '@shared/components/import-order/tracking/analytics/import-order-analytics.actions';
import { QuickOrderItem } from '@order/models/quick-order-entry-view-model';
import { MerchZoneViewModel } from '@order/models/merch-zone-view-model';
import { ReviewOrderMerchZone } from '@shared/constants/merchandising-constants';
import { QuantityType } from '@shared/models/quantity-type';
import { Actions, ofType } from '@ngrx/effects';
import { ShareEventActions } from '@usf/file-share-types';
import { deliveryInstructionsEditTrace } from '../tracing/trace-models/delivery-instructions-edit.trace';

@Injectable({
  providedIn: 'root',
})
export class OrderAnalyticsService {
  constructor(
    private store: Store,
    private panAppState: PanAppState,
    private actions$: Actions,
  ) {}

  /**
   * Gets the appropriate order ID for analytics tracking
   * Priority:
   * 1. Original split order ID (for split orders)
   * 2. Original order ID (for modified orders)
   * 3. Current order ID (default)
   */
  static getAnalyticsOrderId(orderHeader: OrderHeader) {
    const splitId = orderHeader?.origSplitOrderId;
    const origId = orderHeader?.origOrderId;
    const orderId = orderHeader?.orderId;

    if (splitId) return splitId;
    if (origId) return origId;
    return orderId;
  }

  trackHandPricingModalOpen(
    orderItem: OrderItem,
    productMap: Map<number, Product>,
    divisionNumber: number,
  ): void {
    const products = SharedTrackingService.analyticsProductData(
      [orderItem],
      productMap,
      divisionNumber,
    );
    const tracking: Tracking = {
      analytics: {
        data: {
          pageName: OrderAnalyticsMessages.pageName,
          event: OrderAnalyticsMessages.handPricingModalOpenEvent,
          capability: OrderAnalyticsMessages.capability,
          modalName: OrderAnalyticsMessages.modalName,
          products,
        },
        transformFunc: handPricingModalOpen,
      },
    };
    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackHandPricingModalOpen({ tracking }),
    );
  }

  trackHandPricingChange(
    orderItem: OrderItem,
    productMap: Map<number, Product>,
    divisionNumber: number,
  ): void {
    const products = SharedTrackingService.analyticsProductData(
      [orderItem],
      productMap,
      divisionNumber,
    );
    const tracking: Tracking = {
      analytics: {
        data: {
          pageName: OrderAnalyticsMessages.pageName,
          event: OrderAnalyticsMessages.handPricingValueChangeEvent,
          capability: OrderAnalyticsMessages.capability,
          products,
        },
        transformFunc: handPricingValueChange,
      },
    };
    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackHandPricingChange({ tracking }),
    );
  }

  trackDidYouForgetClick(resultRank: number, product: Product) {
    const products = [
      {
        attributes: product?.trackingAttributes,
        divisionApn: `${product?.summary?.divisionNumber}-${product?.summary?.productNumber}`,
      },
    ];

    const tracking = {
      analytics: {
        data: {
          products,
          results: {
            resultRank: resultRank,
          },
          page: {
            pageName: OrderAnalyticsMessages.pageName,
          },
          event: OrderAnalyticsMessages.didYouForgetClick,
          capability: OrderAnalyticsMessages.capability,
        },
        transformFunc: defaultTransformer,
      },
    } as Tracking;
    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackDidYouForgetClick({ tracking }),
    );
  }

  trackBuyMoreAndSaveClick() {
    const tracking = {
      analytics: {
        data: {
          event: OrderAnalyticsMessages.buyMoreAndSaveClick,
          activePromo: {
            promoId: OrderAnalyticsMessages.directBuyPromoId,
          },
          capability: OrderAnalyticsMessages.capability,
        },
        transformFunc: defaultTransformer,
      },
    } as Tracking;

    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackBuyMoreAndSaveClick({ tracking }),
    );
  }

  trackInspiredPicksClick(resultRank: number, product: Product) {
    const products = [
      {
        attributes: product?.trackingAttributes,
        divisionApn: `${product?.summary?.divisionNumber}-${product?.summary?.productNumber}`,
      },
    ];

    const tracking = {
      analytics: {
        data: {
          products,
          results: {
            resultRank: resultRank,
          },
          event: OrderAnalyticsMessages.inspiredPicksClick,
          capability: OrderAnalyticsMessages.capability,
        },
        transformFunc: defaultTransformer,
      },
    } as Tracking;
    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackInspiredPicksClick({ tracking }),
    );
  }

  trackJumpToDirectClick() {
    const tracking = {
      analytics: {
        data: {
          event: OrderAnalyticsMessages.buttonClick,
          capability: OrderAnalyticsMessages.capability,
          button: { buttonName: OrderAnalyticsMessages.jumpToDirectClick },
        },
        transformFunc: defaultTransformer,
      },
    } as Tracking;
    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.jumpToDirectClick({ tracking }),
    );
  }

  trackSearchForSubs(product: Product) {
    const tracking = {
      analytics: {
        data: {
          search: {
            term: product.summary.productDescTxtl
              .split(' ')
              .slice(0, 3)
              .join(' '),
            searchType: OrderAnalyticsMessages.searchForSubs,
          },
          event: OrderAnalyticsMessages.searchSubmit,
          capability: OrderAnalyticsMessages.productDiscoveryCapability,
          products: [
            {
              divisionApn: `${product.summary.divisionNumber}-${product.summary.productNumber}`,
              attributes: product.trackingAttributes,
            },
          ],
        },
        transformFunc: defaultTransformer,
      },
    } as Tracking;
    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackSearchForSubs({ tracking }),
    );
  }

  trackOrderPageLoad(
    orderHeader: OrderHeader,
    orderItemState: OrderItemState,
    dyfProductInfos: DyfProductInfo[],
    shipments: Shipment[],
    inspiredPicksVM: InspiredPicksViewModel,
    merchZoneVM: MerchZoneViewModel,
  ) {
    this.store
      .select(productWithAlternativesSelector)
      .pipe(
        take(1),
        map(productMap => {
          const orderItems = Object.values(orderItemState?.entities);
          const divisionNumber = orderHeader?.divisionNumber;
          const dyfProductNumbers = (dyfProductInfos || []).map(
            product => product?.product?.productNumber,
          );
          const inspiredPicksNumbers = (inspiredPicksVM?.products || []).map(
            product => product?.product?.productNumber,
          );
          const merchZoneProductNumbers = (merchZoneVM?.products || []).map(
            product => product?.product?.productNumber,
          );

          const products = SharedTrackingService.analyticsProductData(
            orderItems,
            productMap,
            divisionNumber,
            true,
          );

          const dyfProducts =
            SharedTrackingService.analyticsAdditionalProductData(
              dyfProductNumbers,
              productMap,
              divisionNumber,
              TrackingTypeEnum.didYouForget,
            );

          // If Dyf Products are displayed then don't track inspired picks products
          let inspiredPicksProducts = [];
          if (!dyfProducts?.length) {
            inspiredPicksProducts =
              SharedTrackingService.analyticsAdditionalProductData(
                inspiredPicksNumbers,
                productMap,
                divisionNumber,
                TrackingTypeEnum.inspiredPicks,
              );
          }

          let merchZoneProducts = [];
          if (!dyfProducts.length && inspiredPicksProducts.length === 0) {
            let variant = '';
            if (
              merchZoneVM?.variant === ReviewOrderMerchZone.topSellerVariant
            ) {
              variant = OrderAnalyticsMessages.topSellers;
            } else if (
              merchZoneVM?.variant === ReviewOrderMerchZone.trendingNowVariant
            ) {
              variant = OrderAnalyticsMessages.trendingNow;
            }
            merchZoneProducts =
              SharedTrackingService.analyticsAdditionalProductData(
                merchZoneProductNumbers,
                productMap,
                divisionNumber,
                variant,
              );
          }

          return {
            analytics: {
              data: {
                products: [
                  ...products,
                  ...dyfProducts,
                  ...inspiredPicksProducts,
                  ...merchZoneProducts,
                ],
                order: {
                  id: OrderAnalyticsService.getAnalyticsOrderId(orderHeader),
                  numberOfShipments: shipments?.length,
                },
                page: {
                  pageName: OrderAnalyticsMessages.loadOrderPageName,
                },
                event: OrderAnalyticsMessages.pageLoadedEvent,
                capability: OrderAnalyticsMessages.capability,
              },
              transformFunc: orderPageLoad,
            },
          };
        }),
        tap(tracking =>
          this.store.dispatch(
            ORDER_ANALYTICS_ACTIONS.trackOrderPageLoad({ tracking }),
          ),
        ),
      )
      .subscribe();
  }

  trackRemoveFromOrder(
    trackingData: Tracking,
    updateOrderHeader: OrderHeader,
    total: number,
    origOrderItemsClone: OrderItem[],
    updateOrderItemSequence: any,
  ) {
    const tracking: Tracking = {
      analytics: {
        data: {
          ...trackingData?.analytics?.data,
          order: {
            id: OrderAnalyticsService.getAnalyticsOrderId(updateOrderHeader),
          },
          event: OrderAnalyticsMessages.removeFromOrder,
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };
    this.store.dispatch(
      CART_ANALYTICS_ACTIONS.createRemoveFromOrderTracking({
        trackingAnalytics: tracking,
        updateOrderHeader,
        total,
        origOrderItemsClone,
        updateOrderItemSequence,
      }),
    );
  }

  private getCapability(trackingData: Tracking): string {
    const addMethod = trackingData?.analytics?.data?.products[0]?.addMethod;

    const isProductDiscoveryCapability = [
      OrderAnalyticsMessages.sellerIncentives,
      OrderAnalyticsMessages.topSellersReviewOrder,
      OrderAnalyticsMessages.trendingNowReviewOrder,
    ].includes(addMethod);

    return isProductDiscoveryCapability
      ? OrderAnalyticsMessages.productDiscoveryCapability
      : OrderAnalyticsMessages.capability;
  }

  trackAddToOrder(
    trackingData: Tracking,
    updateOrderHeader: OrderHeader,
    total: number,
    origOrderItemsClone: OrderItem[],
    updateOrderItemSequence: any,
  ) {
    const tracking: Tracking = {
      analytics: {
        data: {
          ...trackingData.analytics.data,
          capability: this.getCapability(trackingData),
          event: OrderAnalyticsMessages.addToOrder,
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };
    this.store.dispatch(
      CART_ANALYTICS_ACTIONS.createAddToOrderTracking({
        trackingAnalytics: tracking,
        updateOrderHeader,
        total,
        origOrderItemsClone,
        updateOrderItemSequence,
      }),
    );
  }

  trackUpdateUqeQuantity(
    trackingData: Tracking,
    updateOrderHeader: OrderHeader,
    total: number,
    origOrderItemsClone: OrderItem[],
    updateOrderItemSequence: any,
  ) {
    const tracking: Tracking = {
      analytics: {
        data: {
          ...trackingData.analytics.data,
          capability: OrderAnalyticsMessages.capability,
          event: OrderAnalyticsMessages.updateQuantity,
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };
    this.store.dispatch(
      CART_ANALYTICS_ACTIONS.createUpdateUqeTracking({
        trackingAnalytics: tracking,
        updateOrderHeader,
        total,
        origOrderItemsClone,
        updateOrderItemSequence,
      }),
    );
  }

  trackSubmitOrderApiSuccess(trackingData: Tracking) {
    if (!trackingData.tracing.data.attributes?.errorDetails) {
      const tracking: Tracking = {
        analytics: {
          data: {
            ...trackingData.analytics.data,
            event: 'order submitted',
            capability: 'orders',
          },
          transformFunc: submitOrder,
        },
        tracing: {
          data: {
            ...trackingData?.tracing?.data,
            traceContext: 'order',
          },
          transformFunc: genericTracingTransformer,
        },
      };
      this.store.dispatch(
        ORDER_TRACING_ACTIONS.trackSubmitOrderApiResponseSuccess({
          tracking: OrderTracingService.deepRemoveUndefined(tracking),
        }),
      );
    }
  }

  trackImportOrderModalOpen(isOpen: boolean) {
    if (isOpen) {
      const tracking: Tracking = {
        analytics: {
          data: {
            event: ImportOrderAnalyticsMessages.importOrderModalEvent,
            modal: {
              name: ImportOrderAnalyticsMessages.modalName,
            },
            capability: ImportOrderAnalyticsMessages.capability,
          },
          transformFunc: genericAnalyticsTransformer,
        },
      };
      this.store.dispatch(
        IMPORT_ORDER_ANALYTICS_ACTIONS.trackImportOrderModalOpen({
          tracking: OrderTracingService.deepRemoveUndefined(tracking),
        }),
      );
    }
  }

  trackQOEAddToOrder(
    orderHeader: OrderHeader,
    quickOrderItem: QuickOrderItem,
    qtyType: QuantityType,
  ) {
    let qtyUnits =
      qtyType === QuantityType.Eaches
        ? QuantityType.Eaches
        : QuantityType.Cases;

    const tracking: Tracking = {
      analytics: {
        data: {
          event: OrderAnalyticsMessages.quickOrderEntryEvent,
          capability: OrderAnalyticsMessages.capability,
          order: {
            id: orderHeader?.orderId,
          },
          products: [
            {
              addMethod: OrderAnalyticsMessages.quickOrderEntryAddMethod,
              attributes: quickOrderItem?.product?.trackingAttributes,
              divisionApn: `${quickOrderItem?.product?.summary?.divisionNumber}-${quickOrderItem?.product?.summary?.productNumber}`,
              quantity:
                qtyUnits === QuantityType.Eaches
                  ? quickOrderItem?.eachesOrdered?.currentValue
                  : quickOrderItem?.casesOrdered?.currentValue,
              quantityUnits: qtyType,
            },
          ],
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };
    if (
      (quickOrderItem?.product?.orderable &&
        quickOrderItem?.casesOrdered?.currentValue >= 0 &&
        qtyType === QuantityType.Cases) ||
      (quickOrderItem?.eachesOrdered?.currentValue >= 0 &&
        quickOrderItem.product?.summary?.breakable &&
        quickOrderItem?.product?.orderable &&
        qtyType === QuantityType.Eaches)
    ) {
      this.store.dispatch(
        ORDER_ANALYTICS_ACTIONS.trackQoeProductAddToOrder({
          tracking: OrderTracingService.deepRemoveUndefined(tracking),
        }),
      );
    }
  }

  trackOrderInsightAddToOrder(
    orderHeader: OrderHeader,
    newQuantity: number,
    isEaches: boolean,
    productNumber: number,
    productsMap: Map<number, Product>,
  ) {
    this.actions$
      .pipe(
        ofType(ORDER_TRACING_ACTIONS.traceAddToOrder),
        take(1),
        distinctUntilChanged(),
        map(action => {
          let qtyUnits = isEaches ? QuantityType.Eaches : QuantityType.Cases;
          const addedProduct = productsMap.get(productNumber);
          const tracking: Tracking = {
            analytics: {
              data: {
                event: OrderAnalyticsMessages.quickOrderEntryEvent,
                capability: OrderAnalyticsMessages.capability,
                order: {
                  id: orderHeader?.orderId,
                },
                products: [
                  {
                    addMethod: OrderAnalyticsMessages.orderInsightsAddMethod,
                    insightType: OrderAnalyticsMessages.orderInsightType,
                    attributes: addedProduct?.trackingAttributes,
                    divisionApn: `${addedProduct?.summary?.divisionNumber}-${addedProduct?.summary?.productNumber}`,
                    quantity: newQuantity,
                    quantityUnits: qtyUnits,
                  },
                ],
              },
              transformFunc: genericAnalyticsTransformer,
            },
          };
          this.store.dispatch(
            ORDER_ANALYTICS_ACTIONS.trackOrderInsightProductAddToOrder({
              tracking: OrderTracingService.deepRemoveUndefined(tracking),
            }),
          );
          return action;
        }),
      )
      .subscribe();
  }

  trackMerchZoneClick(
    product: Product,
    resultRank: number,
    variant: ReviewOrderMerchZone,
  ) {
    const tracking: Tracking = {
      analytics: {
        data: {
          capability: OrderAnalyticsMessages.productDiscovery,
          event:
            variant === ReviewOrderMerchZone.topSellerVariant
              ? OrderAnalyticsMessages.topSellerClick
              : OrderAnalyticsMessages.trendingNowClick,
          results: {
            resultRank: resultRank,
          },
          products: [
            {
              divisionApn: `${product?.summary?.divisionNumber}-${product?.summary?.productNumber}`,
              attributes: product?.trackingAttributes,
            },
          ],
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };
    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackMerchZoneClick({ tracking }),
    );
  }

  trackFrequentlyBoughtTogetherDisplayed(
    products: {
      divisionApn: string;
      attributes: string;
      merchFeatureView: string;
      merchFeatureName: string;
      variant: string;
    }[],
  ) {
    const tracking: Tracking = {
      analytics: {
        data: {
          capability: OrderAnalyticsMessages.productDiscovery,
          event: OrderAnalyticsMessages.featureLoaded,
          engagement: {
            engagementType:
              OrderAnalyticsMessages.frequentlyBoughtTogetherDisplayed,
          },
          products,
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };

    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackFrequentlyBoughtTogetherDisplayed({
        tracking,
      }),
    );
  }

  trackFrequentlyBoughtTogetherView() {
    const tracking: Tracking = {
      analytics: {
        data: {
          capability: OrderAnalyticsMessages.productDiscovery,
          event: OrderAnalyticsMessages.featureEngagement,
          engagement: {
            engagementType: OrderAnalyticsMessages.frequentlyBoughtTogetherView,
          },
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };

    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackFrequentlyBoughtTogetherView({ tracking }),
    );
  }

  trackQoePageLoad(): void {
    let tracking: Tracking;
    tracking = {
      analytics: {
        data: {
          pageName: OrderAnalyticsMessages.qoePageName,
          capability: OrderAnalyticsMessages.capability,
          event: OrderAnalyticsMessages.pageLoadedEvent,
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };

    this.store.dispatch(ORDER_ANALYTICS_ACTIONS.trackQoePageLoad({ tracking }));
  }

  trackOrderInsightsPageLoad(
    productMap: Map<number, Product>,
    orderHeader: OrderHeader,
  ): void {
    const products = orderHeader.dyfProductsNotOrdered
      .map(prodNum => {
        const product = productMap.get(prodNum);
        return {
          divisionApn: `${product?.summary?.divisionNumber}-${product?.summary?.productNumber}`,
          insightType: 'dyf product',
        };
      })
      .concat(
        orderHeader.newProductsOrderedNotInRecentlyPurchased.map(prodNum => {
          const product = productMap.get(prodNum);
          return {
            divisionApn: `${product?.summary?.divisionNumber}-${product?.summary?.productNumber}`,
            insightType: 'new product',
          };
        }),
      );

    const tracking: Tracking = {
      analytics: {
        data: {
          event: OrderAnalyticsMessages.pageLoadedEvent,
          capability: OrderAnalyticsMessages.capability,
          page: {
            pageName: OrderAnalyticsMessages.orderInsightsPageName,
          },
          products,
          productCount: products.length,
          order: {
            id: orderHeader.orderId,
          },
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };

    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackOrderInsightsPageLoad({ tracking }),
    );
  }

  trackShareViaEmailOrderOpenModal(shareAction: string) {
    let modalName = '';
    switch (shareAction) {
      case ShareEventActions.ShareReviewOrder:
        modalName = OrderAnalyticsMessages.shareViaEmailReviewOrder;
        break;
      case ShareEventActions.ShareOrderConfirmation:
        modalName = OrderAnalyticsMessages.shareViaEmailOrderConfirmation;
        break;
      case ShareEventActions.ShareSubmittedOrder:
        modalName = OrderAnalyticsMessages.shareViaEmailSubmittedOrder;
        break;
    }
    const tracking: Tracking = {
      analytics: {
        data: {
          event: OrderAnalyticsMessages.shareViaEmailModalOpen,
          capability: OrderAnalyticsMessages.shareCapability,
          modal: {
            name: modalName,
          },
        },
        transformFunc: shareViaEmailOrderOpenModal,
      },
    };
    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackShareViaEmailOrderOpenModal({
        tracking,
      }),
    );
  }

  trackShareViaEmailOrder(shareAction: string) {
    let eventName = '';
    let fileType = '';
    switch (shareAction) {
      case ShareEventActions.ShareReviewOrder:
        eventName = OrderAnalyticsMessages.shareViaEmailReviewOrder;
        fileType = OrderAnalyticsMessages.shareViaEmailFileType;
        break;
      case ShareEventActions.ShareOrderConfirmation:
        eventName = OrderAnalyticsMessages.shareViaEmailOrderConfirmation;
        fileType =
          OrderAnalyticsMessages.shareViaEmailFileTypeOrderConfirmation;
        break;
      case ShareEventActions.ShareSubmittedOrder:
        eventName = OrderAnalyticsMessages.shareViaEmailSubmittedOrder;
        fileType = OrderAnalyticsMessages.shareViaEmailFileTypeSubmittedOrder;
        break;
    }
    const tracking: Tracking = {
      analytics: {
        data: {
          event: eventName,
          capability: OrderAnalyticsMessages.shareCapability,
          file: {
            type: fileType,
          },
        },
        transformFunc: shareViaEmailOrder,
      },
    };
    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackShareViaEmailOrder({
        tracking,
      }),
    );
  }

  trackAdditionalDeliveryInstructions(
    orderProductNumbers: any[],
    orderId: string,
  ) {
    const tracking: Tracking = {
      analytics: {
        data: {
          event: TrackingTypeEnum.deliveryInstructionEdit,
          capability: OrderAnalyticsMessages.direct,
          page: {
            pageName: OrderAnalyticsMessages.pageName,
          },
          products: orderProductNumbers,
          orderId: orderId,
        },
        transformFunc: defaultTransformer,
      },
      tracing: {
        data: {
          traceContext: 'order',
          event: TrackingTypeEnum.deliveryInstructionEdit,
          isEndOfTrace: true,
          isStartOfTrace: false,
          attributes: {
            timestamp: new Date().toISOString(),
            action: 'input',
            element: 'delivery_instructions_button',
          },
          products: orderProductNumbers,
          orderId: orderId,
        },
        transformFunc: deliveryInstructionsEditTrace,
      },
    } as Tracking;

    this.store.dispatch(
      ORDER_ANALYTICS_ACTIONS.trackAddAdditionalDeliveryInstructions({
        tracking,
      }),
    );
  }
}
