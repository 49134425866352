import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';

@Component({
  selector: 'app-cpr-manual-modal',
  templateUrl: './cpr-manual-modal.component.html',
  styleUrls: ['./cpr-manual-modal.component.scss'],
})
export class CprManualModalComponent {
  @Input() priceToUpdate: number;
  selectedReason: string;

  reasonOptions = [
    'i18n.productDetail.customPricingRationaleModal.manualModalReasonOptions.keepCompetitionOut',
    'i18n.productDetail.customPricingRationaleModal.manualModalReasonOptions.newLinesCustomer',
    'i18n.productDetail.customPricingRationaleModal.manualModalReasonOptions.qmi',
    'i18n.productDetail.customPricingRationaleModal.manualModalReasonOptions.other',
  ];

  selectPopoverOptions = {
    showBackdrop: false,
    cssClass: 'manual-pricing-popover',
    mode: 'ios',
    alignment: 'center',
  };

  constructor(
    private modalController: ModalController,
    readonly platformService: PlatformService,
  ) {}

  async dismissModal(value: boolean) {
    await this.modalController.dismiss({
      isManual: value,
      reason: this.selectedReason,
    });
  }
}
