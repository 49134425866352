<ng-container
  *ngIf="right?.searchPane?.searchableObservable$ | async as searchableList"
>
  <ion-label
    [attr.data-cy]="dataCyTag + '-seaarch-title'"
    *ngIf="!!right?.searchPane?.searchTitle"
    class="subtitle search-title"
  >
    {{ right?.searchPane?.searchTitle | translate }}
  </ion-label>
  <ion-searchbar
    *ngIf="!right?.searchPane?.hideSearch"
    [attr.data-cy]="dataCyTag + '-search-bar'"
    class="search"
    mode="md"
    placeholder="{{ right?.searchPane?.searchPlaceholder | translate }}"
    [(ngModel)]="right.searchPane.searchKey"
    (ionClear)="resetSearch()"
    (ionInput)="handleSearch()"
  >
  </ion-searchbar>
  <div
    data-cy="no-results-found"
    class="no-results-found"
    *ngIf="searchableList?.length === 0"
  >
    {{
      right?.searchPane?.noResultsOverride ??
        'i18n.lists.copyListModal.noResultsFound' | translate
    }}
  </div>

  <ion-radio-group
    *ngIf="searchableList?.length > 0"
    name="radio-selection"
    value="{{ right?.value }}"
    (ionChange)="handleRadioSelection($event)"
  >
    <ion-item-divider *ngIf="searchableList?.length > 0" class="clamp-height">
    </ion-item-divider>
    <ion-item
      *ngFor="let radioItem of searchableList"
      class="single-select-item"
    >
      <ion-radio
        mode="md"
        labelPlacement="start"
        value="{{ radioItem.value }}"
        [attr.data-cy]="dataCyTag + '-radio-button'"
        [disabled]="radioItem?.disable ?? false"
        class="single-select-radio"
        *ngIf="!radioItem.sortable"
      >
        <div
          class="radio-label item-subtitle-regular"
          [class.item-subtitle-regular]="radioItem.subLabel === undefined"
          [class.item-subtitle-bold]="radioItem.subLabel !== undefined"
        >
          {{ radioItem.label | translate }}
        </div>
        <div class="radio-subLabel item-caption">
          {{ radioItem.subLabel | translate }}
        </div>
      </ion-radio>

      <div
        class="sort-container"
        *ngIf="!!radioItem.sortable"
        (click)="handleRadioSelection({ detail: { value: radioItem.value } })"
        [class.selected]="
          radioItem.sortable.showASC || radioItem.sortable.showDSC
        "
      >
        <ion-label
          [class.selected]="
            radioItem.sortable.showASC || radioItem.sortable.showDSC
          "
        >
          {{ radioItem.label | translate }}
        </ion-label>
        <ion-icon
          *ngIf="radioItem.sortable.showNothing"
          [src]="'../../../../../../assets/icon/sort-by.svg'"
        ></ion-icon>

        <p *ngIf="radioItem.sortable.showASC">
          {{ 'i18n.common.sortAscending' | translate }}
        </p>
        <ion-icon
          *ngIf="radioItem.sortable.showASC"
          [src]="'../../../../../../assets/icon/sort-asc.svg'"
        ></ion-icon>

        <p *ngIf="radioItem.sortable.showDSC">
          {{ 'i18n.common.sortDescending' | translate }}
        </p>
        <ion-icon
          *ngIf="radioItem.sortable.showDSC"
          [src]="'../../../../../../assets/icon/sort-desc.svg'"
        ></ion-icon>
      </div>
    </ion-item>
  </ion-radio-group>
</ng-container>
