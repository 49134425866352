import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ProductSummary, ProductInventory } from '@usf/product-types';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import { ProductInventoryService } from '../../services/product-inventory.service';

@Component({
  selector: 'app-product-inventory-info',
  templateUrl: './product-inventory-info.component.html',
  styleUrls: ['./product-inventory-info.component.scss'],
})
export class ProductInventoryInfoComponent implements OnInit, OnChanges {
  @Input() productInventory: ProductInventory;
  @Input() productProperties: Set<ProductPropertiesEnum> = new Set();
  @Input() productSummary: ProductSummary;
  @Input() isRestock?: boolean = false;
  @Input() hideOutOfStock?: boolean;

  productEnum = ProductPropertiesEnum;
  isAvailable: boolean;
  isDiscontinued: boolean;
  inventoryValue: string;
  inventoryLabel = '';
  displayEaches = false;

  @Input()
  set eaches(value) {
    this.displayEaches = typeof value !== 'undefined' && value !== false;
  }

  constructor(private productInventoryService: ProductInventoryService) {}

  ngOnInit() {
    this.defineValues();
  }

  ngOnChanges() {
    this.defineValues();
  }

  defineValues(): void {
    this.checkInventoryAvailability();
    this.checkIsDiscontinued();
    this.getInventoryValue();
    this.defineInventoryLabel();
    this.inventoryValue = this.inventoryQuantityMessage();
  }

  checkInventoryAvailability(): void {
    this.isAvailable =
      this.productInventoryService.getAvailableItems(
        this.productInventory,
        this.displayEaches,
      ) > 0;
  }

  checkIsDiscontinued(): void {
    // Discontinued does not show inventory info
    this.isDiscontinued = this.productProperties?.has(
      this.productEnum.discontinued,
    );
  }

  defineInventoryLabel(): void {
    this.inventoryLabel = this.productInventoryService.defineInventoryLabel(
      this.displayEaches,
      this.productProperties,
      this.productSummary,
      this.productInventory,
      this.isRestock,
    );
  }

  getInventoryValue(): void {
    this.inventoryValue = this.productInventoryService.getInventoryValueFormat(
      this.productInventory,
      this.displayEaches,
    );
  }

  inventoryQuantityMessage(): string {
    return this.productInventoryService.inventoryQuantityMessage(
      this.inventoryValue,
      this.displayEaches,
      this.productProperties,
      this.productSummary,
      this.productInventory,
      this.hideOutOfStock,
      this.isRestock,
    );
  }
}
