import { createSelector } from '@ngrx/store';
import { Product } from 'src/app/shared/models/product.model';
import { productWithAlternativesSelector } from 'src/app/shared/selectors/product.selectors';
import { selectedCustomer } from 'src/app/ngrx-customer/store';
import { getAllPromotions } from '@usf/ngrx-promotions';
import { PromotionDetails } from '@usf/promotion-types';
import { ProductFilter } from '../../shared/helpers/product-filter.helpers';
import { ProductFilteringEnum } from '../../shared/constants/product-filtering.enum';
import { Customer } from '@usf/customer-types';
import { selectRevenueManagementState } from '@usf/ngrx-product';
import { MerchandisingProductViewModel } from '@shared/models/merchandising-product-view.model';
import { selectAllFeaturesEnabled } from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import { isProprietaryProduct } from '@product-detail/utils/product-summary.utils';

export const getPromosArray = createSelector(
  getAllPromotions(),
  (promotionDetails: PromotionDetails[]) => {
    return promotionDetails;
  },
);

export const selectDealsViewModel = createSelector(
  selectedCustomer,
  getPromosArray,
  productWithAlternativesSelector,

  (
    customer: Customer,
    promotionDetails: PromotionDetails[],
    productsMap: Map<number, Product>,
  ): Product[] => {
    let orderedProducts: Product[] = [];
    orderedProducts = filterProductsWithDeals(
      customer,
      promotionDetails,
      productsMap,
    );
    return orderedProducts;
  },
);

export const filterProductsWithDeals = (
  customer: Customer,
  promotionDetails: PromotionDetails[],
  productsMap: Map<number, Product>,
) => {
  let orderedProducts: Product[] = [];
  const productNumbers: number[] = [];

  // Collect product numbers for common filtering logic
  promotionDetails.forEach(element => {
    productNumbers.push(element.productNumber);
  });

  // Filter out products that dont pass shared filtering logic
  productsMap = ProductFilter.filterProductsMap(
    productsMap,
    productNumbers,
    customer,
    ProductFilteringEnum.PROMOTIONS,
  );

  // Decide whether to remove alternative
  productsMap.forEach(product => {
    const productAndAlternativeHavePromotions =
      product?.inventory?.productStatus === '0' &&
      product?.hasAlternative &&
      product?.promotionDetails &&
      product?.alternative?.product?.promotionDetails;
    if (!productAndAlternativeHavePromotions) {
      product = removeAlternativeFromProduct(product);
    }
    orderedProducts.push(product);
  });

  // Sort by product number
  orderedProducts.sort((a, b) => {
    return a.summary.productNumber < b.summary.productNumber ? -1 : 1;
  });
  return orderedProducts;
};

const removeAlternativeFromProduct = (product: Product) => {
  product = { ...product, hasAlternative: false };
  if (product?.productCardWarningMsg?.display) {
    product = {
      ...product,
      drawerMsg: {
        message: product.productCardWarningMsg.primaryText,
        secondaryMessage: product.productCardWarningMsg.secondaryText,
        secondaryMessageDate: product.productCardWarningMsg.secondaryTextDate,
        iconName: 'close-circle-outline',
        isWarning: true,
      },
    };
  }
  return product;
};

export const selectIsDealsAndProductDataLoaded = createSelector(
  getPromosArray,
  productWithAlternativesSelector,
  (
    promotionDetails: PromotionDetails[],
    productsMap: Map<number, Product>,
  ): boolean => {
    let isLoaded = true;
    promotionDetails.forEach((promotion: PromotionDetails) => {
      if (!productsMap.get(promotion.productNumber)) {
        isLoaded = false;
      }
    });
    return isLoaded;
  },
);

export const selectRevManWinbacks = createSelector(
  selectedCustomer,
  selectRevenueManagementState,
  productWithAlternativesSelector,
  selectAllFeaturesEnabled([FEATURES.split_dynamic_proprietary_ui.name]),
  (
    selectedCustomer,
    revenueManagementState,
    products,
    isProprietaryUIEnabled,
  ): MerchandisingProductViewModel[] => {
    const productViewModels: MerchandisingProductViewModel[] = [];

    if (
      revenueManagementState?.revenueManagementArray?.every(
        revenueManagement =>
          products.get(revenueManagement?.productNumber) !== undefined,
      )
    ) {
      revenueManagementState?.revenueManagementArray?.forEach(
        revenueManagement => {
          const product = products?.get(revenueManagement?.productNumber);

          if (
            !!product &&
            !isProprietaryProduct(product, isProprietaryUIEnabled) &&
            ProductFilter.applyFilter(
              product,
              selectedCustomer,
              ProductFilteringEnum.DEFAULT,
            )
          ) {
            if (!!product?.summary?.properties) {
              product.summary.properties = new Set([
                ...product?.summary?.properties,
                ...product?.summary?.additionalProperties,
              ]);
            }
            const productViewModel = {
              ...product,
              selectedCustomer,
            } as MerchandisingProductViewModel;
            productViewModels.push(productViewModel);
          }
        },
      );
    }

    return productViewModels;
  },
);
