import { Component, HostListener, Input } from '@angular/core';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { PlatformService } from '@panamax/app-state';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-product-detail-carousel',
  templateUrl: 'product-detail-carousel.component.html',
  styleUrls: ['product-detail-carousel.component.scss'],
})
export class ProductDetailCarouselComponent {
  @Input() product: ProductDetailViewModel;
  @Input() activeSlide: number;

  constructor(
    readonly platformService: PlatformService,
    private modalController: ModalController,
  ) {}

  async closeModal() {
    await this.modalController.dismiss();
  }
}
