export enum PdpLnkSrc {
  quickView = 'quickView',
  dyfQuickView = 'didYouForgetQuickView',
  inspiredPickQuickView = 'inspiredPicksQuickView',
  dyf = 'didYouForget',
  inspiredPicks = 'inspiredPicks',
  inspiredPicksQuickView = 'inspiredPicksQuickView',
  tmNotes = 'tmNotes',
  customersAlsoBought = 'customersAlsoBought',
  youMayAlsoNeed = 'youMayAlsoNeed',
  topSellers = 'topSellers',
  deals = 'deals',
  topSellersReviewOrder = 'topSellersReviewOrder',
  trendingNowReviewOrder = 'trendingNowReviewOrder',
  topSellersReviewOrderQuickView = 'topSellersReviewOrderQuickView',
  trendingNowReviewOrderQuickView = 'trendingNowReviewOrderQuickView',
  frequentlyBoughtTogether = 'frequentlyBoughtTogether',
  deliveries = 'deliveries',
  revMan = 'revmanWinbacks',
  recentlyViewed = 'recentlyViewed',
  topSellersNullSearchResults = 'topSellersNullSearchResults',
  sellerIncentives = 'sellerIncentives',
}
