import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ProductSummary } from '@usf/product-types';
import { PlatformEnum, PlatformService } from '@panamax/app-state';
import { ImageVariantEnum } from '../../models/image-variant.enum';
import { getCarouselImages } from '../../utils/product-images.util';
import { getCarouselVideos, VideoModel } from '../../utils/product-videos-util';
import { Capacitor } from '@capacitor/core';
import { ImageModel } from '@shared/models/image.model';
import { ProductDetailVideoPlayerService } from '@product-detail/services/product-detail-video-player.service';
import { Swiper } from 'swiper';
import { BehaviorSubject, combineLatest, filter, Subscription } from 'rxjs';
import { ProductDetailCarouselService } from '@product-detail/services/product-detail-carousel.service';

@Component({
  selector: 'app-product-detail-viewer',
  templateUrl: './product-detail-viewer.component.html',
  styleUrls: ['./product-detail-viewer.component.scss'],
})
export class ProductDetailViewerComponent implements OnInit, OnDestroy {
  //move this to the right file for the module it's declared in
  @ViewChild('mainImage') mainImageSwiperRef: any;
  @Input() product: ProductSummary;
  @Input() isQuickView? = false;
  @Input() isModalView? = false;
  @Input() activeSlide? = 0;
  @Input() sequenceVideoId?: string = '1';
  @Input() showNoImageDescription = false;
  @Output() emitterFromPDPViewer = new EventEmitter();

  carouselPDPSubscription: Subscription;
  carouselImageURLs: ImageModel[];
  carouselVideos: VideoModel[];
  slideContent: ProductImageVideo[] = [];

  swiperSubjectSub: Subscription;
  mainSwiperSubject$ = new BehaviorSubject<Swiper>(undefined);
  thumbsSwiperSubject$ = new BehaviorSubject<Swiper>(undefined);

  constructor(
    readonly platformService: PlatformService,
    private productDetailVideoPlayerService: ProductDetailVideoPlayerService,
    private productDetailCarouselService: ProductDetailCarouselService,
  ) {
    this.carouselPDPSubscription =
      this.productDetailCarouselService.carouselPDP$?.subscribe(activeSlide => {
        this.mainImageSwiperRef?.nativeElement?.swiper?.slideToLoop(
          Number(activeSlide),
        );
      });
  }

  ngOnInit() {
    this.carouselImageURLs = [];
    this.carouselVideos = [];
    this.setSlideContent();

    this.swiperSubjectSub = combineLatest([
      this.mainSwiperSubject$,
      this.thumbsSwiperSubject$,
    ])
      .pipe(
        filter(([mainSwiper, thumbsSwiper]) => !!mainSwiper && !!thumbsSwiper),
      )
      .subscribe(([mainSwiper, thumbsSwiper]) => {
        mainSwiper.thumbs.swiper = thumbsSwiper;
        mainSwiper.thumbs.update(true);
        mainSwiper.thumbs.init();
      });
  }

  ngOnDestroy() {
    this.carouselPDPSubscription?.unsubscribe();
    this.swiperSubjectSub?.unsubscribe();
  }

  setSlideContent(): void {
    this.carouselImageURLs = getCarouselImages(this.product);
    this.carouselVideos = getCarouselVideos(this.product);

    const allImages = this.carouselImageURLs
      .filter(
        image =>
          !!image.urls[ImageVariantEnum.XLarge] &&
          !!image.urls[ImageVariantEnum.Small],
      )
      .map(image => {
        return {
          mainImageUrl: image.urls[ImageVariantEnum.XLarge],
          thumbnailUrl: image.urls[ImageVariantEnum.Small],
        } as ProductImageVideo;
      });
    if (this.carouselVideos.length) {
      const video = {
        videoId: this.carouselVideos[0]?.videoId,
        thumbnailUrl: 'assets/icon/bright-cove-play-button.svg',
      } as ProductImageVideo;
      allImages.splice(3, 0, video);
    }

    this.slideContent = allImages;
  }

  isNative(): boolean {
    return Capacitor.isNativePlatform();
  }

  setMainSwiperInstance(swiper: Swiper) {
    this.mainSwiperSubject$?.next(swiper);
  }

  setThumbsSwiperInstance(swiper: Swiper) {
    this.thumbsSwiperSubject$?.next(swiper);
  }

  handleMainImageClick(): void {
    this.emitterFromPDPViewer.emit(
      this.mainImageSwiperRef?.nativeElement?.swiper?.activeIndex,
    );
  }

  beforeMainInit(swiper: Swiper) {
    // const thumbnailClass = this.isModalView
    //   ? `.thumb-zoom-swiper`
    //   : `.thumb-swiper-${this.product?.productNumber}`;
    // swiper.params.thumbs.swiper = thumbnailClass;

    const noSwipingSelector =
      this.platformService?.platformType === PlatformEnum.desktop
        ? 'app-usf-video-player'
        : '.vjs-control-bar';
    swiper.params.noSwipingSelector = noSwipingSelector;
    swiper.params.initialSlide = this.activeSlide;
  }

  handleImageError(content: ProductImageVideo) {
    content.mainImageUrl = 'assets/icon/restaurant.svg';
    content.thumbnailUrl = 'assets/icon/restaurant.svg';
  }

  slideChange() {
    this.productDetailVideoPlayerService.nextPausevideo(
      this.slideContent[
        this.mainImageSwiperRef?.nativeElement?.swiper?.activeIndex
      ]?.videoId,
    );

    this.syncPDPwithModalCarousel();
  }

  onClickThumbCarouselImg() {
    this.syncPDPwithModalCarousel();
  }

  syncPDPwithModalCarousel() {
    if (this.isModalView) {
      this.productDetailCarouselService.nextSetActiveSlide(
        this.mainImageSwiperRef?.nativeElement?.swiper?.activeIndex,
      );
    }
  }
}

export interface ProductImageVideo {
  mainImageUrl: string;
  videoId: string;
  thumbnailUrl: string;
}
