<ion-header
  *ngIf="(viewModel.isLoading$ | optionalAsync$: false | async) === false"
  class="ion-no-border"
  [ngClass]="
    platform === platformRef.desktop
      ? 'ion-header-modal-desktop'
      : platform === platformRef.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
  "
>
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="!!viewModel.navigationPageCount">
      <ion-button
        class="go-back"
        expand="full"
        [attr.data-cy]="dataCyTag + '-modal-back-button'"
        (click)="goBack()"
      >
        <ion-icon
          src="../../../../../assets/icon/chevron-left.svg"
          slot="end"
        ></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-buttons *ngIf="platform === platformRef.desktop" slot="end">
      <ion-button
        [attr.data-cy]="dataCyTag + '-modal-close-button'"
        (click)="dismiss(true)"
      >
        <ion-icon
          [attr.data-cy]="dataCyTag + '-close-modal-icon'"
          slot="icon-only"
          name="close-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label
      [attr.data-cy]="dataCyTag + '-dual-pane-modal-title'"
      *ngIf="platform === platformRef.mobile"
      class="title-bold"
    >
      {{
        (viewModel?.rightView?.title !== undefined
          ? viewModel?.rightView?.title
          : viewModel?.title
        ) | translate
      }}
    </ion-label>
    <div
      *ngIf="!viewModel.navigationPageCount"
      class="title-container"
      [class.mobile]="
        platform === platformRef.mobile &&
        (!!viewModel?.subTitle ||
          !!viewModel?.complexSubTitle ||
          !!viewModel.htmlSubTitle)
      "
    >
      <ion-label
        [attr.data-cy]="dataCyTag + '-dual-pane-modal-title'"
        *ngIf="platform !== platformRef.mobile"
        class="title-bold small-bump"
      >
        {{ viewModel?.title | translate }}
      </ion-label>
      <ion-label
        [attr.data-cy]="dataCyTag + '-dual-pane-modal-subtitle'"
        *ngIf="platform === platformRef.mobile && !!viewModel?.subTitle"
        class="subtitle pre-line"
      >
        {{
          (!!viewModel.navigationPageCount ? '' : viewModel?.subTitle)
            | translate
        }}
      </ion-label>
      <ion-label
        [attr.data-cy]="dataCyTag + '-dual-pane-modal-subtitle-html'"
        *ngIf="
          platform === platformRef.mobile &&
          !!viewModel?.htmlSubTitle &&
          !viewModel.navigationPageCount
        "
        class="subtitle pre-line"
        [innerHTML]="santizier.bypassSecurityTrustHtml(viewModel?.htmlSubTitle)"
      >
      </ion-label>
      <ion-label
        [attr.data-cy]="dataCyTag + '-dual-pane-modal-subtitle'"
        *ngIf="platform !== platformRef.mobile && !!viewModel?.subTitle"
        class="subtitle pre-line"
      >
        {{ viewModel?.subTitle | translate }}
      </ion-label>

      <ion-label
        [attr.data-cy]="dataCyTag + '-dual-pane-modal-subtitle-html'"
        *ngIf="platform !== platformRef.mobile && !!viewModel?.htmlSubTitle"
        class="subtitle pre-line"
        [innerHTML]="santizier.bypassSecurityTrustHtml(viewModel?.htmlSubTitle)"
      >
      </ion-label>
      <div
        [attr.data-cy]="dataCyTag + '-dual-pane-modal-complex-subtitle'"
        *ngIf="platform === platformRef.mobile && !!viewModel?.complexSubTitle"
        class="subtitle pre-line"
      >
        <ng-container *ngIf="!viewModel?.navigationPageCount">
          <ion-label
            [attr.data-cy]="dataCyTag + '-dual-pane-modal-subtitle'"
            *ngFor="let subtitleComponent of viewModel?.complexSubTitle"
            class="subtitle pre-line"
            [class.bold]="subtitleComponent.isBold"
          >
            {{ subtitleComponent.text | translate }}
          </ion-label>
        </ng-container>
      </div>
      <div
        [attr.data-cy]="dataCyTag + '-dual-pane-modal-complex-subtitle'"
        *ngIf="platform !== platformRef.mobile && !!viewModel?.complexSubTitle"
        class="subtitle pre-line"
      >
        <ion-label
          [attr.data-cy]="dataCyTag + '-dual-pane-modal-subtitle'"
          *ngFor="let subtitleComponent of viewModel?.complexSubTitle"
          class="subtitle pre-line"
          [class.bold]="subtitleComponent.isBold"
        >
          {{ subtitleComponent.text | translate }}
        </ion-label>
      </div>
    </div>
  </ion-toolbar>
</ion-header>

<ion-header
  *ngIf="(viewModel.isLoading$ | optionalAsync$: false | async) === true"
  class="ion-no-border"
  [ngClass]="
    platform === platformRef.desktop
      ? 'ion-header-modal-desktop'
      : platform === platformRef.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
  "
>
  <ion-toolbar>
    <ion-buttons *ngIf="platform === platformRef.desktop" slot="end">
      <ion-button
        [attr.data-cy]="dataCyTag + '-modal-close-button'"
        (click)="dismiss(true)"
      >
        <ion-icon
          [attr.data-cy]="dataCyTag + '-close-modal-icon'"
          slot="icon-only"
          name="close-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  [ngClass]="
    platform === platformRef.desktop
      ? 'ion-content-modal-desktop'
      : platform === platformRef.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
  "
  scroll-y="true"
>
  <div
    *ngIf="(viewModel.isLoading$ | optionalAsync$: false | async) === true"
    class="sorry-loading"
  >
    <ion-label class="loading">
      {{ 'i18n.common.loading' | translate | titlecase }}
    </ion-label>
    <ion-spinner
      class="spinner"
      name="circles"
      data-cy="loading-spinner"
    ></ion-spinner>
  </div>
  <div
    class="container"
    [class.mobile]="platform === platformRef.mobile"
    *ngIf="(viewModel.isLoading$ | optionalAsync$: false | async) === false"
  >
    <div
      class="left-col"
      [ngSwitch]="viewModel.leftView.view"
      [class.hide-for-mobile]="
        viewModel?.leftView?.view === dualPaneViewRef.noView
      "
    >
      <app-basic-list
        *ngSwitchCase="dualPaneViewRef.basicList"
        [dataCyTag]="dataCyTag"
        [dataItems]="viewModel.leftView.content"
        [title]="viewModel.leftView.title"
        [platform]="platform"
        (itemSelectedEvent)="basicListItemSelected($event)"
        (checkboxClicked)="leftCheckboxClicked($event)"
      ></app-basic-list>
    </div>
    <div class="right-col" [ngSwitch]="viewModel?.rightView?.view">
      <app-single-select-radio
        *ngSwitchCase="dualPaneViewRef.searchableItemRadio"
        [right]="viewModel.rightView"
        [left]="viewModel.leftView"
        [platform]="platform"
        [dataCyTag]="dataCyTag"
        (checkValidity)="validateSubmitOrSave()"
      >
      </app-single-select-radio>

      <app-pick-a-file
        *ngSwitchCase="dualPaneViewRef.filePicker"
        [right]="viewModel.rightView"
        [left]="viewModel.leftView"
        [platform]="platform"
        [dataCyTag]="dataCyTag"
        (fileAddEvent)="handleFileAddRequest()"
        (fileDeleteEvent)="handleFileDeleteRequest()"
        (checkValidity)="validateSubmitOrSave()"
      >
      </app-pick-a-file>

      <app-single-select-countable-item
        *ngSwitchCase="dualPaneViewRef.selectableCountableRadio"
        [right]="viewModel.rightView"
        [left]="viewModel.leftView"
        [platform]="platform"
        [dataCyTag]="dataCyTag"
        (checkValidity)="validateSubmitOrSave()"
      >
      </app-single-select-countable-item>
      <app-multi-select-checkbox
        *ngSwitchCase="dualPaneViewRef.multiSelectCheckbox"
        [right]="viewModel.rightView"
        [left]="viewModel.leftView"
        [platform]="platform"
        [dataCyTag]="dataCyTag"
        (checkValidity)="validateSubmitOrSave()"
      >
      </app-multi-select-checkbox>
      <app-single-select-product
        *ngSwitchCase="dualPaneViewRef.searchableProductRadio"
        [right]="viewModel.rightView"
        [left]="viewModel.leftView"
        [platform]="platform"
        [dataCyTag]="dataCyTag"
        (checkValidity)="validateSubmitOrSave()"
      >
      </app-single-select-product>
      <app-single-select-inventory
        *ngSwitchCase="dualPaneViewRef.searchableInventoryRadio"
        [right]="viewModel.rightView"
        [left]="viewModel.leftView"
        [platform]="platform"
        [dataCyTag]="dataCyTag"
        (checkValidity)="validateSubmitOrSave()"
      >
      </app-single-select-inventory>
      <app-multi-select-inventory
        *ngSwitchCase="dualPaneViewRef.multiSelectInventory"
        [right]="viewModel.rightView"
        [left]="viewModel.leftView"
        [platform]="platform"
        [dataCyTag]="dataCyTag"
        (checkValidity)="validateSubmitOrSave()"
      >
      </app-multi-select-inventory>
      <app-multi-input-and-label
        *ngSwitchCase="dualPaneViewRef.multiInput"
        [right]="viewModel.rightView"
        [left]="viewModel.leftView"
        [platform]="platform"
        [dataCyTag]="dataCyTag"
        (checkValidity)="validateSubmitOrSave()"
      >
      </app-multi-input-and-label>
    </div>
  </div>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="platform === platformRef.desktop"
  mode="md"
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        [attr.data-cy]="dataCyTag + '-cancel-button'"
        [ngClass]="
          platform === platformRef.mobile
            ? 'usf-outline-green-mobile-modal-button'
            : 'usf-outline-green-dsktp-tblt-modal-button'
        "
        (click)="dismiss()"
      >
        {{
          (!!viewModel.cancelText ? viewModel.cancelText : 'i18n.common.cancel')
            | translate
        }}
      </ion-button>

      <ion-button
        [attr.data-cy]="dataCyTag + '-next-button'"
        [ngClass]="
          (viewModel?.submitIsBackButton
            ? viewModel?.disableSave
            : viewModel?.disableSubmit) ||
          (viewModel.isLoading$ | optionalAsync$: false | async) === true
            ? platform === platformRef.mobile
              ? 'usf-fill-disabled-mobile-modal-button'
              : 'usf-fill-disabled-dsktp-tblt-modal-button'
            : platform === platformRef.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
        "
        (click)="
          viewModel.submitIsBackButton ? goBackAndSave() : submit(viewModel)
        "
      >
        {{
          (viewModel?.submitIsBackButton
            ? viewModel?.backText
            : viewModel?.submitText
          ) | translate
        }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<div
  *ngIf="this.outsideLifecyleOperator$ | async as manualChangeDetection"
></div>
