import { Injectable } from '@angular/core';
import { PanAppState } from '@panamax/app-state';
import { distinctUntilChanged } from 'rxjs/operators';
import { FEATURES } from '../constants/splitio-features';
import { Script } from '../models/script.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KiboTagService {
  kiboTagScript: Script = {
    name: 'kiboTag',
    src: environment.kibo?.src,
  };

  constructor(private panAppState: PanAppState) {}

  MONETATE_ID_COOKIE = 'mt.v=';

  public init() {
    this.panAppState
      .feature$([FEATURES.split_global_kibo_tag.name], false)
      .pipe(distinctUntilChanged())
      .subscribe(async enabled => {
        if (enabled) {
          await this.loadKiboTagScript();
        }
      });
  }

  async loadKiboTagScript(): Promise<Script> {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = this.kiboTagScript.src;
      script.onload = () => {
        resolve({ loaded: true, name: this.kiboTagScript.name });
      };

      script.onerror = error =>
        resolve({ loaded: false, name: this.kiboTagScript.name });

      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  getMonetateId(): String {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
      const cookieName = cookie.replace(/^\s+/g, '');
      if (cookieName.indexOf(this.MONETATE_ID_COOKIE) === 0) {
        return cookieName.slice(this.MONETATE_ID_COOKIE.length);
      }
    }
    return '';
  }
}
