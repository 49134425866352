import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import {
  selectAllFeaturesEnabled,
  selectOnline,
  selectPlatformType,
} from '@panamax/app-state';
import { UserState } from '@panamax/app-state/lib/models/user.model';
import {
  directShippingSelectors,
  OrderHeader,
  orderHeaderSelectors,
  orderItemSelectors,
  OrderItemState,
  UQE_SELECTORS,
  UqeProductLimits,
  ORDER_ITEM_FILTER_SELECTORS,
} from '@usf/ngrx-order';
import {
  productPricingAdapter,
  selectAllSellerPrice,
  selectProductPricingState,
} from '@usf/ngrx-pricing';
import { selectedCustomer } from 'src/app/ngrx-customer/store';
import { ProductPricing, SellerPriceDetails } from '@usf/price-types';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import { selectAppStateUser } from 'src/app/ngrx-customer/store';
import { ImageVariantEnum } from 'src/app/product-detail/models/image-variant.enum';
import { getFirstImageURL } from 'src/app/product-detail/utils/product-images.util';
import { Product } from 'src/app/shared/models/product.model';
import { productWithAlternativesSelector } from 'src/app/shared/selectors/product.selectors';
import { DyfViewModel } from '../models/dyf-view-model';
import { AlternativeCartItem, Shipment } from '../models/order-view-model';
import { selectDYFViewModel } from './dyf/dyf.selectors';
import { selectInspiredPickViewModel } from './inspired-picks/inspired-picks.selectors';
import { InspiredPicksViewModel } from '@order/models/inspired-picks-view-model';
import { FEATURES } from '@shared/constants/splitio-features';
import { Customer } from '@usf/customer-types';
import { selectPunchoutProfile } from '../../punchout/selectors/punchout.selector';
import { PartnerPunchoutProfile } from '@usf/partner-types/partner-punchout-profile';
import { UqeProductNumber } from '@usf/ngrx-order/lib/models/client/uqe-product';
import { PlatformType } from '@panamax/app-state/lib/models/platform.model';
import { DirectShippingState } from '@usf/ngrx-order/lib/models/state/direct-ship-state';
import { OrderViewModelService } from '@order/services/order-view-model.service';
import { UsfProductCardViewModel } from '../../shared/models/usf-product-card.model';
import { selectMerchZoneViewModel } from './merch-zone/merch-zone.selectors';
import { MerchZoneViewModel } from '@order/models/merch-zone-view-model';
import { selectFrequentlyBoughtTogetherViewModel } from './frequently-bought-together/frequently-bought-together.selectors';
import { FrequentlyBoughtTogetherViewModel } from '@order/models/frequently-bought-together-view-model';
import { productMatchesKeyword } from '@app/lists/shared/list-detail-management/selectors/helpers/list-detail-management.selectors.helper';
import { selectUserKind } from '@app/user/store';
import { UserKinds } from '@usf/user-types/user';
export const selectOrderViewModel = (
  isMerchZoneEnabled: boolean,
  isFrequentlyBoughtTogetherEnabled: boolean,
) =>
  createSelector(
    selectAllFeaturesEnabled([FEATURES.split_global_national_customers.name]),
    selectPlatformType(),
    selectOnline(),
    directShippingSelectors.selectDirectShippingState,
    selectPunchoutProfile,
    selectedCustomer,
    UQE_SELECTORS.selectUnusualQuantities,
    orderHeaderSelectors.selectOrderHeader,
    orderItemSelectors.selectOrderItemState,
    productPricingAdapter.getSelectors(selectProductPricingState)
      .selectEntities,
    selectAppStateUser,
    selectAllSellerPrice,
    productWithAlternativesSelector,
    selectInspiredPickViewModel(),
    selectDYFViewModel(),
    selectMerchZoneViewModel(isMerchZoneEnabled),
    selectFrequentlyBoughtTogetherViewModel(isFrequentlyBoughtTogetherEnabled),
    ORDER_ITEM_FILTER_SELECTORS.selectSearchKey,
    selectUserKind,
    (
      restrictedNationalFlagEnabled: boolean,
      platformType: PlatformType,
      online: boolean,
      directShippingState: DirectShippingState,
      punchoutProfile: PartnerPunchoutProfile,
      selectedCustomer: Customer,
      unusualQuantities: UqeProductLimits[],
      orderHeader: OrderHeader,
      orderItemState: OrderItemState,
      pricingState: Dictionary<ProductPricing>,
      loggedInUser: UserState,
      sellerPrices: SellerPriceDetails[],
      productsMapOrig: Map<number, Product>,
      inspiredPickVM: InspiredPicksViewModel,
      dyfProductVM: DyfViewModel,
      merchZoneVM: MerchZoneViewModel,
      frequentlyBoughtTogetherVM: FrequentlyBoughtTogetherViewModel,
      searchKey: string,
      userKind: UserKinds,
    ) => {
      const productsMap = OrderViewModelService.processAutoSubbedProducts(
        orderItemState,
        selectedCustomer,
        productsMapOrig,
      );

      // Initialize all Variables used in the selector
      const validatedOrderItemState =
        OrderViewModelService.validateOrderItemStateVendorDetails(
          productsMap,
          orderHeader,
          orderItemState,
        );

      const shipments: Shipment[] = [];
      const alternatives = new Map<number, AlternativeCartItem>();
      const uqeProductNumbers: UqeProductNumber[] = [];
      const uqeCards: UsfProductCardViewModel[] = [];
      const orderTallies = OrderViewModelService.newOrderTallies();
      const orderItems = OrderViewModelService.sortOrderItems(
        validatedOrderItemState,
      );
      const sellerPrice =
        OrderViewModelService.createSellerPriceMap(sellerPrices);
      const uqeProductLimits =
        OrderViewModelService.createUqeProductLimitsMap(unusualQuantities);
      let hasSpecialVendorItem = false;
      for (const orderItem of orderItems) {
        const product = productsMap.get(orderItem.productNumber);
        const thumbnailImageURL = getFirstImageURL(
          product?.summary,
          ImageVariantEnum.Thumbnail,
        );
        const xlargeImageURL = getFirstImageURL(
          product?.summary,
          ImageVariantEnum.XLarge,
        );
        const pricing = OrderViewModelService.determinePricing(
          orderHeader,
          orderItem,
          pricingState,
          product,
          uqeProductNumbers,
        );

        OrderViewModelService.addAltProductToUqe(
          uqeProductNumbers,
          productsMap,
          product,
        );

        const isSpecialVendor = !!product?.summary.properties?.has(
          ProductPropertiesEnum.specialVendor,
        );

        const allowHandPricing = OrderViewModelService.displayPricingLink(
          product?.inventory,
          pricing,
          sellerPrice,
          userKind === UserKinds.Internal,
          online,
          isSpecialVendor,
        );
        if (isSpecialVendor) hasSpecialVendorItem = true;

        const handPricingDetails =
          OrderViewModelService.buildHandPricingDetails(
            validatedOrderItemState,
            product,
            orderItem,
            pricing,
          );
        const unusualQuantityLimit = uqeProductLimits.get(
          product?.productNumber,
        )?.quantityLimit;
        const matchesSearchFilter = productMatchesKeyword(
          searchKey,
          product?.summary,
          product?.alternative,
        );

        const newCartItem = OrderViewModelService.createCartItem(
          orderItem,
          product,
          pricing,
          allowHandPricing,
          thumbnailImageURL,
          xlargeImageURL,
          unusualQuantityLimit,
          matchesSearchFilter,
        );
        if (
          OrderViewModelService.orderItemExceedsQuantityLimit(
            orderItem,
            unusualQuantityLimit,
          )
        ) {
          uqeCards.push(
            OrderViewModelService.createProductCardViewModel(
              orderHeader,
              product,
              validatedOrderItemState,
              online,
              selectedCustomer,
              allowHandPricing,
              handPricingDetails,
              pricing,
              pricingState,
              orderHeader?.orderStatus,
              platformType,
              true,
              userKind,
            ),
          );
        }

        const alternatives = OrderViewModelService.processAlternativeProducts(
          product,
          orderItem,
          validatedOrderItemState,
          uqeProductLimits,
        );

        // Update existing alternative product info if necessary
        if (alternatives.has(orderItem?.productNumber)) {
          const alternative = alternatives.get(orderItem?.productNumber);
          alternative.eachesOrdered = orderItem.eachesOrdered;
          alternative.unitsOrdered = orderItem.unitsOrdered;
        }

        OrderViewModelService.categorizeShipment(
          shipments,
          newCartItem,
          product,
          orderItem,
          directShippingState,
          orderHeader,
          validatedOrderItemState,
          online,
          selectedCustomer,
          allowHandPricing,
          handPricingDetails,
          pricing,
          pricingState,
          platformType,
          userKind,
        );
      }

      OrderViewModelService.finalizeShipments(shipments, orderTallies);

      return OrderViewModelService.buildViewModel(
        shipments,
        orderHeader,
        validatedOrderItemState,
        alternatives,
        loggedInUser,
        punchoutProfile,
        dyfProductVM,
        inspiredPickVM,
        merchZoneVM,
        frequentlyBoughtTogetherVM,
        sellerPrice,
        online,
        productsMap,
        uqeProductNumbers,
        uqeProductLimits,
        selectedCustomer,
        restrictedNationalFlagEnabled,
        uqeCards,
        hasSpecialVendorItem,
        userKind,
      );
    },
  );
