<!-- LAST 4 BUYS FOR DESKTOP / TABLET -->
<app-usf-table
  *ngIf="
    last4ProductsDataSource?.length &&
    platformService?.platformType !== platformService?.platformEnum?.mobile
  "
  [visibleColumns]="last4ProductsColumns"
  [columnsConfig]="last4ProductsHeaderConfig"
  [dataSource]="last4ProductsDataSource"
></app-usf-table>

<!-- LAST 4 BUYS FOR MOBILE -->
<div
  *ngIf="
    last4ProductsDataSource?.length &&
    platformService?.platformType === platformService?.platformEnum?.mobile
  "
>
  <div
    *ngFor="let lastBuy of last4ProductsDataSource; last as isLast"
    class="last-buy-mobile"
    [class.separated]="!isLast"
  >
    <h5>
      <strong
        >{{
          'i18n.productDetail.sellerDetailMoreInformation.invoiceDate'
            | translate
        }}:
      </strong>
      {{ lastBuy?.invoiceDate }}
    </h5>
    <div class="last-buy-data">
      <div class="column">
        <p>
          <strong
            >{{
              'i18n.productDetail.sellerDetailMoreInformation.quantity'
                | translate
            }}:
          </strong>
          {{ lastBuy?.quantity }}
        </p>
        <p><strong>WWP: </strong>{{ lastBuy?.wwp }}</p>
        <p>
          <strong
            >{{
              'i18n.productDetail.sellerDetailMoreInformation.price'
                | translate
            }}:</strong
          >
          {{ lastBuy?.price }}
        </p>
      </div>
      <div class="column">
        <p>
          <strong
            >{{
              'i18n.productDetail.sellerDetailMoreInformation.invoiceNumber'
                | translate
            }}:
          </strong>
          {{ lastBuy?.invoiceNumber }}
        </p>
        <p>
          <strong
            >{{
              'i18n.productDetail.sellerDetailMoreInformation.method'
                | translate
            }}:</strong
          >
          {{ lastBuy?.method }}
        </p>
      </div>
    </div>
  </div>
</div>
