import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BrowserPageTitle } from './shared/constants/browser-page-title.enum';
import { PATHS } from './shared/constants/paths';
import { PlatformGuardService } from './shared/services/authentication/platform-guard.service';
import { TranslationResolverService } from './shared/services/translation-resolver.service';
import { splitGuard } from '@shared/guards/split.guard';
import { FEATURES } from '@shared/constants/splitio-features';

const routes: Routes = [
  {
    path: '',
    redirectTo: PATHS.LOGIN,
    pathMatch: 'full',
  },
  {
    path: PATHS.LOGIN,
    data: { title: BrowserPageTitle.login },
    loadChildren: () =>
      import('./login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: PATHS.LOGIN_PUNCHOUT,
    loadChildren: () =>
      import('./login-punch/login-punchout/login-punchout.module').then(
        m => m.LoginPunchoutPageModule,
      ),
  },
  {
    path: PATHS.LOGIN_ERROR,
    loadChildren: () =>
      import('./login-error/login-error.module').then(m => m.LoginErrorModule),
  },
  {
    path: PATHS.LOGIN_PUNCHTHRU,
    loadChildren: () =>
      import('./login-punch/login-punchthru/login-punchthru.module').then(
        m => m.LoginPunchthruPageModule,
      ),
  },
  {
    path: PATHS.LOGOUT,
    data: { title: BrowserPageTitle.login },
    loadChildren: () =>
      import('./logout/logout.module').then(m => m.LogoutPageModule),
  },
  {
    path: PATHS.PUNCHOUT,
    loadChildren: () =>
      import('./punchout/punchout.module').then(m => m.PunchoutPageModule),
  },
  {
    path: PATHS.DESKTOP,
    loadChildren: () =>
      import('./desktop/desktop.module').then(m => m.DesktopPageModule),
    resolve: { model: TranslationResolverService },
  },
  {
    path: PATHS.TOUCH,
    loadChildren: () =>
      import('./touch/touch.module').then(m => m.TouchPageModule),
    resolve: { model: TranslationResolverService },
  },
  {
    path: PATHS.SCHEDULED_MAINTENANCE,
    loadChildren: () =>
      import('./scheduled-maintenance/scheduled-maintenance.module').then(
        m => m.ScheduledMaintenancePageModule,
      ),
  },
  {
    path: PATHS.NOT_FOUND,
    loadChildren: () =>
      import('./notfound/notfound.module').then(m => m.NotfoundPageModule),
  },
  {
    path: PATHS.ERROR,
    loadChildren: () =>
      import('./error/error.module').then(m => m.ErrorPageModule),
  },
  {
    path: PATHS.GUEST_LOGIN,
    loadChildren: () =>
      import('./guest-login/guest-login.module').then(m => m.GuestLoginModule),
    canActivate: [splitGuard([FEATURES.split_global_guest_experience.name])],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./notfound/notfound.module').then(m => m.NotfoundPageModule),
    canActivate: [PlatformGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
