import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PanAppState, PlatformEnum, PlatformService } from '@panamax/app-state';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import { ProductRow } from '../../../../lists/shared/list-detail-management/model/list-detail-management-view.model';
import { ProductDetailViewModel } from '../../../../product-detail/models/product-detail-view-model';
import { ApiUpdatable } from '../../../models/api-updatable';
import { QuantityUpdate } from '../../../models/quantity-update';
import { TagProduct } from '../../usf-product-tag-list/models/tag-product.model';
import { AddToListModalService } from '@shared/services/dual-pane/add-to-list-modal.service';
import { CoveoService } from '@shared/services/coveo/coveo.service';

@Component({
  selector: 'app-usf-product-quick-view-info',
  templateUrl: './usf-product-quick-view-info.component.html',
  styleUrls: ['./usf-product-quick-view-info.component.scss'],
})
export class UsfProductQuickViewInfoComponent {
  coveoService = inject(CoveoService);
  readonly propertiesEnum = ProductPropertiesEnum;

  @Input() product: ProductRow;
  @Input() pricingDisabled: boolean;
  @Input() casesOrdered: ApiUpdatable<number>;
  @Input() eachesOrdered: ApiUpdatable<number>;
  @Input() productTags: TagProduct[];
  @Input() disableNotes: boolean;
  @Input() productDetail: ProductDetailViewModel;
  @Input() claimTags: TagProduct[];
  @Input() platformEnum: PlatformEnum;
  @Input() isOriginalProduct: boolean;
  @Input() showSearchForSubs: boolean;

  @Output() searchForReplacementEmitter = new EventEmitter<any>();
  @Output() quantityUpdateEmitter = new EventEmitter<QuantityUpdate>();

  constructor(
    private modalController: ModalController,
    readonly panAppState: PanAppState,
    readonly platformService: PlatformService,
    readonly addToListModalService: AddToListModalService,
  ) {}

  changeQuantityHandler(quantityUpdate: QuantityUpdate) {
    this.quantityUpdateEmitter.emit(quantityUpdate);
  }

  async goToProductDetail(productNumber: number) {
    let price =
      this.product?.pricing?.unitPrice ?? this.product?.pricing?.eachPrice;
    this.coveoService.onSearchResultClickEvent(
      this.product?.productNumber,
      price,
    );
    await this.modalController.dismiss(productNumber);
  }

  async openAddToListModal() {
    await this.dismissModal();
    this.addToListModalService.openModalViaController(
      [this.product],
      false,
      false,
    );
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }

  async searchReplacement() {
    this.searchForReplacementEmitter.emit();
  }
}
