import { AnalyticsTransformer, TrackingData } from '@panamax/app-state';

export const deliveryInstructionsEditTrace: AnalyticsTransformer = (
  trackingData: TrackingData,
) => {
  const { traceContext, isStartOfTrace, isEndOfTrace, ...rest } =
    trackingData.data;

  return {
    traceContext,
    isStartOfTrace,
    isEndOfTrace,
    event: trackingData?.data?.event,
    products: trackingData?.data?.products,
    orderId: trackingData?.data?.orderId,
    ...rest.attributes,
  };
};
