import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '@panamax/app-state';
import { ToastService } from '@shared/services/toast/toast.service';
import {
  OpportunityResult,
  ProductAnalyticsService,
  ProductStateService,
} from '@usf/ngrx-product';
import { ProductOpportunityRequest } from '@usf/product-types';
import dayjs from 'dayjs';
import { take } from 'rxjs/operators';
import { ModalWithNavComponent } from '../../shared/components/modal-with-nav/modal-with-nav.component';
import { ModalService } from '../../shared/services/modal/modal.service';
import { SellerCreateOpportunityComponent } from '../components/seller-create-opportunity/seller-create-opportunity.component';
import {
  OpportunityModalActions,
  OpportunityPublishOpportunity,
} from '../models/create-opportunity.enum';
import { ImageVariantEnum } from '../models/image-variant.enum';
import { ProductDetailViewModel } from '../models/product-detail-view-model';
import { getFirstImageURL } from '../utils/product-images.util';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';

@Injectable({
  providedIn: 'root',
})
export class SellerCreateOpportunityService {
  readonly publishOpportunity: OpportunityPublishOpportunity;
  private product: ProductDetailViewModel;

  constructor(
    private modalController: ModalController,
    private modalService: ModalService,
    private productStateService: ProductStateService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private productAnalyticsService: ProductAnalyticsService,
    readonly platformService: PlatformService,
  ) {}

  async showCreateOpportunityModal(
    product: ProductDetailViewModel,
    sourcePageName?: string,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage: SellerCreateOpportunityComponent,
        rootPageParams: {
          selectedTitle: '',
          product,
          sourcePageName,
        },
      },
      'modal-desktop-size-xl',
    );
    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );

    await modal.present().then((resp: any) => {
      this.trackCreateOpportunityClickButton();
    });

    await modal.onDidDismiss().then((resp: any) => {
      if (resp?.data !== OpportunityModalActions.SUBMIT.toString()) {
        this.trackOpportunityCancel();
      }
    });
  }

  createOpportunity(
    opportunityRequest: ProductOpportunityRequest,
    product: ProductDetailViewModel,
  ): void {
    this.productStateService
      .createOpportunity(this.sanitizeRequest(opportunityRequest, product))
      .pipe(take(1))
      .subscribe(opportunityResult =>
        this.handleCreateOpportunityResult(opportunityResult),
      );
  }

  trackUserSubmitOpportunityEvent(
    publishOpportunity: boolean,
    pageName: string,
  ): void {
    const { divisionNumber, productNumber } = this.product?.summary;
    const { trackingAttributes } = this.product;
    this.productAnalyticsService.trackUserSubmitOpportunityEvent({
      divisionNumber,
      productNumber,
      productAttributes: trackingAttributes,
      publishNote: publishOpportunity,
      pageName,
    });
  }

  private sanitizeRequest(
    raw: ProductOpportunityRequest,
    product: ProductDetailViewModel,
  ): ProductOpportunityRequest {
    const dateFormat = 'YYYY-MM-DD';

    return {
      ...raw,

      // Product data
      productNumber: `${product.summary.productNumber}`,
      brand: product.summary.brand ?? '',
      packSize: product.summary.salesPackSize ?? '',
      productImageLink:
        getFirstImageURL(product.summary, ImageVariantEnum.Thumbnail) ?? '',
      productDescription: product.summary.productDescTxtl,
      casePrice: `${product.pricing.unitPrice ?? 0}`,
      eachPrice: `${product.pricing.eachPrice ?? 0}`,
      eachConvFactor: `${product.inventory.eachConversionFactor}`,
      packWeight: `${product.summary.netWeight ?? ''}`,
      uom: product.summary.priceUom ?? '',
      breakable: product.summary.breakable,

      // Form 1 (create opportunity)
      publishOpportunity: raw.publishOpportunity,
      opportunityName: raw.opportunityName,
      weeklyCases: `${raw.weeklyCases ?? 0}`,
      weeklyEaches: `${raw.weeklyEaches ?? 0}`,
      weeklyPotential: `${raw.weeklyPotential ?? ''}`,
      estimatedShipDate: dayjs(raw.estimatedShipDate).utc().format(dateFormat),

      // Form 2 (publish)
      title: raw.title ?? '',
      noteToCustomer: raw.noteToCustomer ?? '',
      validUntil: dayjs(raw.validUntil).utc().format(dateFormat),
    };
  }

  private handleCreateOpportunityResult(
    opportunityResult: OpportunityResult,
  ): void {
    if (opportunityResult.success) {
      this.presentToastMessage(
        'i18n.productDetail.toastMessages.createOpportunity.success',
        MessageTypeEnum.success,
      );
    } else {
      // TODO we will handle the status code when the errors are defined by Mulesoft
      switch (opportunityResult.statusCode) {
        // Ecom Error
        case 606:
          this.presentToastMessage(
            'i18n.productDetail.toastMessages.createOpportunity.ecomError',
            MessageTypeEnum.failure,
          );
          break;
        // Mulesoft API errors
        case 400: // Bad request
        case 401: // Unauthorized
        case 404: // Resource not found
        case 405: // Unauthorized
        case 500: // Server Error
        case 0: // Something went wrong
        default:
          this.presentToastMessage(
            'i18n.productDetail.toastMessages.createOpportunity.error',
            MessageTypeEnum.failure,
          );
          break;
      }
    }
  }

  presentToastMessage(messageText: string, messageType: MessageTypeEnum): void {
    this.toastService.presentToastMsg(
      this.translateService.instant(messageText),
      messageType === MessageTypeEnum.success ? 'green-toast' : 'red-toast',
      messageType,
      [],
    );
  }

  trackOpportunityCancel(): void {
    const { divisionNumber, productNumber } = this.product?.summary;
    const { trackingAttributes } = this.product;
    this.productAnalyticsService.trackOpportunityCancel(
      divisionNumber,
      productNumber,
      trackingAttributes,
    );
  }

  trackCreateOpportunityClickButton(): void {
    const { divisionNumber, productNumber } = this.product?.summary;
    const { trackingAttributes } = this.product;
    this.productAnalyticsService.trackCreateOpportunityClickButton(
      divisionNumber,
      productNumber,
      trackingAttributes,
    );
  }

  trackOpportunityContinue(): void {
    const { divisionNumber, productNumber } = this.product?.summary;
    const { trackingAttributes } = this.product;
    this.productAnalyticsService.trackOpportunityContinue(
      divisionNumber,
      productNumber,
      trackingAttributes,
    );
  }

  setProduct(value: ProductDetailViewModel) {
    this.product = value;
  }
}
