export enum ProfileAnalyticsMessages {
  capability = 'alerts',
  pageName = 'us foods:r4:profile',
  pageLoadedEvent = 'page loaded',
  clearAppDataTrackingEvent = 'User Clear Cache',
  clearAppData = 'User Clicked Clear Cache',
  clearAppDataSuccess = 'User Clear Cache Success',
  logoutTraceName = 'Logout Devices Trace',
  logoutTraceNameEvent = 'Logout All Sessions Trace',
  logoutSuccessTraceNameEvent = 'Logout All Sessions Success',
  upcLink = 'outbound link click',
  inviteNewUserModalName = 'invite new user',
  inviteExistingUserModalName = 'invite existing user',
  inviteUserModalEvent = 'modal open',
  buttonClickEvent = 'button click',
}
