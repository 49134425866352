import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { CustomPricingRationaleComponent } from '@product-detail/components/custom-pricing-rationale/custom-pricing-rationale.component';
import { OpportunityModalActions } from '@product-detail/models/create-opportunity.enum';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { ModalWithNavComponent } from '@shared/components/modal-with-nav/modal-with-nav.component';
import { ModalService } from '@shared/services/modal/modal.service';
import { SellerPriceDetails } from '@usf/price-types';
import { ProductAnalyticsService } from '@usf/ngrx-product';
import { CPRModalComponent } from '@shared/components/cpr-modal/cpr-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CustomPricingRationaleService {
  constructor(
    private modalController: ModalController,
    private modalService: ModalService,
    readonly platformService: PlatformService,
    private productAnalyticsService: ProductAnalyticsService,
  ) {}

  async showCustomPricingRationaleModal(
    product: ProductDetailViewModel,
    sellerPriceDetail: SellerPriceDetails,
    showCommonCprModal = false,
    pageName?: string,
  ) {
    if (showCommonCprModal) {
      this.modalService.setModalOptions(
        this.platformService.isTouch.value,
        ModalWithNavComponent,
        {
          rootPage: CPRModalComponent,
          rootPageParams: {
            selectedTitle: '',
            product,
            sellerPriceDetail,
            isOnlyCPR: true,
            pageName,
          },
        },
        'modal-desktop-size-xl',
      );
    } else {
      this.modalService.setModalOptions(
        this.platformService.isTouch.value,
        ModalWithNavComponent,
        {
          rootPage: CustomPricingRationaleComponent,
          rootPageParams: {
            selectedTitle: '',
            product,
            sellerPriceDetail,
            pageName,
          },
        },
        'modal-desktop-size-xl',
      );
    }

    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );

    await modal.present().then((resp: any) => {
      this.trackCustomPricingRationaleClickButton(null, null);
    });

    await modal.onDidDismiss().then((resp: any) => {
      if (resp?.data !== OpportunityModalActions.SUBMIT.toString()) {
        this.trackCustomPricingRationaleCancel();
      }
    });
  }

  trackCustomPricingRationaleClickButton(
    product: ProductDetailViewModel,
    priceType: string,
    pageName?: string,
  ): void {
    if (product && priceType) {
      const { divisionNumber, productNumber } = product?.summary;
      const { trackingAttributes } = product;
      this.productAnalyticsService.trackCustomPricingRationaleUpdate(
        divisionNumber,
        productNumber,
        trackingAttributes,
        priceType,
        pageName,
      );
    }
  }

  trackCustomPricingRationaleCancel(): void {
    //TODO: Track action after CRP click Cancel Button
  }
}
