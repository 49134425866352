import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerStoreService } from '@app/ngrx-customer/services';
import { getCustomers, getDivisions } from '@app/ngrx-customer/store';
import { Store } from '@ngrx/store';
import { PanAppState, PlatformEnum } from '@panamax/app-state';
import { ImportListMapOptions } from '@shared/constants/lists-constants';
import { CustDivDeptInfo, ImportCsvRow, List, ListKey } from '@usf/list-types';
import {
  CopyListModalOutput,
  LIST_ACTIONS,
  LIST_GROUP_ACTIONS,
  LIST_ITEM_ACTIONS,
  ListAnalyticsService,
  ListItemState,
  ListTracingConstants,
  MASTER_LIST_ACTIONS,
  ORDER_GUIDE_ACTIONS,
  RECENT_PURCHASE_ACTIONS,
  createListTransformer,
  selectCustomersWithListName,
  selectMassDeleteLoading,
} from '@usf/ngrx-list';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  finalize,
  firstValueFrom,
  map,
} from 'rxjs';
import { State } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { FEATURES } from '../../shared/constants/splitio-features';
import { LoadingSpinnerService } from '../../shared/services/loading-spinner/loading-spinner.service';
import { doesListNameExist } from '../helpers/lists-checks-helpers';
import { ListsPageData } from '../model/lists-page.model';
import {
  listsForInventory,
  listsView,
  selectListItemsWithSameProductNumber,
  selectListNameBlackList,
  selectListsForHomePageTile,
} from '../selectors/lists-view-model.selector';
import {
  DeleteListViewModel,
  DivisionNameNumber,
  MassDeleteCustomer,
} from '../shared/delete-list/models/delete-list.model';
import { selectListStatesAreLoaded } from '../shared/list-detail-management/selectors/list-detail-management-view-model.selector';
import { selectAiListsAsListViewModels } from '../selectors/ai-list-view-model.selector';

@Injectable({
  providedIn: 'root',
})
export class ListsService {
  private readonly _aiListFeatureFlag$ = this.panAppState.feature$([
    FEATURES.split_global_ai_list.name,
  ]);
  private _listNameBlackList$: Observable<Set<string>> = this.store.select(
    selectListNameBlackList,
  );

  constructor(
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    private analytics: ListAnalyticsService,
    private loadingSpinnerService: LoadingSpinnerService,
    private customerStoreService: CustomerStoreService,
    private panAppState: PanAppState,
  ) {}

  public get aiListFeatureFlag$(): Observable<boolean> {
    return this._aiListFeatureFlag$;
  }

  public get listNameBlackList$(): Observable<Set<string>> {
    return this._listNameBlackList$;
  }

  listsView$ = (platform?: PlatformEnum, keyword?: string) =>
    this.store.select(listsView(platform, keyword));

  listsForHomePageTile$ = () => this.store.select(selectListsForHomePageTile());

  listsForInventory$ = (keyword?: string) =>
    this.store.select(listsForInventory(keyword));

  replacementProducts$ = (productNumber: number, listStatusIndicator: string) =>
    this.store.select(
      selectListItemsWithSameProductNumber(productNumber, listStatusIndicator),
    );

  fetchListChanges() {
    this.store.dispatch(LIST_ACTIONS.loadChanges());
    this.store.dispatch(LIST_GROUP_ACTIONS.loadChanges());
    this.store.dispatch(LIST_ITEM_ACTIONS.loadChanges());
  }

  listStatesAreLoaded$ = () => this.store.select(selectListStatesAreLoaded());

  goToShoppingList(shoppingListPath: string[]) {
    this.router.navigate(shoppingListPath, { relativeTo: this.route });
  }

  /**
   * updateListOfListsSort: Calls the ngrx updateListOfListsSort list action which
   * updates the sort direction of the selected header and resets the others their default values
   * in the given listOfListsSort section.
   */
  updateListOfListsSort(
    listSection: string,
    selectedHeader: string,
    sortDirection: string,
  ) {
    this.store.dispatch(
      LIST_ACTIONS.updateListOfListsSort({
        listSection,
        selectedHeader,
        sortDirection,
      }),
    );
  }

  /**
   * updateListOfListsSort: Calls the ngrx resetListOfListsSort list action which
   * resets the listOfListsSort.
   */
  resetListOfListsSort() {
    this.store.dispatch(LIST_ACTIONS.resetListOfListsSort());
  }

  /** Creates new list object and dispatches ngrx create list action */
  createList(listName: string, listType: string) {
    const tempId = Math.floor(-100000000 * Math.random());
    const newList = {
      listKey: {
        listTypeId: 'SL',
        listId: tempId,
      },
      r4TempListId: tempId,
      listName,
      listState: listType.toUpperCase(),
    };
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        LIST_ACTIONS.create({
          list: newList as List,
          tracking: {
            tracing: {
              data: {
                traceContext: ListTracingConstants.listMaintenance,
                isEndOfTrace: false,
                isStartOfTrace: true,
                attributes: {
                  listId: newList.listKey.listId,
                  listType: newList.listKey.listTypeId,
                  event: ListTracingConstants.createListEvent,
                  spanName: ListTracingConstants.createListSpan,
                },
              },
              transformFunc: createListTransformer,
            },
          },
        }),
      );
    });
  }

  async importList(
    listName: string,
    csvRows: ImportCsvRow[],
    listState: string,
  ) {
    await this.loadingSpinnerService.createSpinnerModal();
    this.store.dispatch(
      LIST_ACTIONS.importList({
        csvRows,
        listName,
        listState,
      }),
    );
  }

  copyList(copyListModalOutput: CopyListModalOutput) {
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(LIST_ACTIONS.copy({ copyListModalOutput }));
    });
  }

  getListTemplate() {
    this.store.dispatch(LIST_ACTIONS.requestDownloadTemplate());
  }
  /** Checks to see if the list name already exists */
  listNameExists(listPageData: ListsPageData, listName: string): boolean {
    return doesListNameExist(listPageData, listName);
  }

  trackListsPageLoad() {
    this.analytics.trackListsPageLoad();
  }

  selectAList(key: string, value: boolean) {
    this.store.dispatch(LIST_ACTIONS.selectAList({ key, value }));
  }

  selectAMasterList(id: number, value: boolean) {
    this.store.dispatch(MASTER_LIST_ACTIONS.selectAList({ id, value }));
  }

  selectAnOrderGuide(key: string, value: boolean) {
    this.store.dispatch(ORDER_GUIDE_ACTIONS.selectAList({ key, value }));
  }

  selectRecentPurchase(value: boolean) {
    this.store.dispatch(RECENT_PURCHASE_ACTIONS.selectAList({ value }));
  }

  // Avoiding binding mmanagement service or store to list-product-card-service
  deleteListItemNote(item: ListItemState, list: List) {
    this.store.dispatch(
      LIST_ITEM_ACTIONS.deleteNote({
        list,
        productNumber: item.productNumber,
      }),
    );
  }

  async getCustomersWithSameList(listName: string) {
    const customers = await firstValueFrom(this.store.select(getCustomers));
    const custDivDept: CustDivDeptInfo[] = [];
    customers.forEach(customer => {
      if (customer.departments.length > 0) {
        customer.departments.forEach(department => {
          custDivDept.push({
            customerNumber: customer.customerNumber,
            divisionNumber: customer.divisionNumber,
            departmentNumber: Number(department.departmentNumber),
          });
        });
      } else {
        custDivDept.push({
          customerNumber: customer.customerNumber,
          divisionNumber: customer.divisionNumber,
          departmentNumber: 0,
        });
      }
    });
    this.store.dispatch(
      LIST_ACTIONS.getCustomersWithListName({
        custDivDeptInfo: custDivDept,
        listName,
      }),
    );
  }

  selectMassDeleteViewModel$ = (
    listName: string,
    listKey: ListKey,
  ): Observable<DeleteListViewModel> => {
    const customersPerList$ = this.store.select(
      selectCustomersWithListName(listName),
    );
    const divisions$ = this.store.select(getDivisions);
    const customers$ = this.store.select(getCustomers);
    const custDivDept$ =
      this.customerStoreService.selectCustomerDivisionDepartment$();
    return combineLatest([
      customersPerList$,
      divisions$,
      customers$,
      custDivDept$,
    ]).pipe(
      map(([customersPerList, divisions, customers, custDivDept]) => {
        const initialMassDeleteCustomer: MassDeleteCustomer = {
          ...custDivDept,
          listKey,
          isHeader: false,
          isDepartment: custDivDept.departmentNumber !== 0,
        };
        const massDeleteViewModel: DeleteListViewModel = {
          listName,
          initialMassDeleteCustomer,
          markets: [],
          marketCustomerMap: new Map(),
        };
        divisions.forEach(division => {
          if (
            customersPerList?.custInfo?.find(
              customerResponse =>
                division.divisionNumber === customerResponse.divisionNumber,
            )
          ) {
            massDeleteViewModel.markets.push({
              divisionName: division.divisionName,
              divisionNumber: division.divisionNumber,
            } as DivisionNameNumber);
          }
        });
        customersPerList?.custInfo?.forEach(minimalCustomer => {
          const fullCustomer = customers.find(
            cust => cust.customerNumber === minimalCustomer.customerNumber,
          );
          const massDeleteCustomer: MassDeleteCustomer = {
            ...minimalCustomer,
            customerName: fullCustomer.customerName,
            departmentName: '',
            divisionName: '',
            isHeader: false,
            isDepartment: false,
          };

          if (fullCustomer.departments.length > 0) {
            if (
              !(
                massDeleteViewModel.marketCustomerMap.get(
                  minimalCustomer.divisionNumber,
                ) ?? []
              ).find(value => {
                return value.customerNumber === fullCustomer.customerNumber;
              })
            ) {
              const headerForCustomer = {
                ...minimalCustomer,
                customerName: fullCustomer.customerName,
                departmentName: '',
                divisionName: '',
                isHeader: true,
                isDepartment: false,
              };
              if (
                massDeleteViewModel.marketCustomerMap.has(
                  minimalCustomer.divisionNumber,
                )
              ) {
                massDeleteViewModel.marketCustomerMap.set(
                  massDeleteCustomer.divisionNumber,
                  [
                    ...massDeleteViewModel.marketCustomerMap.get(
                      massDeleteCustomer.divisionNumber,
                    ),
                    headerForCustomer,
                  ],
                );
              } else {
                massDeleteViewModel.marketCustomerMap.set(
                  massDeleteCustomer.divisionNumber,
                  [headerForCustomer],
                );
              }
            }
            const departmentName = fullCustomer.departments.find(
              dept =>
                dept.departmentNumber ===
                minimalCustomer.departmentNumber.toString(),
            )?.departmentName;
            massDeleteCustomer.departmentName = departmentName;
            massDeleteCustomer.isDepartment = true;
          }
          if (
            massDeleteViewModel.marketCustomerMap.has(
              minimalCustomer.divisionNumber,
            )
          ) {
            massDeleteViewModel.marketCustomerMap.set(
              massDeleteCustomer.divisionNumber,
              [
                ...massDeleteViewModel.marketCustomerMap.get(
                  massDeleteCustomer.divisionNumber,
                ),
                massDeleteCustomer,
              ],
            );
          } else {
            massDeleteViewModel.marketCustomerMap.set(
              massDeleteCustomer.divisionNumber,
              [massDeleteCustomer],
            );
          }
        });
        return massDeleteViewModel;
      }),
    );
  };

  selectMassDeleteLoading$ = () => {
    return this.store.select(selectMassDeleteLoading());
  };

  resetRecentPurchaseSelections = () => {
    this.store.dispatch(
      RECENT_PURCHASE_ACTIONS.setIsSelectedForAllRecentPurchases({
        value: false,
      }),
    );
    this.store.dispatch(RECENT_PURCHASE_ACTIONS.selectAList({ value: false }));
  };

  handleImportSubmission = (event: any) => {
    this.importList(
      event.get(ImportListMapOptions.listName),
      event.get(ImportListMapOptions.fileSelect),
      (event.get(ImportListMapOptions.listType) ?? 'Public').toUpperCase(),
    );
  };

  trackClickGenerateAIList() {
    this.analytics.trackGenerateAIListButton();
  }

  selectAiListsAsLists$ = () => {
    return this.store.select(selectAiListsAsListViewModels);
  };
}
