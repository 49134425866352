<div class="header-user-popover" (mouseleave)="dismiss()">
  <ion-list data-cy="list-actions-user">
    <ion-item
      lines="none"
      data-cy="list-actions-user-item-preference-center"
      (click)="routeTo(PATHS.PROFILE)"
    >
      <ion-label translate>{{ 'i18n.common.myProfile' }}</ion-label>
    </ion-item>
    <ion-item
      *ngIf="supportCenterFlag$ | async"
      lines="none"
      data-cy="list-actions-user-item-preference-center"
      (click)="routeTo(PATHS.SUPPORT_CENTER)"
    >
      <ion-label translate>{{ 'i18n.supportCenter.title' }}</ion-label>
    </ion-item>
    <ion-item
      *ngIf="!isSuperUser"
      lines="none"
      data-cy="list-actions-user-item-preference-center"
      (click)="openUPC()"
    >
      <ion-label translate>{{ 'i18n.common.preferenceCenter' }}</ion-label>
    </ion-item>
    <ion-item
      *ngIf="getInviteNewUserVisibility === true"
      lines="none"
      data-cy="list-actions-user-item-invite-new-user"
      (click)="openInviteNewUserModal()"
    >
      <ion-label translate>{{
        'i18n.profilePage.inviteUser.inviteUser'
      }}</ion-label>
    </ion-item>
    <ion-item
      *ngIf="(manageUsersFlag$ | async) && isCustomerAdmin"
      lines="none"
      data-cy="list-actions-user-item-manage-users"
      (click)="routeTo(PATHS.MANAGE_USERS)"
    >
      <ion-label translate>{{ 'i18n.manageUsers.title' }}</ion-label>
    </ion-item>
    <ng-container *ngIf="punchoutReturnLinkData">
      <ion-item
        *ngIf="
          punchoutReturnLinkData?.returnLinkFlag === true;
          else regularLogout
        "
        lines="none"
        data-cy="list-actions-user-item-logout"
        (click)="returnToPartnerSite(punchoutReturnLinkData?.returnLinkUrl)"
      >
        <ion-label>
          {{ punchoutReturnLinkData?.returnLinkText }}
        </ion-label>
      </ion-item>
    </ng-container>
    <ng-template #regularLogout>
      <ion-item
        lines="none"
        data-cy="list-actions-user-item-logout"
        (click)="logout()"
      >
        <ion-label translate>{{ 'i18n.login.logOut' }}</ion-label>
      </ion-item>
    </ng-template>
  </ion-list>
</div>
