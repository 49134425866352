import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IonInput } from '@ionic/angular';
import { PanAppState, PlatformService } from '@panamax/app-state';
import {
  RatingAverageCSSEnum,
  RatingStarsCSSEnum,
} from '@shared/models/power-reviews/pwr-rating-css';
import { ProductAnalyticsService, ProductState } from '@usf/ngrx-product';
import { PowerReviewRollupModel } from '@usf/ngrx-product/lib/models/power-review-product.model';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import { getAlternativeThumbnail } from 'src/app/product-detail/utils/product-images.util';
import {
  ItemTypes,
  ProductRow,
} from '../../../lists/shared/list-detail-management/model/list-detail-management-view.model';
import { ProductTypes } from '../../constants/product-type.enum';
import { UsfProductCardModeEnum } from '../../constants/usf-product-card-mode.enum';
import { QuantityUpdate } from '../../models/quantity-update';
import { CatchWeightPopoverComponent } from '../popovers/catch-weight-popover/catch-weight-popover.component';
import { Tooltip, TooltipOptions } from '../popovers/tooltip/tooltip.component';
import { TooltipService } from '../popovers/tooltip/tooltip.service';
import { ProductDetailViewModel } from '../../../product-detail/models/product-detail-view-model';
import { Observable, Subscription } from 'rxjs';
import { ListsPageData } from '@app/lists/model/lists-page.model';
import { ListsService } from '@app/lists/services/lists.service';
import { ListsOverlayService } from '@app/lists/services/lists-overlay.service';
import { FEATURES } from '@shared/constants/splitio-features';
import { ProductResolutionMessage } from '@product-detail/models/product-resolution-message';

@Component({
  template: '',
})
export class UsfProductCardInfoBaseComponent implements OnInit, OnDestroy {
  @Input() isOriginalProduct: boolean;
  @Input() index: number;
  @Input() lnkSrc: string;
  @Input() mode: UsfProductCardModeEnum;
  @Input() product: ProductRow;
  @Input() itemType: ItemTypes;
  @Input() pricingDisabled: boolean;
  @Input() sequenceNumber: number;
  @Input() hasAlternative: boolean;
  @Input() hasNote: boolean;
  @Input() hasPromotions: boolean;
  @Input() hasAlternativePromotions: boolean;
  @Input() productCardResolutionMsg: ProductResolutionMessage;
  @Input() listId: string | number;
  @Input() powerReviewRollupModel: PowerReviewRollupModel;
  @Input() alternatePowerReviewRollupModel: PowerReviewRollupModel;
  @Input() showNotes: boolean;
  @Input() showConfirmQuantityButton: boolean;
  @Input() showAddTolistButton = false;
  @Input() merchFeature = '';
  @Input() carouselPosition = 0;
  @Input() addMethod = '';
  @Input() useOrderObservable = false;
  @Input() tooltipHoverCssClass = 'tooltip-catch-weight-hover-card';
  @Input() isGuestUser = false;
  @Input() clickable = true;

  @Output()
  ellipsisEmitter: EventEmitter<any> = new EventEmitter();
  @Output() itemNoteEmitter = new EventEmitter<any>();
  @Output() productCardClickEmitter = new EventEmitter<number>();
  @Output()
  promotionClickEmitter: EventEmitter<any> = new EventEmitter();
  @Output() quantityInputEmitter = new EventEmitter<IonInput>();
  @Output() quantityUpdateEmitter = new EventEmitter<QuantityUpdate>();
  @Output()
  resequenceProductEmitter = new EventEmitter<number>();
  @Output() confirmQuantityEmitter = new EventEmitter<number>();

  readonly ItemTypes = ItemTypes;
  readonly productCardModes = UsfProductCardModeEnum;
  readonly propertiesEnum = ProductPropertiesEnum;
  readonly productTypes = ProductTypes;

  private _tooltip: Tooltip;
  tooltipOptions: TooltipOptions;
  offlineQtyBoxEnableFlag$: Observable<boolean>;

  listPageData$: Observable<ListsPageData>;
  listPageDateSub$: Subscription;
  listPageData: ListsPageData;

  constructor(
    readonly platformService: PlatformService,
    readonly panAppState: PanAppState,
    private tooltipService: TooltipService,
    readonly listsService: ListsService,
    readonly listsOverlayService: ListsOverlayService,
    readonly analyticsService: ProductAnalyticsService,
  ) {}

  ngOnInit(): void {
    if (this.showAddTolistButton === true) {
      this.listPageData$ = this.listsService.listsView$();
      this.listPageDateSub$ = this.listPageData$.subscribe(data => {
        this.listPageData = data;
      });
    }
    this.offlineQtyBoxEnableFlag$ = this.panAppState.feature$([
      FEATURES.split_global_offline_update_quantities.name,
    ]);
  }

  ngOnDestroy(): void {
    if (!!this.listPageDateSub$) {
      this.listPageDateSub$.unsubscribe();
    }
  }

  isCatchWeightProduct(): Boolean {
    if (this.itemType === ItemTypes.product) {
      return this.product?.summary?.catchWeightFlag === true;
    }
    return false;
  }

  getAternativeThumbnail = (thumbnailImageURL: string): string => {
    if (this.itemType === ItemTypes.product) {
      const newThumbnail = getAlternativeThumbnail(
        this.product?.summary as ProductState,
        thumbnailImageURL,
      );
      return newThumbnail;
    }
  };

  changeQuantityHandler(quantityUpdate: QuantityUpdate) {
    this.quantityUpdateEmitter.emit(quantityUpdate);
  }

  trackProductImageClick() {
    this.analyticsService.trackProductImageClick(
      this.product?.summary?.divisionNumber,
      this.product?.productNumber,
      this.product?.trackingAttributes,
      this.product?.resultRank,
    );
  }

  productImageClick() {
    this.trackProductImageClick();
    this.productClick();
  }

  productClick() {
    this.productCardClickEmitter.emit(this.product?.productNumber);
  }

  handleMouseOver($event: Event) {
    if (!this._tooltip?.isOpen()) {
      this.createAndPresentToolTip($event);
    }
  }

  async createAndPresentToolTip($event: Event) {
    if (this.isCatchWeightProduct()) {
      this.tooltipOptions = {
        component: CatchWeightPopoverComponent,
        popoverCssClass: ['tooltip-catch-weight-popover'],
        hoverCssClass: [this.tooltipHoverCssClass],
      };

      this._tooltip = await this.tooltipService.create(
        this.tooltipOptions,
        $event,
      );
      await this._tooltip.present();
    }
  }

  async handleMouseLeave() {
    if (this._tooltip?.isOpen()) {
      await this._tooltip.dismiss();
    }
  }

  itemNoteClick() {
    this.itemNoteEmitter.emit();
  }

  ellipsisClick(event: any) {
    this.ellipsisEmitter.emit(event);
  }

  promotionClick(event: any) {
    this.promotionClickEmitter.emit(event);
  }

  resequenceProduct(newSequenceNum: number) {
    this.resequenceProductEmitter.emit(newSequenceNum);
  }

  quantityInputUpdate(input: IonInput) {
    this.quantityInputEmitter.emit(input);
  }

  showPromotionsBanner() {
    if (
      this.mode !== UsfProductCardModeEnum.listManagement &&
      this.mode !== UsfProductCardModeEnum.addToInventory &&
      ((this.isOriginalProduct && this.hasPromotions) ||
        (!this.isOriginalProduct &&
          this.hasAlternative &&
          this.hasAlternativePromotions))
    ) {
      return true;
    } else {
      return false;
    }
  }

  public get ratingStarsCSSEnum(): typeof RatingStarsCSSEnum {
    return RatingStarsCSSEnum;
  }

  public get ratingAverageCSSEnum(): typeof RatingAverageCSSEnum {
    return RatingAverageCSSEnum;
  }

  transformProductToProductDetail() {
    return {
      ...this.product,
    } as ProductDetailViewModel;
  }

  confirmUnusualQuantity(productNumber: number) {
    this.confirmQuantityEmitter.emit(productNumber);
  }

  showPromotionsAndLineNumber() {
    return (
      (this.mode !== UsfProductCardModeEnum.listManagement &&
        this.mode !== UsfProductCardModeEnum.addToInventory &&
        this.product?.promotionDetails) ||
      this.mode === UsfProductCardModeEnum.listDetail ||
      this.mode === UsfProductCardModeEnum.listManagement ||
      this.mode === UsfProductCardModeEnum.productNumberSearch
    );
  }
}
