import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import brightcovePlayerLoader from '@brightcove/player-loader';
import { environment } from 'src/environments/environment';
import { ProductDetailVideoPlayerService } from '@product-detail/services/product-detail-video-player.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-usf-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  private accountID: string;
  private playerID: string;
  @Input() videoID: string;
  @Input() sequence?: string = '1';
  private pauseVideoSubscription: Subscription;
  private bcPlayer: any;
  bcAccountId: string;
  bcPlayerId: string;
  componentId: string;

  constructor(
    private productDetailVideoPlayerService: ProductDetailVideoPlayerService,
  ) {}

  ngOnInit(): void {
    this.videoID = this.videoID;
    this.accountID = environment.brightCoveAccountId;
    this.playerID =
      environment.brightCovePlayerId == null
        ? 'default'
        : environment.brightCovePlayerId;
    this.componentId = 'brightcove-player-' + this.sequence;
    this.pauseVideoSubscription =
      this.productDetailVideoPlayerService.pauseVideo$?.subscribe(videoId => {
        if (!videoId) {
          this.bcPlayer?.pause();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updatePlayerVideo();
  }

  private loadBrightcoveVideo() {
    brightcovePlayerLoader({
      refNode: document.getElementById(this.componentId),
      accountId: this.accountID,
      videoId: this.videoID,
      playerId: this.playerID,
      options: {
        fullscreen: {
          options: { navigationUI: 'show' },
        },
        controls: true,
        fluid: true,
        controlBar: {
          fullscreenToggle: false,
          pictureInPictureToggle: false,
        },
      },
      onSuccess: success => {
        this.bcPlayer = success.ref;
      },
      onFailure: error => {
        console.error('error loading video', error);
      },
    });
  }

  private updatePlayerVideo() {
    this.bcPlayer?.ready(() => {
      this.bcPlayer?.catalog?.getVideo(this.videoID, (err, vid) => {
        this.bcPlayer?.catalog?.load(vid);
      });
    });
  }

  ngAfterViewInit(): void {
    this.loadBrightcoveVideo();
  }

  ngOnDestroy() {
    this.pauseVideoSubscription?.unsubscribe();
  }
}
