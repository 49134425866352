import {
  HandPricingAdditionalDetails,
  HandPricingDetails,
} from '@order/models/order-view-model';
import { SellerPriceDetails } from '@usf/price-types';
import { CprRequestTypeEnum } from '@usf/ngrx-pricing';
import { ProductPriceUtil } from './product-price.util';
import { HotKeys } from '@shared/constants/hot-key.enum';

const RadioOptions = {
  minimum: 'minimum',
  maximum: 'maximum',
  target: 'target',
  custom: 'custom',
  makeCpr: 'makeCpr',
};

const cprResponseText = {
  belowLimit: 'ENTERED PRICE BELOW LIMIT - CPR NOT UPDATED',
  aboveLimit: 'ENTERED PRICE ABOVE LIMIT - CPR NOT UPDATED',
};

export const getRequestType = (
  selectedValue: string,
  sellerPriceDetail: SellerPriceDetails,
) => {
  if (
    sellerPriceDetail?.currentPrice?.priceControls?.locallyPricedInd?.toUpperCase() ===
    'Y'
  ) {
    return CprRequestTypeEnum.custom;
  }

  switch (selectedValue) {
    case RadioOptions.maximum:
      return CprRequestTypeEnum.maximum;
    case RadioOptions.minimum:
      return CprRequestTypeEnum.minimum;
    case RadioOptions.target:
      return CprRequestTypeEnum.target;
    default:
      return CprRequestTypeEnum.custom;
  }
};

export const getPricesForPriceRadios = sellerPriceDetail => {
  if (sellerPriceDetail) {
    const { maximum, minimum, target } =
      ProductPriceUtil.getMaxMinTargetPrices(sellerPriceDetail);
    const maximumLabel =
      sellerPriceDetail.districtNgpCprInd === 'Y'
        ? 'i18n.productDetail.customPricingRationaleModal.changeToStart'
        : 'i18n.productDetail.customPricingRationaleModal.changeToMaximum';
    const radioMap = new Map();
    radioMap.set(RadioOptions.minimum, {
      label: 'i18n.productDetail.customPricingRationaleModal.changeToMinimum',
      price: minimum,
      id: RadioOptions.minimum,
    });
    radioMap.set(RadioOptions.target, {
      label: 'i18n.productDetail.customPricingRationaleModal.changeToTarget',
      price: target,
      id: RadioOptions.target,
    });
    radioMap.set(RadioOptions.maximum, {
      label: maximumLabel,
      price: maximum,
      id: RadioOptions.maximum,
    });
    radioMap.set(RadioOptions.custom, {
      label: 'i18n.productDetail.customPricingRationaleModal.customPrice',
      price: 0,
      id: RadioOptions.custom,
    });
    return radioMap;
  }
};

//returns an object containing hand pricing details for NGP-CPR modal
export const mapHandPricingDetails = (
  pricingDetails: SellerPriceDetails,
  productDetails: HandPricingDetails,
  cprPricing: boolean,
): HandPricingDetails => {
  const currentPrice = pricingDetails?.currentPrice;
  const cookbookPrices = currentPrice?.cookbookPrices;
  const divisionCosts = currentPrice?.divisionCosts;
  const autoShippingCharges = currentPrice?.autoShippingCharges;

  const cookbookMaximumPrice = cookbookPrices?.cookbookMaximumPrice;
  const listAmount = divisionCosts?.listAmount;
  const targetPrice = cookbookPrices?.cookbookTargetPrice;
  const sellAmount = divisionCosts?.sellAmount;

  let maximum = cookbookMaximumPrice > 0 ? cookbookMaximumPrice : listAmount;
  let minimum = divisionCosts?.commissionAmount;
  let target = targetPrice > 0 ? targetPrice : sellAmount;

  if (pricingDetails?.customerAscInd === 'P' && !cprPricing) {
    const unitAscRate = autoShippingCharges?.unitAscRate || 0;
    maximum = maximum + unitAscRate;
    minimum = minimum + unitAscRate;
    target = target + unitAscRate;
  }

  const additionalPricingInfo: HandPricingAdditionalDetails = {
    unitPrice: currentPrice?.unitPrice,
    eachPrice: currentPrice?.eachPrice,
    commissionAmount: divisionCosts?.commissionAmount,
    ascInd: pricingDetails?.customerAscInd,
    unitAscRate: autoShippingCharges?.unitAscRate,
    eachAscRate: autoShippingCharges?.eachAscRate,
    grossProfitPercent: currentPrice?.grossProfitPercent,
    grossProfitDollars: currentPrice?.grossProfitDollars,
  };
  return {
    maximum,
    minimum,
    target,
    additionalPricingInfo,
    ...productDetails,
  };
};
export const roundPrice = (priceToUpdate: number) => {
  const roundedPrice = Math.round(priceToUpdate * 100) / 100;
  return roundedPrice;
};
export const allowOnlyNumbers = (event: KeyboardEvent): boolean => {
  const allowedKeys = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '.',
    HotKeys.backSpace,
    HotKeys.tab,
  ];

  // Check if the decimal point is already in the input value to prevent multiple decimal points
  const inputElement = event.target as HTMLInputElement;
  if (event.key === '.' && inputElement.value.includes('.')) {
    event.preventDefault();
    return false;
  }

  if (!allowedKeys.includes(event.key)) {
    event.preventDefault();
    return false;
  }

  return true;
};
