import {
  DivisionalPromotionFilterType,
  DivisionalPromotionProduct,
  DivisionalPromotionSectionHeaders,
  DivisionalPromotionSorting,
  DivisionalPromotionFilterValues,
  SellerShowcaseDivisionalPromotion,
  SellerShowcaseUpdates,
  SellerShowcaseProductCardDesktopHeight,
  SellerShowcaseProductCardTouchHeight,
} from '@app/seller-showcase/models/seller-showcase.model';
import { convertStringToDate } from '@shared/helpers/calendar.helpers';
import { Product } from '@shared/models/product.model';
import { SortDirections } from '@usf/ngrx-inventory';
import { DivisionalPromotion } from '@usf/product-types';
import { PlatformEnum } from '@panamax/app-state';
import { Customer } from '@usf/customer-types';
import { ProductShippingPriceService } from '@shared/services/product-shipping-price/product-shipping-price.service';

/**
 *
 * @param endEffDate
 * @returns December 31st of the current year if endEffDate year is 2099, otherwise returns endEffDate
 */
export const getPromotionEndDate = (endEffDate: Date | string): Date => {
  const endDate =
    endEffDate instanceof Date ? endEffDate : convertStringToDate(endEffDate);
  return endDate.getFullYear() === 2099
    ? new Date(new Date().getFullYear(), 11, 31)
    : endDate;
};

export const getSortedAndFilteredPromotionalProducts = (
  promotionalProducts: DivisionalPromotionProduct[],
  filters?: Map<DivisionalPromotionFilterType, string>,
  sorting?: DivisionalPromotionSorting,
): DivisionalPromotionProduct[] => {
  const filteredProducts = filterPromotionalProducts(
    promotionalProducts,
    filters,
  );
  return sortPromotionalProducts(filteredProducts, sorting);
};

/**
 * @param promotionalProducts - an array of DivisionalPromotionProduct to filter.
 * @param filters - an optional map containing filter types as keys and an array of filter values.
 * @returns an array of DivisionalPromotionProduct that match the searchWithin filter, or the original array if no filter is provided.
 */
const filterPromotionalProducts = (
  promotionalProducts: DivisionalPromotionProduct[],
  filters?: Map<DivisionalPromotionFilterType, string>,
): DivisionalPromotionProduct[] => {
  let response = promotionalProducts;

  if (filters) {
    const filterFunctions = {
      [DivisionalPromotionFilterType.searchWithin]:
        filterPromotionalProductsBySearchWithin,
      [DivisionalPromotionFilterType.brand]: filterPromotionalProductsByBrand,
      [DivisionalPromotionFilterType.exclusiveBrand]:
        filterPromotionalProductsByExclusiveBrand,
      [DivisionalPromotionFilterType.productClass]:
        filterPromotionalProductsByProductClass,
    };

    filters.forEach((value, key) => {
      if (filterFunctions[key]) {
        response = filterFunctions[key](response, value);
      }
    });
  }

  return response;
};

const filterPromotionalProductsBySearchWithin = (
  promotionalProducts: DivisionalPromotionProduct[],
  searchKey: string,
) => {
  searchKey = searchKey.toLowerCase();
  return promotionalProducts.filter(
    promotionalProduct =>
      promotionalProduct?.productNumber?.toString().includes(searchKey) ||
      promotionalProduct?.product?.summary?.brand
        ?.toLowerCase()
        .includes(searchKey) ||
      promotionalProduct.product?.summary?.productDescTxtl
        ?.toLowerCase()
        .includes(searchKey),
  );
};

const filterPromotionalProductsByBrand = (
  promotionalProducts: DivisionalPromotionProduct[],
  brand: string,
) => {
  if (brand === DivisionalPromotionFilterValues.allSelected) {
    return promotionalProducts;
  }

  brand = brand.toLowerCase();

  return promotionalProducts.filter(
    promotionalProduct =>
      promotionalProduct?.product?.summary?.brand?.toLowerCase() === brand,
  );
};

const filterPromotionalProductsByProductClass = (
  promotionalProducts: DivisionalPromotionProduct[],
  productClass: string,
) => {
  if (productClass === DivisionalPromotionFilterValues.allSelected) {
    return promotionalProducts;
  }

  productClass = productClass.toLowerCase();

  return promotionalProducts.filter(
    promotionalProduct =>
      promotionalProduct?.product?.summary?.classDescription?.toLowerCase() ===
      productClass,
  );
};

const filterPromotionalProductsByExclusiveBrand = (
  promotionalProducts: DivisionalPromotionProduct[],
  exclusiveBrand: string,
) => {
  if (exclusiveBrand === DivisionalPromotionFilterValues.allSelected) {
    return promotionalProducts;
  }

  const isExclusiveBrand =
    exclusiveBrand === DivisionalPromotionFilterValues.yes;

  return promotionalProducts.filter(promotionalProduct => {
    const brandType = promotionalProduct?.product?.summary?.brandType;
    return isExclusiveBrand ? brandType === '2' : brandType !== '2';
  });
};

/**
 *
 * @param promotionalProducts
 * @param sorting the sorting criteria
 * @returns promotionalProducts sorted by the received sorting criteria or alphabetically by product description if no valid sorting criteria is provided.
 */
const sortPromotionalProducts = (
  promotionalProducts: DivisionalPromotionProduct[],
  sorting?: DivisionalPromotionSorting,
): DivisionalPromotionProduct[] => {
  const isAscending = sorting?.sortDirection === SortDirections.asc;

  return promotionalProducts?.slice().sort((productA: any, productB: any) => {
    switch (sorting?.headerName) {
      case DivisionalPromotionSectionHeaders.productNumber:
        return isAscending
          ? productA.productNumber - productB.productNumber
          : productB.productNumber - productA.productNumber;

      case DivisionalPromotionSectionHeaders.productDescription:
        return compareByText(
          productA.product?.summary?.productDescTxtl,
          productB.product?.summary?.productDescTxtl,
          isAscending,
        );

      case DivisionalPromotionSectionHeaders.brand:
        return compareByText(
          productA.product?.summary?.brand,
          productB.product?.summary?.brand,
          isAscending,
        );

      default:
        return compareByText(
          productA.product?.summary?.productDescTxtl,
          productB.product?.summary?.productDescTxtl,
          true,
        );
    }
  });
};

const compareByText = (textA: string, textB: string, isAscending: boolean) => {
  const compareResult = textA?.localeCompare(textB);
  return isAscending ? compareResult : -compareResult;
};

const filterDivisionalPromotions = (
  divisionalPromotions: SellerShowcaseDivisionalPromotion[],
  filters?: Map<DivisionalPromotionFilterType, string>,
): SellerShowcaseDivisionalPromotion[] => {
  const filterValue = filters?.get(DivisionalPromotionFilterType.promoType);

  if (
    !filterValue ||
    filterValue === DivisionalPromotionFilterValues.allSelected
  ) {
    return divisionalPromotions;
  }

  return divisionalPromotions.filter(
    divisionalPromotion =>
      divisionalPromotion?.promotionDescription?.toLowerCase() ===
      filterValue.toLowerCase(),
  );
};

/**
 *
 * @param divisionalPromotions
 * @returns divisionalPromotions sorted by end date, keeping promotions with "Scoop" in their description at the end and filtered by received criteria
 */
export const getSortedAndFilteredDivisionalPromotions = (
  divisionalPromotions: SellerShowcaseDivisionalPromotion[],
  filters?: Map<DivisionalPromotionFilterType, string>,
): SellerShowcaseDivisionalPromotion[] => {
  const response = filterDivisionalPromotions(divisionalPromotions, filters);
  return response
    ?.slice()
    ?.sort((promotionA, promotionB) =>
      sortDivisionalPromotion(promotionA, promotionB),
    );
};

export const getSortedDivisionalPromotions = (
  divisionalPromotions: DivisionalPromotion[],
): DivisionalPromotion[] => {
  return divisionalPromotions
    ?.map(promotion => ({
      ...promotion,
      endEffDate: getPromotionEndDate(promotion?.endEffDate),
    }))
    ?.slice()
    ?.sort((promotionA, promotionB) =>
      sortDivisionalPromotion(promotionA, promotionB),
    );
};

const sortDivisionalPromotion = (
  promotionA: DivisionalPromotion,
  promotionB: DivisionalPromotion,
): number => {
  const dateA = new Date(promotionA?.endEffDate).getTime();
  const dateB = new Date(promotionB?.endEffDate).getTime();

  const isScoopA = promotionA?.promotionDescription
    ?.toLowerCase()
    .includes('scoop')
    ? 1
    : 0;
  const isScoopB = promotionB?.promotionDescription
    ?.toLowerCase()
    .includes('scoop')
    ? 1
    : 0;

  if (isScoopA !== isScoopB) {
    return isScoopA - isScoopB;
  }

  return dateA - dateB;
};

export const decorateDivisionalPromotionsWithProductsExpansionAndHeights = (
  divisionalPromotions: SellerShowcaseDivisionalPromotion[],
  platform: PlatformEnum,
  shippingPriceFlag: boolean,
  selectedCustomer: Customer,
  expansion?: Map<string, boolean>,
): SellerShowcaseDivisionalPromotion[] => {
  return divisionalPromotions
    ?.map((promotion, index) => ({
      ...promotion,
      products: promotion?.promotionalProducts?.map(
        promotion => promotion?.product,
      ),
      isExpanded: expansion?.get(promotion?.key) ?? index === 0,
    }))
    ?.map(promotion => ({
      ...promotion,
      itemHeights: calculateItemHeights(
        promotion,
        platform,
        shippingPriceFlag,
        selectedCustomer,
      ),
    }));
};

export const processSellerDivisionalPromotions = (
  divisionalPromotions: DivisionalPromotion[],
  products: Map<number, Product>,
  updates?: SellerShowcaseUpdates,
) => {
  let brandNames: Set<string> = new Set();
  let productClasses: Set<string> = new Set();

  const sellerDivisionalPromotions = divisionalPromotions?.map(
    divisionalPromotion => {
      const divisionalPromotionKey = `${divisionalPromotion?.divisionNumber}-${divisionalPromotion?.promotionName}`;

      const productsAreAvailable = arePromotionProductsAvailable(
        divisionalPromotion,
        products,
      );
      const promotionalProducts = productsAreAvailable
        ? divisionalPromotion.promotions
            .map(promotion => {
              const product = products.get(promotion.productNumber);

              brandNames.add(product?.summary?.brand);
              productClasses.add(product?.summary?.classDescription);

              return {
                ...promotion,
                product,
              };
            })
            ?.filter(
              promotionalProduct => !promotionalProduct?.product?.notFound,
            )
        : [];

      return {
        ...divisionalPromotion,
        key: divisionalPromotionKey,
        endEffDate: getPromotionEndDate(divisionalPromotion?.endEffDate),
        promotionalProducts: getSortedAndFilteredPromotionalProducts(
          promotionalProducts,
          updates?.filters,
          updates?.sorting?.get(divisionalPromotionKey),
        ),
        productsAreAvailable,
        sortBy: updates?.sorting?.get(divisionalPromotionKey),
      };
    },
  );

  return {
    sellerDivisionalPromotions,
    brandsNames: Array.from([...brandNames])
      .filter(value => !!value)
      .sort((a, b) => compareByText(a, b, true)),
    productClasses: Array.from([...productClasses])
      .filter(value => !!value)
      .sort((a, b) => compareByText(a, b, true)),
  };
};

export const arePromotionProductsAvailable = (
  divisionalPromotion: DivisionalPromotion,
  products: Map<number, Product>,
): boolean => {
  return divisionalPromotion?.promotions?.every(promotion =>
    products.has(promotion?.productNumber),
  );
};

const calculateItemHeights = (
  promotion: SellerShowcaseDivisionalPromotion,
  platform: PlatformEnum,
  shippingPriceFlag: boolean,
  selectedCustomer: Customer,
) => {
  if (!promotion || !promotion.isExpanded) {
    return [];
  }
  let itemHeights: number[];

  if (platform === PlatformEnum.mobile || platform === PlatformEnum.tablet) {
    itemHeights = calculateTouchProductHeights(
      promotion,
      shippingPriceFlag,
      selectedCustomer,
    );
  } else {
    itemHeights = calculateDesktopProductHeights(promotion);
  }

  return itemHeights;
};

const calculateDesktopProductHeights = (
  promotion: SellerShowcaseDivisionalPromotion,
) => {
  const itemHeights: number[] = [];
  for (const promotionalProduct of promotion.promotionalProducts) {
    const product = promotionalProduct?.product;

    let height = 0;
    if (
      product?.drawerMsg?.isWarning ||
      product?.productCardWarningMsg?.display
    ) {
      height += SellerShowcaseProductCardDesktopHeight.border;
    }
    if (
      !!product?.drawerMsg?.message ||
      product?.productCardWarningMsg?.display
    ) {
      height += SellerShowcaseProductCardDesktopHeight.drawer;
    }

    height += SellerShowcaseProductCardDesktopHeight.baseProduct;
    height += SellerShowcaseProductCardDesktopHeight.margin;

    itemHeights.push(height);
  }

  return itemHeights;
};

const calculateTouchProductHeights = (
  promotion: SellerShowcaseDivisionalPromotion,
  shippingPriceFlag: boolean,
  selectedCustomer: Customer,
) => {
  const itemHeights: number[] = [];
  for (const promotionalProduct of promotion.promotionalProducts) {
    const product = promotionalProduct?.product;

    let height = 0;
    if (
      promotionalProduct?.product?.drawerMsg?.isWarning ||
      product?.productCardWarningMsg?.display
    ) {
      height += SellerShowcaseProductCardTouchHeight.border;
    }
    if (
      !!product?.drawerMsg?.message ||
      product?.productCardWarningMsg?.display
    ) {
      height += SellerShowcaseProductCardTouchHeight.drawer;
    }

    height += SellerShowcaseProductCardTouchHeight.baseProduct;
    height += SellerShowcaseProductCardTouchHeight.margin;

    if (
      ProductShippingPriceService.doesProductHaveShippingPrice(
        shippingPriceFlag,
        product,
        selectedCustomer,
      )
    ) {
      height += SellerShowcaseProductCardTouchHeight.separateShippingPrice;
    }

    itemHeights.push(height);
  }

  return itemHeights;
};
