import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import moment from 'moment';
import { ProductOpportunityRequest } from '@usf/product-types';
import { OpportunityPublishOpportunity } from '../../../../models/create-opportunity.enum';
import { Product } from 'src/app/shared/models/product.model';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { calculatePrice } from '@shared/selectors/helpers/order-total-price.selectors.helper';

export const YES_VALUE = OpportunityPublishOpportunity.YES;

@Component({
  selector: 'app-seller-create-opportunity-form',
  templateUrl: './seller-create-opportunity-form.component.html',
  styleUrls: ['./seller-create-opportunity-form.component.scss'],
})
export class SellerCreateOpportunityFormComponent implements OnInit {
  @Input() disabledPrices: boolean;
  @Input() showEaches: boolean;
  @Input() productInformation: ProductDetailViewModel;
  @Output() updateFormValidity = new EventEmitter<any>();

  createOpportunityForm: UntypedFormGroup = new UntypedFormGroup({});
  productOpportunity: ProductOpportunityRequest;

  // When publish option is equal to No
  showNextView = false;

  publishOptions = [
    { text: this.translateService.instant('i18n.common.yes') },
    { text: this.translateService.instant('i18n.common.no') },
  ];

  newSelectedDate: moment.Moment | null;
  formattedNewSelectedDate: moment.Moment | null;
  currentSelectedDate: Date | null;
  selectedDate: Date;

  minDate = moment().format();
  maxDate = moment().add(1, 'year').format();
  selectedShipDate = moment().add(30, 'day').utc().format();

  invalidQuantity: boolean;
  invalidName: boolean;
  defaultShipDate = new Date();

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.createOpportunityForm = this.fb.group({
      publishOption: [YES_VALUE, Validators.required],
      name: ['', Validators.required],
      weeklyCases: [''],
      weeklyEaches: [''],
      weeklyPotential: ['0', Validators.required],
      shipDate: [moment().add(30, 'day').utc().toDate(), Validators.required],
    });
    this.checkFormValues();
  }

  checkFormValues() {
    // keep form values updated
    // Memory Leak
    this.createOpportunityForm?.valueChanges.subscribe(controls => {
      if (controls['weeklyCases'] || controls['weeklyEaches']) {
        this.calculateWeeklyPotential();
      }
      if (this.createOpportunityForm.valid) {
        this.formatCreateOpportunityForm();
      }

      const casesControl = this.createOpportunityForm.get('weeklyCases');
      const eachesControl = this.createOpportunityForm.get('weeklyEaches');
      const nameControl = this.createOpportunityForm.get('name');

      this.invalidQuantity =
        // the quantity is invalid when
        // cases is not greater than 0
        !(Number(casesControl.value ?? 0) > 0) &&
        // and eaches is defined and is not greater than 0
        !(Number(eachesControl?.value ?? 0) > 0);

      this.updateFormValidity.emit({
        isValid: this.createOpportunityForm.valid && !this.invalidQuantity,
        form: this.productOpportunity,
        publishOpportunity: controls['publishOption'],
      });

      this.invalidName = !(
        nameControl.value === '' || nameControl.value === null
      );
    });
  }

  updateFormDate(newDate) {
    this.selectedShipDate = moment(newDate.detail.value).format();
    this.createOpportunityForm.controls['shipDate'].setValue(
      moment(newDate.detail.value).utc(),
    );
  }

  formatCreateOpportunityForm() {
    const publishOpportunity =
      this.createOpportunityForm.get('publishOption').value === YES_VALUE;
    const opportunityName = this.createOpportunityForm.get('name').value.trim();
    const weeklyCases =
      this.createOpportunityForm.get('weeklyCases').value || 0;
    const weeklyEaches =
      this.createOpportunityForm.get('weeklyEaches').value || 0;
    const weeklyPotential = this.createOpportunityForm.get('weeklyPotential')
      .value;
    const estimatedShipDate = this.createOpportunityForm.get('shipDate').value;
    this.productOpportunity = {
      publishOpportunity,
      opportunityName,
      weeklyCases,
      weeklyEaches,
      weeklyPotential,
      estimatedShipDate,
    } as ProductOpportunityRequest;
  }

  calculateWeeklyPotential() {
    let weeklyCasesPotential = 0;
    let weeklyEachesPotential = 0;

    if (!this.disabledPrices) {
      const weeklyCasesPt = Number(
        this.createOpportunityForm.get('weeklyCases').value,
      );
      const weeklyEachesPt = Number(
        this.createOpportunityForm.get('weeklyEaches').value,
      );

      let totalPotential = 0;

      if (weeklyCasesPt > 0) {
        totalPotential = calculatePrice(
          weeklyCasesPt,
          this.productInformation.pricing.unitPrice.toString(),
          true,
          this.productInformation.summary.catchWeightFlag,
          this.productInformation.summary.netWeight,
          this.productInformation.inventory.eachConversionFactor,
        );
      }

      if (weeklyEachesPt > 0) {
        totalPotential += calculatePrice(
          weeklyEachesPt,
          this.productInformation.pricing.eachPrice.toString(),
          false,
          this.productInformation.summary.catchWeightFlag,
          this.productInformation.summary.netWeight,
          this.productInformation.inventory.eachConversionFactor,
        );
      }

      this.createOpportunityForm
        .get('weeklyPotential')
        .patchValue(totalPotential?.toFixed(2), {
          emitEvent: false,
        });
    }
  }

  get form() {
    return this.createOpportunityForm?.controls;
  }
}
