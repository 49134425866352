<ion-header class="ion-no-border" [ngClass]="getHeaderClass()">
  <ion-toolbar>
    <ion-buttons *ngIf="!isMobile()" slot="end">
      <ion-button data-cy="invite-new-user-close-button" (click)="dismiss()">
        <ion-icon
          data-cy="invite-new-user-close-button-icon"
          slot="icon-only"
          name="close-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="getContentClass()">
  <ng-container *ngIf="inviteUserFlag; else inviteNewUser">
    <ng-container *ngTemplateOutlet="currentViewTemplate"></ng-container>
  </ng-container>
  <ng-template #inviteNewUser>
    <ng-container *ngTemplateOutlet="inviteNewUserTemplate"></ng-container>
  </ng-template>
</ion-content>

<ion-footer class="ion-footer-modal" [ngClass]="getFooterClass()">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="invite-new-user-cancel-button"
        [class.usf-outline-green-mobile-modal-button]="
          platformService?.platformType ===
          platformService?.platformEnum?.mobile
        "
        [class.usf-outline-green-dsktp-tblt-modal-button]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
        "
        (click)="
          dismiss(
            mobileExistingUserInfoSelected ||
              mobileExistingCustomersSelected ||
              mobileNewCustomersSelected ||
              mobileNewUserInfoSelected ||
              mobileNewCustomersSelected ||
              mobileNewUserAdvOptionsOrganizationSelected ||
              mobileNewUserAdvOptionsSiteRoleSelected ||
              mobileNewUserAdvOptionsUserAccessSelected ||
              mobileNewUserAdvOptionsNationalCustomizationSelected ||
              mobileNewUserAdvOptionsClientConceptSelected,
            mobileNewUserInfoSelected ||
              mobileNewCustomersSelected ||
              mobileNewUserAdvOptionsOrganizationSelected ||
              mobileNewUserAdvOptionsSiteRoleSelected ||
              mobileNewUserAdvOptionsUserAccessSelected ||
              mobileNewUserAdvOptionsNationalCustomizationSelected ||
              mobileNewUserAdvOptionsClientConceptSelected
          )
        "
      >
        {{
          mobileExistingUserInfoSelected ||
          mobileExistingCustomersSelected ||
          mobileNewUserInfoSelected ||
          mobileNewCustomersSelected ||
          mobileNewUserAdvOptionsOrganizationSelected ||
          mobileNewUserAdvOptionsSiteRoleSelected ||
          mobileNewUserAdvOptionsUserAccessSelected ||
          mobileNewUserAdvOptionsNationalCustomizationSelected ||
          mobileNewUserAdvOptionsClientConceptSelected
            ? ('i18n.common.goBack' | translate)
            : ('i18n.common.cancel' | translate)
        }}
      </ion-button>
      <ion-button
        *ngIf="!inviteUserFlag"
        data-cy="invite-new-user-modal-save-button"
        [ngClass]="
          this.inviteNewUserForm.valid
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onInviteNewUser()"
      >
        {{ 'i18n.profilePage.inviteNewUser.button' | translate }}
      </ion-button>
      <ion-button
        *ngIf="inviteUserFlag && currentView === 'inviteUserTypeSelection'"
        data-cy="invite-new-user-modal-save-button"
        [ngClass]="
          this.inviteUserForm.valid
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onNext()"
      >
        {{ 'i18n.common.next' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          inviteUserFlag &&
          modalTitle === 'i18n.profilePage.inviteUser.inviteAnExistingUser' &&
          mobileExistingUserInfoSelected
        "
        data-cy="invite-new-user-modal-submit-button"
        [disabled]="
          !this.existingUserResult.existingUser.currentValue ||
          !this.existingUserResult.existingUser.isUserSelected
        "
        [ngClass]="
          this.existingUserResult.existingUser.currentValue &&
          this.existingUserResult.existingUser.isUserSelected
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onMobileExistingUserInformationNext()"
      >
        {{ 'i18n.common.next' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          inviteUserFlag &&
          modalTitle === 'i18n.profilePage.inviteNewUser.button' &&
          mobileNewUserInfoSelected
        "
        data-cy="invite-new-user-modal-submit-button"
        [disabled]="
          !this.inviteNewUserForm.get('newUserEmailAddress').value ||
          !this.inviteNewUserForm.valid ||
          !this.newUserResult.newUserEmail.hasChanges
        "
        [ngClass]="
          this.inviteNewUserForm.get('newUserEmailAddress').value &&
          this.inviteNewUserForm.valid &&
          this.newUserResult.newUserEmail.hasChanges
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onMobileNewUserInformationNext()"
      >
        {{ 'i18n.common.next' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          inviteUserFlag &&
          modalTitle === 'i18n.profilePage.inviteUser.inviteAnExistingUser' &&
          mobileExistingCustomersSelected
        "
        data-cy="invite-new-user-modal-submit-button"
        [ngClass]="
          this.existingUserResult.existingUser.savedValue &&
          this.existingUserResult.customers.currentValue.length > 0
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onMobileExistingCustomersnNext()"
      >
        {{ 'i18n.common.next' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          inviteUserFlag &&
          modalTitle === 'i18n.profilePage.inviteNewUser.button' &&
          mobileNewCustomersSelected
        "
        data-cy="invite-new-user-modal-submit-button"
        [ngClass]="
          this.newUserResult.customers.currentValue.length > 0
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onMobileNewCustomersnNext()"
      >
        {{ 'i18n.common.next' | translate }}
      </ion-button>

      <ion-button
        *ngIf="
          inviteUserFlag &&
          modalTitle === 'i18n.profilePage.inviteUser.inviteAnExistingUser' &&
          !mobileExistingCustomersSelected &&
          !mobileExistingUserInfoSelected
        "
        data-cy="invite-new-user-modal-submit-button"
        [ngClass]="getSubmitButtonClass(false)"
        (click)="onSubmit(false)"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>

      <ion-button
        *ngIf="
          inviteUserFlag &&
          modalTitle === 'i18n.profilePage.inviteNewUser.button' &&
          !mobileNewCustomersSelected &&
          !mobileNewUserInfoSelected &&
          !mobileNewUserAdvOptionsOrganizationSelected &&
          !mobileNewUserAdvOptionsSiteRoleSelected &&
          !mobileNewUserAdvOptionsUserAccessSelected &&
          !mobileNewUserAdvOptionsNationalCustomizationSelected &&
          !mobileNewUserAdvOptionsClientConceptSelected
        "
        data-cy="invite-new-user-modal-submit-button"
        [ngClass]="getSubmitButtonClass(true)"
        (click)="onSubmit(true)"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>

      <ion-button
        *ngIf="
          inviteUserFlag &&
          modalTitle === 'i18n.profilePage.inviteNewUser.button' &&
          mobileNewUserAdvOptionsOrganizationSelected
        "
        data-cy="invite-new-user-modal-save-button"
        [ngClass]="
          this.newUserAdvOptions?.organization?.hasChanges
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onNewUserAdvOptionsSaveChanges()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          inviteUserFlag &&
          modalTitle === 'i18n.profilePage.inviteNewUser.button' &&
          mobileNewUserAdvOptionsSiteRoleSelected
        "
        data-cy="invite-new-user-modal-save-button"
        [ngClass]="
          this.newUserAdvOptions?.siteRole?.hasChanges
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onNewUserAdvOptionsSaveChanges()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          inviteUserFlag &&
          modalTitle === 'i18n.profilePage.inviteNewUser.button' &&
          mobileNewUserAdvOptionsUserAccessSelected
        "
        data-cy="invite-new-user-modal-save-button"
        [ngClass]="
          this.newUserAdvOptions?.userAccessOptions?.hasChanges
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onNewUserAdvOptionsSaveChanges()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          inviteUserFlag &&
          modalTitle === 'i18n.profilePage.inviteNewUser.button' &&
          mobileNewUserAdvOptionsNationalCustomizationSelected
        "
        data-cy="invite-new-user-modal-save-button"
        [ngClass]="
          this.newUserAdvOptions?.nationalCustomization?.hasChanges
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onNewUserAdvOptionsSaveChanges()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          inviteUserFlag &&
          modalTitle === 'i18n.profilePage.inviteNewUser.button' &&
          mobileNewUserAdvOptionsClientConceptSelected
        "
        data-cy="invite-new-user-modal-save-button"
        [ngClass]="
          this.newUserAdvOptions?.clientConcept?.hasChanges
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onNewUserAdvOptionsSaveChanges()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<!-- Templates -->
<ng-template #headerTemplate>
  <ion-label data-cy="invite-new-user-actions-label" *ngIf="!inviteUserFlag">
    {{ 'i18n.profilePage.inviteNewUser.title' | translate }}
    {{ 'i18n.profilePage.inviteNewUser.moxe' | translate }}
  </ion-label>
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="
      inviteUserFlag &&
      !mobileExistingUserInfoSelected &&
      !mobileExistingCustomersSelected &&
      !mobileNewUserInfoSelected &&
      !mobileNewCustomersSelected &&
      !mobileNewUserAdvOptionsOrganizationSelected &&
      !mobileNewUserAdvOptionsSiteRoleSelected &&
      !mobileNewUserAdvOptionsUserAccessSelected &&
      !mobileNewUserAdvOptionsNationalCustomizationSelected &&
      !mobileNewUserAdvOptionsClientConceptSelected
    "
  >
    {{ modalTitle | translate }}
  </ion-label>
  <!-- User Information -->
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="
      (mobileExistingUserInfoSelected && !mobileExistingCustomersSelected) ||
      (mobileNewUserInfoSelected && !mobileNewCustomersSelected)
    "
    (click)="resetMobileOptions(mobileNewUserInfoSelected)"
  >
    <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
    {{ 'i18n.profilePage.inviteUser.userInformation' | translate }}
  </ion-label>

  <!-- Customers -->
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="mobileExistingCustomersSelected || mobileNewCustomersSelected"
    (click)="resetMobileOptions(mobileNewCustomersSelected)"
  >
    <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
    {{ 'i18n.profilePage.inviteUser.customers' | translate }}
  </ion-label>

  <!-- Organization -->
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="mobileNewUserAdvOptionsOrganizationSelected"
    (click)="resetMobileOptions(mobileNewUserAdvOptionsOrganizationSelected)"
  >
    <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
    {{
      'i18n.profilePage.inviteNewUser.advancedOptions.organization' | translate
    }}
  </ion-label>

  <!-- Site Role -->
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="mobileNewUserAdvOptionsSiteRoleSelected"
    (click)="resetMobileOptions(mobileNewUserAdvOptionsSiteRoleSelected)"
  >
    <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
    {{ 'i18n.profilePage.inviteNewUser.advancedOptions.siteRole' | translate }}
  </ion-label>

  <!-- User Access Options -->
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="mobileNewUserAdvOptionsUserAccessSelected"
    (click)="resetMobileOptions(mobileNewUserAdvOptionsUserAccessSelected)"
  >
    <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
    {{
      'i18n.profilePage.inviteNewUser.advancedOptions.userAccessOptions'
        | translate
    }}
  </ion-label>

  <!-- National Customization -->
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="mobileNewUserAdvOptionsNationalCustomizationSelected"
    (click)="
      resetMobileOptions(mobileNewUserAdvOptionsNationalCustomizationSelected)
    "
  >
    <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
    {{
      'i18n.profilePage.inviteNewUser.advancedOptions.nationalCustomization'
        | translate
    }}
  </ion-label>

  <!-- Client Concept -->
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="mobileNewUserAdvOptionsClientConceptSelected"
    (click)="resetMobileOptions(mobileNewUserAdvOptionsClientConceptSelected)"
  >
    <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
    {{
      'i18n.profilePage.inviteNewUser.advancedOptions.clientConcept' | translate
    }}
  </ion-label>
</ng-template>

<ng-template #inviteNewUserTemplate>
  <app-invite-new-user-modal
    *ngIf="inviteUserFlag"
    #newUserModal
    (selectNewUserEmail)="selectNewUser($event)"
    (selectNewUserAdvOptions)="selectNewUserAdvOptions($event)"
    (mobileNewUserInfoSelected)="
      mobileNewUserInfoSelected = !mobileNewUserInfoSelected
    "
    (mobileNewCustomersSelected)="
      mobileNewCustomersSelected = !mobileNewCustomersSelected
    "
    (mobileNewUserAdvOptionsOrganizationSelected)="
      mobileNewUserAdvOptionsOrganizationSelected =
        !mobileNewUserAdvOptionsOrganizationSelected
    "
    (mobileNewUserAdvOptionsSiteRoleSelected)="
      mobileNewUserAdvOptionsSiteRoleSelected =
        !mobileNewUserAdvOptionsSiteRoleSelected
    "
    (mobileNewUserAdvOptionsUserAccessSelected)="
      mobileNewUserAdvOptionsUserAccessSelected =
        !mobileNewUserAdvOptionsUserAccessSelected
    "
    (mobileNewUserAdvOptionsNationalCustomizationSelected)="
      mobileNewUserAdvOptionsNationalCustomizationSelected =
        !mobileNewUserAdvOptionsNationalCustomizationSelected
    "
    (mobileNewUserAdvOptionsClientConceptSelected)="
      mobileNewUserAdvOptionsClientConceptSelected =
        !mobileNewUserAdvOptionsClientConceptSelected
    "
  ></app-invite-new-user-modal>
  <div *ngIf="!inviteUserFlag">
    <div class="description-container">
      <ion-label data-cy="invite-new-user-actions-label">
        {{ 'i18n.profilePage.inviteNewUser.modalDescription' | translate }}
      </ion-label>
      <ion-label data-cy="invite-new-user-actions-label">
        {{ 'i18n.profilePage.inviteNewUser.modalNewUsersEmail' | translate }}
      </ion-label>
    </div>
    <form
      [formGroup]="inviteNewUserForm"
      (ngSubmit)="onInviteNewUser()"
      class="invite-new-user-form"
    >
      <ion-item>
        <ion-input
          aria-label="invite-new-user"
          data-cy="invite-new-user-input"
          placeholder="name@example.com"
          type="email"
          maxlength="80"
          formControlName="newUserEmailAddress"
        ></ion-input>
      </ion-item>
    </form>
    <div class="customer-container">
      <div class="customer-information" *ngFor="let info of customerInfo">
        <ion-label data-cy="invite-new-user-actions-label">
          {{ info.label | translate }}
        </ion-label>
        <ion-label data-cy="invite-new-user-actions-label">
          {{ info.value }}
        </ion-label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #inviteUserTypeSelectionTemplate>
  <div class="description-container">
    <ion-label data-cy="invite-new-user-actions-label">
      {{ 'i18n.profilePage.inviteUser.typeTitle' | translate }}
    </ion-label>
  </div>
  <form [formGroup]="inviteUserForm" class="invite-user-form">
    <ion-list>
      <ion-radio-group formControlName="inviteUserType">
        <ion-item *ngFor="let userType of userTypes">
          <ion-radio
            labelPlacement="start"
            mode="md"
            [value]="userType.value"
            [attr.data-cy]="userType.cy"
          >
            {{ userType.label | translate }}
          </ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </form>
</ng-template>

<ng-template #inviteExistingUserTemplate>
  <app-invite-existing-user-modal
    #existingUserModal
    (selectExistingUser)="selectExistingUser($event)"
    (mobileExistingUserInfoSelected)="
      mobileExistingUserInfoSelected = !mobileExistingUserInfoSelected
    "
    (mobileExistingCustomersSelected)="
      mobileExistingCustomersSelected = !mobileExistingCustomersSelected
    "
  ></app-invite-existing-user-modal>
</ng-template>
