import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Notification, PushOpenedRequest } from '@usf/alert-types';
import {
  AlertAnalyticsService,
  ALERTS_ACTIONS,
  NOTES_ACTIONS,
  PUSH_NOTIFICATIONS_ACTIONS,
  TRACE_CONTEXT,
} from '@usf/ngrx-alerts';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import { MessageTypeEnum } from 'src/app/ngrx-message/constants/messageTypeEnum';
import { Message } from 'src/app/ngrx-message/models/client/message';
import { MessageStoreService } from 'src/app/ngrx-message/services/message/message-store.service';
import { v4 as uuidv4 } from 'uuid';
import { NotificationFilters } from '../model/notification-filters.model';
import { selectNotes } from '../selectors/note.selector';
import {
  selectAlertBatchIdByDetailsId,
  selectNotifications,
} from '../selectors/notifications.selector';
import { filter, take, catchError } from 'rxjs/operators';
import { AlertTypeEnum } from '@notifications/constants/constants';
import {
  LOCAL_STORAGE_KEYS,
  PanAppState,
  SelectedCustomerState,
  UserState,
  UsfTokenService,
} from '@panamax/app-state';
import { Router } from '@angular/router';
import { CustomerStoreService } from '@app/ngrx-customer/services';
import { OrderService } from '../../order/services/order.service';
import { ServiceHandlerService } from '@shared/services/service-handler.service';
import { environment } from 'src/environments/environment';
import { PATHS } from '@shared/constants/paths';
import { notificationsTransformerFunc } from '@notifications/tracing/notifications.transformers';

import { UserService } from '@app/user/services';
import { CART_SELECTORS, CartState } from '@usf/ngrx-order';
import { LoginService } from '@app/login/services';
import { CustomerSwitchForbidden } from '@shared/constants/app_error_messages';
import { FEATURES } from '@shared/constants/splitio-features';
import { ToastMessages } from '@shared/constants/toast-messages.enum';
import { AppErrorService } from '@app/ngrx-message/services/app-error/app-error.service';
import { Customer } from '@usf/customer-types';
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  notifications: Notification[];
  unreadAlertsCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  unreadNotesCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  unreadTotalCount$: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
    private store: Store,
    private analytics: AlertAnalyticsService,
    private actions$: Actions,
    private messageStoreService: MessageStoreService,
    private router: Router,
    private panAppState: PanAppState,
    private customerStoreService: CustomerStoreService,
    private orderService: OrderService,
    private serviceHandler: ServiceHandlerService,
    private userService: UserService,
    private loginService: LoginService,
    private tokenService: UsfTokenService,
    private appErrorService: AppErrorService,
  ) {}

  loadNotifications(): Observable<any> {
    return this.store.select(selectNotifications);
  }

  loadTmNotes(): Observable<any> {
    try {
      return this.store.select(selectNotes);
    } catch (err) {
      return throwError(() => err);
    }
  }

  markAsRead(unreadNotificationsIds: string): Observable<any> {
    this.dispatchGuarded(
      this.store.dispatch(
        ALERTS_ACTIONS.markAlertAsRead({
          alertBatchIds: unreadNotificationsIds,
          tracking: {
            tracing: {
              data: {
                traceContext: TRACE_CONTEXT.markAlertAsRead,
                isStartOfTrace: true,
                isEndOfTrace: false,
              },
              transformFunc: notificationsTransformerFunc,
            },
          },
        }),
      ),
    );
    return this.actions$.pipe(ofType(ALERTS_ACTIONS.markAlertAsReadSuccess));
  }

  markAsReadTmNote(unreadTmNotesIds: string) {
    this.dispatchGuarded(
      this.store.dispatch(
        NOTES_ACTIONS.markNoteAsRead({
          noteIds: unreadTmNotesIds,
          tracking: {
            tracing: {
              data: {
                traceContext: TRACE_CONTEXT.markNoteAsRead,
                isStartOfTrace: true,
                isEndOfTrace: false,
              },
              transformFunc: notificationsTransformerFunc,
            },
          },
        }),
      ),
    );
    return this.actions$.pipe(ofType(NOTES_ACTIONS.markNoteAsReadSuccess));
  }

  getUnreadNotificationsCount(): void {
    this.dispatchGuarded(
      this.store.dispatch(
        ALERTS_ACTIONS.getUnreadAlertsCount({
          tracking: {
            tracing: {
              data: {
                traceContext: TRACE_CONTEXT.getUnreadAlertsCount,
                isStartOfTrace: true,
                isEndOfTrace: false,
              },
              transformFunc: notificationsTransformerFunc,
            },
          },
        }),
      ),
    );
  }

  getUnreadAlertsCount$() {
    return this.unreadAlertsCount$;
  }

  setUnreadAlertsCount(count: number) {
    this.unreadAlertsCount$.next(count);
    this.setUnreadTotalCount$();
  }

  getUnreadNotesCount$() {
    return this.unreadNotesCount$;
  }

  setUnreadNotesCount(count: number) {
    this.unreadNotesCount$.next(count);
    this.setUnreadTotalCount$();
  }

  getUnreadTotalCount$() {
    return this.unreadTotalCount$;
  }

  setUnreadTotalCount$() {
    this.unreadTotalCount$.next(
      this.unreadAlertsCount$.value + this.unreadNotesCount$.value,
    );
  }

  saveFilters(filters: NotificationFilters) {
    sessionStorage.setItem('notification-filters', JSON.stringify(filters));
  }

  loadFilters(): NotificationFilters {
    return JSON.parse(sessionStorage.getItem('notification-filters'));
  }

  saveLastActiveTab(tab: string) {
    sessionStorage.setItem('notification-tab', tab);
  }

  getLastActiveTab(): string {
    return sessionStorage.getItem('notification-tab');
  }

  trackAlertsPageLoad(filters: any) {
    this.analytics.trackAlertsPageLoad(filters);
  }

  trackAlertDetailsPageLoad(alertBatchId: string) {
    this.analytics.trackAlertDetailPageLoad(alertBatchId);
  }

  trackAlertFiltersApplied(filters: any) {
    this.analytics.trackAlertFiltersApplied(filters);
  }

  trackTmNotesPageLoad(noteIdList: string, productTrackingInfos?: any[]) {
    this.analytics.trackTmNotesPageLoad(noteIdList, productTrackingInfos);
  }

  trackOrderedProductsUnavailablePageLoad() {
    this.analytics.trackOrderedProductsUnavailablePageLoad();
  }

  trackProductsAddedToNextDeliveryClick() {
    this.analytics.trackAddProductsToNextDeliveryClick();
  }

  registerDevice() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.pushDeviceInfo)) {
      const request = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.pushDeviceInfo),
      );
      if (request.registered) {
        delete request.registered;
      }
      this.dispatchGuarded(
        this.store.dispatch(
          PUSH_NOTIFICATIONS_ACTIONS.registerDevice({
            registerDeviceRequest: request,
            tracking: {
              tracing: {
                data: {
                  traceContext: TRACE_CONTEXT.registerDevice,
                  isStartOfTrace: true,
                  isEndOfTrace: false,
                },
              },
            },
          }),
        ),
      );
    }
  }

  pushNotificationOpened(notification: any) {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.pushDeviceInfo)) {
      const pushDeviceInfo = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.pushDeviceInfo),
      );
      const request: PushOpenedRequest = {
        deviceId: pushDeviceInfo?.deviceId,
        deviceToken: pushDeviceInfo?.deviceToken,
        alertBatchId: notification?.data?.alertBatchId,
        status: 'notificationOpened',
      };
      this.dispatchGuarded(
        this.store.dispatch(
          PUSH_NOTIFICATIONS_ACTIONS.pushOpened({
            pushOpenedRequest: request,
            tracking: {
              tracing: {
                data: {
                  traceContext: TRACE_CONTEXT.pushOpened,
                  isStartOfTrace: true,
                  isEndOfTrace: false,
                },
              },
            },
          }),
        ),
      );
    }
  }

  handleNoteError() {
    this.saveLastActiveTab('tmnotes');
    this.messageStoreService.upsertMessage({
      id: uuidv4(),
      stack: null,
      watermark: new Date().toUTCString(),
      read: false,
      type: MessageTypeEnum.error,
      display:
        "We're sorry but the details of this note are not available at this time.",
      toast: false,
      sticky: false,
      dismissRoute: ['notifications'],
    } as Message);
  }

  checkMissedNotification() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.lastTappedPushWhileLoggedOff)) {
      const notification = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.lastTappedPushWhileLoggedOff),
      );
      localStorage.removeItem(LOCAL_STORAGE_KEYS.lastTappedPushWhileLoggedOff);
      this.pushNotificationOpened(notification);
      combineLatest([this.panAppState.user$, this.panAppState.customer$])
        .pipe(
          filter(([user, customer]) => !!user && !!user.userId && !!customer),
          take(1),
        )
        .subscribe(([user, customer]) => {
          this.alertUser(user, customer, notification);
        });
    }
  }

  alertUser(
    user: UserState,
    customer: Customer | SelectedCustomerState,
    notification: any,
  ) {
    if (notification?.data?.alertId?.toString() === AlertTypeEnum.TM_NOTE) {
      if (
        user.userId !==
        notification?.data?.userName?.toString().replace('[AD_AUTO]', '').trim()
      ) {
        // notification is intended for another user; route to TM Note tab with error modal
        this.routeToNotificationPath(notification, customer, true);
      } else {
        this.customerStoreService
          .loadCustomers$()
          .pipe(
            filter(customers => !!customers.length),
            take(1),
          )
          .subscribe(async customers => {
            const customersAvail = customers;
            if (
              notification?.data &&
              !customersAvail.find(
                c => c.customerNumber === +notification.data.customerNumber,
              )
            ) {
              // If the user is no longer associated with the customer, route to TM Note tab with error modal
              this.routeToNotificationPath(notification, customer, true);
            } else {
              try {
                this.routeToNotificationPath(notification, customer);
              } catch (e) {
                //catch customer switch errors, notify the user and navigate home
                if (e.status === 403) {
                  this.showCustomerSwitchError(e);
                }
              }
            }
          });
        this.markAsReadTmNote(notification?.data?.noteId);
      }
    } else {
      this.markAsRead(notification?.data?.alertBatchId?.toString());
      this.routeToNotificationPath(notification, customer);
    }
  }

  async routeToNotificationPath(
    notification: any,
    customer: Customer | SelectedCustomerState,
    routeToTmNoteTab?: boolean,
  ) {
    this.pushNotificationOpened(notification);
    if (
      notification?.data?.alertId?.toString() ===
        AlertTypeEnum.DELIVERY_EXCEPTION ||
      notification?.data?.alertId?.toString() ===
        AlertTypeEnum.DELIVERY_HEADSUP ||
      notification?.data?.alertId?.toString() ===
        AlertTypeEnum.DIRECT_SHIP_TRACKING ||
      notification?.data?.alertId?.toString() ===
        AlertTypeEnum.DIRECT_STANDARD_DELIVERY ||
      notification?.data?.alertId?.toString() ===
        AlertTypeEnum.DIRECT_ORDER_EXCEPTIONS ||
      notification?.data?.alertId?.toString() ===
        AlertTypeEnum.DIRECT_DELIVERY_EXCEPTIONS
    ) {
      const orderNumbers = notification?.data?.orderNumber?.split(',');
      if (orderNumbers?.length === 1) {
        if (
          customer?.customerNumber?.toString() !==
          notification?.data?.customerNumber?.toString()
        ) {
          try {
            await this.loginService.switchToCustomer(
              notification?.data?.customerNumber,
              notification?.data?.departmentNumber ?? 0,
              notification?.data?.divisionNumber,
            );
          } catch (e) {
            //catch customer switch errors, notify the user and navigate home
            if (e.status === 403) {
              this.showCustomerSwitchError(e);
              return;
            }
          }
        }
        this.orderService
          .selectOrderWithTandemNumber(Number(orderNumbers[0]))
          .pipe(take(1))
          .subscribe(async order => {
            if (order) {
              const orderInfo =
                `${order?.orderHeader?.customerNumber}:` +
                `${order?.orderHeader?.departmentNumber}:` +
                `${order?.orderHeader?.orderId}`;
              notification.data.path =
                notification?.data?.alertId?.toString() ===
                AlertTypeEnum.DELIVERY_EXCEPTION
                  ? notification.data.path + '&orderInfo=' + orderInfo
                  : notification?.data?.path?.replace(
                      '/my-orders',
                      `/order/submitted-order/${orderInfo}`,
                    );
            }
            if (
              notification?.data?.path &&
              notification?.data?.path?.indexOf('?') > -1
            ) {
              const path = notification?.data?.path?.split('?')[0];
              const query = notification?.data?.path?.split('?')[1];
              const params = query.split('&');
              const finalParams = this.getQueryParams(params);
              this.router.navigate([path], {
                queryParams: finalParams,
              });
            }
          });
      } else {
        if (
          notification?.data?.path &&
          notification?.data?.path?.indexOf('?') > -1
        ) {
          const path = notification?.data?.path?.split('?')[0];
          const query = notification?.data?.path?.split('?')[1];
          const params = query.split('&');
          const finalParams = this.getQueryParams(params);
          this.router.navigate([path], {
            queryParams: finalParams,
          });
        }
      }
    } else if (
      notification?.data?.alertId?.toString() ===
      AlertTypeEnum.ORDERED_PRODUCT_UNAVAILABLE
    ) {
      this.trackWarehouseCutsPushNotificationClick();
      if (customer?.customerNumber !== notification?.data?.customerNumber) {
        await this.loginService.switchToCustomer(
          notification?.data?.customerNumber,
          notification?.data?.departmentNumber ?? 0,
          notification?.data?.divisionNumber,
        );
      }
      const path = notification?.data?.path?.split('?')[0];
      const query = notification?.data?.path?.split('?')[1];
      const params = query?.split('&');
      const finalParams = this.getQueryParams(params);
      this.router.navigate([path], {
        queryParams: finalParams,
      });
    } else {
      if (routeToTmNoteTab) {
        this.handleNoteError();
      } else if (
        notification?.data?.path &&
        notification?.data?.path?.indexOf('?') > -1
      ) {
        const path = notification?.data?.path?.split('?')[0];
        const query = notification?.data?.path?.split('?')[1];
        const params = query.split('&');
        const finalParams = this.getQueryParams(params);
        this.router.navigate([path], {
          queryParams: finalParams,
        });
      }
    }
  }

  getQueryParams(query: any) {
    const finalParams = {};
    query?.forEach(key => {
      var tokens = key.split('=');
      finalParams[tokens[0]] = tokens[1];
    });
    return finalParams;
  }

  // need to be able to mock this method in unit tests by creating a wrapper
  getTransactionId() {
    return uuidv4();
  }

  getWarehouseDetails(detailsId: string) {
    return this.serviceHandler
      .get<any>(
        environment.alertsApiUrl +
          '/warehouse-cut-details/?detailsId=' +
          detailsId,
      )
      ?.pipe(
        filter(resp => !!resp),
        catchError(error => throwError(() => error)),
      );
  }

  updateWarehouseDetails(
    detailsId: string,
    customerNumber: number,
    divisionNumber: number,
    products: any[],
  ) {
    return this.serviceHandler
      .put<any>(environment.alertsApiUrl + '/warehouse-cut-details', {
        detailsId: detailsId,
        customerNumber: customerNumber,
        divisionNumber: divisionNumber,
        products: products,
      })
      ?.pipe(
        filter(resp => !!resp),
        catchError(error => throwError(() => error)),
      );
  }

  getAlertBatchIdByDetailsId(detailsId: string): Observable<string | null> {
    return this.store.select(selectAlertBatchIdByDetailsId(detailsId));
  }

  getCart(): Observable<CartState> {
    return this.store.select(CART_SELECTORS.selectCart);
  }

  trackWarehouseCutsPushNotificationClick() {
    this.analytics.trackWarehouseCutsPushNotificationClick();
  }

  private dispatchGuarded(action: any): void {
    if (action) {
      this.userService
        ?.isGuestUser$()
        ?.pipe(take(1))
        ?.subscribe(isGuestUser => {
          if (!isGuestUser) {
            this.store.dispatch(action);
          }
        });
    }
  }

  private showCustomerSwitchError(e) {
    console.error('Unable to switch customers', e);
    this.panAppState
      .feature$([FEATURES.split_global_user_app_error_modal.name])
      .pipe(take(1))
      .subscribe(async on => {
        if (on) {
          this.appErrorService.appModalErrorHandler(
            CustomerSwitchForbidden.title,
            CustomerSwitchForbidden.description,
            ['home'],
            e,
          );
        } else {
          this.messageStoreService.upsertMessage({
            type: MessageTypeEnum.error,
            read: false,
            toast: true,
            display: ToastMessages.customerSwitchForbidden,
          } as Message);
          this.router.navigate([PATHS.HOME]);
        }
      });
  }
}
