import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { SellerShowcaseActions } from '@usf/ngrx-product';
import { tap } from 'rxjs';
import { SellerShowcaseService } from '../services/seller-showcase.service';
import { getSortedDivisionalPromotions } from '../selector/helpers/seller-showcase.selector.helpers';

@Injectable({
  providedIn: 'root',
})
export class ClientSellerShowcaseEffects {
  loadDivisionalPromotionsProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SellerShowcaseActions.FetchSellerShowcaseResultSuccess),
        tap(action => {
          const divisionalPromotions = getSortedDivisionalPromotions(
            action?.response,
          );
          const productNumbersForPromos = divisionalPromotions
            ?.map(divisionalPromotion => divisionalPromotion.promotions)
            .filter(promotions => !!promotions)
            .map(promotions =>
              promotions?.map(promotion => promotion?.productNumber),
            );

          const seenProductNumbers = new Set<number>();
          for (const productNumbers of productNumbersForPromos) {
            const productNumbersToLoad = productNumbers?.filter(
              (productNumber): productNumber is number =>
                !seenProductNumbers.has(productNumber) &&
                typeof productNumber === 'number',
            );
            const uniqueProductNumbers = [...new Set(productNumbersToLoad)];
            uniqueProductNumbers?.forEach(item => seenProductNumbers.add(item));

            const batchCount = 500;
            for (let i = 0; i < uniqueProductNumbers?.length; i += batchCount) {
              this.service.loadProducts(
                uniqueProductNumbers.slice(i, i + batchCount),
              );
            }
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private service: SellerShowcaseService,
  ) {}
}
