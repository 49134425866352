import { ProductSummary } from '@usf/product-types';
import { VideoData } from '@usf/product-types/Product-Summary';

export interface VideoModel {
  videoId: string;
  subtType: string;
}

export const getCarouselVideos = (
  productSummary: ProductSummary,
): VideoModel[] => {
  let productVideos: { [key: string]: VideoData } =
    productSummary?.productAssets?.videos || {};

  return Object.entries(productVideos).map(([subtypeKey, vidData]) => {
    return {
      videoId: vidData.extSourceSystemId,
      subtType: subtypeKey,
    } as VideoModel;
  });
};

export const getSellerCarouselVideos = (
  productSummary: ProductSummary,
): VideoModel[] => {
  let productVideos: { [key: string]: VideoData } =
    productSummary?.productAssets?.sellerVideos || {};

  return Object.entries(productVideos).map(([subtypeKey, vidData]) => {
    return {
      videoId: vidData.extSourceSystemId,
      subtType: subtypeKey,
    } as VideoModel;
  });
};
