<ng-container>
  <app-usf-chip
    *ngFor="let tag of tagList?.slice(0, maxTags); let i = index"
    [attr.data-cy]="'product-tag-' + i"
    [type]="tag?.type"
    [ngClass]="hiddenTags.includes(i) ? 'hide' : ''"
    [clickable]="tag?.clickable || allTagsClickable"
    [iconConfig]="tag?.iconConfig"
    [iconUrl]="tag?.iconUrl"
    [popoverOptions]="tag?.popoverOptions"
    [textColor]="tag?.textColor"
    [backgroundColor]="tag?.backgroundColor"
    (clickEmitter)="productTagClick(tag)"
    (imageError)="hiddenTags.push(i)"
  >
    {{ tag?.content }}
  </app-usf-chip>

  <span
    *ngIf="maxTags && tagList?.length > maxTags"
    data-cy="product-tag-num-additional-tags-text"
    class="tag-list-span"
  >
    {{ '+' + (tagList?.length - maxTags) }}
  </span>

  <app-info-icon
    [attr.data-cy]="'info-icon-' + index"
    [infoIconEnabled]="infoIconEnabled"
    (click)="infoButtonClick()"
  ></app-info-icon>
</ng-container>
