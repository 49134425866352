<div
  *ngIf="slideContent.length > 0"
  class="img-carousel-container"
  [class.modal-view]="isModalView"
>
  <swiper-container
    (init)="setMainSwiperInstance($event.detail[0])"
    class="main-image"
    space-between="10"
    zoom="true"
    observer="true"
    resize-observer="true"
    observe-parents="true"
    (beforeinit)="beforeMainInit($event?.detail[0])"
    [class.quick-view]="isQuickView"
    [class.modal-view]="isModalView"
    [attr.data-cy]="'main-product-image-swiper-' + product.productNumber"
    (slidechange)="slideChange()"
    #mainImage
  >
    <swiper-slide class="main-image-slide" *ngFor="let content of slideContent">
      <ng-container *ngIf="content.mainImageUrl">
        <div
          class="main-image-container"
          [class.swiper-zoom-container]="isModalView"
        >
          <img
            (click)="handleMainImageClick()"
            src="{{ content.mainImageUrl }}"
            (error)="handleImageError(content)"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="content.videoId">
        <div class="main-image-container">
          <div class="video-player">
            <app-usf-video-player
              [videoID]="content.videoId"
              [sequence]="sequenceVideoId"
            ></app-usf-video-player>
          </div>
        </div>
      </ng-container>
    </swiper-slide>
  </swiper-container>
  <swiper-container
    (init)="setThumbsSwiperInstance($event.detail[0])"
    class="thumbnails"
    [ngClass]="
      isModalView
        ? 'thumb-zoom-swiper'
        : 'thumb-swiper-' + product.productNumber
    "
    watch-slides-progress="true"
    slides-per-view="auto"
    space-between="16px"
    observer="true"
    resize-observer="true"
    observe-parents="true"
    #thumbCarousel
  >
    <swiper-slide
      #thumbCarousel
      class="thumb-slide"
      *ngFor="let thumb of slideContent; let i = index"
    >
      <div class="thumb-container" [class.modal-view]="isModalView">
        <img
          class="thumb"
          src="{{ thumb.thumbnailUrl }}"
          (click)="onClickThumbCarouselImg()"
        />
        <div class="active-indicator"></div>
      </div>
    </swiper-slide>
  </swiper-container>
</div>
<div
  *ngIf="!slideContent?.length"
  class="image-placeholder main-image"
  [class.quick-view]="isQuickView"
>
  <ion-icon
    name="restaurant"
    class="no-image"
    data-cy="no-image-icon"
  ></ion-icon>
  <span
    *ngIf="showNoImageDescription"
    class="no-image-description medium-font-weight"
    data-cy="no-image-description"
  >
    {{ 'i18n.productDetail.productDetailViewer.noImage' | translate }}
  </span>
</div>
