import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailCarouselService {
  carouselPDPSubject: Subject<string> = new Subject<string>();
  carouselPDP$: Observable<string> = this.carouselPDPSubject.asObservable();

  nextSetActiveSlide(activeSlide: number) {
    this.carouselPDPSubject.next(activeSlide.toString());
  }
}
