import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerUser } from '../models/customer-user.model';
import { Store } from '@ngrx/store';
import { UserActions } from '@app/user/store/actions/action-types';
import { Customer, Department } from '@usf/customer-types';
import { selectedCustomer, selectedDepartment } from '@app/ngrx-customer/store';
import { CustomerUserUpdate } from '../models/customer-user-update.model';

@Injectable({
  providedIn: 'root',
})
export class ManageUsersService {
  currentCustomer$ = this.store.select(selectedCustomer);

  currentDepartment$ = this.store.select(selectedDepartment);

  customerUsersSubject = new BehaviorSubject<CustomerUser[]>([]);
  private customerUsersObservable$ = this.customerUsersSubject.asObservable();

  customerUsersCallCompleteSubject = new BehaviorSubject<boolean>(false);
  private customerUsersCallCompleteObservable$ =
    this.customerUsersCallCompleteSubject.asObservable();

  constructor(private store: Store) {}

  getCurrentCustomer$(): Observable<Customer> {
    return this.currentCustomer$;
  }

  getCurrentDepartment$(): Observable<Department> {
    return this.currentDepartment$;
  }

  loadAllUsersForCustomer(customerNumber?: number): void {
    this.store.dispatch(UserActions.getCustomerUsers());
  }

  setCustomerUsers(customerUsers: CustomerUser[]): void {
    this.customerUsersSubject.next([...customerUsers]);
  }

  getCustomerUsers$(): Observable<CustomerUser[]> {
    return this.customerUsersObservable$;
  }
  setCustomerUsersCallComplete(isCallComplete: boolean): void {
    this.customerUsersCallCompleteSubject.next(isCallComplete);
  }
  isCustomerUsersCallComplete$(): Observable<boolean> {
    return this.customerUsersCallCompleteObservable$;
  }

  updateUserAdmin(user: CustomerUser, isCustomerAdmin: boolean): void {
    const customerUserUpdate: CustomerUserUpdate = {
      userId: user.userId,
      customerAdmin: isCustomerAdmin,
    };
    this.store.dispatch(
      UserActions.updateCustomerUserAdmin({ customerUserUpdate }),
    );
  }

  removeUser(user: CustomerUser): void {
    const customerUserUpdate: CustomerUserUpdate = {
      userId: user.userId,
      deactivated: true,
    };
    this.store.dispatch(UserActions.removeCustomerUser({ customerUserUpdate }));
  }
}
