import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';

/**
 * @param value The number to format.
 * @param locale A locale code for the locale format rules to use.
 * @param currency A string containing the currency symbol or its name,
 * such as "$" or "Canadian Dollar". Used in output string, but does not affect the operation
 * of the function.
 * @param currencyCode The [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217)
 * currency code, such as `USD` for the US dollar and `EUR` for the euro.
 * Used to determine the number of digits in the decimal part.
 * @param digitsInfo Decimal representation options, specified by a string in the following format:
 * `{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}`. See `DecimalPipe` for more details.
 *
 * @returns The formatted currency value.
 *
 */
@Pipe({ name: 'formatCurrencyAndDecimalPipe' })
export class FormatCurrencyAndDecimalPipe implements PipeTransform {

    transform(
      value: number,
      digitsInfo: string = '1.2-2',
      locale: string = 'en-US',
      currencyCode: string = 'USD',
      display:
      | 'code'
      | 'symbol'
      | 'symbol-narrow'
      | string
      | boolean = 'symbol',
  ): string | null {
      if (value === null || value === 0.0 || value === undefined || value === 0 ) {
        return '$00.00';
      }
      return formatCurrency(
        value,
        locale,
        getCurrencySymbol(currencyCode, 'wide'),
        currencyCode,
        digitsInfo,
      );
    }
}
