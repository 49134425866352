import { Injectable } from '@angular/core';
import {
  getFilteredProductsSelector,
  productByIdSelector,
  productSelector,
  productWithAlternativesSelector,
} from '../../selectors/product.selectors';
import {
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { Product } from '../../models/product.model';
import { combineLatest, Observable, of } from 'rxjs';
import {
  ProductScoopActions,
  ProductStateService,
  getLoadingStateOfProducts,
} from '@usf/ngrx-product';
import { selectUserProfiles } from 'src/app/user/store';
import { ProductPricingService } from '@usf/ngrx-pricing';
import { Store } from '@ngrx/store';
import { PanAppState } from '@panamax/app-state';
import {
  CabRequest,
  ProductOpportunityRequest,
  ProductOpportunityResponse,
  ProductPropertiesEnum,
} from '@usf/product-types';
import {
  selectProductNumbersByOrderId,
  selectProductsById,
} from '../../selectors/products-from-order.selector';
import { ProductFilter } from '@shared/helpers/product-filter.helpers';
import { ProductFilteringEnum } from '@shared/constants/product-filtering.enum';
import { selectedCustomer } from '../../../ngrx-customer/store';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private productPricingService: ProductPricingService,
    private productStateService: ProductStateService,
    private panAppState: PanAppState,
    private store: Store<any>,
  ) {}

  /** Loads all Scoop eligible products into state based on user and customer settings */
  async loadAllProductScoops(): Promise<void> {
    // TODO this can be moved to @ngrx-product if we get user profiles added to PanAppState
    this.panAppState.user$
      .pipe(
        withLatestFrom(
          this.panAppState.customer$,
          this.store.select(selectUserProfiles),
        ),
        filter(([_, customer, profiles]) => !!customer && !!profiles),
        first(),
      )
      .subscribe(([_, customer, profiles]) => {
        const scoopNavDisabled = profiles.find(
          profile => profile.key === 'scoopNavDisabled',
        );
        if (
          !scoopNavDisabled ||
          scoopNavDisabled.value?.toUpperCase() !== 'Y'
        ) {
          this.productStateService.loadProductScoops();
        } else {
          ProductScoopActions.loadProductScoopsSuccess({
            productScoops: [],
          });
        }
      });
  }

  /***
   * Loads the data necessary to obtain a Product given a product number
   * Run this before using any product info selector
   * @param productNumber
   */
  loadProduct(productNumber: number): void {
    this.productStateService.loadProduct(productNumber);
    this.productPricingService.getPrice(productNumber);
  }

  loadProduct$(productNumber: number): Observable<boolean> {
    return combineLatest([
      this.productStateService.loadProduct$(productNumber),
      this.panAppState.session$,
    ]).pipe(
      map(([productLoaded, currentSession]) => {
        if (!currentSession.isGuest) {
          this.productPricingService.getPrice(productNumber);
        }
        return true;
      }),
    );
  }

  /***
   * Loads the data necessary to obtain a Product for a given product numbers list
   * Run this before using any product info selector
   * @param productNumbers
   */
  loadProducts(productNumbers: number[]): void {
    this.productStateService.loadProducts(productNumbers);
    this.panAppState.session$.pipe(first()).subscribe(currentSession => {
      if (!currentSession.isGuest) {
        this.productPricingService.getPrices(productNumbers);
      }
    });
  }

  loadProducts$(productNumbers: number[]): Observable<boolean> {
    return combineLatest([
      this.productStateService.loadProducts$(productNumbers),
      this.panAppState.session$,
    ]).pipe(
      map(([_, currentSession]) => {
        if (!currentSession.isGuest) {
          this.productPricingService.getPrices(productNumbers);
        }
        return true;
      }),
    );
  }

  /***
   * Loads the data necessary to obtain a Product for a given product numbers list
   * Run this to load product data without pricing into offline storage and state
   * TODO: This is WIP and not currently saving offlne storage.
   * The initial goal is to avoid uneeded pricing calls
   * @param productNumbers
   */
  loadProductsForOfflineStorage(productNumbers: number[]): void {
    this.productStateService.loadProducts(productNumbers);
  }

  loadProductsForOfflineStorage$(
    productNumbers: number[],
  ): Observable<boolean> {
    return this.productStateService.loadProducts$(productNumbers).pipe(
      map(() => {
        return true;
      }),
    );
  }

  /***
   * Returns a Product for the given product number
   * @param productNumber
   */
  getProduct(productNumber: number): Observable<Product> {
    return this.store
      .select(productByIdSelector(productNumber))
      .pipe(filter(product => product !== undefined));
  }

  /***
   * Returns a map of Product for the given list of product numbers
   * @param productNumbers
   */
  getProducts(productNumbers: number[]): Observable<Map<number, Product>> {
    return this.store.select(productSelector).pipe(
      filter(products => products?.size > 0),
      map(products =>
        productNumbers.reduce(
          (resultMap, productNumber) =>
            resultMap.set(productNumber, products.get(productNumber)),
          new Map<number, Product>(),
        ),
      ),
    );
  }

  /***
   * Returns a map of Product With Alternative/Substitution for the given list of product numbers
   * @param productNumbers
   */
  getProductsWithAlternatives(
    productNumbers: number[],
  ): Observable<Map<number, Product>> {
    return this.store.select(productWithAlternativesSelector).pipe(
      filter(products => products?.size > 0),
      map(products =>
        productNumbers.reduce(
          (resultMap, productNumber) =>
            resultMap.set(productNumber, products.get(productNumber)),
          new Map<number, Product>(),
        ),
      ),
    );
  }

  loadProductDetail(productNumber: number): void {
    this.productStateService.loadProductDetail(productNumber);
  }

  loadProductDetails(productNumbers: number[]): void {
    this.productStateService.loadProductDetails(productNumbers);
  }

  loadProductScoops(): void {
    this.productStateService.loadProductScoops();
  }

  loadRevenueManagement$(): Observable<boolean> {
    return this.productStateService.loadRevenueManagement$();
  }

  loadSneakPeek$(): Observable<boolean> {
    return this.productStateService.loadSneakPeek$();
  }

  loadCabProductNumbers$(cabRequest: CabRequest): Observable<boolean> {
    return this.productStateService.loadCabProductNumbers$(cabRequest);
  }

  loadSellerShowcase$() {
    return this.productStateService.loadSellerShowcase$();
  }

  waitFor<T>(signal$: Observable<any>) {
    return (source$: Observable<T>) =>
      new Observable<T>(observer => {
        // combineLatest emits the first value only when
        // both source and signal emitted at least once
        combineLatest([source$, signal$.pipe(first())]).subscribe(([v]) =>
          observer.next(v),
        );
      });
  }

  loadCABProducts(productNumbers: number[]): Observable<Map<number, Product>> {
    this.loadProducts(productNumbers);
    return this.store
      .select(
        getFilteredProductsSelector(productNumbers, ProductFilteringEnum.CAB),
      )
      .pipe(
        filter(products => {
          if (products?.length === 0) return false;
          return products?.every(
            val =>
              !!val.summary && !!val.pricing && val.pricing.loading === false,
          );
        }),
        map(products => {
          const productMap = new Map<number, Product>();
          products.forEach(product => {
            productMap.set(product.productNumber, product);
          });
          return productMap;
        }),
        take(1),
      );
  }

  getYMANProducts(productNumbers): Observable<Product[]> {
    this.loadProducts(productNumbers);
    return this.store
      .select(
        getFilteredProductsSelector(
          productNumbers,
          ProductFilteringEnum.YOU_MAY_ALSO_NEED,
        ),
      )
      .pipe(
        filter(products => {
          if (products?.length === 0) return false;
          return products?.every(
            val =>
              !!val.summary && !!val.pricing && val.pricing.loading === false,
          );
        }),
        take(1),
      );
  }

  getTopSellerProducts(productNumbers): Observable<Product[]> {
    this.loadProducts(productNumbers);
    return combineLatest([
      this.store.select(selectedCustomer),
      this.getProducts(productNumbers),
    ]).pipe(
      distinctUntilChanged(),
      map(([selectedCustomer, products]) => {
        return Array.from(products.values()).filter(
          product => !!product && !product.notFound,
        );
      }),
    );
  }

  postProductOpportunity$(
    body: ProductOpportunityRequest,
  ): Observable<ProductOpportunityResponse> {
    // Pending Add method in ngrx - Temporal Mocking
    return of({
      statusCode: 200,
      description: 'This is a product Opportunity',
      opportunityId: '1234',
    });
  }

  filterRecommendations(products: Product[]): Product[] {
    let recommendedProducts: Product[] = [];
    products.forEach(product => {
      if (!!product) {
        if (
          !(
            product.summary?.properties?.has(
              ProductPropertiesEnum.outOfStock,
            ) ||
            product.summary?.properties?.has(
              ProductPropertiesEnum.specialOrder,
            ) ||
            product.summary?.properties?.has(ProductPropertiesEnum.dwo) ||
            product.summary?.properties?.has(ProductPropertiesEnum.direct) ||
            product.summary?.properties?.has(
              ProductPropertiesEnum.discontinued,
            ) ||
            product.summary?.proprietaryInd === 'Y' ||
            product.inventory?.cashCarryIndicator === '2' ||
            product.inventory?.cashCarryIndicator === '3' ||
            product.inventory?.productStatus === '2'
          )
        ) {
          recommendedProducts = [...recommendedProducts, product];
        }
      }
    });

    return recommendedProducts;
  }

  /***
   * Returns a list of products to be displayed in the TM notes list page.
   * @param productNumbers
   */
  getTMNotesProducts(productNumbers): Observable<Product[]> {
    this.loadProducts(productNumbers);
    const selectedCustomer$ = this.store.select(selectedCustomer);
    const productsWithAlternatives$ =
      this.getProductsWithAlternatives(productNumbers);
    return combineLatest([selectedCustomer$, productsWithAlternatives$]).pipe(
      distinctUntilChanged(),
      switchMap(([selectedCustomer, products]) => {
        return of(
          Array.from(products.values()).filter(product => {
            if (!!product) {
              return ProductFilter.applyFilter(
                product,
                selectedCustomer,
                ProductFilteringEnum.TM_NOTES,
              );
            }
            return false;
          }),
        );
      }),
      finalize(() => {}),
    );
  }

  getProductReviewByNumbers$(
    productNumbers: Number[],
    productKey: string | undefined,
  ) {
    return this.store.select(selectProductsById(productNumbers, productKey));
  }

  getProductNumbersById$(orderId: string) {
    return this.store.select(selectProductNumbersByOrderId(orderId));
  }

  selectLoadingStateOfProducts(productNumbers: number[]) {
    return this.store.select(getLoadingStateOfProducts(productNumbers));
  }
}

export function initializeProductScoop(productService: ProductService): any {
  const scoops = () => productService.loadAllProductScoops();
  return scoops;
}
