import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import moment from 'moment';
import { ProductOpportunityRequest } from '@usf/product-types';
import { PlatformService } from '@panamax/app-state';
import { IonNav } from '@ionic/angular';
import { TitleDropdownComponent } from './title-dropdown/title-dropdown.component';

@Component({
  selector: 'app-seller-create-opportunity-form2',
  templateUrl: './seller-create-opportunity-form2.component.html',
  styleUrls: ['./seller-create-opportunity-form2.component.scss'],
})
export class SellerCreateOpportunityForm2Component
  implements OnInit, OnChanges {
  @Output() updateFormValidity = new EventEmitter<any>();

  createOpportunityForm: UntypedFormGroup = new UntypedFormGroup({});
  productOpportunity: ProductOpportunityRequest;
  titleOptions = [
    'Picked For You',
    'You Asked About',
    "I Thought You'd Like",
    'We Talked About',
    'From Your Seller',
    'From Your Specialists',
    'Following Up',
  ];
  @Input() selectedTitle: string;

  minDate = moment().format();
  maxDate = moment().add(1, 'year').format();
  defaultValidThrough = new Date();
  selectedValidThrough = moment().add(45, 'day').utc().format();

  constructor(
    private fb: UntypedFormBuilder,
    readonly platformService: PlatformService,
    public nav: IonNav,
  ) {}

  ngOnInit() {
    this.createOpportunityForm = this.fb.group({
      noteTitle: ['', Validators.required],
      noteToCustomer: ['', Validators.required],
      validThrough: [
        moment().add(45, 'day').utc().toDate(),
        Validators.required,
      ],
    });
    this.checkFormValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!this.selectedTitle) {
      this.createOpportunityForm?.controls['noteTitle'].setValue(
        this.selectedTitle,
      );
    }
    this.checkFormValues();
  }

  checkFormValues() {
    // Memory Leak
    this.createOpportunityForm?.valueChanges.subscribe(controls => {
      if (this.createOpportunityForm.valid) {
        this.formatCreateOpportunityForm();
      }
      this.updateFormValidity.emit({
        isValid: this.createOpportunityForm.valid,
        form: this.productOpportunity,
      });
    });
  }

  updateFormDate(newDate) {
    this.selectedValidThrough = moment(newDate.detail.value).format();
    this.createOpportunityForm.controls['validThrough'].setValue(
      moment(newDate.detail.value).utc(),
    );
    this.createOpportunityForm.controls['validThrough'].markAsDirty();
  }

  formatCreateOpportunityForm() {
    const title = this.createOpportunityForm.get('noteTitle').value.trim();
    const noteToCustomer = this.createOpportunityForm
      .get('noteToCustomer')
      .value.trim();
    const validUntil = this.createOpportunityForm.get('validThrough').value;

    this.productOpportunity = {
      title,
      noteToCustomer,
      validUntil,
    } as ProductOpportunityRequest;
  }

  get form() {
    return this.createOpportunityForm?.controls;
  }

  get isNoteToCustomerInvalid() {
    return (
      (this.form?.noteToCustomer?.touched ||
        this.form?.noteToCustomer?.dirty ||
        this.form?.validThrough?.dirty) &&
      this.form?.noteToCustomer.invalid
    );
  }

  async openTitleOptionsInTouchMode() {
    await this.nav.push(TitleDropdownComponent, {
      titles: this.titleOptions,
    });
  }
}
