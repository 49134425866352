import { Component, Input } from '@angular/core';
import { PoDate } from '@usf/product-types';

@Component({
  selector: 'app-seller-detail-decription-upcoming',
  templateUrl: './seller-detail-decription-upcoming.component.html',
  styleUrls: ['./seller-detail-decription-upcoming.component.scss'],
})
export class SellerDetailDecriptionUpcomingComponent {
  @Input() poDates: PoDate[];
  @Input() productNumber: number;

  constructor() {}
}
